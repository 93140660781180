
import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate, useParams } from "react-router-dom";
import '../../components/CSS/Style.css';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

const Segment = () => {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [error, setError] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);

    const fetchSegmentData = async () => {
        try {
                setRows([]);
                setError(true);
                const api = await axios.post(
                    `${process.env.REACT_APP_BASE_URL_API}/api/masterData/getSmsMasterList`,
                    {},
                    { withCredentials: true }
                );
                const response = api.data;
                if (response && response.length > 0) {
                    const formattedRows = response.map((item) => {
                        
                        return {
                            ...item,
                        };
                    });
                    
                    setRows(formattedRows);
                    setFilteredRows(formattedRows);
                    setError(false);
                    setColumns([
                        { field: '_id', headerName: 'ID', width: 250 },
                        { field: 'name', headerName: 'SMS Name', width: 350 },
                    ]);
                } else {
                    setRows([]);
                    setFilteredRows([]);
                    setError('No data found for this segment.');
                }
        } catch (error) {
            console.log('Error fetching segment data:', error);
            setError('Failed to fetch data.');
        }
    }

    useEffect(() => {
        fetchSegmentData();
    }, [])

    return (
        <div className="app">
            <NavbarComp />
            <div className="content overflow-hidden">
                <h3 className="fw-bold">Sms</h3>
                <div className="container-fluid px-4 pt-3">
                    <div className="row justify-content-end my-3">
                        {error ? (
                                <p>No data found</p>
                            ) : (
                            <DataGrid
                                rows={rows} 
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                getRowId={(row) => row._id}
                                disableSelectionOnClick
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Segment