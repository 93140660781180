import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { showToast } from 'screens/LeadGeneration/Helpers/ToastHelper';
import { CheckCircle } from '@mui/icons-material';
import { BiCopy } from 'react-icons/bi';
import useClipboard from 'screens/LeadGeneration/Hooks/CopyText';
const WebPagesList = ({ form,programId,webpagemodal, setwebpagemodal,formId,pageId,setform }) => {
    const [formData, setFormData] = useState({ name: '', url: '' });
    const [Verified, setVerified] = useState(false);
    const clipboard=useClipboard();
    useEffect(() => {
        setFormData({name:form.pageName||'',url:form.url||''});
    }, [form]);
    
    const handleVerify=async(e) => {
        e.preventDefault();
        if(formData.url && formData.name){
            const payload={programId,channel:'web_page_label',builder:'reachBuilder',webpageUrl:formData.url , webpageName:formData.name};
            const verifiedPage = await fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                credentials: 'include',
            });
            if(verifiedPage.ok){
                showToast('Website added successfully!','success');
                setVerified(true);
                setform(formData);
                setwebpagemodal(false)
            }
            else{
                showToast('Form or Tracking script not found in page','error');
                setVerified(false);
            }    
        }
        else{
            showToast('Enter Valid name and url','error');
        }
    }
    const handlewebpagemodal = async () => {
        setwebpagemodal(!webpagemodal);
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const copyForm = ()=>{
        const url = `<iframe src="${process.env.REACT_APP_PUBLIC_URL}/UserPreview/${formId}" width="400" height="450"><p>Your browser does not support iframes.</p></iframe>`;
        clipboard(url,'Form')
    }
    const copyTracking = () =>{
        const url = `<script src="https://cdnjs.cloudflare.com/ajax/libs/uuid/8.2.0/uuid.min.js" integrity="sha512-X0CJvcClD8EXFn5Oq8BD5lIsznKDfxb0Sf9Wr8MCCTyAEWaAAxr0Jvm3qyofsmN7eSHMZ0PPQGjVKroLAfexrA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
        <script src="${process.env.REACT_APP_BASE_URL_API}/pages/assets/trackingscript.js?pageId=${pageId}&host=${process.env.REACT_APP_BASE_URL_API}"></script>`;
        clipboard(url,'Tracking Script')
    }

    return (
        <>
            <Modal show={webpagemodal} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={handlewebpagemodal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add External Website</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="formWebsiteName">
                        <Form.Label>Website Name</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="Enter website name"
                        name="name"
                        value={formData.name||''}
                        onChange={handleChange}
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formWebsiteURL">
                        <Form.Label>Website URL</Form.Label>
                        <InputGroup>
                            <Form.Control
                            type="url"
                            placeholder="Enter website URL"
                            name="url"
                            value={formData.url||''}
                            onChange={handleChange}
                            required
                            />
                            <Button variant="primary" className='disabled'>{Verified?(<><CheckCircle></CheckCircle> verified</>  ):("Not verified")}</Button>
                        </InputGroup>
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={handleVerify}>
                        Submit
                    </Button>
                    
                    <Button variant="primary" onClick={copyTracking}>
                        tracking script
                        <BiCopy></BiCopy>
                    </Button>
                    <Button variant="primary" onClick={copyForm}>
                        Form
                        <BiCopy></BiCopy>
                    </Button>
                    
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default WebPagesList;
