import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BsEye } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavbarComp from "../../../components/NavbarComp";
import HeaderComp from '../../../components/HeaderComp';
import BasicSpeedDial from "../../../components/SpeedDial";
import ProgressStepper from "../../EventProgram/ProgressStepper";
import '../../../components/CSS/NewsLetter.css'
import { useGlobal } from "../../../context/GlobalProvider";
import IndustryAndFrequency from "./IndustryAndFrequency";
import LogoAndBanner from "./LogoAndBanner";
import BasicSection from "./BasicSection";
const steps = [
    {
        label: '1',
        name: 'step 1',
        className: 'step1 custom-step',
        subSteps: [
            { label: 'Step 1.1' },
            { label: 'Step 1.2' },
            { label: 'Step 1.3' },
            { label: 'Step 1.4' },
        ],
        stepHead: 'Basic Information',
        stepText: 'Provide essential details about your business and event to enhance marketing automation with our AI.'
    },
    {
        label: '2',
        name: 'step 2',
        className: 'step2 custom-step',
        subSteps: [
            { label: 'Step 2.1' },
            { label: 'Step 2.2' },
            { label: 'Step 2.3' },
            { label: 'Step 2.4' },
        ],
        stepHead: 'Journey',
        stepText: 'Guide, remind, and follow up with attendees through every event stage.'
    },
    {
        label: '3',
        name: 'step 3',
        className: 'step3 custom-step',
        subSteps: [
            { label: 'Step 3.1' },
            { label: 'Step 3.2' },
            { label: 'Step 3.3' },
            { label: 'Step 3.4' },
        ],
        stepHead: 'Results/Outcomes',
        stepText: 'Evaluate success and impact from event awareness to attendance and follow-up communication.'
    },
];

const NewsLetter = () => {
    const navigate = useNavigate();
    const [tab, setactivetab] = useState(0);
    const tabname = ['Industry & Frequency', 'Logo & Banner', 'Basic Sections of NewsLetter'];
    const [currentStep, setCurrentStep] = useState(0);
    const [currentSubStep, setCurrentSubStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const { ToggleAnimation } = useGlobal();
    const [newsLetterDetails, setNewsLetterDetails] = useState({
        industry: '',
        subIndustry: '',
        frequency: '',
        sendDay: '',
        week: '',
        month: '',
        newsLetterName: '',
        logo: '',
        newsletterBanners: '',
        BasicSections: [],
        url: '',
        cms: '',
        generateByAi: {
            blog: null,
            industryTrends: null
        },
        latestBlogPrompt: '',
        industryTrendsPrompt: ''
    });

    const [logoAndBanner, setLogoAndBanner] = useState({ logo: true, banner: true });
    const handleNextSubStep = () => {
        const nextSubStep = currentSubStep + 1.5;
        if (nextSubStep < steps[currentStep].subSteps.length) {
            setCurrentSubStep(nextSubStep);
        } else {
            handleNextStep();
        }
    };

    const handlePreviousSubStep = () => {
        const previousSubStep = currentSubStep - 1.5;
        if (previousSubStep >= 0) {
            setCurrentSubStep(previousSubStep);
        } else {
            handlePreviousStep();
        }
    };

    const handleNextStep = () => {
        const nextStep = currentStep + 1;
        if (nextStep < steps.length) {
            setCurrentStep(nextStep);
            setCurrentSubStep(0);
        }
    };

    const handlePreviousStep = () => {
        const previousStep = currentStep - 1;
        if (previousStep >= 0) {
            setCurrentStep(previousStep);
            setCurrentSubStep(steps[previousStep].subSteps.length - 1);
        }
    };

    const handleSubmit = () => {
        console.log('Form submitted!');
    };

    const tabchange = (direction) => {
        if (direction === 'up' && tab > 0) {
            setactivetab(tab - 1);
        } else if (direction === 'down' && tab < 3) {
            setactivetab(tab + 1);
        }
    };
    const showToast = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
    };

    const handleCheckFields = () => {
        if (tab === 0) {
            if (newsLetterDetails.industry === '') {
                showToast('Please select Industry');
                return false;
            } else if (newsLetterDetails.subIndustry === '' || newsLetterDetails.subIndustry === null) {
                showToast('Please select Sub-Industry');
                return false;
            } else if (newsLetterDetails.frequency === '') {
                showToast('Please select Frequency');
                return false;
            } else if (newsLetterDetails.sendDay === '') {
                showToast('Please select Send Day');
                return false;
            } else if (newsLetterDetails.week === '') {
                showToast('Please select Week');
                return false;
            }else if (newsLetterDetails.frequency === 'Quarterly' && newsLetterDetails.month === '') {
                showToast('Please select Frequency Month');
                return false;
            }
        } else if (tab === 1) {
            if (newsLetterDetails.newsLetterName === '') {
                showToast('Please select News Letter Name');
                return false;
            } else if (newsLetterDetails.logo === '' && logoAndBanner.logo) {
                showToast('Please Upload Logo');
                return false;
            } else if (newsLetterDetails.newsletterBanners === '' && logoAndBanner.banner) {
                showToast('Please Upload Banner');
                return false;
            }
        }

        return true;
    };

    const handleEventFormSubmit = async () => {
        if (tab === 2) {
            if (newsLetterDetails.generateByAi.blog === null) {
                showToast('Please Select Blog Type');
                return false;
            } else if (newsLetterDetails.generateByAi.blog === false && newsLetterDetails.url === '') {
                showToast('Please  Enter Blog URL');
                return false;

            } else if (newsLetterDetails.generateByAi.blog === false && newsLetterDetails.cms === '') {
                showToast('Please Select CMS');
                return false;
            } else if (newsLetterDetails.generateByAi.blog === true && newsLetterDetails.latestBlogPrompt === '') {
                showToast('Please Select the Topic for Blog');
                return false;
            }

            if (newsLetterDetails.generateByAi.industryTrends === null) {
                showToast('Please Select the Industry Trends Type');
                return false;
            } else if (newsLetterDetails.generateByAi.industryTrends === true && newsLetterDetails.industryTrendsPrompt === '') {
                showToast('Please Select the Topic for Industry Trends');
                return false;
            }else if(newsLetterDetails.generateByAi.industryTrends === false){
                showToast('Please Select Generate By AI for Industry Trends');
                return false;
            }
        }   
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) {
                alert('User not found. Please log in.');
                return;
            }

            const formData = new FormData();
            formData.append('newsLetterData', JSON.stringify(newsLetterDetails));
            formData.append('tab', tab);
            formData.append('createdBy', user.id)

            if (newsLetterDetails.logo) {
                formData.append('logo', newsLetterDetails.logo);
            }
            if (newsLetterDetails.newsletterBanners) {
                formData.append('newsletterBanners', newsLetterDetails.newsletterBanners);
            }

            // Save newsletter details
            const submitResponse = await fetch(`${process.env.REACT_APP_BASE_URL_API}/newsletter/saveDetails`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
            const submitData = await submitResponse.json();

            if (submitData.result !== 1) {
                const errorMessage = submitData.message
                    ? JSON.stringify(submitData.message)
                    : 'Failed to save newsletter details.';
                alert(errorMessage);
                return;
            }

            if (!submitData.programId) {
                alert('Program ID is missing. Failed to save program details.');
                return;
            }

            console.log('Newsletter saved successfully:', submitData);

            // Create a subscriber segment
            const segmentPayload = {
                is_published: 1,
                name: `${newsLetterDetails.newsLetterName} ${submitData.programId}`,
                description: "",
                alias: `${newsLetterDetails.newsLetterName}_${submitData.programId}`,
                created_by: user.id,
            };

            const segmentResponse = await fetch(`${process.env.REACT_APP_BASE_URL_API}/segments/createSegment`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(segmentPayload),
            });
            const segmentData = await segmentResponse.json();

            if (!segmentData.segmentId) {
                alert('Failed to create subscriber segment.');
                return;
            }

            console.log('Segment created successfully:', segmentData);

            // Save newsletter builder
            const builderPayload = {
                newsLetterData: newsLetterDetails,
                segmentId: segmentData.segmentId,
                tab: tab,
                programId: submitData.programId,
            };

            const builderResponse = await fetch(`${process.env.REACT_APP_BASE_URL_API}/newsletter/saveNewsLetterBuilder`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(builderPayload),
            });
            const builderData = await builderResponse.json();

            if (builderData.result === 1) {
                console.log('Newsletter builder saved successfully:', builderData);
                ToggleAnimation(true);
                navigate(`/journey/${submitData.programId}`);
            } else {
                alert('Failed to save newsletter builder.');
            }
        } catch (error) {
            console.error('Failed to submit newsletter:', error);
            alert('An unexpected error occurred. Please try again.');
        }
    };



    return (
        <div className="app">
            <NavbarComp />
            <div className="content overflow-hidden">
                <HeaderComp></HeaderComp>
                <ToastContainer />
                <div className="container-fluid px-4 pt-3">
                    <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">Basic News Letter Information</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        {/* Tab Content Start */}
                        <div className="offcanvas-body">
                            <ul className="nav nav-tabs" id="PreviewTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="objectives-tab" data-bs-toggle="tab" href="#objectives" role="tab">I and F</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="Speaker-tab" data-bs-toggle="tab" href="#Speaker" role="tab">Logo and Banner</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="Goals-tab" data-bs-toggle="tab" href="#Goals" role="tab">Sections</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active container-fluid" id="objectives" role="tabpanel" aria-labelledby="objectives-tab">
                                    <div className="row mb-3">
                                        {newsLetterDetails.industry !== '' &&
                                            <div className="col-md-12 mt-4 ms-2">
                                                <label className="form-label">Industry</label>
                                                <input name="servicename" type="text" value={newsLetterDetails.industry} className="form-control" readOnly />
                                            </div>
                                        }
                                        {newsLetterDetails.subIndustry !== '' &&
                                            <div className="col-md-12 mt-4 ms-2">
                                                <label className="form-label">Industry</label>
                                                <input name="servicename" type="text" value={newsLetterDetails.subIndustry} className="form-control" readOnly />
                                            </div>
                                        }
                                        <div className="col-md-12 mt-4 ms-2">
                                            <ul className="frequency-list-styles">
                                                {newsLetterDetails.frequency !== '' && <li>Frequency : {newsLetterDetails.frequency}</li>}
                                                {newsLetterDetails.sendDay !== '' && <li>day : {newsLetterDetails.sendDay}</li>}
                                                {newsLetterDetails.week !== '' && <li>Week : {newsLetterDetails.week}</li>}
                                                {newsLetterDetails.month !== '' && newsLetterDetails.frequency === 'Quarterly' && <li>Month : {newsLetterDetails.month}</li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="Speaker" role="tabpanel" aria-labelledby="Speaker-tab">
                                    {newsLetterDetails.industry !== '' &&
                                        <div className="col-md-12 mt-4 ms-2">
                                            <label className="form-label">News Letter Name</label>
                                            <input name="servicename" type="text" value={newsLetterDetails.newsLetterName} className="form-control" readOnly />
                                        </div>
                                    }
                                    {newsLetterDetails.logo !== '' &&
                                        <>
                                            <label className="form-label">Logo</label>
                                            <div className="logo-preview">
                                                <img
                                                    src={newsLetterDetails.logo !== '' && URL.createObjectURL(newsLetterDetails.logo)}
                                                    alt="logo"
                                                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                />
                                            </div>
                                        </>
                                    }
                                    {newsLetterDetails.newsletterBanners !== '' &&
                                        <div className='newsletter-banner'>
                                            <label className="form-label">banner</label>
                                            <img src={newsLetterDetails.newsletterBanners !== '' && URL.createObjectURL(newsLetterDetails.newsletterBanners)} alt="Generated Banner" className='generated-banner' />
                                        </div>
                                    }
                                </div>
                                <div className="tab-pane fade pt-4 container-fluid" id="Goals" role="tabpanel" aria-labelledby="Goals">
                                    <div className="row mb-3">
                                        {newsLetterDetails.BasicSections.length > 0 && (
                                            <div className="col-md-12 mt-4 ms-2">
                                                <label className="form-label">Basic Sections</label>
                                                <ul className="frequency-list-styles">
                                                    {newsLetterDetails.BasicSections?.map((sections, index) => (
                                                        <li key={index}>{sections}</li>
                                                    ))}
                                                </ul>
                                                <label className="form-label">Url</label>
                                                <input name="servicename" type="text" value={newsLetterDetails.url} className="form-control" readOnly />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tab Content End */}
                    </div>
                    <div className="row justify-content-around">
                        <div className="col-md-2">
                            <div style={{ height: '63vh' }} className="bg-light rounded border border-secondary border-2">
                                <ProgressStepper
                                    steps={steps}
                                    currentStep={currentStep}
                                    currentSubStep={currentSubStep}
                                    setCurrentStep={setCurrentStep}
                                    setCurrentSubStep={setCurrentSubStep}
                                />
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div style={{ height: '63vh' }} className="bg-light rounded  border border-secondary border-2 overflow-hidden position-relative">
                                <div className="container ms-3 pt-4">
                                    <div className="row align-items-center">
                                        <div className="col-md-11">
                                            <div className="mb-0 alert bg-secondary text-success p-2 fw-bold tab-name">{tabname[tab]}</div>
                                        </div>
                                        <div className="col-md-1 text-center">
                                            <button className="rounded-circle btn btn-secondary custom-hover" style={{ minWidth: 'unset' }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop">
                                                <BsEye strokeWidth={0.5}></BsEye>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row mt-4 newsletter-tabs">
                                        <div className="col-md-11">
                                            <div className={tab === 0 ? "d-block" : "d-none"}>
                                                <IndustryAndFrequency newsLetterDetails={newsLetterDetails} setNewsLetterDetails={setNewsLetterDetails} />
                                            </div>
                                            <div className={tab === 1 ? "d-block" : "d-none"}>
                                                <LogoAndBanner newsLetterDetails={newsLetterDetails} setNewsLetterDetails={setNewsLetterDetails} setLogoAndBanner={setLogoAndBanner} />
                                            </div>
                                            <div className={tab === 2 ? "d-block" : "d-none"}>
                                                <BasicSection newsLetterDetails={newsLetterDetails} setNewsLetterDetails={setNewsLetterDetails} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-11">
                                        </div>
                                        <div className="col-md-1">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="d-flex flex-column" style={{ right: '15px', position: 'absolute', bottom: '15px' }}>
                                    <button className="btn btn-light border rounded-circle" style={{ width: '41px', height: '41px' }} onClick={() => tabchange('up')} ><IoIosArrowUp></IoIosArrowUp></button>
                                    <button
                                        className="btn btn-light border rounded-circle mt-3" style={{ width: '41px', height: '41px' }}
                                        onClick={() => tabchange('down')}
                                        disabled={
                                            (tab === 0) ||
                                            (tab === 1) ||
                                            (tab === 2)
                                        }
                                    >
                                        <IoIosArrowDown></IoIosArrowDown>
                                    </button>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="d-flex flex-column" style={{ right: '15px', position: 'absolute', bottom: '15px' }}>
                                    <button
                                        className="btn btn-light border rounded-circle"
                                        style={{ width: '41px', height: '41px' }}
                                        onClick={() => {
                                            if (currentStep === 0 && currentSubStep === 0) return;
                                            handlePreviousSubStep();
                                            tabchange('up');
                                        }}
                                    >
                                        <IoIosArrowUp></IoIosArrowUp>
                                    </button>
                                    <button
                                        className="btn btn-light border rounded-circle mt-3"
                                        style={{ width: '41px', height: '41px' }}
                                        onClick={() => {
                                            if (handleCheckFields()) {
                                                if (currentStep === steps.length - 1 && currentSubStep === steps[currentStep].subSteps.length - 1) {
                                                    handleSubmit();
                                                } else {
                                                    handleNextSubStep();
                                                }
                                                tabchange('down');
                                            }
                                        }}
                                        disabled={
                                            (tab === 2) ||
                                            (currentStep === steps.length - 1 && currentSubStep === steps[currentStep].subSteps.length - 1)
                                        }
                                    >
                                        <IoIosArrowDown></IoIosArrowDown>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end my-3">
                        <div className="col">
                            <BasicSpeedDial></BasicSpeedDial>
                        </div>
                        <div className="col-md-6">
                            <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                                <button
                                    className="btn btn-secondary me-2 btn-full"
                                    onClick={() => navigate("/program")}
                                    style={{ border: '2px solid #028897' }}
                                    type="button"
                                >
                                    cancel
                                </button>
                                <button
                                    className={`btn text-white btn-full ${tab !== 2 ? "disabled btn-grey" : "btn-success"}`}
                                    onClick={handleEventFormSubmit}

                                    type="button"
                                    disabled={loading}
                                >
                                    {loading ? 'Loading...' : 'Start NewsLetter'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default NewsLetter;
