import React from "react";

const Pagination = ({ currentPage, totalPages, rowsPerPage, onRowsPerPageChange, onPageChange }) => {
    // Function to generate the visible page numbers
    const createPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 3;

        if (totalPages > maxVisiblePages) {
            let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
            let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

            if (endPage - startPage + 1 < maxVisiblePages) {
                startPage = Math.max(endPage - maxVisiblePages + 1, 1);
            }
            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i);
            }


            //   if (startPage > 1) {
            //     pageNumbers.unshift("...");
            //   }
            if (endPage < totalPages) {
                pageNumbers.push("...");
            }
        } else {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        }

        return pageNumbers;
    };

    return (
        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
            {/* Rows per page selection */}
            <div className="mastertable-rows">
                Rows per page:{" "}
                <select className="select-rows" value={rowsPerPage} onChange={onRowsPerPageChange}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                </select>
            </div>

            {/* Pagination buttons and page numbers */}
            <div>
                {/* Previous button */}
                <button
                    className="navigation-btn"
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    &lt; Previous
                </button>

                {/* Page numbers */}
                {createPageNumbers().map((page, index) => {
                    return page === "..." ? (
                        <span key={index} className="lightblue-pageno">
                            {page}
                        </span>
                    ) : (
                        <button
                            key={index}
                            className={`lightblue-pageno ${currentPage === page ? "currentpage" : ""}`}
                            onClick={() => onPageChange(page)}
                        >
                            {page}
                        </button>
                    );
                })}

                {/* Next button */}
                <button
                    className="navigation-btn"
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next &gt;
                </button>
            </div>
        </div>
    );
};

export default Pagination;
