import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";

const Trackingscript = ({settingType}) => {
    const scriptText = "script here";

    const handleCopy = () => {
        navigator.clipboard.writeText(scriptText)
            .then(() => {
                alert("Text copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
                alert("Failed to copy text. Please try again.");
            });
    };

    return (
        <div
            className="overflow-hidden position-relative"
            style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '91vh'
            }}>
            <div className="container">
                <div className="row justify-content-center" style={{ paddingTop: '20px' }}>
                    <h3>Tracking Script</h3>
                    <br />
                    <br />
                    <div
                        style={{
                            border: '1px solid #ccc',
                            padding: '10px',
                            borderRadius: '5px',
                            width: '70%',
                            textAlign: 'center',
                            marginBottom: '10px',
                            cursor: 'default',
                        }}
                    >
                        {scriptText}
                    </div>
                    <button
                        onClick={handleCopy}
                        style={{
                            padding: '8px 15px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Copy
                    </button>
                </div>
            </div>
            <div className="position-absolute" style={{ bottom: '60px', left: '32px' }}>

            </div>
        </div>
    );
};

export default Trackingscript;
