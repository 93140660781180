import React from 'react'
import CardComp from './journeyModels/CardComp';

function RenderCards({CardData, CardType,handleChannelModel}) {
    const getClassName = (data) => {
        if(data.find(item=>['lead_list_label'].includes(item.channel)))
            return 'custom-margin';
        if(data.find(item=>['lead_score','qualified_leads_list_label'].includes(item.channel)) && (data.length === 2))
            return 'custom-margin double';
        if (data.length === 1) return 'single';
        if (data.length === 2) return 'double';
        if (data.length > 2) return 'multiples';
        return '';
    };
    return (
        <div className={`col-sm-3 ${CardType} card-center ${getClassName(CardData)} `}>
            {CardData.map((data, index) => (
                <div className='card-wrap' key={`${index}`} id={`box-${data.id}`}>
                    <CardComp
                        CardData={data}
                        handleChannelModel={handleChannelModel}
                    />
                </div>
            ))}
        </div>
    )
}

export default RenderCards