import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './components/CSS/Custom.scss';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import NoMatch from './screens/404';
import Home from './screens/home';
import EventProgram from './screens/eventProgram';
import Program from './screens/Program';
import MasterData from './screens/masterData';
import Setting from "./screens/Settings";
import Outcome from './screens/Outcome/Outcome';
import Editor from './screens/formBuilder/Editor';
import Form from './screens/formBuilder/Form';
import FormsList from './screens/formBuilder/FormsList';
import MainJourney from './screens/Journey';
import EmailTemplates from './screens/EmailBuilder/EmailTemplates';
import EmailTemplateEditor from './screens/EmailBuilder/EmailTemplateEditor';
import MyEmailTemplates from './screens/EmailBuilder/MyEmailTemplates';
import MyEmailTemplateEditor from './screens/EmailBuilder/MyEmailTemplateEditor';
import Login from './screens/Login/Login';
import ProtectedRoute from './components/Auth/ProtectedRoute';
//Master Routes
import Contact from './screens/masterData/Contact';
import ContactView from './screens/masterData/ContactView';
import Lead from './screens/masterData/Lead';
import Segment from './screens/masterData/Segment';
import Company from './screens/masterData/Company';
import Asset from './screens/masterData/Asset';
import Email from './screens/masterData/Email';
import Page from './screens/masterData/Page';
import Forms from './screens/masterData/Form';
import Whatsapp from './screens/masterData/Whatsapp';
import Sms from './screens/masterData/Sms';

import { useParams } from 'react-router-dom';
import { GlobalProvider } from './context/GlobalProvider';
import JourneyEdit from './screens/Journey/JourneyEdit';
import DisplayLp from './screens/PageBuilder/DisplayLp';
import UserPreview from './screens/formBuilder/UserPreview';
import Savetemplate from './screens/PageBuilder/Savetemplate';
import JourneyBytype from './screens/JourneyBytype';
// NewsLetter
import NewsLetter from './screens/NewsLetter/InfoGathering/NewsLetter';
import Sectionsuilder from './screens/EmailBuilder/Sectionsbuilder';
import LetterJournyEdit from './screens/NewsLetter/Journey/LetterJournyEdit';
import LetterOutcome from './screens/NewsLetter/Outcome/LetterOutcome';

import LeadGeneration from './screens/LeadGeneration/InfoGathering/leadGeneration';
const AliasRoute = () => {
  const { alias } = useParams();

  switch (alias) {
    case 'event':
      return <EventProgram />;
    case 'event1':
      return <EventProgram />;
    case 'Newsletters':
      return <NewsLetter/>;
    case 'lead':
      return <LeadGeneration></LeadGeneration>
    default:
      return <Navigate to="/noMatch" />;
  }
};
function App() {
  return (
    <Router>
      <GlobalProvider>
        <Routes>
          {/* {--------event program routes------------} */}
          {/* Public routes */}
          <Route path="/" element={<Login />} />
          <Route path="*" element={<NoMatch />} />
          <Route path="/pages/view/:id" element={<DisplayLp />} />
          <Route path="/UserPreview/:id" element={<UserPreview />} />
          <Route path='/pages/templates/create' element={<Savetemplate programType={'66b1ebb82db00e2fa0db66cd'} templateId={'66f63e23c3ac7def5bfc91b6'} />}></Route>
          {/* Protected routes using ProtectedRoute */}
          <Route path="/home" element={<ProtectedRoute element={Home} />} />
          <Route path="/setting" element={<ProtectedRoute element={Setting} />} />
          <Route path="/program" element={<ProtectedRoute element={Program} />} />
          <Route path="/menus" element={<ProtectedRoute element={MasterData} />} />
          <Route path="/masterData/contact" element={<ProtectedRoute element={Contact} />} />
          <Route path="/masterData/contact/:contactId" element={<ProtectedRoute element={ContactView} />} />
          <Route path="/masterData/lead" element={<ProtectedRoute element={Lead} />} />
          <Route path="/masterData/segment" element={<ProtectedRoute element={Segment} />} />
          <Route path="/masterData/company" element={<ProtectedRoute element={Company} />} />
          <Route path="/masterData/asset" element={<ProtectedRoute element={Asset} />} />
          <Route path="/masterData/email" element={<ProtectedRoute element={Email} />} />
          <Route path="/masterData/page" element={<ProtectedRoute element={Page} />} />
          <Route path="/masterData/form" element={<ProtectedRoute element={Forms} />} />
          <Route path="/masterData/whatsapp" element={<ProtectedRoute element={Whatsapp} />} />
          <Route path="/masterData/sms" element={<ProtectedRoute element={Sms} />} />
          
          <Route path="/program/:alias" element={<ProtectedRoute element={AliasRoute} />} />
          {/* <Route path="/journey/:programId" element={<ProtectedRoute element={Journey} />} /> */}
          <Route path="/journey/:programId" element={<ProtectedRoute element={JourneyBytype} />} />
          <Route path="/outcome/:programId" element={<ProtectedRoute element={Outcome} />} />
          <Route path="/journeyMain" element={<ProtectedRoute element={MainJourney} />} />
          <Route path="/Editor" element={<ProtectedRoute element={Editor} />} />
          <Route path="/preview/:id" element={<ProtectedRoute element={Form} />} />
          <Route path="/formEdit/:id" element={<ProtectedRoute element={Editor} />} />
          <Route path="/formslist" element={<ProtectedRoute element={FormsList} />} />
          <Route path="/emailTemplates" element={<ProtectedRoute element={EmailTemplates} />} />
          <Route path="/emailTemplateEditor/:id" element={<ProtectedRoute element={EmailTemplateEditor} />} />
          <Route path="/myEmailTemplateEditor/:id" element={<ProtectedRoute element={MyEmailTemplateEditor} />} />
          <Route path="/MyEmailTemplates" element={<ProtectedRoute element={MyEmailTemplates} />} />
          <Route path="/SectionsBuilder" element={<ProtectedRoute element={Sectionsuilder} />} />
          <Route path="/JourneyEdit/:programId" element={<ProtectedRoute element={JourneyEdit} />} />
          <Route path="/LettexrJournyEdit/:programId" element={<ProtectedRoute element={LetterJournyEdit} />} />
          <Route path="/LetterOutcome/:programId" element={<ProtectedRoute element={LetterOutcome} />} />
        </Routes>
      </GlobalProvider>
    </Router>
  );
}

export default App;
