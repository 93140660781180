import React from 'react'
import { useGlobal } from 'context/GlobalProvider';
import { ReactComponent as AiIcon } from 'components/Assets/AiIcon.svg';

function OffCanvas() {
    const { EventDetails } = useGlobal();
    return (
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">Basic Event Information</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            {/* Tab Content Start */}
            <div className="offcanvas-body">
                <ul className="nav nav-tabs" id="PreviewTab" role="tablist">
                    <li className="nav-item">
                    <a className="nav-link active" id="objectives-tab" data-bs-toggle="tab" href="#objectives" role="tab">Campaign details</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" id="Event-tab" data-bs-toggle="tab" href="#Event" role="tab">Event</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" id="Speaker-tab" data-bs-toggle="tab" href="#Speaker" role="tab">Speaker</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" id="Goals-tab" data-bs-toggle="tab" href="#Goals" role="tab">Goals</a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="objectives" role="tabpanel" aria-labelledby="objectives">
                    <div className="container-fluid mt-4">
                        <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ms-2 position-relative">

                            <label className="form-label fs-sm">Objectives</label>
                            <div className="mb-3">

                            </div>
                            <div className="position-relative">
                                <textarea
                                name="objective"
                                className="form-control shadow-none ps-5 pe-5"
                                rows="1"
                                value={''}
                                style={{
                                    resize: 'none', border: '2px solid',
                                    borderColor: 'rgba(147, 129, 251, 0.4) rgba(101, 127, 247, 0.4) rgba(101, 127, 247, 0.4) rgba(147, 129, 251, 0.4)',
                                    borderRadius: '8px', fontSize: '14px', lineHeight: 2, paddingLeft: '60px'
                                }}
                                readOnly
                                ></textarea>
                                <div>
                                <span className="btn btn-link position-absolute"
                                    style={{ top: '11px', left: '14px', padding: 0 }}>
                                    <AiIcon style={{ height: '25px', width: '25px', color: '#7280f9' }}></AiIcon>
                                </span>
                                </div>
                            </div>

                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12 mt-4 ms-2">
                                <label className="form-label">Product/Service Name</label>
                                <input name="servicename" type="text" value={''} className="form-control" placeholder="Product/Service Name" readOnly />
                                </div>
                                <div className="col-md-12 mt-4 ms-2">
                                    <label className="form-label">Event Type : </label>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="Event" role="tabpanel" aria-labelledby="Event-tab">
                    <div className="container mt-4">
                        <div className="mb-3">
                        <label htmlFor="eventName" className="form-label">Event Name</label>
                        <input type="text" className="form-control" value={''} id="eventName" readOnly />
                        </div>

                        <div className="mb-3">
                        <label htmlFor="eventDate" className="form-label">Event Date</label>
                        <input
                            type="text"
                            className="form-control"
                            id="eventDate"
                            value={
                           ''
                            }
                            readOnly
                        />
                        </div>

                        <div className="mb-3">
                        <label htmlFor="eventVenue" className="form-label">Event Venue</label>
                        <input type="text" className="form-control" value={''} id="eventVenue" readOnly />
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="Speaker" role="tabpanel" aria-labelledby="Speaker-tab">
                    <div className="row my-3">
                        {undefined && undefined?.speaker.map((data, index) => (
                        <div className="col-md-6">
                            <div className="card position-relative shadow" style={{ borderRadius: "24px" }}>
                            <img src={data.profilePic} alt="Speaker" className="rounded-circle mt-4 mx-auto" height={90} width={90} style={{ padding: "0.15rem", backgroundColor: 'var(--bs-body-bg)', border: "3px solid var(--bs-success)" }} />
                            <div className="card-body text-center">
                                <span className="card-title" style={{ fontSize: "14px" }}>
                                <a className="text-success" href={data.linkedinURL} target="_blank" rel="noopener noreferrer">
                                    <p className="card-text text-truncate" style={{ fontSize: "14px", cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top"
                                    title={data.name} >{data.name}</p>
                                </a>
                                </span>
                                <p className="card-text text-truncate" style={{ fontSize: "14px", cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top"
                                title={data.occupation} >{data.occupation}</p>
                            </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    </div>
                    <div className="tab-pane fade pt-4" id="Goals" role="tabpanel" aria-labelledby="Goals">
                    {/* <div>
                        {expectationDetails.media && <>
                        {expectationDetails.media && expectationDetails.media.map((item, index) => (
                            <div className={`${item.enabled ? 'd-block' : 'd-none'}`} key={index}>
                            <div className="card mb-3 shadow border-0" key={index} style={{ borderRadius: '15px' }}>
                                <div className="card-header bg-light">
                                {item.displayName}
                                </div>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col-5">
                                    <h6 className="text-muted text-nowrap" style={{ fontSize: '10px' }}>No of Registrations</h6>
                                    <p className="text-success fw-bolder mb-0">{formatNumber(item.registrations)}</p>
                                    </div>
                                    <div className="col-3">
                                    <h6 className="text-muted text-nowrap" style={{ fontSize: '10px' }}>Total Budget</h6>
                                    <p className="text-success fw-bolder mb-0">{formatNumber(item.budget)} $</p>
                                    </div>
                                    <div className="col-4">
                                    <h6 className="text-muted text-nowrap" style={{ fontSize: '10px' }}>Cost/Registration</h6>
                                    <p className="text-success fw-bolder mb-0">{formatNumber(item.costPerRegistration)} $</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        ))}
                        </>
                        }
                    </div> */}
                    </div>
                </div>
            </div>
            {/* Tab Content End */}
        </div>
    )
}

export default OffCanvas