import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { load } from '@fingerprintjs/botd';
import NoMatch from '../404';
import { v4 as uuidv4 } from 'uuid';

const DisplayLp = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');
    const [formSettings, setFormSettings] = useState([]);
    const [formHtml, setFormHtml] = useState('');
    const [captchaResponse, setCaptchaResponse] = useState('');
    const formContainerRef = useRef(null);
    const { id } = useParams();
    const [loadState, setLoadState] = useState(false);
    const [lead_id,setlead_id]=useState(localStorage.getItem('contactId'));
    const [hit_id,sethit_id]=useState('');
    const [ipstatus,setipstatus]=useState('loading');
    const [ipDetails,setipDetails]=useState({ip: 'Unknown',country: 'Unknown',city: 'Unknown'});

    //page data
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/data/${id}`,{
            method: 'GET',
          })
            .then(res => {
                // console.log(res);
                if(res.status===404){
                    return null;
                }
                return res.json()
            })
            .then(data => {
                if(data===null){
                    setFormHtml(``);
                    return
                }
                setFormSettings(data);
                setFormHtml(data.htmlContent);
                // console.log(data);
            })
            .catch(error => console.error('Error fetching form:', error));
    }, [id]);
    //recaptcha
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // Initialize the reCAPTCHA with a delay
    useEffect(() => {
        const initializeRecaptchaWithDelay = () => {
            setTimeout(() => {
                try {
                    if (formHtml && formContainerRef.current) {
                        const formElement = formContainerRef.current.querySelector('#dynamicForm');
                        const addAutoFillEvent = formContainerRef.current.querySelector('input[name=email]');

                        if (addAutoFillEvent) {
                            addAutoFillEvent.addEventListener('input', handleChangeEmail); 
                        }

                        const recaptchaContainer = formContainerRef.current.querySelector('.g-recaptcha');
                        if (formElement) {
                            formElement.addEventListener('submit', handleSubmit);
                        }

                        if (window.grecaptcha && recaptchaContainer) {
                            if (recaptchaContainer.childElementCount === 0) {
                                window.grecaptcha.render(recaptchaContainer, {
                                    'sitekey': '6LfoASoqAAAAANWkZyCrk8KwQwrAwu2OB0yskKqW',
                                    'callback': (response) => {
                                        setCaptchaResponse(response);
                                    },
                                    'expired-callback': () => {
                                        setCaptchaResponse('');
                                    }
                                });
                            }
                        } else {
                            console.warn('reCAPTCHA script not loaded or recaptchaContainer not found');
                        }

                        return () => {
                            if (formElement) {
                                formElement.removeEventListener('submit', handleSubmit);
                            }
                            if (addAutoFillEvent) {
                                addAutoFillEvent.removeEventListener('input', handleChangeEmail);
                            }
                        };
                    }
                    setLoadState(true)
                } catch (error) {
                    console.log(error);
                }
            }, 1000); 
        };

        initializeRecaptchaWithDelay();
    }, [formHtml]);
    //get ip address
    useEffect(()=>{
        fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
            setipstatus('completed');
            setipDetails({
                ip: data.ip,
                country: data.country_name,
                city: data.city
            });
        })  
        .catch(() => {
            setipstatus('error');
            setipDetails({
                ip: 'Unknown',  
                country: 'Unknown',
                city: 'Unknown'
            });
        });

    },[]);
    //track page
    useEffect(() => {
        if(ipstatus==='error'|| ipstatus==='completed'){
            let startTime = new Date().getTime();
            let totalTimeSpent = 0;
            let intervalId;
            const Page_track = (hitid) => {
                const currentTime = new Date().getTime();
                totalTimeSpent += (currentTime - startTime) / 1000;
                startTime = currentTime;
                load()
                .then((botd) => botd.detect())
                .then((result) => {
                    if(!result.bot){                        
                        fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/track`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                hitid:hitid,
                                event: 'page_view',
                                pageId: id,
                                date_hit: new Date().toLocaleString(),
                                referrer: document.referrer || 'direct',
                                userAgent: navigator.userAgent,
                                eventType: 'page_view',
                                additionalData: {
                                    ...ipDetails,
                                    contactId: getContactId(),
                                },
                                timeSpent: totalTimeSpent,
                                source:source
                            }),
                            credentials: 'include'
                        })
                        .then(response => response.json())
                        .then(data => {
                            // console.log(data);
                            
                            handlehit_id(data.event._id);
                            // console.log('this',hit_id);
                            
                        })
                        .catch(error => console.error('Error tracking event:', error));
                    }
                })
                .catch((error) => console.error(error));
            };
            Page_track(hit_id);
            intervalId = setInterval(() => Page_track(hit_id), 30000);
            const handleUnload = () => {
                Page_track(hit_id);
            };
            window.addEventListener('beforeunload', handleUnload);
            return () => {
                clearInterval(intervalId);
                window.removeEventListener('beforeunload', handleUnload);
            };
        }
    }, [hit_id,ipstatus]);    
    useEffect(() => {        
        const initHover = () => {
            const hoverElements = document.querySelectorAll('[data-hover-color], [data-hover-bgcolor], [data-hover-bordercolor]');
            console.log(hoverElements);
    
            hoverElements.forEach((element) => {
                const originalColor = element.style.color;
                const hoverColor = element.getAttribute('data-hover-color');
    
                if (hoverColor) {
                    element.addEventListener('mouseenter', () => {
                        element.style.color = hoverColor;
                    });
                    element.addEventListener('mouseleave', () => {
                        element.style.color = originalColor;
                    });
                }
    
                const originalBgColor = element.style.backgroundColor;
                const hoverBgColor = element.getAttribute('data-hover-bgcolor');
    
                if (hoverBgColor) {
                    element.addEventListener('mouseenter', () => {
                        element.style.backgroundColor = hoverBgColor;
                    });
                    element.addEventListener('mouseleave', () => {
                        element.style.backgroundColor = originalBgColor;
                    });
                }
    
                const originalBorderColor = element.style.borderColor;
                const hoverBorderColor = element.getAttribute('data-hover-bordercolor');
    
                if (hoverBorderColor) {
                    element.addEventListener('mouseenter', () => {
                        element.style.setProperty('border-color', hoverBorderColor, 'important');
                    });
                    element.addEventListener('mouseleave', () => {
                        element.style.setProperty('border-color', originalBorderColor, 'important');
                    });
                }
            });
        };
    
        if(document.querySelector('#page_content')){
            initHover();
        }
        return () => {
            const hoverElements = document.querySelectorAll('[data-hover-color], [data-hover-bgcolor], [data-hover-bordercolor]');
            hoverElements.forEach((element) => {
                element.replaceWith(element.cloneNode(true)); // Removes all event listeners
            });
    
            window.removeEventListener('load', initHover);
        };
    }, [loadState,formHtml]);
    
   
    const handlehit_id=(hit_id)=>{
        // console.log(hit_id);
        
        if(hit_id)
            sethit_id(hit_id);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formObject = {};

        formData.forEach((value, key) => {
            if (key in formObject) {
                if (!Array.isArray(formObject[key])) {
                    formObject[key] = [formObject[key]];
                }
                formObject[key].push(value);
            } else {
                formObject[key] = value;
            }
        });



        formObject.recaptchaResponse = captchaResponse;
        formObject.referer = 'not implemented';
        formObject.form_id = formSettings._id;
        formObject.lead_source=source;

        if (formObject['g-recaptcha-response'] === '') {
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Please verify the captcha',
                showConfirmButton: false,
                timer: 3000
            });
            return;
        }

        fetch(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/submitUserForm`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formObject),
        })
            .then(response => response.json())
            .then(data => {
                if (data.downloadUrl) {
                    const link = document.createElement('a');
                    link.href = `${process.env.REACT_APP_BASE_URL_API}/asset` + data.downloadUrl;
                    link.download = '';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                if (formSettings.successfulSubmitAction === 'remainFormWithMessage') {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: formSettings.redirectUrlOrMessage,
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else if (formSettings.successfulSubmitAction === 'redirectUrl') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Thank you',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        window.open(formSettings.redirectUrlOrMessage)
                    });
                } else if (formSettings.successfulSubmitAction === 'remainForm') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Thank you',
                        showConfirmButton: false,
                        timer: 3000
                    });
                }
                else {
                    window.location.reload();
                }
            })
            .catch(error => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong please try again later!',
                    showConfirmButton: false,
                    timer: 3000
                });
                console.error('Error submitting form:', error);
            });
    };


    let debounceTimeout;

    const handleChangeEmail = (e) => {
        const email = e.target.value;

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        debounceTimeout = setTimeout(async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/lead/getLead`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });

                const data = await response.json();

                if (data) {
                    const formElement = formContainerRef.current.querySelector('#dynamicForm');
                    if (formElement) {
                        Object.keys(data).forEach(key => {
                            const input = formElement.querySelector(`input[name=${key}]`);
                            if (input) {
                                input.value = data[key];
                            }
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching lead data:', error);
            }
        }, 1000);
    };


    function getContactId() {
        try {
            let contactId = localStorage.getItem('contactId');
            if (!contactId) {
                contactId = 'contact-' + uuidv4(); // More robust unique ID
                localStorage.setItem('contactId', contactId);
            }
            return contactId;
        } catch (error) {
            console.error('Error accessing localStorage:', error);
            return 'contact-' + uuidv4(); // Fallback in case of failure
        }
    }
    

    return (
        <div className=''>
            {loadState ?
                formHtml ?(<div ref={formContainerRef}>
                    <div dangerouslySetInnerHTML={{ __html: formHtml }} id='page_content'/>
                </div>):<NoMatch></NoMatch>
            :'loading'}
        </div>
    );
};

export default DisplayLp;
