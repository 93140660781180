import React from 'react';
import { showToast } from '../Helpers/ToastHelper';

const useClipboard = () => {
    const copyToClipboard = async (text,type) => {
        try {
            await navigator.clipboard.writeText(text);
            console.log(`${type} copied to clipboard!`);
            showToast(`${type} copied to clipboard`,'success')
        } catch (err) {
            console.error('Failed to copy: ', err);
            showToast(`Failed to copy ${type}`,'error')
        }
    };

    return copyToClipboard;
};

export default useClipboard;
