import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate, useParams } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";

const ContactView = () => {
    const navigate = useNavigate();
    const { contactId } = useParams();
    const [contactDetails, setContactDetails] = useState([]);
    const [contactName, setContactName] = useState('');
    
    useEffect(() => {
        const fetchData = async () => {
            const api = await axios.get(
                `${process.env.REACT_APP_BASE_URL_API}/api/masterData/getContactMasterView/${contactId}`,{ withCredentials: true }
            );
            const response = api.data;
            setContactDetails(response.contactData);
            // Combine firstname and lastname
            const fullName = `${response.contactData.firstname} ${response.contactData.lastname}`;
            setContactName(fullName);
        };

        fetchData();
    }, []);

    return (
        <div className="app">
            <NavbarComp />
            <div className="content overflow-hidden">
                <div style={{ margin: "20px 20px 10px 20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h3 className="fw-bold">{contactName}</h3>
                    <button
                        onClick={() => navigate("/masterData/contact")}
                        style={{
                            padding: "5px 15px",
                            backgroundColor: "#007BFF",
                            color: "#FFFFFF",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        Back to List
                    </button>
                </div>
                <div className="container-fluid px-4 pt-3">
                    <div className="row justify-content-end my-3">
                        {contactDetails ? (
                            <div>
                                <p>Contact ID: {contactDetails._id}</p>
                                <p>First Name: {contactDetails.firstname}</p>
                                <p>Last Name: {contactDetails.lastname}</p>
                                <p>Email: {contactDetails.email}</p>
                                <p>Email Status: {contactDetails.email_bouncify_status}</p>
                                <p>Contact No: {contactDetails.country_code}{contactDetails.mobile}</p>
                                <p>Points: {contactDetails.points}</p>
                                <p>Qualification: {contactDetails.qualification}</p>
                                <p>Company: {contactDetails.company_website}</p>
                                <p>Designation: {contactDetails.designation}</p>
                                <p>Industry: {contactDetails.industry}</p>
                                <p>LinkedIn URL: {contactDetails.linkedin_url_personal}</p>
                                <p>Country: {contactDetails.country}</p>
                                <p>State: {contactDetails.state}</p>
                                <p>City: {contactDetails.city}</p>
                                <p>Created Date: {contactDetails.date_added}</p>
                                <p>Lead Source: {contactDetails.lead_source}</p>
                            </div>
                        ) : (
                            <p>No contact data available</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactView;
