import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";
import otherplatformimg1 from '../../components/Assets/SettingsImg/calendly.png'
import attach from '../../components/Assets/SettingsImg/attach.png'

const Otherplatform = () => {
    
        //--------------------------- events -----------------------------------
             const events = [
                    {
                        id: 1,
                        imgSrc: otherplatformimg1,
                        title: 'Calendly',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    },
                     
                ]
    return (
        <>
            <div
                className="overflow-hidden position-relative"
                style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '75vh'
                }}>
                <div className="container">
                     
                    {/* ----------- */}
                    <div className="row " style={{ paddingTop: '10px' }}>
                        <div className="col-sm-12">
                            <p className="setting-header">OTHER PLATFORM SETTINGS</p>
                        </div>
                        {events.map((event) => (
                            <div className="card event-card " key={event.id} style={{ width: '230px', height: '260px' }}>
                                <div className="">
                                    <img src={event.imgSrc} className="" alt="" />
                                </div>
                                <p className="onlineheader">{event.title}</p>
                                <p className="onlineParagraph">{event.description}</p>
                                <button type="button" className="attach-connect"> <img src={attach} className="" alt="" /> Connect</button>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

        </>
    );
};
export default Otherplatform;