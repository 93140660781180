import { toast, Bounce } from 'react-toastify';
export const showToast = (message, type = 'error') => {
  const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  };

  switch (type) {
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'info':
      toast.info(message, toastOptions);
      break;
    case 'warning':
      toast.warn(message, toastOptions);
      break;
    case 'error':
    default:
      toast.error(message, toastOptions);
      break;
  }
};
