import React from 'react';

const NavTabs = ({ tabs, setActiveTab, activeTab }) => {
  return (
    <nav className='emailChannel-tab'>
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
            id={`nav-${tab.id}-tab`}
            data-bs-toggle="tab"
            data-bs-target={`#nav-${tab.id}`}
            type="button"
            role="tab"
            aria-controls={`nav-${tab.id}`}
            aria-selected={activeTab === tab.id ? 'true' : 'false'}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </nav>
  );
};

export default NavTabs;
