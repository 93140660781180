import { useState, useEffect } from "react";
const platforms = {
    google_ad: "api/google/getAccountData",
    linkedin_ad: "api/linkedinPro/getAccountData",
    meta_ad: "api/facebook/getAccountData",
};
const useAccountDetails = () => {
    const [accountDetails, setAccountDetails] = useState([]);

    useEffect(() => {
        const fetchDetailsForPlatform = async (platform, apiCallURL) => {
            try {
                if (!apiCallURL) {
                    console.warn(`API URL is missing for ${platform}`);
                    return {
                        accountId: '0123456789',
                        accountName: 'Account Name',
                        accountImage: '',
                    };
                }

                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/${apiCallURL}`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch ${platform} data: ${response.status}`);
                }

                const data = await response.json();
                return {
                    accountId: data.accountId || 'N/A',
                    accountName: data.accountName || 'N/A',
                    accountImage: data.profilePic || '',
                    platformName: platform,
                };
            } catch (err) {
                console.error(`Error fetching data for ${platform}:`, err);
                return { error: err.message };
            }
        };

        const fetchAllPlatforms = async () => {
            const detailsMap = [];
            for (const [platform, apiCallURL] of Object.entries(platforms)) {
                const details = await fetchDetailsForPlatform(platform, apiCallURL);
                if (details) {
                    detailsMap.push(details);
                }
            }
            setAccountDetails((prevDetails) => {
                const isSame = JSON.stringify(prevDetails) === JSON.stringify(detailsMap);
                return isSame ? prevDetails : [...detailsMap];
            });
        };

        fetchAllPlatforms();
    }, []);

    return accountDetails;
};

export default useAccountDetails;
