import React from 'react'
import starcircle from './Assets/starcircle.svg'

function JourneyAnimation({tabLoading}) {
    return (
        <section className='text-animation'>
            <div className={`animation-journey ${tabLoading ? 'move-to-top-right' : ''}`}>
                {!tabLoading &&
                    <div className='stars mb-5'>
                        <div className='star-one'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.30468 0.980382C8.40067 0.154838 9.59933 0.154837 9.69532 0.980381L9.81268 1.98981C10.172 5.07998 12.5276 7.56224 15.5947 8.08273L16.9332 8.30987C17.7104 8.44175 17.7104 9.55825 16.9332 9.69013L15.5947 9.91727C12.5276 10.4378 10.172 12.92 9.81268 16.0102L9.69532 17.0196C9.59933 17.8452 8.40067 17.8452 8.30468 17.0196L8.18732 16.0102C7.82804 12.92 5.47244 10.4378 2.4053 9.91727L1.06683 9.69013C0.289646 9.55825 0.289644 8.44175 1.06682 8.30987L2.4053 8.08273C5.47244 7.56224 7.82804 5.07998 8.18732 1.98981L8.30468 0.980382Z" fill="url(#paint0_linear_4088_16896)" />
                                <defs>
                                    <linearGradient id="paint0_linear_4088_16896" x1="9" y1="-3" x2="9" y2="21" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#9482FB" />
                                        <stop offset="1" stopColor="#317DF4" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className='star-two'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.30468 0.980382C8.40067 0.154838 9.59933 0.154837 9.69532 0.980381L9.81268 1.98981C10.172 5.07998 12.5276 7.56224 15.5947 8.08273L16.9332 8.30987C17.7104 8.44175 17.7104 9.55825 16.9332 9.69013L15.5947 9.91727C12.5276 10.4378 10.172 12.92 9.81268 16.0102L9.69532 17.0196C9.59933 17.8452 8.40067 17.8452 8.30468 17.0196L8.18732 16.0102C7.82804 12.92 5.47244 10.4378 2.4053 9.91727L1.06683 9.69013C0.289646 9.55825 0.289644 8.44175 1.06682 8.30987L2.4053 8.08273C5.47244 7.56224 7.82804 5.07998 8.18732 1.98981L8.30468 0.980382Z" fill="url(#paint0_linear_4088_16896)" />
                                <defs>
                                    <linearGradient id="paint0_linear_4088_16896" x1="9" y1="-3" x2="9" y2="21" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#9482FB" />
                                        <stop offset="1" stopColor="#317DF4" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className='star-three'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.30468 0.980382C8.40067 0.154838 9.59933 0.154837 9.69532 0.980381L9.81268 1.98981C10.172 5.07998 12.5276 7.56224 15.5947 8.08273L16.9332 8.30987C17.7104 8.44175 17.7104 9.55825 16.9332 9.69013L15.5947 9.91727C12.5276 10.4378 10.172 12.92 9.81268 16.0102L9.69532 17.0196C9.59933 17.8452 8.40067 17.8452 8.30468 17.0196L8.18732 16.0102C7.82804 12.92 5.47244 10.4378 2.4053 9.91727L1.06683 9.69013C0.289646 9.55825 0.289644 8.44175 1.06682 8.30987L2.4053 8.08273C5.47244 7.56224 7.82804 5.07998 8.18732 1.98981L8.30468 0.980382Z" fill="url(#paint0_linear_4088_16896)" />
                                <defs>
                                    <linearGradient id="paint0_linear_4088_16896" x1="9" y1="-3" x2="9" y2="21" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#9482FB" />
                                        <stop offset="1" stopColor="#317DF4" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                }
                <div className='animation-flex'>
                    {tabLoading && <img src={starcircle} alt='star'/>}&nbsp;&nbsp;<h6 className='pt-2'>Camp AI is building the Journey, Please wait...</h6>
                </div>
            </div>
        </section>
    )
}

export default JourneyAnimation