import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Settings } from "@mui/icons-material";
import { Logout } from "@mui/icons-material";
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { IoIosArrowDown,IoIosArrowUp} from "react-icons/io";
import { useGlobal } from '../context/GlobalProvider';

export default function BasicSpeedDial() {
    const { logout } = useGlobal();
    const navigate = useNavigate();
    const actions = [
        {
        icon: <FileCopyIcon sx={{ color: 'black' }} />,
        name: 'Master Data',
        onclick: () => { navigate('/menus'); }
        },
        {
        icon: <Settings sx={{ color: 'black' }} />,
        name: 'settings',
        onclick: () => { navigate('/setting'); }
        },
        {
        icon: <Logout sx={{ color: 'red' }} />,
        name: 'logout',
        onclick: () => { logout(); } 
        },
    ];
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 ,'& .MuiFab-root':{backgroundColor:'#fff',boxShadow:'0px 0px 20px 0px #0000000',width:'40px',height:'40px'},'& .MuiSpeedDialIcon-root>svg':{color:'#000',height:'20px',width:'20px'},'& .MuiSpeedDial-fab:hover':{backgroundColor:"unset"}}}>
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute',bottom:'-40px'}}
            icon={<SpeedDialIcon icon={<IoIosArrowUp width={'2em'} height={'2em'}></IoIosArrowUp>} openIcon={<IoIosArrowDown></IoIosArrowDown>} />}
            open={open}
            onClick={handleClick}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onclick}
                    onMouseEnter={(e)=>e.preventDefault()}
                    onMouseLeave={(e)=>e.preventDefault()}
                />
            ))}
        </SpeedDial>
        </Box>
    );
}