import React, { useEffect, useRef, useState } from 'react';
import { FaEdit } from "react-icons/fa";
import { MdMoreVert } from "react-icons/md";
import { useGlobal } from '../../../context/GlobalProvider';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NumberShorthand from '../../../components/FormatNumber';
import { ReactComponent as Loadingimg } from '../../../components/Assets/journeycard.svg'

const CardComp = ({ channel, no_campains, expected, reach, icon, status, isEnabled, builder, handleChannelModel, cardType, cardId, fetchProgramDetails }) => {
    const [customeChannelData, setCustomeChannelData] = useState({ displayName: channel, icon: '', displayCountLabel1: '', displayCountLabel2: '', displaySelectedLabel: '' });

    const expectedValue = Number(expected);
    const reachValue = Number(reach);
    const maxValue = Math.max(expectedValue, reachValue);
    const calculateHeight = (value) => (value / maxValue) * 20;
    const isReachAboveExpected = reachValue > expectedValue;
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [isDisabled, setISDisabled] = useState(false);
    const { programId } = useParams();
    const { setTabLoading, resetTransformRef, AnimationMode } = useGlobal();
    const handleTooltipToggle = () => {
        setTooltipVisible(!isTooltipVisible);
    };

    // Function to fetch media data from the API
    const fetchMediaData = async (alias) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/labelIcon?alias=${alias}`, { withCredentials: true });
            return response.data;
        } catch (error) {
            console.error(`Error fetching data for ${alias}:`, error);
            return null;
        }
    };

    useEffect(() => {
        const enhanceChannel = async () => {
            const data = await fetchMediaData(channel);
            if (data) {
                setCustomeChannelData({
                    displayName: data.name === '' ? channel : data.name,
                    icon: `/assets/socialIcons/${data.icon}`,
                    displayCountLabel1: data.countLabel1,
                    displayCountLabel2: data.countLabel2,
                    displaySelectedLabel: data.selectedLabel,
                });
            } else {
                setCustomeChannelData({
                    displayName: channel,
                    icon: `/assets/socialIcons/${channel}.svg`,
                    displayCountLabel1: '',
                    displayCountLabel2: '',
                    displaySelectedLabel: '',
                });
            }
        };
        enhanceChannel();
    }, [channel]);

    const [isloaded, setisloaded] = useState(false);
    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            if (AnimationMode === true) {
                cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setTimeout(() => {
                    setisloaded(true)
                }, 1000);
            }
            else {
                setisloaded(true)
            }
        }
    }, []);
    const handleDisabled = async (channel, builder, status) => {
        resetTransformRef.current();
        setTabLoading(false);
        const payload = {
            status: status,
            channel: channel,
            builder: builder,
            programId: programId
        }
        try {
            const updateisEnabledApi = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/disableCard`, { payload }, { withCredentials: true });
            const response = updateisEnabledApi.data;
            // console.log(response);

            setISDisabled(!isDisabled);
            setTooltipVisible(!isTooltipVisible);

            setTimeout(async () => {
                setTabLoading(true);
                await fetchProgramDetails();
            }, 500);
        } catch (error) {
            console.log(error);
        }
    };
    const handleRemoveChannel = (channel) => {
        // const updatedChannels = register_reminderDatas.filter(data => data.channel !== channel);
        // setRegister_reminderDatas(updatedChannels);
    };

    return (
        <div className={`meta cards ${isEnabled === false ? 'disabled' : ''} ${status === '1' ? '' : 'incomplete-shadow'} ${isloaded === false ? 'animation' : ''}`} ref={cardRef}>
            {isloaded ? (
                <>
                    <div className={`status ${status === '1' ? 'completed' : 'not-completed'}`}>
                        {status === '1' ? (
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7" cy="7" r="7" fill={`${isEnabled === false ? '#616161' : '#05925D'}`} />
                                <path d="M9.62061 5.58816L6.33169 8.87502C6.29315 8.9153 6.24684 8.94736 6.19557 8.96926C6.14429 8.99117 6.08911 9.00246 6.03336 9.00246C5.9776 9.00246 5.92242 8.99117 5.87115 8.96926C5.81988 8.94736 5.77357 8.9153 5.73502 8.87502L4.3889 7.53715C4.34972 7.49784 4.31867 7.45119 4.29752 7.39988C4.27637 7.34857 4.26553 7.29359 4.26563 7.23809C4.26582 7.126 4.31053 7.01857 4.38993 6.93945C4.46933 6.86033 4.5769 6.81598 4.689 6.81618C4.80109 6.81637 4.90851 6.86108 4.98763 6.94048L6.03439 7.98517L9.02188 4.99149C9.101 4.9121 9.20842 4.86738 9.32051 4.86719C9.43261 4.86699 9.54018 4.91134 9.61958 4.99046C9.69898 5.06958 9.74369 5.17701 9.74388 5.2891C9.74408 5.40119 9.69974 5.50877 9.62061 5.58816Z" fill="white" />
                            </svg>
                        ) : (
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7" cy="7" r="7" fill={`${isEnabled === false ? '#616161' : '#EA4335'}`} />
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.63882 4.87189C9.77837 4.73234 9.77837 4.50594 9.63882 4.36638C9.49927 4.22683 9.27288 4.22684 9.13332 4.36638L7.00475 6.49535L4.87619 4.36678C4.73664 4.22723 4.51024 4.22723 4.37068 4.36678C4.23113 4.50594 4.23113 4.73234 4.37068 4.87189L6.49925 7.00045L4.37029 9.12982C4.23074 9.26898 4.23074 9.49537 4.37029 9.63492C4.50984 9.77447 4.73623 9.77447 4.87539 9.63492L7.00475 7.50596L9.13372 9.63492C9.27327 9.77447 9.49966 9.77447 9.63922 9.63492C9.77878 9.49537 9.77877 9.26937 9.63922 9.12982L7.50986 7.00045L9.63882 4.87189Z" fill="white" />
                            </svg>
                        )}
                        &nbsp;
                        {status === '1' ? <p>Setup Completed </p> : <p>{cardType === 'letters' && isEnabled === false ?'This News Letter is Inactive' :'Setup Not Completed'}</p>}
                    </div>

                    {cardType !== 'letters' &&
                        <>
                            <div className='icon'>

                            </div>
                            <div className="icon-wrapper">
                                <div className="icon-back">
                                    <img src={customeChannelData.icon} alt={channel} />
                                </div>
                            </div>
                        </>
                    }

                    <div className='card-details row'>
                        <div className='col-sm-8 cd'>
                            <h4>{customeChannelData.displayName}</h4>
                            <p className='no-campains'>&nbsp;{no_campains} &nbsp; {customeChannelData.displaySelectedLabel}</p>
                        </div>
                        <div className='col-sm-4' style={{ marginTop: '10px', paddingLeft: '0px', paddingRight: '5px' }}>
                            <p className='tagname'>{customeChannelData.displayCountLabel1} - {<NumberShorthand value={expected}></NumberShorthand>}</p>
                            {customeChannelData.displayCountLabel2 && (
                                <p className='tagname'>{customeChannelData.displayCountLabel2} - {<NumberShorthand value={reach}></NumberShorthand>}</p>
                            )}
                        </div>
                    </div>
                    <div className='button-div'>
                        <button className='edit-bt' onClick={() => handleChannelModel(channel, cardType, cardId, builder, isEnabled)} 
                        style={{ width: '82%', float: 'left' }}>
                            <FaEdit />&nbsp;&nbsp;Edit
                        </button>
                        <button
                            className='more-bt'
                            style={{ width: '13%', float: 'right', padding: '0px', fontSize: '22px', color: "#000" }}
                            onClick={handleTooltipToggle}
                        >
                            <MdMoreVert />
                        </button>
                        
                        {/* {(isTooltipVisible && cardType !== 'letters' ) && ( */}
                        {(isTooltipVisible  ) && (
                            <>
                                <div
                                    onClick={() => handleDisabled(channel, builder, !isEnabled)}
                                    className={`tooltip ${isTooltipVisible ? 'visible' : ''}`}
                                    style={{
                                        right: '-75px',
                                        bottom: '10px',
                                        width: '80px',
                                        color: isEnabled === false ? 'rgb(8, 171, 110)' : 'rgb(234, 67, 53)',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {isEnabled === false ? 'Enable' : 'Disable'}
                                </div>
                                {(channel === 'Voice' || channel === 'SMS') && (
                                    <div
                                        onClick={() => handleRemoveChannel(channel)}
                                        className={`tooltip ${isTooltipVisible ? 'visible' : ''}`}
                                        style={{
                                            right: '-75px',
                                            bottom: '-16px',
                                            width: '80px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Remove
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <div className='customsvg'>
                    <Loadingimg></Loadingimg>
                </div>
            )}

        </div>
    );
}

export default CardComp;