import React from "react";

const Table = ({
    data,
    selectedColumns,
    selectedRows,
    onRowSelect,
    onHeaderSelect,
    onColumnClick,
    showCheckboxColumn = false, // New prop to control checkbox visibility
}) => {
    const allRowsSelected = data.length > 0 && selectedRows.length === data.length;

    return (
        <div style={{ overflowX: "auto" }}>
            <table
                className="table table-borderless"
                id="newtable"
                cellPadding="10"
                style={{ width: "100%" }}
            >
                <thead>
                    <tr>
                        {showCheckboxColumn && (
                            <th>
                                <input
                                    type="checkbox"
                                    onChange={() => onHeaderSelect(!allRowsSelected)}
                                    checked={allRowsSelected}
                                    className="custom-checkbox"
                                />
                            </th>
                        )}
                        {selectedColumns.map((col) => (
                            <th key={col}>{col.replace("_", " ")}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => (
                        <tr key={row._id} style={{ borderBottom: "2px solid #F1F3F5" }}>
                            {showCheckboxColumn && (
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(row._id)}
                                        onChange={() => onRowSelect(row._id)}
                                        className="custom-checkbox"
                                    />
                                </td>
                            )}
                            {selectedColumns.map((col, index) => (
                                <td
                                    key={col}
                                    onClick={() =>
                                        index === 0 && onColumnClick && onColumnClick(row._id)
                                    }
                                    style={
                                        index === 0
                                            ? {
                                                  cursor: "pointer",
                                                  color: "blue",
                                                  textDecoration: "underline",
                                              }
                                            : {}
                                    }
                                >
                                    {row[col] || "-"}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
