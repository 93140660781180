import React from 'react';

const TabFooter = ({ activeTab, tabContents }) => {
  return (
    <>
        {tabContents.map((content) => (
            <div
            key={content.id}
            className={`w-100 ${activeTab === content.id ? 'd-block' : 'd-none'}`}
            id={`nav-${content.id}`}
            >
            {content.footer}
            </div>
        ))}
    </>
  );
};

export default TabFooter;
