import React, { useRef, useState } from 'react'
import uploadCsv from '../../../components/Assets/upload.png'
import csvIcon from '../../../components/Assets/csv.png'
import csvCancel from '../../../components/Assets/csv-cancel.png'
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
function HandleUpload({handleViewFailedList,getRecipientListCount,User,channelData,programId,fetchProgramDetails,setSegmentId,setFailedColumns,setFailedRows}) {
    const [pro, setPro] = useState(0);
    const [success, setSuccess] = useState(0);
    const [successMsg, setSuccessMsg] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [fileName, setFileName] = useState('');
    const [dragActive, setDragActive] = useState(false);
    const [successType, setSuccessType] = useState(null);
    const fileInputRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const file = e.dataTransfer.files[0];
        handleUpload(file); // Validate and process the dropped file
    };

    const onInputChange = (e) => {
        const file = e.target.files[0];
        handleUpload(file);
    };
    const handleUpload = async (file) => {
        // console.log(channelData);
        setPro(10);
        const validMimeTypes = ['text/csv', 'application/vnd.ms-excel'];
        const filename=file.name.trim().toLowerCase();
        if (!file || !validMimeTypes.includes(file.type) || !filename.endsWith('.csv')) {
            alert("Please upload a valid CSV file.");
            setPro(0);
            return;
        }
        setFileName(file.name);
        setPro(20);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('channel', channelData.name);
        formData.append('programId', programId);
        formData.append('type', 'csv');
        formData.append('platformType', 'email');
        formData.append('builder', channelData.builder);
        formData.append('segmentData', JSON.stringify({
            name: 'Hello Tony Stark',
            created_by: User.id,
            created_by_user: User.username,
            description: 'Testing this description',
            alias: 'Hello Tony Stark'
        }));
        setPro(30);
        try {
            setPro(50);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/uploadCSV`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    withCredentials: true,
                }
            );
            setPro(70);
            if (response.status === 200) {
                const data = response.data;
                setSuccessMsg(data.data);
                setPro(100);
                setSuccess(1);
                await fetchProgramDetails();
                getRecipientListCount(data.segmentId);
                setSegmentId(data.segmentId);
                setSuccessType('csv');
                console.log('Upload Success:', data);
                setFailedColumns([
                    { field: 'id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                    { field: 'reason', headerName: 'Failed Reason', width: 300 }
                ]);
                setFailedRows(data.failedData);
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            } else {
                setSuccess(2);
                setPro(0);
            }
        } catch (error) {
            console.log('Error uploading file:', error);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
            setPro(0);
            setSuccess(2);
        }
    };
    const handleDownload = () => {
        if (!process.env.REACT_APP_PUBLIC_URL) {
            alert('.env file is missing');
            return
        }
        const filePath = `${process.env.REACT_APP_PUBLIC_URL}/demo-files/Upload contacts to Camp -demo.csv`;

        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'Upload contacts to Camp -demo.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    return (
        <>
            <div className='upload-csv'>
                <div className='csv-header'>
                    <div className='row'>
                        <div className='col-sm-7 pt-1'>
                            <h6>Upload CSV</h6>
                        </div>
                        <div className='col-sm-5'>
                            <button className='csv-download' onClick={handleDownload}>Download CSV Format</button>
                        </div>
                    </div>
                </div>
                <div
                    className={`csv-body ${dragActive ? 'drag-active' : ''}`}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <div className='upload-div'>
                        <img src={uploadCsv} alt='upload-csv' />
                        <p className='drop-upload'>Drop Your Files Here</p>
                        <p>or</p>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={onInputChange}
                            accept=".csv"
                            style={{ display: 'none' }}
                            id="file-upload"
                        />

                        <label htmlFor="file-upload" className="upload-button">
                            Upload File
                        </label>
                    </div>
                </div>

                {pro > 0 &&
                    <div className='csv-footer'>
                        <p>Uploaded File</p>
                        <div className='csv-card'>
                            <div className='csv-icon'>
                                <img src={csvIcon} alt='csv-icon' />
                            </div>
                            <div className='csv-progress'>
                                <p>{fileName}</p>

                                <ProgressBar now={pro} label={`${pro}%`} className='mt-3' />

                            </div>
                            <div className='csv-cancel'>
                                <img src={csvCancel} alt='csv-icon' onClick={() => setPro(0)} />
                            </div>
                        </div>
                    </div>
                }

            </div>

            {success === 1 && successType === 'csv' && (
                <div className="success-card">
                    <h6>Contacts Uploaded !</h6>
                    <ul>
                        <li><strong>Created : </strong> {successMsg.uploaded}</li>
                        <li><strong>Updated : </strong> {successMsg.existingUpdated}</li>
                        <li><strong>Failed : </strong> {successMsg.failed} <button onClick={() => handleViewFailedList('csv')} style={{ color: 'red' }}>View Failed List</button></li>
                        <li><strong>Existing - Segment: </strong> {successMsg.existingLeadSegment}</li>
                    </ul>
                </div>
            )}
            {success === 2 && successType === 'csv' && (
                <div className="error-card">
                    <h6>Failed  to Upload</h6>
                    <p>{errorMsg}</p>
                </div>
            )}
        </>
    )
}

export default HandleUpload