import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bg from '../components/Assets/HomeBg.png'
import BasicSpeedDial from "../components/SpeedDial";
import { CustomPrevArrow, CustomNextArrow } from '../components/CustomArrows';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Settings } from "@mui/icons-material";
import { Logout } from "@mui/icons-material";
import NavbarComp from '../components/NavbarComp';
import Slider from "react-slick";
import {ReactComponent as Img1} from '../components/Assets/ProgramIcons/1.svg';

const actions = [
    { icon: <FileCopyIcon sx={{ color: 'black' }} />, name: 'Copy', onclick: () => { console.log('add onclick action here') } },
    { icon: <Settings sx={{ color: 'black' }} />, name: 'settings', onclick: () => { console.log('add onclick action here') } },
    { icon: <Logout sx={{ color: 'red' }} />, name: 'logout', onclick: () => { console.log('add onclick action here') } },
];
const MasterData = () => {
    const [activeTab, setActiveTab] = useState('Firsttab');
    const audienceData = [
        {
            name: 'Lead',
            iconImg: '1',
            alias: 'contact',
        },
        /*{
            name: 'Lead',
            iconImg: '1',
            alias: 'lead',
        },*/
        {
            name: 'Segments',
            iconImg: '1',
            alias: 'segment',
        },
        {
            name: 'Companies',
            iconImg: '1',
            alias: 'company',
        }
    ];
    const audienceSettings = {
        dots: true,
        infinite: audienceData.length>3?true:false,
        speed: 500,
        slidesToShow: audienceData.length>3?3:audienceData.length,
        slidesToScroll: 3,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        autoplay: true,  
        autoplaySpeed: 3000
    };
    const assetData = [
        {
            name: 'Assets',
            iconImg: '1',
            alias: 'asset',
        },
        {
            name: 'Email',
            iconImg: '1',
            alias: 'email',
        },
        {
            name: 'Page',
            iconImg: '1',
            alias: 'page',
        },
        {
            name: 'Form',
            iconImg: '1',
            alias: 'form',
        },
        {
            name: 'Whatsapp',
            iconImg: '1',
            alias: 'whatsapp',
        },
        {
            name: 'SMS',
            iconImg: '1',
            alias: 'sms',
        }
    ];
    const assetSettings = {
        dots: true,
        infinite: assetData.length>3?true:false,
        speed: 500,
        slidesToShow: assetData.length>3?3:assetData.length,
        slidesToScroll: 3,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        autoplay: true,  
        autoplaySpeed: 3000
    };
    const navigate = useNavigate();
    const handleCardClick  = (alias) => {
        navigate(`/masterData/${alias}`);
    };
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <>
            <NavbarComp></NavbarComp>
            <div
                className="overflow-hidden position-relative"
                style={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '91vh'
                }}
            >
                <div className="Container my-5">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-5">
                            <ul className="nav nav-tabs w-100 rounded-pill" id="PreviewTab" role="tablist">
                                <li className="nav-item w-50">
                                    <a
                                        className={`nav-link rounded-pill ${activeTab === 'Firsttab' ? 'active' : ''}`}
                                        id="First-tab"
                                        data-bs-toggle="tab"
                                        href="#Firsttab"
                                        role="tab"
                                        onClick={() => handleTabChange('Firsttab')}
                                    >
                                        Audience
                                    </a>
                                </li>
                                <li className="nav-item w-50">
                                    <a
                                        className={`nav-link rounded-pill ${activeTab === 'Event' ? 'active' : ''}`}
                                        id="Second-tab"
                                        data-bs-toggle="tab"
                                        href="#Event"
                                        role="tab"
                                        onClick={() => handleTabChange('Event')}
                                    >
                                        Marketing Assets
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div
                        className={`tab-pane fade ${activeTab === 'Firsttab' ? 'show active' : ''}`}
                        id="Firsttab"
                        role="tabpanel"
                        aria-labelledby="objectives"
                    >
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="slider-container">
                                    <Slider {...audienceSettings}>
                                        {audienceData.map(program => (
                                            <div className="slide" key={program._id}>
                                                <div className="m-5 d-flex align-items-end position-relative" onClick={() => handleCardClick(program.alias)}>
                                                    <div className="svg-container text-start m-auto d-inline">
                                                        <Img1 className="svg-icon"/>
                                                    </div>
                                                    <div className="text-start">
                                                        <div className="mb-3 card-title" dangerouslySetInnerHTML={{ __html: program.name }}></div>
                                                        <div className="card-text">
                                                            {program.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`tab-pane fade ${activeTab === 'Event' ? 'show active' : ''}`}
                        id="Event"
                        role="tabpanel"
                        aria-labelledby="objectives"
                    >
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-10">
                                    <div className="slider-container">
                                        <Slider {...assetSettings}>
                                            {assetData.map(program => (
                                                <div className="slide" key={program._id}>
                                                    <div className="m-5 d-flex align-items-end position-relative" onClick={() => handleCardClick(program.alias)}>
                                                        <div className="svg-container text-start m-auto d-inline">
                                                            <Img1 className="svg-icon"/>
                                                        </div>
                                                        <div className="text-start">
                                                            <div className="mb-3 card-title" dangerouslySetInnerHTML={{ __html: program.name }}></div>
                                                            <div className="card-text">
                                                                {program.description}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="position-absolute" style={{ bottom: '60px', left: '32px' }}>
                    <BasicSpeedDial actions={actions}></BasicSpeedDial>
                </div>
            </div>
        </>
    );
}
export default MasterData;