import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Tab, Tabs } from 'react-bootstrap'

function HandleCrmUpload({handleViewFailedList,getRecipientListCount,User,channelData,programId,setSegmentId,setFailedColumns,setFailedRows}) {
    const [selectedCrm, setSelectedCrm] = useState(null);
    const [selectedDataProvider, setselectedDataProvider] = useState(null);
    const [crms, setCrms] = useState([]);
    const [dataProviders, setdataProviders] = useState([]);
    const [ImportType, setImportType] = useState('CRM');
    const [crmLoading, setCrmLoading] = useState(false);
    const [CriteriaModal,setCriteriaModal]=useState(false);
    const [ApolloFilters, setApolloFilters] = useState({ filter: '',list:''});
    const [ApolloList,setApolloList]=useState([]);
    const [success, setSuccess] = useState(0);
    const [successMsg, setSuccessMsg] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [successType, setSuccessType] = useState(null);


    useEffect(() => {
        if (['email','whatsapp'].includes(channelData.name)) {
            fetchCrmPlatForms();
            fetchdataProviderPlatForms();
        }
    }, [channelData.name])
    
    const handleSelect = (crmId, crmAlias) => {
        if (selectedCrm !== null && selectedCrm.id === crmId) {
            setSelectedCrm(null);
        } else {
            setSelectedCrm({ id: crmId, alias: crmAlias });
        }
    };
    const handleSelectVariant = (dataProviderid, DataproviderAlias) => {
        if (selectedCrm !== null && selectedCrm.id === dataProviderid) {
            setselectedDataProvider(null);
        } else {
            setselectedDataProvider({ id: dataProviderid, alias: DataproviderAlias });
        }
    };
    const fetchCrmPlatForms = async () => {
        try {
            const crm = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/getCrmList`, { withCredentials: true });
            if (crm.data.length > 0) {
                setCrms(crm.data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchdataProviderPlatForms = async () => {
        try {
            const dataprovider = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/api/platform/getplatform`, {type:'dataprovider'},{ withCredentials: true });
            if (dataprovider?.data?.length > 0) {
                setdataProviders(dataprovider.data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleImportCrm = async () => {
        setCrmLoading(true);
        try {
            if (selectedCrm && selectedCrm.alias) {
                const payload = {
                    type: 'crm',
                    programId: programId,
                    channel: channelData.name,
                    builder: channelData.builder,
                    alias: selectedCrm.alias,
                    segmentData: {
                        name: 'Hello Tony Stark',
                        created_by: User.id,
                        created_by_user: User.username,
                        description: 'Testing this description',
                        alias: 'Hello Tony Stark'
                    }
                };
                const importApi = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/importLeads`, payload, { withCredentials: true });
                if (importApi.status === 200) {
                    const importApiData = importApi.data;
                    setSuccessMsg(importApiData.data);
                    setSuccess(1);
                    setSuccessType('crm');
                    setCrmLoading(false);
                    setSegmentId(importApiData.segmentId);
                    getRecipientListCount(importApiData.segmentId);

                    setFailedColumns([
                        { field: '_id', headerName: 'ID', width: 150 },
                        { field: 'firstname', headerName: 'First Name', width: 150 },
                        { field: 'lastname', headerName: 'Last Name', width: 150 },
                        { field: 'email', headerName: 'Email', width: 200 },
                        { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                        { field: 'country', headerName: 'Country', width: 100 },
                        { field: 'state', headerName: 'State', width: 100 },
                        { field: 'city', headerName: 'City', width: 100 },
                        { field: 'company', headerName: 'Company', width: 100 },
                        { field: 'designation', headerName: 'Designation', width: 100 },
                        { field: 'industry', headerName: 'Industry', width: 100 },
                        { field: 'reason', headerName: 'Failed Reason', width: 300 }
                    ]);
                    setFailedRows(importApiData.failedData);
                } else {
                    setSuccess(2);
                    setCrmLoading(false);
                }
            } else {
                console.log("No CRM selected");
            }
        } catch (error) {
            console.error("Error importing CRM data:", error);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
            setSuccess(2);
            setCrmLoading(false);
        }
    };
    const handleImportDataProvider = async (e) => {
        e.preventDefault();
        setCrmLoading(true);
        try {
            if (selectedDataProvider && selectedDataProvider.alias) {
                const payload = {
                    type: 'dataprovider',
                    programId: programId,
                    channel: channelData.name,
                    builder: channelData.builder,
                    alias: selectedDataProvider.alias,
                    segmentData: {
                        name: 'Hello Tony Stark',
                        created_by: User.id,
                        created_by_user: User.username,
                        description: 'Testing this description',
                        alias: 'Hello Tony Stark'
                    },
                    filters:ApolloFilters,
                };
                const importApi = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/api/${selectedDataProvider.alias}/importLeads`, payload, { withCredentials: true });
                if (importApi.status === 200) {
                    const importApiData = importApi.data;
                    setSuccessMsg(importApiData.data);
                    setSuccess(1);
                    setSuccessType('crm');
                    setCrmLoading(false);
                    setSegmentId(importApiData.segmentId);
                    getRecipientListCount(importApiData.segmentId);

                    setFailedColumns([
                        { field: '_id', headerName: 'ID', width: 150 },
                        { field: 'firstname', headerName: 'First Name', width: 150 },
                        { field: 'lastname', headerName: 'Last Name', width: 150 },
                        { field: 'email', headerName: 'Email', width: 200 },
                        { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                        { field: 'country', headerName: 'Country', width: 100 },
                        { field: 'state', headerName: 'State', width: 100 },
                        { field: 'city', headerName: 'City', width: 100 },
                        { field: 'company', headerName: 'Company', width: 100 },
                        { field: 'designation', headerName: 'Designation', width: 100 },
                        { field: 'industry', headerName: 'Industry', width: 100 },
                        { field: 'reason', headerName: 'Failed Reason', width: 300 }
                    ]);
                    setFailedRows(importApiData.failedData);
                } else {
                    setSuccess(2);
                    setCrmLoading(false);
                }
            } else {
                console.log("No Data provider selected");
            }
        } catch (error) {
            console.error("Error importing data:", error);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
            setSuccess(2);
            setCrmLoading(false);
        }
    };
    const handleCriteriaModal=()=>{
        if(!CriteriaModal){
            fetchApolloList();
        }
        setCriteriaModal(!CriteriaModal)
    };
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        console.log(name,value);
        
        setApolloFilters((prev) => ({ ...prev, [name]: value }));
    };
    const fetchApolloList= async () => {
        try {
            const contactlistRes= await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/apollo/contactlist`,{credentials:'include'});
            const contactlist=await contactlistRes.json();
            setApolloList(contactlist);
        } catch (error) {
            
        }        
    }
    useEffect(() => {
        return () => {
            setApolloFilters("");
        };
    }, [CriteriaModal]);
    return (
        <>
            <div className='crm-div'>
                <div className='crm-header'>
                    <h6>Connect to Your CRM/Data Provider</h6>
                    <p>Select your CRM/Data Provider</p>
                </div>
                <div className='crm-body'>
                    <Tabs
                        defaultActiveKey="CRM"
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                        onSelect={(key)=>setImportType(key)}
                    >
                        <Tab eventKey="CRM" title="CRM">
                            <div className='row crm-card-main'>
                                {crms.length > 0 && crms.map((crm, index) => (
                                    <div className='col-sm-6 mt-2' key={`crm-${index}`}>
                                        <div
                                            className={`crm-card ${selectedCrm && selectedCrm.id === crm._id ? 'selected' : ''}`}
                                            onClick={() => handleSelect(crm._id, crm.alias)}
                                        >
                                            <p>{crm.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Tab>

                        <Tab eventKey="DataProvider" title="Data Provider">
                            <div className='row crm-card-main'>
                                {dataProviders.length > 0 && dataProviders.map((dataPro, index) => (
                                    <div className='col-sm-6 mt-2' key={`Dp-${index}`}>
                                        <div
                                            className={`crm-card ${selectedDataProvider && selectedDataProvider.id === dataPro._id ? 'selected' : ''}`}
                                            onClick={() => handleSelectVariant(dataPro._id, dataPro.alias)}
                                        >
                                            <p>{dataPro.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                <div className='crm-footer'>
                    {ImportType==='CRM'&&
                        <button
                            className='crm-import'
                            onClick={handleImportCrm}
                            disabled={crmLoading || !selectedCrm}
                        >
                            {crmLoading ? (
                                <>
                                    <span className='spinner-border-crm spinner-border-sm mr-2' role='status' aria-hidden='true'></span>
                                    &nbsp;Importing Data...
                                </>
                            ) : (
                                'Import Data'
                            )}
                        </button>
                    }
                    {ImportType==='DataProvider'&&
                        <button
                            className='crm-import'
                            onClick={handleCriteriaModal}
                            disabled={crmLoading || !selectedDataProvider}
                        >
                            {crmLoading ? (
                                <>
                                    <span className='spinner-border-crm spinner-border-sm mr-2' role='status' aria-hidden='true'></span>
                                    &nbsp;Importing Data...
                                </>
                            ) : (
                                'Import Data'
                            )}
                        </button>
                    }
                </div>
            </div>
            {success === 1 && successType === 'crm' && (
                <div className="success-card">
                    <h6>Contacts Uploaded !</h6>
                    <ul>
                        <li><strong>Created : </strong> {successMsg.uploaded}</li>
                        <li><strong>Updated : </strong> {successMsg.existingUpdated}</li>
                        <li><strong>Failed : </strong> {successMsg.failed} <button onClick={() => handleViewFailedList('crm')} style={{ color: 'red' }}>View Failed List</button></li>
                        <li><strong>Existing - Segment: </strong> {successMsg.existingLeadSegment}</li>
                    </ul>
                </div>
            )}
            {success === 2 && successType === 'crm' && (
                <div className="error-card">
                    <h6>Failed  to Upload</h6>
                    <p>{errorMsg}</p>
                </div>
            )}
            <Modal show={CriteriaModal} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={handleCriteriaModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Criteria</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleImportDataProvider}>
                        <Form.Group className="mb-3">
                            <Form.Label>Set filters</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="filter"
                            name="filter"
                            value={ApolloFilters.filter || ''}
                            onChange={handleFormChange}
                            />
                        </Form.Group>                        
                        <Form.Group className="mb-3">
                            <Form.Label>Select Channel</Form.Label>
                            <Form.Select 
                                name="list" 
                                value={ApolloFilters.list || ""} 
                                onChange={handleFormChange}>
                                <option value="" disabled>Select a List</option>
                                {ApolloList.map((item,index)=>(<option key={`option-${index}`} value={item.id}>{item.name}</option>))}
                            </Form.Select>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            import
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default HandleCrmUpload