
import React, { useState, useCallback } from "react";
import NavbarComp from "../../components/NavbarComp";
import HeaderComp from '../../components/HeaderComp';
import { useNavigate, useParams } from "react-router-dom";
import ProgressStepper from "../EventProgram/ProgressStepper";
import ReachThumbnail from '../../components/Assets/reach-thumbnail.png'
import '../../components/CSS/Style.css'
import Tconnector from '../../components/Assets/t-connector.svg';
const steps = [
    {
        label: '1',
        name: 'step 1',
        className: 'step1 custom-step',
        subSteps: [
            { label: 'Step 1.1' },
            { label: 'Step 1.2' },
            { label: 'Step 1.3' },
            { label: 'Step 1.4' },
        ],
        stepHead: 'Basic Information',
        stepText: 'Provide essential details about your business and event to enhance marketing automation with our AI.'
    },
    {
        label: '2',
        name: 'step 2',
        className: 'step2 custom-step',
        subSteps: [
            { label: 'Step 2.1' },
            { label: 'Step 2.2' },
            { label: 'Step 2.3' },
            { label: 'Step 2.4' },
        ],
        stepHead: 'Journey',
        stepText: 'Guide, remind, and follow up with attendees through every event stage.'
    },
    {
        label: '3',
        name: 'step 3',
        className: 'step3 custom-step',
        subSteps: [
            { label: 'Step 3.1' },
            { label: 'Step 3.2' },
            { label: 'Step 3.3' },
            { label: 'Step 3.4' },
        ],
        stepHead: 'Results/Outcomes',
        stepText: 'Evaluate success and impact from event awareness to attendance and follow-up communication.'
    },
];
const JourneyEdit = () => {
    const navigate = useNavigate();
    const {programId} = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [currentSubStep, setCurrentSubStep] = useState(0);

    const handleContinue = () => {
        navigate(`/outcome/${programId}`);
    }
    const handleEdit = ( ) => {
        navigate(`/journey/${programId}`);
    }
    
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredR, setIsHoveredR] = useState(false);
    const [isHoveredA, setIsHoveredA] = useState(false);
    return (
        <div className="app">
            <NavbarComp />
            <div className="content overflow-hidden">
                <HeaderComp></HeaderComp>
                <div className="container-fluid px-4 pt-3">

                    <div className="row justify-content-around">
                        <div className="col-md-2">
                            <div style={{ height: '63vh' }} className="bg-light rounded border border-secondary border-2">
                                <ProgressStepper
                                    steps={steps}
                                    currentStep={1}
                                    currentSubStep={0}
                                    setCurrentStep={setCurrentStep}
                                    setCurrentSubStep={setCurrentSubStep}
                                />
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div style={{ height: '63vh' }} className="bg-light rounded  border border-secondary border-2 overflow-hidden position-relative">
                                <div className="container ms-3 pt-4">
                                    <div className="row align-items-center">
                                        <div className="col-md-11">
                                            <div className="mb-0 alert bg-secondary text-success p-2 fw-bold" style={{ fontWeight: '600', fontFamily: 'Poppins' }}>Journey</div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-11">
                                            <div className="journey-edit-tab">
                                                <div className="journey-cards">
                                                    <div className="reach-card">
                                                        <h6>Reach</h6>
                                                        <div className="reach-card-data"
                                                            onMouseEnter={() => setIsHovered(true)}
                                                            onMouseLeave={() => setIsHovered(false)}>
                                                            <div className="card-thumbnail">
                                                                {isHovered ?
                                                                    <>
                                                                        <p className="p-4 thumbnail-text">
                                                                        Make potential attendees aware of your event and guide them to register.
                                                                        </p>
                                                                    </>
                                                                    :

                                                                    <>
                                                                        <img src={ReachThumbnail} alt="reach thumbnail" />
                                                                    </>
                                                                }

                                                            </div>
                                                            <div className="card-thumbnail-btn">
                                                                <button className="thumbnail-edit" onClick={handleEdit}>Edit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="thumbnail-connector ">
                                                        <div className="t-wrapper">
                                                            <img src={Tconnector} alt="t-connector" />
                                                        </div>
                                                    </div>
                                                    <div className="reach-card">
                                                        <h6>Register</h6>
                                                        <div className="reach-card-data"
                                                            onMouseEnter={() => setIsHoveredR(true)}
                                                            onMouseLeave={() => setIsHoveredR(false)}>
                                                            <div className="card-thumbnail">
                                                                {isHoveredR ?
                                                                    <>
                                                                        <p className="p-4 thumbnail-text">
                                                                        Keep your registrants excited and remind them to attend the upcoming event.
                                                                        </p>
                                                                    </>
                                                                    :

                                                                    <>
                                                                        <img src={ReachThumbnail} alt="reach thumbnail" />
                                                                    </>
                                                                }

                                                            </div>
                                                            <div className="card-thumbnail-btn">
                                                                <button className="thumbnail-edit" onClick={handleEdit}>Edit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="thumbnail-connector ">
                                                        <div className="t-wrapper">
                                                            <img src={Tconnector} alt="t-connector" />
                                                        </div>
                                                    </div>
                                                    <div className="reach-card">
                                                        <h6>Attend</h6>
                                                        <div className="reach-card-data"
                                                            onMouseEnter={() => setIsHoveredA(true)}
                                                            onMouseLeave={() => setIsHoveredA(false)}>
                                                            <div className="card-thumbnail">
                                                                {isHoveredA ?
                                                                    <>
                                                                        <p className="p-4 thumbnail-text">
                                                                        Follow up after the event, offering highlights and further engagement opportunities.
                                                                        </p>
                                                                    </>
                                                                    :

                                                                    <>
                                                                        <img src={ReachThumbnail} alt="reach thumbnail" />
                                                                    </>
                                                                }

                                                            </div>
                                                            <div className="card-thumbnail-btn">
                                                                <button className="thumbnail-edit" onClick={handleEdit}>Edit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end my-3">
                        <div className="col-md-6">
                            <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                                <button
                                    className="btn btn-secondary me-2 btn-full"
                                    onClick={() => navigate("/program")}
                                    style={{ border: '2px solid #028897' }}
                                    type="button"
                                >
                                    cancel
                                </button>
                                <button
                                    className={`btn text-white btn-full btn-success`}
                                    onClick={handleContinue}
                                    type="button"
                                >
                                    continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default JourneyEdit