import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";
import img1 from '../../components/Assets/SettingsImg/delete1.png'

const Customfeild = () => {

    return (
        <>
            <div
                className="overflow-hidden position-relative"
                style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '69vh'
                }}>
                <div className="container">
                    <div className="row " style={{ paddingTop: '10px' }}>
                        <div className="col-sm-12">
                            <p className="setting-header">CUSTOM FIELD SETTINGS</p>
                        </div>
                    </div>

                    {/* ----------- */}
                    <div className="row">
                        <div className="col-sm-12 ">
                            <form action="" className="custom-main-div">
                                <div>
                                    <label>Feild Type</label>
                                    <select name="" id="">
                                        <option value="">Select</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Field Label</label>
                                    <input type="text" className="feildlabel" name="" placeholder="Enter Field Label" />
                                </div>
                                <div>
                                    <label>Default Value</label>
                                    <input type="text" className="defaultvalue" name="" placeholder="Enter Default Value" />

                                </div>
                                <div>
                                    <label>Default Value</label>
                                    <input type="text" className="defaultvalue1" name="" placeholder="Enter Default Value" />

                                </div>
                                <div style={{ width: '15%',padding:'0',margin:'0'}}>
                                    <button type="button" className="delete-customimg"><img src={img1} className="" alt="" /></button>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <button className="addcustom">+ Add Custom Field</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default Customfeild;