import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";
import SearchFilter from "./listModels/SearchBar";
import TableComponent from "./listModels/Table";
import Pagination from "./listModels/Pagination";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { RxDownload } from "react-icons/rx";

const Contact = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([
        "firstname",
        "lastname",
        "email",
        "mobile",
        "country",
        "state",
        "city",
        "linkedin_url_personal",
        "date_added",
        "join_time",
    ]);
    const [additionalColumns, setAdditionalColumns] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterValues, setFilterValues] = useState([]);
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        const fetchData = async () => {
            const api = await axios.post(
                `${process.env.REACT_APP_BASE_URL_API}/api/masterData/getContactMasterList`,
                {},
                { withCredentials: true }
            );
            const response = api.data;
            setData(response);

            if (response.length > 0) {
                const allColumns = Object.keys(response[0]);
                setAdditionalColumns(allColumns.filter((col) => !selectedColumns.includes(col)));
                // Get unique countries for filter, excluding blank values
                const countries = [
                    ...new Set(response.map((item) => item.country).filter((country) => country && country.trim() !== ""))
                ];
                setFilterValues(countries);
            }
        };

        fetchData();
    }, [selectedColumns]);

    const filteredData = data.filter((row) => {
        const matchesSearch = searchTerm
            ? Object.values(row).some((value) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            : true;
        const matchesFilter =
            filterType && selectedFilterValue
                ? row[filterType]?.toLowerCase() === selectedFilterValue.toLowerCase()
                : true;

        return matchesSearch && matchesFilter;
    });

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );
    // ------------------------ export to excel --------------------------------------------
    const handleExport = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Contacts");
        const columns = selectedColumns.map((col) => ({
            header: col,
            key: col,
            width: 20,
        }));
        worksheet.columns = columns;

        const exportData = selectedRows.length > 0
            ? data.filter((row) => selectedRows.includes(row._id))
            : data;
        exportData.forEach((row) => {
            const rowData = {};
            selectedColumns.forEach((col) => {
                rowData[col] = row[col] || "";
            });
            worksheet.addRow(rowData);
        });

        //------- stlyes for the excel ----
        worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell) => {
                cell.font = { name: "Arial", size: 10 };
                cell.alignment = { vertical: "middle", horizontal: "center" };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                if (rowNumber === 1) {
                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FFD3E4CD" },
                    };
                }
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        saveAs(blob, "Contacts.xlsx");
    };

    return (
        <div className="app">
            <NavbarComp />
            <div className="container-fluid content overflow-hidden">
                <h3 className="fw-bold master-heading">Contacts</h3>
                {/* Back to Menu Button */}

                <button
                    onClick={() => navigate("/menus")}
                    style={{
                        padding: "5px 15px",
                        backgroundColor: "#048998",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        float: 'right'
                    }}
                >
                    Back to Menu
                </button>
                {/* ---- export button ---- */}
                <button
                    onClick={handleExport}
                    style={{
                        padding: "5px 15px",
                        backgroundColor: "#048998",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        float: 'right',
                        marginRight: '10px'
                    }}
                >
                   <RxDownload id="contactexcel-icon" />
                   Export
                </button>
                <div className="container-fluid px-0 pt-2">
                    <SearchFilter
                        searchTerm={searchTerm}
                        onSearchChange={(e) => setSearchTerm(e.target.value)}
                        filterType={filterType}
                        onFilterTypeChange={(e) => setFilterType(e.target.value)}
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                        data={data}
                        selectedFilterValue={selectedFilterValue}
                        onFilterValueChange={(e) => setSelectedFilterValue(e.target.value)}
                        additionalColumns={additionalColumns}
                        onAddColumn={(col) => setSelectedColumns([...selectedColumns, col])}
                        showAddColumn={true} 
                        filterOptions={[
                            { value: "country", label: "Country" },
                            { value: "company", label: "Company" },
                            { value: "department", label: "Department" },
                        ]}
                    />
                    <TableComponent
                        data={paginatedData}
                        selectedColumns={selectedColumns}
                        selectedRows={selectedRows}
                        onRowSelect={(id) =>
                            setSelectedRows((prev) =>
                                prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
                            )
                        }
                        onHeaderSelect={(e) =>
                            setSelectedRows(e.target.checked ? data.map((row) => row._id) : [])
                        }
                        onColumnClick={(id) => (window.location.href = `/masterData/contact/${id}`)}
                        showCheckboxColumn={true} // Set to false to hide checkboxes
                    />
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        onPageChange={setCurrentPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Contact;
