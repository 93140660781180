import React from 'react';
import '../../components/CSS/ProgressStepper.css'
import { FaCheck } from 'react-icons/fa';



const ProgressStepper = ({ steps, currentStep, currentSubStep}) => {

  const calculateProgress = () => {
    const totalSubSteps = steps[currentStep].subSteps.length;
    return ((currentSubStep + 1) / totalSubSteps) * 100;
  };

  return (
    <div className="vertical-steps">
      <div className="stepper-container">
        <div className="stepper-steps">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`stepper-step ${
                currentStep >= index ? 'active' : ''
              }`}
            >
              {currentStep > index ? (
                <FaCheck></FaCheck>
              ) : (
                step.label
              )}
              {index < steps.length - 1 && (
                <div className="connector">
                  <div
                    className="progress-bar"
                    style={{
                      height: `${
                        currentStep === index ? calculateProgress() : currentStep > index ? 100 : 0
                      }%`,
                    }}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="stepper-text-container justify-content-space-around">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`stepper-text`}
            >
              <div className='head'>
                <h6>{step.stepHead}</h6>
                <p>{step.stepText}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressStepper;

