import React, { forwardRef } from 'react'
import { useGlobal } from 'context/GlobalProvider';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Paper } from '@mui/material';
import industriesData from "components/Assets/industries.json";
function IndustryDropDown() {
  
    const industryOptions = Object.keys(industriesData);
  	const {EventDetails,setEventDetails}=useGlobal();
    const subIndustryOptions = EventDetails.industry
      ? industriesData[EventDetails.industry].map((sub) => sub.description)
      : [];
    const ForwardedPaper = forwardRef((props, ref) => (
        <Paper {...props} ref={ref} style={{ maxHeight: 200, overflow: 'auto' }} />
    ));
    return (
        <>
            <div className='col-md-6'>
                {/* Industry Dropdown */}
                <Autocomplete
                    size='small'
                    options={industryOptions}
                    value={EventDetails.industry}
                    onChange={(event, value) =>setEventDetails({...EventDetails,industry:value,subIndustry:null})}
                    renderInput={(params) => (
                    <TextField {...params} label="Select Industry" variant="outlined" />
                    )}
                    ListboxComponent={ForwardedPaper}
                />
            </div>

                {/* Sub-Industry Dropdown */}
            <div className='col-md-6'>
                {EventDetails.industry &&
                    <Autocomplete
                    size='small'
                        options={subIndustryOptions}
                        value={EventDetails.subIndustry}
                        onChange={(event, value) => setEventDetails({...EventDetails,subIndustry:value})}
                        renderInput={(params) => (
                        <TextField {...params} label="Select Sub-Industry" variant="outlined" />
                        )}
                        ListboxComponent={ForwardedPaper}
                    />
                }
            </div>
        </>
    );
}

export default IndustryDropDown;