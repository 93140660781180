import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavbarComp from "components/NavbarComp";
import HeaderComp from 'components/HeaderComp';
import BasicSpeedDial from "components/SpeedDial";
import ProgressStepper from "../../EventProgram/ProgressStepper";
import 'components/CSS/Style.css'
import { useGlobal } from "context/GlobalProvider";
import ProgramDetailTab from "./ProgramDetailTab";
import InfoTabOverall from "components/InfoTabOverall";
import ExpectationTab from "./ExpectationTab";
import OffCanvas from "./OffCanvas";
import { showToast } from "../Helpers/ToastHelper";
import useMediaData from "../Hooks/MediaIcons";

const steps = [
	{
		label: '1',
		name: 'step 1',
		className: 'step1 custom-step',
		subSteps: [
		{ label: 'Step 1.1' },
		{ label: 'Step 1.2' },
		],
		stepHead:'Basic Information',
		stepText:'Provide essential details about your business and event to enhance marketing automation with our AI.'
	},
	{
		label: '2',
		name: 'step 2',
		className: 'step2 custom-step',
		subSteps: [
		{ label: 'Step 2.1' },
		{ label: 'Step 2.2' },
		{ label: 'Step 2.3' },
		{ label: 'Step 2.4' },
		],
		stepHead:'Journey',
		stepText:'Guide, remind, and follow up with attendees through every event stage.'
	},
	{
		label: '3',
		name: 'step 3',
		className: 'step3 custom-step',
		subSteps: [
		{ label: 'Step 3.1' },
		{ label: 'Step 3.2' },
		{ label: 'Step 3.3' },
		{ label: 'Step 3.4' },
		],
		stepHead:'Results/Outcomes',
		stepText:'Evaluate success and impact from event awareness to attendance and follow-up communication.'
	},
];

const LeadGeneration = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { ToggleAnimation,EventDetails,setprogressSteps,progressSteps,currentStep,currentSubStep,setHeader,activeTab } = useGlobal();
	useEffect(() => {
		setprogressSteps(steps);
		setHeader('Lead Generation');
	}, [setHeader, setprogressSteps]);

	const expectionTabData = [
		{ name: 'email', leads: 0, budget: 0, costPerLead: 0.00, enabled: true },
		{ name: 'whatsapp', leads: 0, budget: 0, costPerLead: 0.00, enabled: true },
		{ name: 'google_ad', leads: 0, budget: 0, costPerLead: 0.00, enabled: true },
		{ name: 'meta_ad', leads: 0, budget: 0, costPerLead: 0.00, enabled: true },
		{ name: 'linkedin_ad', leads: 0, budget: 0, costPerLead: 0.00, enabled: true }
	];
	const expectationData = useMediaData({initialMedia:expectionTabData,variable:'name'});//expection data with icons
	let exLeadsTotal=0;
	const formPayload = {
		programType: "lead",
		tabStepNo: activeTab,
		isStepCompleted: 1,
		eventName: EventDetails.eventName,
		eventType: EventDetails.eventType,
		serviceUrl:EventDetails.serviceUrl,
		industry:EventDetails?.industry,
		subIndustry:EventDetails?.subIndustry,
		channelDetails: EventDetails.channelDetails && EventDetails.channelDetails.map(item => {
			exLeadsTotal+=item.leads;
			return {
			channelId: 0,
			isEnabled: item.enabled,
			channelName: item.name,
			exRegistrations: item.leads,
			exBudget: item.budget,
			exCostperregister: item.costPerLead,
		}}),
		exBudgetsTotal:exLeadsTotal,
		exLeadsTotal:EventDetails.exLeads,
	};	
	const channelCards = [
		...(EventDetails.channelDetails
		? EventDetails.channelDetails
			.filter(item => item.enabled)
			.map((item, index) => ({
			cardType: "channel",
			cardId: 0,
			cardName: item.name,
			icon:item.logo,
			isEnabled: true,
			projectedCount: "0",
			actualCount: "0",
			cardLabel:item.displayName,
			expectedLabel:item.expected_label,
			reachLabel:item.reach_label,
			no_campaigns_label:item.no_campaigns_label,
			selectedData: [],
			setupStatus: "0",
			childConnector: (index + 1).toString(),
			}))
		: []),
		{
			cardType: "channel",
			cardId: "0",
			cardName: "organic_traffic",
			isEnabled: true,
			projectedCount: "0",
			selectedData: [],
			setupStatus: "1",
			childConnector: (EventDetails.channelDetails ? EventDetails.channelDetails.filter(item => item.enabled).length + 1 : 1).toString(),
		},
		{
		cardType: "page",
		cardId: "0",
		cardName: "web_page_label",
		isEnabled: true,
		projectedCount: "0",
		actualCount: "0",
		selectedData: [],
		setupStatus: "0",
		childConnector: (EventDetails.channelDetails ? EventDetails.channelDetails.filter(item => item.enabled).length + 2 : 1).toString(),
		},
		{
		cardType: "form",
		cardId: '0',
		cardName: "web_form_label",
		isEnabled: true,
		projectedCount: "0",
		actualCount: "0",
		selectedData: [],
		setupStatus: "0",
		childConnector: (EventDetails.channelDetails ? EventDetails.channelDetails.filter(item => item.enabled).length + 3 : 2).toString(),
		}
	];


	const handleEventFormSubmit = async () => {
		const channelDetails=EventDetails?.channelDetails;
		if (!channelDetails || !channelDetails.some(item => item.enabled === true)) {
			showToast('Please select any one channel','error');
			return false;
		}
		setLoading(true);
		try {
			const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programDetails`, {
				method: 'POST',
				credentials: 'include',
				headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				formPayload,
				}),
			});
			const data = await response.json();
			const programId = data._id;
			//console.log(data);
			const eventName = formPayload.eventName;
			const segmentName = `${eventName} Lead List`;
			const segmentAlias =`lead_list_${programId}`;


			const qualifiedSegmentApi = await fetch(`${process.env.REACT_APP_BASE_URL_API}/segments/createSegment`, {
				method: 'POST',
				credentials: 'include',
				headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				is_published: 1,
				name: `Qualified_list_${programId}`,
				description: "",
				alias: `Qualified_list_${programId}`
				}),
			});
			const qualifiedLeads = await qualifiedSegmentApi.json();

			const VoicequalSegmentApi = await fetch(`${process.env.REACT_APP_BASE_URL_API}/segments/createSegment`, {
				method: 'POST',
				credentials: 'include',
				headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				is_published: 1,
				name: `voice_qual_list_${programId}`,
				description: "",
				alias: `voice_qual_list_${programId}`,
				watchNotification:{status:true,actions:[{action:'push_to_crm',segment_id:qualifiedLeads.segmentId}]}
				}),
			});
			const voice_qual_list = await VoicequalSegmentApi.json();

			const ordersSegmentApi = await fetch(`${process.env.REACT_APP_BASE_URL_API}/segments/createSegment`, {
				method: 'POST',
				credentials: 'include',
				headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				is_published: 1,
				name: `order_list_${programId}`,
				description: "",
				alias: `order_list_${programId}`
				}),
			});
			const orderLeads = await ordersSegmentApi.json();

			const leadList = await fetch(`${process.env.REACT_APP_BASE_URL_API}/segments/createSegment`, {
				method: 'POST',
				credentials: 'include',
				headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				is_published: 1,
				name: segmentName,
				description: "",
				alias: segmentAlias,
				watchNotification:{status:true,actions:[{action:'add_to_voice_qual',segment_id:voice_qual_list.segmentId}]}
				}),
			});
			const dataSeg = await leadList.json();
			const segmentId = dataSeg.segmentId;
			const lastChildConnector = channelCards.length > 0
				? parseInt(channelCards[channelCards.length - 1].childConnector, 10)
				: 0;
			channelCards.push({
				cardType: "list",
				cardId: segmentId,
				cardName: "lead_list_label",
				isEnabled: true,
				projectedCount: "0",
				actualCount: "0",
				selectedData: [],
				setupStatus: "1",
				childConnector: (lastChildConnector + 1).toString(),
			});
			const qualifyBuilder = [
				{
				cardType: "list",
				cardId: segmentId,
				cardName: "lead_list_label",
				isEnabled: true,
				projectedCount: "0",
				actualCount: "0",
				selectedData: [],
				setupStatus: "1",
				childConnector: "1",
				},
				{
				cardType: "r_reminder",
				cardId: segmentId,
				cardName: 'email_nurture',
				isEnabled: true,
				projectedCount: "0",
				actualCount: "0",
				selectedData: [],
				setupStatus: "0",
				childConnector: "2",
				},
				{
				cardType: "r_reminder",
				cardId: segmentId,
				cardName: 'whatsapp_series',
				isEnabled: true,
				projectedCount: "0",
				actualCount: "0",
				selectedData: [],
				setupStatus: "0",
				childConnector: "3",
				},
				{
					cardType: "filter1",
					cardId: segmentId,
					cardName: 'qual_filters',
					isEnabled: true,
					projectedCount: "0",
					actualCount: "0",
					selectedData: [],
					setupStatus: "0",
					childConnector: "4",
				},
				{
					cardType: "lead_score",
					cardId: segmentId,
					cardName: 'lead_score',
					isEnabled: true,
					projectedCount: "0",
					actualCount: "0",
					selectedData: [],
					setupStatus: "0",
					childConnector: "5",
				},
				{
					cardType: "filter2",
					cardId: voice_qual_list.segmentId,
					cardName: 'voice_qual',
					isEnabled: true,
					projectedCount: "0",
					actualCount: "0",
					selectedData: [],
					setupStatus: "1",
					childConnector: "6",
				},
				{
				cardType: "qualifiedlist",
				cardId: qualifiedLeads.segmentId,
				cardName: 'qualified_leads_list_label',
				isEnabled: true,
				projectedCount: "0",
				actualCount: "0",
				selectedData: [],
				setupStatus: "1",
				childConnector: "7",
				},
				{
					cardType: "filter3",
					cardId: qualifiedLeads.segmentId,
					cardName: 'status_check',
					isEnabled: true,
					projectedCount: "0",
					actualCount: "0",
					selectedData: [],
					setupStatus: "0",
					childConnector: "8",
				},
			]

			const sendtosalesBuilder = [
				{
					cardType: "qualifiedlist",
					cardId: qualifiedLeads.segmentId,
					cardName: 'qualified_leads_list_label',
					isEnabled: true,
					projectedCount: "0",
					actualCount: "0",
					selectedData: [],
					setupStatus: "1",
					childConnector: "1",
				},
				{
					cardType: "sendtosales",
					cardId: qualifiedLeads.segmentId,
					cardName: "qualified_crm",
					isEnabled: true,
					projectedCount: "0",
					actualCount: "0",
					selectedData: [],
					setupStatus: "0",
					childConnector: "2",
				},
				{
					cardType: "leadprogress",
					cardId: qualifiedLeads.segmentId,
					cardName: "lead_progress",
					isEnabled: true,
					projectedCount: "0",
					actualCount: "0",
					selectedData: [],
					setupStatus: "0",
					childConnector: "3",
				},
				{
					cardType: "orderlist",
					cardId: orderLeads.segmentId,
					cardName: 'ordered_leads_list_label',
					isEnabled: true,
					projectedCount: "0",
					actualCount: "0",
					selectedData: [],
					setupStatus: "1",
					childConnector: "4",
				},
			]
			//page and form clone 

			const page = await fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/new`, {
				method: 'POST',
				credentials: 'include',
				headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				is_published: 0,
				pagename: `${eventName}_page`,
				EventDetails: formPayload,
				programDetailId: programId,
				formName: `${eventName} Form`,
				segmentId: segmentId,
				segmentName: segmentName,
				}),
			});
			const pagedata = await page.json();
			let pageId;
			if (pagedata?.page?._id) {
				pageId = pagedata?.page?._id
			} else {
				showToast('Failed to clone Page','error');
				return false
			}




			/*if (!emailResponse.ok || !emailData.clonedTemplateIds) {
				alert('Failed to create emails');
				return;
			}*/
			channelCards.forEach((item) => {
				if (item.cardType === 'page' || item.cardType === 'form') {
				  item.cardId = pageId;
				}
			});
			// console.log(expectationData,channelCards);
			// return;
			const responseProgramBuilder = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder`, {
				method: 'POST',
				credentials: 'include',
				headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				programDetailId: programId,
				reachBuilder: channelCards,
				attendBuilder: sendtosalesBuilder,
				registerBuilder: qualifyBuilder
				}),
			});
			if (responseProgramBuilder) {
				setLoading(false);
				ToggleAnimation(true);
				navigate(`/journey/${programId}`);
			}

		} catch (error) {
		setLoading(false);
		console.error('Error fetching data:', error);
		}
	};

	const handleCheckFields = () => {
		const fieldMap=[
			{name:'eventName',tab:1,validationMsg:'Please Enter Service Name'},
			{name:'eventType',tab:1,validationMsg:'Please Select Nature of Business'},
			{name:'industry',tab:1,validationMsg:'Select at least one industries'},
			{name:'subIndustry',tab:1,validationMsg:'Select at least one sub industry'},
			{name:'exLeads',tab:2,validationMsg:'Enter a valid Lead number'},
			{name:'exBudgets',tab:2,validationMsg:'Enter a valid Budget'}
		];
		const error=fieldMap.find(field=>(activeTab+1=== field.tab && !EventDetails[field.name]));
			if(error){
				showToast(error.validationMsg,'error');
				return false;
			}
			else {
				return true
			}
	}
	const tabs=[
		{name:'Campaign Details',tabContent:<ProgramDetailTab></ProgramDetailTab>}, 
		{name:'Campaign expectation',tabContent:<ExpectationTab eventType={EventDetails.eventType} initialMedia={expectationData||[]}></ExpectationTab>}
	];
	return (
		<div className="app">
		<NavbarComp />
		<div className="content overflow-hidden">
			<HeaderComp></HeaderComp>
			<ToastContainer />
			<div className="container-fluid px-4 pt-3">
				<OffCanvas></OffCanvas>
			<div className="row justify-content-around">
				<div className="col-md-2">
				<div style={{ height: '63vh' }} className="bg-light rounded border border-secondary border-2">
					<ProgressStepper
					steps={progressSteps}
					currentStep={currentStep}
					currentSubStep={currentSubStep}
					/>
				</div>
				</div>
				<InfoTabOverall tabs={tabs} TabwiseValidation={handleCheckFields} ></InfoTabOverall>
			</div>
			<div className="row justify-content-end my-3">
				<div className="col">
				<BasicSpeedDial></BasicSpeedDial>
				</div>
				<div className="col-md-6">
				<div className="d-grid gap-1 d-md-flex justify-content-md-end">
					<button
					className="btn btn-secondary me-2 btn-full"
					onClick={() => navigate("/program")}
					style={{ border: '2px solid #028897' }}
					type="button"
					>
					cancel
					</button>
					<button
					className={`btn text-white btn-full ${activeTab !== tabs.length-1 ? "disabled btn-grey" : "btn-success"}`}
					onClick={handleEventFormSubmit}

					type="button"
					disabled={loading}
					>
					{loading ? 'Loading...' : 'Start Journey'}
					</button>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
	);

}
export default LeadGeneration;
