import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import PageBuilderModal from "screens/Journey/journeyModels/BuilderModal";
import axios from 'axios';
import { useGlobal } from "context/GlobalProvider";
import NavTabs from "components/NavTab";
import TabContent from "components/NavTabContent";
import TabFooter from "components/NavTabFooter";
import WebPagesList from "./WebPagesList";
import { GoArrowSwitch } from "react-icons/go";
import { Settings } from "@mui/icons-material";
import { showToast } from "screens/LeadGeneration/Helpers/ToastHelper";
import useClipboard from "screens/LeadGeneration/Hooks/CopyText";
const PagePreview= ({programId,pageUrl,setPageUrl})=>{
    const [PageHtml,setPageHtml]=useState('');
    const [isPublished,setisPublished]=useState('');
    const [Iframe,setIframe]=useState(false);
    const [selectedCampPage,setselectedCampPage]=useState(true);
    const [webpagemodal, setwebpagemodal] = useState(false);
    const [webpage,setwebpage] = useState({});
    const {modalState,toggleModal,channelData}=useGlobal();
    const [campformId,setcampformId]=useState('');
    const clipboard=useClipboard();

    useEffect(() => {
        if(channelData && modalState.web_page_label && !Iframe){
            setwebpage({pageName:channelData?.selectedData?.[0]?.pageName||'',url:channelData?.selectedData?.[0]?.url});
            fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/data/${channelData.cardId}?key=${Date.now()}`,{
                method: 'GET',
            })
            .then(res => {
                if(res.status===404){
                    return null;
                }
                return res.json()
            })
            .then(data => {
                if(data===null){
                    setPageHtml(`Page not found`);
                    return
                }
                setselectedCampPage(data.selectedCampPage);
                if(data.selectedCampPage){
                    setPageHtml(data.htmlContent);
                    setisPublished(data.isPublished);    
                }
                setcampformId(data.formid);
                // console.log(data);
            })
            .catch(error => console.error('Error fetching form:', error));
        }
    }, [Iframe, modalState.web_page_label, channelData]);
    const handlePublish=async(publish)=>{
        try {
            const payload={updates:[{ value: publish,field:'is_published' }]};
            const response = await axios.put(
              `${process.env.REACT_APP_BASE_URL_API}/pages/update/${channelData.cardId}`,
              payload,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
              }
            );
            // console.log('Page publish status updated:', response.data);
            if(publish)
                showToast('Page has been published!','success');
            else
                showToast('Page has been unpublished!','error');    
            setisPublished(publish);
            const metricsdata = await fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/metrics/${channelData.cardId}`, {
                method: 'get',
                credentials: 'include',
            });
            const metrics= await metricsdata.json();
            const selectedData={
                programId:programId, 
                builder:'reachBuilder',
                projected:metrics.totalClicks,
                actual:'0', 
                cardId:channelData.cardId, 
                cardName:'web_page_label',
                cardType:'page',
                cardstatus:publish?'1':'0'
            };
            await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedCardbytype`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });
        } catch (error) {
            showToast('Something went wrong','error');
            console.error('Error updating page publish status:', error.response ? error.response.data : error.message);
        }
    };
    const handleCopyLink = () => {
        const url = pageUrl;
        clipboard(url,'Link');
    };
    const handlewebpagemodal = async () => {
        setwebpagemodal(!webpagemodal);
    }
    const handleSwitch = async () => {
        if(webpage.url){
            setselectedCampPage(!selectedCampPage);
            try {
                const url=selectedCampPage?webpage.url:`${process.env.REACT_APP_PUBLIC_URL}/pages/view/${channelData.cardId}`;
                const payload={updates:[{value:!selectedCampPage ,field:'selectedCampPage'},{value:url,field:'pageurl'}]};
                const response = await axios.put(
                  `${process.env.REACT_APP_BASE_URL_API}/pages/update/${channelData.cardId}`,
                  payload,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                  }
                );
                setPageUrl(url);
                console.log(response);
            } catch (error) {
                showToast('Something went wrong','error');
                console.error('Error updating page publish status:', error.response ? error.response.data : error.message);
            }    
        }
        else{
            showToast('Add your page in the settings','error')
        }
    }
    useEffect(() => {
        const handleMessages = (event) => {
            // Check the origin of the message for security
            if (event.origin === webpage.url) {
                console.log(event);
                
            }
        };
    
        window.addEventListener('message', handleMessages);
    
        return () => {
            window.removeEventListener('message', handleMessages);
        };
    }, []);
    // console.log(isPublished,pageUrl);
    const [activeTab, setActiveTab] = useState('page_preview');
    const tabs = [
        { id: 'page_preview', name: 'Page Preview',
            component: 
            <>
                {Iframe ?(
                    <PageBuilderModal channelData={channelData} pageId={channelData.cardId} PageModal={Iframe} pagecardData={channelData} closePageModal={setIframe} programId={programId}></PageBuilderModal>
                ):(
                    <>
                        {selectedCampPage ? (
                            <div dangerouslySetInnerHTML={{__html:PageHtml}}>

                            </div>
                        ):(
                            <iframe
                                title="Page Content"
                                style={{ width: '100%', height: '700px', border: 'none' }}
                                src={webpage.url}
                            />
                        )}
                    </>
                )}
            </>,
            footer:
            <div className="row w-100 align-items-end">
                {selectedCampPage
                ?   <>
                        <div className="col-md-6">
                                {isPublished && <>
                                <h5 className='list-head'>Registration Page URL</h5>
                                <div className='footer-input'>
                                    <div className='url-input'>
                                        <input type='text' className='page-url pe-none' value={pageUrl} readOnly />
                                    </div>
                                    <div className='copy-btn'>
                                        <button onClick={handleCopyLink}><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 0.8V17.6C24 18.042 23.642 18.4 23.2 18.4H20C19.558 18.4 19.2 18.042 19.2 17.6C19.2 17.158 19.558 16.8 20 16.8H22.4V1.6H7.2V4C7.2 4.44201 6.84201 4.8 6.4 4.8C5.95799 4.8 5.6 4.44201 5.6 4V0.8C5.6 0.35799 5.95799 0 6.4 0H23.2C23.642 0 24 0.35799 24 0.8ZM17.6 5.6H0.8C0.35799 5.6 0 5.95799 0 6.4V23.2C0 23.642 0.35799 24 0.8 24H17.6C18.042 24 18.4 23.642 18.4 23.2V6.4C18.4 5.95799 18.042 5.6 17.6 5.6Z" fill="#028897" />
                                        </svg>
                                        </button>
                                    </div>
                                </div>
                                </>}
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <div className="button-div" style={{display: 'flex', width:'70%'}}>
                                <button onClick={()=>setIframe(true)}>Edit</button>
                                <button className="ms-4" onClick={()=>handlePublish(!isPublished)}>{isPublished&&<>unpublish</>}{!isPublished&&<>publish</>}</button> 

                            </div>
                        </div>
                    </>
                :<></>}
            </div>,
            customButtons:
            <div>
                <button className="btn btn-link p-0" title={`switch to ${selectedCampPage?'Web page':'Camp page'}`} onClick={()=>handleSwitch()}><GoArrowSwitch size={20}></GoArrowSwitch></button>
                <button className="btn btn-link p-0 ms-2" title="web page settings" onClick={()=>handlewebpagemodal()}><Settings></Settings> </button>
            </div>
        },
    ];
    return(
        <>
            <Modal
            show={modalState.web_page_label}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={false}
            onHide={()=>toggleModal("web_page_label")} fullscreen={true}
            style={webpagemodal?{zIndex:'1048'}:{}}
            >
                <Modal.Header closeButton>
                    <NavTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}></NavTabs>
                </Modal.Header>
                <Modal.Body>
                    <TabContent activeTab={activeTab} tabContents={tabs}></TabContent>
                </Modal.Body>
                <Modal.Footer>
                    <TabFooter  activeTab={activeTab} tabContents={tabs}></TabFooter>
                </Modal.Footer>
            </Modal>
            <WebPagesList webpagemodal={webpagemodal} setwebpagemodal={setwebpagemodal} programId={programId} form={webpage} formId={campformId} pageId={channelData?.cardId} setform={setwebpage}></WebPagesList>
        </>
    );
}
export default PagePreview;