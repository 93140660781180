import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import connector from 'components/Assets/Connector line.svg'
import { MdMoreVert } from "react-icons/md";
import { CgMathPlus } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import FailedList from 'components/FailedList';
import { GrClone } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegClone } from "react-icons/fa6";
import { useGlobal } from 'context/GlobalProvider';
import HandleUpload from '../Recipient/HandleUpload';
import HandleCrmUpload from '../Recipient/HandleCrmUpload';
import DataGridTable from 'components/DataGrid';

const types = [
    { name: 'CRM', type: 'crm' },
    { name: 'CSV', type: 'csv' },
    { name: 'None', type: '' }
];

const WhatsappChannelModel = ({ programId, programDetails }) => {
    const [whatsapps, setWhatsapps] = useState([]);
    const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
    const [recipientCount, setRecipientCount] = useState(0);
    const [previewShow, setPreviewShow] = useState(false);
    const [segmentId, setSegmentId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialRows] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [activeTab, setActiveTab] = useState('Setup Drip Email');
    const User = JSON.parse(localStorage.getItem('user'));
    const [failedRows, setFailedRows] = useState([]);
    const [failedColumns, setFailedColumns] = useState([]);
    const [failed, setFailed] = useState(false);
    const [senderList, setSenderList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const {modalState,toggleModal,channelData}=useGlobal();

    const handleTemplateChange = async (selectedwhatsappTemp) => {
        
        try {
            setSelectedWhatsapp({...selectedWhatsapp, ...selectedwhatsappTemp});
            setWhatsapps((prev)=>{
                let whatsappList = [...prev];
                whatsappList[selectedWhatsapp.index]={...selectedWhatsapp, ...selectedwhatsappTemp};
                return whatsappList;
            })
        } catch (error) {
            console.error('API call failed:', error);
        }
    };

    useEffect(() => {
        const getAllWhatsappTemp = async () => {
            try {
                const api = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/sendWhatsapp/getTemplates`, { withCredentials: true });
                const dataAll = api.data.templates.map(whatsapp => ({
                    value: whatsapp.tempSid,
                    label: whatsapp.name,
                    templateName: whatsapp.name,
                    cta:whatsapp.cta||'',
                    tempSid:whatsapp.tempSid,
                    body:whatsapp.body
                }));

                setTemplateList(dataAll);
            } catch (error) {
                console.log(error);
            }
        };
        getAllWhatsappTemp();
    }, []);
    useEffect(() => {
        const fetchSenderList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/platform?platformAlias=sendWhatsapp`, {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const result = await response.json();
                    console.log('API response:', result);
                    if (result.length > 0 && result[0].tokenOrKey) {
                        const tokenArray = result[0].tokenOrKey.split(',');
                        setSenderList(tokenArray);
                    } else {
                        console.error('No valid tokenOrKey found in API response');
                    }
                } else {
                    console.error('Error in API response:', response.statusText);
                }
            } catch (error) {
                console.error('Error making API request:', error);
            }
        };
        fetchSenderList();
    }, []);
    const getDateLogic = (index) =>{
        let dateLogic,sendAfterDays;
        const dayOffset = index * 10;
        if (channelData.builder === "reachBuilder") {
            if (index === 0) {
                dateLogic = 'This Whatsapp will be Sent when the program starts';
                sendAfterDays = 0;
            } else {
                dateLogic = `This Whatsapp will be Sent after ${dayOffset} days`;
                sendAfterDays = dayOffset;
            }
        };
        if (channelData.builder === "registerBuilder") {
            if (index === 0) {
                dateLogic = 'This Whatsapp will be Sent when the user registers';
                sendAfterDays = 0;
            } else {
                dateLogic = `This Whatsapp will be Sent after ${dayOffset} days`;
                sendAfterDays = dayOffset;
            }
        }
        return { dateLogic, sendAfterDays }
    };

    const getWhatsapp = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/leadgen/getWhatsapps`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    programId: programId,
                    eventName: programDetails.eventName,
                    cardName: channelData.name,
                    builder: channelData.builder
                }),
            });

            const api = await response.json();
            const whatsappPromises = api.map(async (whatsapp, index) => {
                const { dateLogic,sendAfterDays } = getDateLogic(index);
                return {
                    index:index,
                    tempSid: whatsapp.tempSid,
                    templateName: whatsapp.name||whatsapp.templateName,
                    status: whatsapp.status ?? true,
                    formSubmit: (channelData.name==="whatsapp" && index === 0 )? true : false,
                    dateLogic: whatsapp.dateLogic||dateLogic,
                    sendAfterDays: whatsapp.sendAfterDays||sendAfterDays,
                    cta:whatsapp.cta||'',
                    body:whatsapp.body,
                    whatsappId:whatsapp.whatsappId
                };
            });
            const data = await Promise.all(whatsappPromises);


            setWhatsapps(data);
            if (data.length > 0) {
                setSelectedWhatsapp(data[0]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getRecipientListCount = async (segmentId) => {
        try {
            const list = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/getRecipentCounts/${segmentId}`, { withCredentials: true });
            if (list.data.count) {
                setRecipientCount(list.data.count)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (channelData.name === 'whatsapp' && channelData.cardId !== '0' && channelData.cardId !== undefined) {
            getRecipientListCount(channelData.cardId);
        }
        if (channelData.name === 'whatsapp' ||  channelData.name==='whatsapp_series') {
            getWhatsapp();
        }
    }, [channelData]);


    const handleWhatsappClick = (whatsapp) => {
        console.log(whatsapp);
        setWhatsappEdit(false);
        setSelectedWhatsapp(whatsapp);
    };

    const handleSave = async () => {
        console.log('Channel Data:', channelData);
        const selectedData = {
            programId: programId,
            selectedChannel: channelData.name,
            builder: channelData.builder,
            saveData: whatsapps
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/leadgen/saveSelectedChannel`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });

            if (response.ok) {
                await response.json();
                //console.log('API response:', result);
                toggleModal("whatsapp");
            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };

    const [whatsappEdit, setWhatsappEdit] = useState(false);
    const [whatsappTempPreview, setWhatsappTempPreview] = useState('');
    const previewTemplate = async (whatsapptempId) => {
        if (whatsapptempId !== '') {
            try {
                const template=templateList.find(({tempSid})=>tempSid===whatsapptempId);
                setWhatsappEdit(true);
                setWhatsappTempPreview(template.body);
            } catch (error) {
                alert('Error storing data!');
                console.error('Error fetching data:', error);
            }
        } else {
            alert('Data is missing');
            setWhatsappEdit(false);
        }
    }
    const handlePreview = async (type) => {
        let id;
        if (segmentId === '') {
            id = channelData.cardId;
        } else {
            id = segmentId;
        }

        setPreviewShow(true);
        try {
            const segmentList = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/getLeadSegmentListType`, { type, id }, { withCredentials: true });
            const response = segmentList.data;

            if (response && response.length > 0) {
                const formattedRows = response.map(item => {
                    const countryCode = item.leadData.country_code;
                    const mobile = item.leadData.mobile;

                    // Logic to format mobile number conditionally
                    let formattedMobile = '';
                    if (countryCode && mobile) {
                        formattedMobile = `+${countryCode}-${mobile}`;
                    } else if (mobile) {
                        formattedMobile = mobile; // Just display mobile if countryCode is null
                    } else if (countryCode) {
                        formattedMobile = `+${countryCode}`; // Just display country code if mobile is null
                    }

                    return {
                        ...item.leadData,
                        segment_id: item.segment_id,
                        lead_id: item.lead_id,
                        formattedMobile,
                    };
                });

                setRows(formattedRows);
                setInitialRows(formattedRows);
                setError(false)
                setColumns([
                    { field: '_id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                ]);
            } else {
                setRows([]);
                setError('No data found for this segment.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);

        if (value === '') {
            // Reset to the original rows if search is cleared
            setRows(initialRows);
        } else {
            const filteredRows = initialRows.filter((row) =>
                Object.values(row).some((val) =>
                    String(val).toLowerCase().includes(value)
                )
            );
            setRows(filteredRows);
        }
    };


    const options = types.map((item) => ({
        value: item.type,
        label: item.name,
    }));
    const [activeTooltip, setActiveTooltip] = useState(null);

    const toggleTooltip = (index) => {
        setActiveTooltip((prevState) => (prevState === index ? null : index));
    };

    const handleViewFailedList = (type) => {
        setFailed(true);
    }

    const handleAddNewWhatsapp = async () => {
        const index=whatsapps.length;
        const { dateLogic,sendAfterDays } = getDateLogic(index);
        const newWhatsapp={
            index:index,
            status: true,
            formSubmit: (channelData.name==="whatsapp" && index === 0 )? true : false,
            dateLogic: dateLogic,
            sendAfterDays:sendAfterDays
        };
        setWhatsapps(prev=>([...prev,newWhatsapp]));
        setSelectedWhatsapp(newWhatsapp);
    };

    const handleDelete = (index) => {
        setWhatsapps(prevEmails => {
            const updatedEmails = prevEmails.filter(email => email.index !== index);
            const reindexedEmails = updatedEmails.map((email, i) => {
                const { dateLogic,sendAfterDays } = getDateLogic(i);
                return {
                    ...email,
                    index: i,
                    dateLogic,
                    sendAfterDays
                };
            });
            return reindexedEmails;
        });

        setActiveTooltip(null);
    };

    const handleClone = async (template) => {
        const index=whatsapps.length;
        const { dateLogic,sendAfterDays } = getDateLogic(index);
        const cloneWhatsapp={
            ...template,
            templateName:`${template.templateName} clone`,
            index:index,
            status: true,
            formSubmit: (channelData.name==="whatsapp" && index === 0 )? true : false,
            dateLogic: dateLogic,
            sendAfterDays:sendAfterDays
        };
        setWhatsapps(prev=>([...prev,cloneWhatsapp]));
        setSelectedWhatsapp(cloneWhatsapp);
        setActiveTooltip(null)
    };


    const [moveEmail, setMoveEmail] = useState(null);

    const handleMoveStart = (email) => {
        setMoveEmail(email);
        setActiveTooltip(null)
    };

    const handleMoveEnd = (targetEmail) => {
        if (!moveEmail) return;

        const moveIndex = whatsapps.findIndex(email => email.index === moveEmail.index);
        const targetIndex = whatsapps.findIndex(email => email.index === targetEmail.index);

        const updatedEmails = [...whatsapps];
        [updatedEmails[moveIndex], updatedEmails[targetIndex]] = [updatedEmails[targetIndex], updatedEmails[moveIndex]];

        setWhatsapps(updatedEmails);
        setMoveEmail(null);
        setActiveTooltip(null)
    };
    const handleCancelMove = () => {
        setMoveEmail(null);
        setActiveTab(null)
    }
    const handleUploadProps = {
        handleViewFailedList,
        getRecipientListCount,
        User,
        channelData,
        programId,
        setSegmentId,
        setFailedColumns,
        setFailedRows
    };
    return (
        <div>
            <Modal
                size="xl"
                show={modalState.whatsapp}
                onHide={()=>toggleModal("whatsapp")}
                aria-labelledby="example-modal-sizes-title-lg"
                className="custom-emailChannel-modal"
            >
                <Modal.Header closeButton>
                    <nav className='emailChannel-tab'>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setActiveTab('Setup Drip Email')}>Drip Whatsapp</button>
                            {channelData.name === 'whatsapp' && <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setActiveTab('Recipient List')}>Recipient List ( {recipientCount} )</button>}
                        </div>
                    </nav>
                </Modal.Header>
                <Modal.Body>

                    <div className='' style={{ display: 'flex', width: '100%' }}>
                        <div className='' style={{ width: '80%', background: '#E6F4F5', padding: '10px 30px' }}>
                            <b>Setup Whatsapp</b>
                        </div>
                        <div className='' style={{ width: '10%', }}>
                            {activeTab === 'Recipient List' &&
                                <>
                                    {previewShow === false
                                        ? <button onClick={() => {
                                            handlePreview('')
                                            setPreviewShow(!previewShow)
                                        }} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Preview List</button> : <button onClick={() => setPreviewShow(!previewShow)} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Close</button>
                                    }
                                </>
                            }
                        </div>
                        <div className='' style={{ width: '10%', }}>
                            <button onClick={handleSave} style={{ marginLeft: '10px', padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Save & Close</button>
                        </div>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            {whatsapps.length > 0 &&
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='email-flow-div'>
                                            <div className='flow-header d-flex'>
                                                <div className='header-text'>
                                                    <h6>Flow Preview</h6>
                                                </div>
                                                <div className='header-buttons'>
                                                    <button>Edit Flow</button>
                                                    <button className='add-new-email' onClick={handleAddNewWhatsapp}>Add New</button>
                                                </div>
                                            </div>
                                            <div className='email-flow mt-4'>
                                                {whatsapps?.length > 0 && whatsapps.map((template, index) => (
                                                    <React.Fragment key={`${template.index}-${template.tempSid}`}>
                                                        <div
                                                            className={`email-flow-card ${selectedWhatsapp && selectedWhatsapp.index === index ? 'selected' : ''}`}
                                                            onClick={() => handleWhatsappClick(template)}
                                                        >
                                                            {moveEmail && moveEmail.index !== template.index ? (
                                                                <div className="move-here" onClick={() => handleMoveEnd(template)}>
                                                                    <CgMathPlus />
                                                                </div>
                                                            )
                                                                :
                                                                <>
                                                                    <div className='row'>
                                                                        <div className='col-sm-2'>
                                                                            <div className='icon-email'>
                                                                                <svg width="25" height="25" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#028897">
                                                                                    <path
                                                                                        d="M16.032 0c-8.837 0-16 7.163-16 16 0 2.824.739 5.573 2.142 8.008l-2.167 7.992 8.257-2.165c2.361 1.285 5.017 1.961 7.768 1.961 8.837 0 16-7.163 16-16 0-8.837-7.163-16-16-16zm0 29.438c-2.546 0-5.03-.675-7.188-1.956l-.515-.303-5.591 1.465 1.489-5.437-.336-.559c-1.328-2.213-2.032-4.757-2.032-7.348 0-7.317 5.952-13.271 13.272-13.271 7.318 0 13.271 5.953 13.271 13.271-.001 7.318-5.954 13.271-13.271 13.271zm7.346-9.734c-.402-.201-2.373-1.175-2.742-1.307-.368-.134-.636-.201-.904.201-.268.4-1.036 1.307-1.269 1.576-.234.268-.468.301-.87.1-.402-.201-1.692-.621-3.224-1.978-1.191-1.063-1.998-2.376-2.233-2.778-.234-.402-.025-.618.177-.819.184-.183.402-.469.603-.703.201-.234.268-.402.402-.67.134-.268.067-.502-.034-.703-.1-.201-.902-2.176-1.236-2.978-.268-.636-.538-.669-.804-.678-.201-.009-.435-.01-.669-.01-.234 0-.603.085-.92.402-.302.302-1.204 1.176-1.204 2.877s1.232 3.331 1.403 3.558c.168.235 2.425 3.707 5.876 5.196.822.355 1.463.567 1.963.725.826.263 1.577.226 2.17.138.661-.099 2.038-.831 2.325-1.633.286-.802.286-1.487.201-1.633-.083-.146-.301-.235-.503-.335z"
                                                                                        fill={selectedWhatsapp && selectedWhatsapp.index === index  ? 'white' : '#028897'}
                                                                                    />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-sm-8'>
                                                                            <div className='email-info'>
                                                                                <p>Send Whatsapp {template.index+1}</p>
                                                                                <p className="template-name" style={{ cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top" title={template.templateName} >{template.templateName}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-sm-2 tooltip-container-flow' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            {moveEmail && moveEmail.index === index?
                                                                                <div className='close-move' onClick={handleCancelMove}><RxCross2 /></div>
                                                                                : <button className='email-more' onClick={() => toggleTooltip(index)}>
                                                                                    <MdMoreVert />
                                                                                </button>}

                                                                            {activeTooltip === index && (
                                                                                <div className='tooltip-options'>
                                                                                    <ul>
                                                                                        <li onClick={() => handleClone(template)}><FaRegClone /> Clone</li>
                                                                                        <li onClick={() => handleMoveStart(template)}><GrClone /> Move</li>
                                                                                        <li onClick={() => handleDelete(index)} style={{ color: 'red' }}><RiDeleteBin6Line /> Delete</li>
                                                                                    </ul>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>



                                                        {index !== whatsapps.length - 1 && (
                                                            <>
                                                                <div className='connector-line'>
                                                                    <img src={connector} alt='connector' />
                                                                </div>
                                                                <div className='remove-div'>
                                                                    <div className='remove-card'>
                                                                        <h6>Remove Registered from flow</h6>
                                                                    </div>
                                                                </div>
                                                                <div className='connector-line'>
                                                                    <img src={connector} alt='connector' />
                                                                </div>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3 email-sub'>
                                        <div className='email-sub-card'>
                                            <div className='sub-header'>
                                                <svg width="25" height="25" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#028897">
                                                    <path d="M16.032 0c-8.837 0-16 7.163-16 16 0 2.824.739 5.573 2.142 8.008l-2.167 7.992 8.257-2.165c2.361 1.285 5.017 1.961 7.768 1.961 8.837 0 16-7.163 16-16 0-8.837-7.163-16-16-16zm0 29.438c-2.546 0-5.03-.675-7.188-1.956l-.515-.303-5.591 1.465 1.489-5.437-.336-.559c-1.328-2.213-2.032-4.757-2.032-7.348 0-7.317 5.952-13.271 13.272-13.271 7.318 0 13.271 5.953 13.271 13.271-.001 7.318-5.954 13.271-13.271 13.271zm7.346-9.734c-.402-.201-2.373-1.175-2.742-1.307-.368-.134-.636-.201-.904.201-.268.4-1.036 1.307-1.269 1.576-.234.268-.468.301-.87.1-.402-.201-1.692-.621-3.224-1.978-1.191-1.063-1.998-2.376-2.233-2.778-.234-.402-.025-.618.177-.819.184-.183.402-.469.603-.703.201-.234.268-.402.402-.67.134-.268.067-.502-.034-.703-.1-.201-.902-2.176-1.236-2.978-.268-.636-.538-.669-.804-.678-.201-.009-.435-.01-.669-.01-.234 0-.603.085-.92.402-.302.302-1.204 1.176-1.204 2.877s1.232 3.331 1.403 3.558c.168.235 2.425 3.707 5.876 5.196.822.355 1.463.567 1.963.725.826.263 1.577.226 2.17.138.661-.099 2.038-.831 2.325-1.633.286-.802.286-1.487.201-1.633-.083-.146-.301-.235-.503-.335z" />
                                                </svg>
                                                <h6
                                                    className=""
                                                    style={{
                                                        cursor: 'pointer',
                                                        whiteSpace: 'nowrap',  // Prevents text from wrapping to the next line
                                                        overflow: 'hidden',    // Ensures content is clipped
                                                        textOverflow: 'ellipsis', // Adds ellipsis (...) when text overflows
                                                        maxWidth: '200px',     // Adjust the width as needed
                                                    }}
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={selectedWhatsapp.templateName}  // Tooltip with the full name
                                                >
                                                    {selectedWhatsapp.templateName}
                                                </h6>
                                            </div>
                                            <div className='sub-body'>

                                                <div className='input'>
                                                    <Form.Group className="mb-3" controlId="formGridAddress1">

                                                        <Form.Label>Select Sender</Form.Label>
                                                        <select className='form-select'>
                                                            {senderList.map((sender, index) => (
                                                                <option key={index} value={sender.trim()}>
                                                                    {sender.trim()}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Change Template</Form.Label>
                                                        <Select 
                                                            placeholder='Select template'
                                                            options={templateList} 
                                                            value={{value:selectedWhatsapp.tempSid||'',label:selectedWhatsapp.templateName||''}} 
                                                            menuShouldScrollIntoView={true} onChange={(e)=>handleTemplateChange(e)}
                                                        >
                                                        </Select>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Send Date</Form.Label>
                                                        <p style={{ color: '#028897', fontSize: '10px' }}>{selectedWhatsapp.dateLogic}</p>
                                                    </Form.Group>
                                                    <button
                                                        className='btn btn-primary mt-2 edit-preview'
                                                        onClick={() => previewTemplate(selectedWhatsapp.tempSid)}
                                                    >
                                                        Preview
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-5 email-builder-show'>
                                        {whatsappEdit && (
                                            <>
                                                <button onClick={() => setWhatsappEdit(!whatsappEdit)} className='email-builder-close'>Close</button>
                                                {/* Display the value of whatsappTempPreview */}
                                                <p>{whatsappTempPreview}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            {previewShow ?
                                <>
                                    {error ? (
                                        <p>No data found</p>
                                    ) : (
                                        <div style={{ height: 450, width: '100%' }}>

                                            <div className="search-wrapper mb-2" style={{ width: '100%', display: 'flex' }}>
                                                <div className=''>
                                                    <input
                                                        type="text"
                                                        className="grid-search"
                                                        placeholder="search "
                                                        value={searchText}
                                                        onChange={handleSearch}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <Select
                                                        style={{ width: 400 }}
                                                        placeholder="Select type"
                                                        options={options}  // Provide transformed options
                                                        onChange={(option) => handlePreview(option.value)}  // Use onChange to get the selected value
                                                    />
                                                </div>
                                            </div>
                                            <DataGridTable rows={rows} columns={columns}></DataGridTable>
                                        </div>
                                    )}
                                </> :
                                <>
                                    {failed ?
                                        <>
                                            <button onClick={() => setFailed(false)} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', fontFamily: 'Poppins' }}>Close</button>
                                            <FailedList rows={failedRows} columns={failedColumns} />
                                        </>
                                        :
                                        <div className='row mt-4'>
                                            <div className='col-sm-4'>
                                                <HandleUpload {...handleUploadProps}></HandleUpload>
                                            </div>
                                            <div className='col-sm-4'>
                                                <HandleCrmUpload {...handleUploadProps}></HandleCrmUpload>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default WhatsappChannelModel;
