import React, { useState, useRef, useEffect } from "react";
import { FaTrash, FaExternalLinkAlt } from 'react-icons/fa';
import { MdOutlineLibraryAdd } from "react-icons/md";
import {ReactComponent as AiIcon} from '../../components/Assets/AiIcon.svg';
import { useLoadScript, GoogleMap, Autocomplete, Marker } from '@react-google-maps/api';
import 'rsuite/dist/rsuite.min.css';
import DateTimePicker from "../../components/DateTimepicker";

// Define the libraries array outside the component
const LIBRARIES = ['places'];

const EventDetails=({ suggestedEventNames, onData })=>{
    const [eventName, setEventName] = useState('');
    const [meetings, setMeetings] = useState([]);
    const [selectedMeetingUrl, setSelectedMeetingUrl] = useState('');
    const [selectedMeetingName, setSelectedMeetingName] = useState('');
    const [venue,setvenue]=useState(null);
    const [showvenue,setshowvenue]=useState(true);
    const [chosenplatform,setchosenplatform]=useState(null);
    const [chosenlocations,setchosenlocations]=useState([]);
	const [showmap,setshowmap]=useState(false);
    const [place, setPlace] = useState(null);
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const autocompleteRef = useRef(null);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDXJTAXgXLfDLvucefdiQ3j2ofO2_f7CE8',
        libraries: LIBRARIES,
    });
    const [eventdate, seteventdate] = useState({
        startDate: null, endDate: null
    });

    useEffect(() => {
        if (onData) {
            onData({
                eventName,
                eventdate,
                meetings,
                selectedMeetingUrl,
                selectedMeetingName,
                venue,
                chosenplatform,
                chosenlocations,
                showmap,
                place,
                inputValue
            });
        }
    }, [
        eventName, eventdate, meetings, selectedMeetingUrl, selectedMeetingName, venue, chosenplatform,
        chosenlocations, showmap, place, inputValue, onData
    ]);

    const formatDateTime = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

      
    const handleDateChange = (range) => {
        const [startDate, endDate] = range;
        seteventdate({ startDate, endDate });
      };
    
    const currentDate = new Date(); // Get the current date

	const handleremovelocation = (locationToRemove) => {
        const updatedLocations = chosenlocations.filter(location => location !== locationToRemove);
        setchosenlocations(updatedLocations);
        if(chosenlocations.length===1)
            setshowmap(true)
        else
            setshowmap(false)
    };

	const handlechangevenue=(mode)=>{
		setvenue(mode);
        if(chosenlocations.length===0)
            setshowmap(true)
        else if(chosenlocations.length>0 && showvenue)
            setshowmap(true)
        else
        setshowmap(false)
	}
    useEffect(() => {
        if (chosenplatform) {
            setSelectedMeetingUrl('');
            setSelectedMeetingName('');
          const fetchMeetings = async () => {
            try {
              const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/onlineMeeting?meetingType=${chosenplatform.toLowerCase()}`,{
                method: 'GET',
                credentials: 'include', 
              });
              const data = await response.json();
              setMeetings(data);
            } catch (error) {
              console.error('Error fetching meetings:', error);
            }
          };
    
          fetchMeetings();
        }
      }, [chosenplatform]);
    const handleMeetingChange = (event) => {
        const selectedMeetingId = event.target.value;
        const selectedMeeting = meetings.find(meeting => meeting.meetingId === selectedMeetingId);
        
        if (selectedMeeting) {
            setSelectedMeetingUrl(selectedMeeting.meetingLink);
            setSelectedMeetingName(selectedMeeting.meetingName);
        } else {
            setSelectedMeetingUrl('');
            setSelectedMeetingName('');
        }
    };
    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
          const { lat, lng } = place.geometry.location;
          setMarker({ lat: lat(), lng: lng() });
          setPlace(place);
        }
      };
      const handleGoogleMapSubmit = () => {
        if (place) {
          const name = place.name;
          const address = place.formatted_address;
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          const mapsLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
          const photos = place.photos;
          const photoUrl = photos && photos[0] ? photos[0].getUrl() : null;
          const locationData = {
            placeName: name,
            address: address,
            photoUrl: photoUrl,
            mapsLink: mapsLink,
          };
    
          // Check if the location already exists in the chosenlocations array
          const isExistingLocation = chosenlocations.some(
            (location) => location.placeName === name && location.address === address
          );
    
          if (isExistingLocation) {
            alert("This location has already been added.");
            setshowmap(false);
          } else {
            // Update the chosenlocations array
            const tempVariable = [...chosenlocations]; // Create a new array to avoid mutating state directly
            tempVariable.push(locationData);
    
            setchosenlocations(tempVariable);
            setshowmap(false);
          }
        }
      };

      /*if (loadError) {
        return <div>Error loading maps</div>;
      }*/
    
      if (!isLoaded) {
        return <div>Loading Maps...</div>;
      }

    return(        
    <div className="container">
        <div className="row mb-3">
            <div className="col-md-6">
                <label htmlFor="eventName" className="form-label">Event Name</label>
                <input
                name="eventname"
                type="text"
                className="form-control"
                id="eventName"
                placeholder="Enter event name"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                />
            </div>

            <div className="col-md-5">
                <label htmlFor="eventTime" className="form-label">Event Time and Date</label>
                    <DateTimePicker onChange={handleDateChange} />
                <input
                    type="hidden"
                    name="startDate"
                    value={formatDateTime(eventdate.startDate) || ''}
                    readOnly
                />
                <input
                    type="hidden"
                    name="endDate"
                    value={formatDateTime(eventdate.endDate) || ''}
                    readOnly
                />
            </div>
        </div>   
        {suggestedEventNames && suggestedEventNames.length > 0 ? (
              <div className={`row mb-3`}>
                <div className={`col-md-6 mb-3 d-flex d-block`}>
                    <button className="btn btn-link me-2 p-0">
                        <AiIcon style={{height:'20px',width:'20px',color:'#7280f9'}}></AiIcon>
                    </button>
                    {suggestedEventNames.map((name, index) => (
                        <button key={index} className="btn btn-sm btn-outline-gradient mx-1 text-nowrap text-truncate" style={{fontSize:'11px'}} data-bs-toggle="tooltip" data-bs-placement="top" 
                        title={name} onClick={() => {
                            if (name?.startsWith('"') && name?.endsWith('"')) {
                                name = name.slice(1, -1);
                            }
                            setEventName(name)
                        }}>
                        {name}
                      </button>
                    ))}
                </div>
              </div>  
            ) : ( <></> )} 
        <div className={venue==="online"?"row mb-3":"row"}>
            <div className={venue==='offline' && chosenlocations.length>0 && !showvenue?"d-none col":"d-block col"}>
                <label className="form-label">Event Venue</label>
                <div>
                    <div className="form-check form-check-inline">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="venueOptions"
                        id="venueOnline"
                        value="Online"
                        checked={venue==="online"}
                        onChange={()=>handlechangevenue('online')}
                        />
                        <label className="form-check-label" htmlFor="venueOnline">Online</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="venueOptions"
                        id="venueOffline"
                        value="Offline"
                        checked={venue==="offline"}
                        onChange={()=>handlechangevenue('offline')}
                        />
                        <label className="form-check-label" htmlFor="venueOffline">Offline</label>
                    </div>
                </div>
            </div>      
            <div className={venue==='online'?"d-block col":"d-none col"}>
                <label className="form-label">Choose Platform</label>
                <div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="platformOptions"
                            id="platformGoogle"
                            value="Google"
                            checked={chosenplatform==="google"}
                            onChange={()=>setchosenplatform('google')}
                        />
                        <label className="form-check-label" htmlFor="platformGoogle">Google</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="platformOptions"
                            id="platformTeams"
                            value="Teams"
                            checked={chosenplatform==="teams"}
                            onChange={()=>setchosenplatform('teams')}
                        />
                        <label className="form-check-label" htmlFor="platformTeams">Teams</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="platformOptions"
                            id="platformZoom"
                            value="Zoom"
                            checked={chosenplatform==="zoom"}
                            onChange={()=>setchosenplatform('zoom')}
                        />
                        <label className="form-check-label" htmlFor="platformZoom">Zoom</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="platformOptions"
                            id="platformOthers"
                            value="Others"
                            checked={chosenplatform==="others"}
                            onChange={()=>setchosenplatform('others')}
                        />
                        <label className="form-check-label" htmlFor="platformOthers">Others</label>
                    </div>
                </div>
            </div>
            <div className={venue==='offline' && showmap ?"d-block col":"d-none col"}>
            <div className="position-relative" style={{ top: '-4.5em' }}>
            <div className="position-absolute d-flex align-items-end" style={{bottom:30,zIndex:1}}>
               <Autocomplete
                    onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                    onPlaceChanged={handlePlaceChanged}
                    >
                    <input
                        name="geoMapSearch"
                        type="text"
                        className="form-control rounded-pill"
                        placeholder="Search for a location"
                        style={{ width: '300px', padding: '5px' }}
                    />
                </Autocomplete>
                <button className="btn btn-sm btn-success rounded-pill text-white cfs-5 ms-3" 
                onClick={() => {
                    if (!place) {
                    alert("Please Search Location before submitting."); // Optional: you can replace this with any other form of validation message
                    return;
                    }
                    handleGoogleMapSubmit();
                    setshowvenue(false);
                }}
                style={{ marginTop: '10px' }}>Submit</button>
                </div>
                <GoogleMap
                    mapContainerStyle={{
                        width: '33vw',
                        height: '33vh',
                        borderRadius: '8px',
                    }}
                    center={{ lat: 40.7128, lng: -74.0060 }} // Inline center object
                    zoom={10}
                    onLoad={map => setMap(map)}
                >
                    {marker && <Marker position={marker} />}
                </GoogleMap></div>
            {/*<button className="btn btn-success text-white rounded-pill" onClick={()=>handleaddlocation('ex2')}>Add sample place</button>*/}
            </div>
        </div>
        <div className={venue==='online'?"row mb-3 justify-content-end":"row"}>
            <div className={chosenplatform!==null && chosenplatform!=="others" && venue==="online"?"d-block col":"d-none col"}>
                <label htmlFor="meeting" className="form-label">Select Event Meeting</label>
                <select
                className="form-select"
                id="meeting"
                onChange={handleMeetingChange}
                >
                <option value="">Select a meeting</option>
                {meetings.map((meeting, index) => (
                    <option key={index} value={meeting.meetingId}>{meeting.meetingName}</option>
                ))}
                </select>
            </div>

            <div className={chosenplatform!==null &&  venue==="online"?"d-block col-md-6":"d-none"}>
                <label htmlFor="meetingUrl" className="form-label">Meeting URL</label>
                <input
                type="url"
                className="form-control"
                id="meetingUrl"
                placeholder="Enter meeting URL"
                value={selectedMeetingUrl}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className={chosenlocations.length>0 && venue==='offline'&& !showvenue?"col d-block":"d-none"}>
                <div className="form-label">Event location</div>
                <div className="container-fluid p-0 " style={{ overflow: 'scroll', maxHeight: '25vh'}}>
                        <div className="row">
                        {chosenlocations.map(function(location) {
                                return (
                                    <div className="col-md-3" style={{paddingBottom: '20px'}}>
                                        <div className="card position-relative" style={{height:'171px'}}>
                                            <img src={location.photoUrl} className="card-img-top"  height={'100px'}/>
                                            <div className="card-body">
                                            <span className="card-title text-nowrap text-truncate" style={{fontSize:"14px", display:'flex', alignItems: 'center'}}>
                                                <span className="text-truncate" style={{ display: 'inline-block', width: '170px' }}>
                                                {location.placeName}</span>
                                                <a href={location.mapsLink} target="_blank" rel="noopener noreferrer"><FaExternalLinkAlt /></a>
                                            </span>
                                            <p className="card-text text-truncate"  style={{fontSize:"14px"}}>{location.address}</p>
                                            <button className="position-absolute btn btn-light py-1" style={{ top: '10px', right: '10px' }} onClick={()=>{handleremovelocation(location)}}><FaTrash /></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}                   
                            <div className="col-md-3">
                                <div className="card position-relative border border-2 d-flex h-100 align-items-center justify-content-center bg-secondary border-success text-success border-dashed">
                                <button className="btn btn-link text-success" onClick={() => {
                                    setshowmap(true);
                                    setshowvenue(true);
                                    setPlace('');
                                    document.querySelector('input[name="geoMapSearch"]').value = ''; // Clear the text box
                                }}>
                                        <div> <MdOutlineLibraryAdd size={"40px"} color=""></MdOutlineLibraryAdd></div>
                                        Add location
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EventDetails;