import React, { useState, useEffect } from 'react';
import IndustryAndSubIndustry from './industries.json'; // Make sure the path is correct
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
const IndustryAndFrequency = ({newsLetterDetails,setNewsLetterDetails}) => {

    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [selectedSubIndustry, setSelectedSubIndustry] = useState(null);

    const industryOptions = Object.keys(IndustryAndSubIndustry).map(industry => ({
        label: industry,
        value: industry
    }));

    const subIndustryOptions = selectedIndustry
        ? IndustryAndSubIndustry[selectedIndustry].map(subIndustry => ({
            label: subIndustry.description,
            value: subIndustry.code
        }))
        : [];

    const handleIndustryChange = (selectedOption) => {
        setSelectedIndustry(selectedOption ? selectedOption.value : null);
        setSelectedSubIndustry(null);
        setNewsLetterDetails(prevDetails => ({
            ...prevDetails,
            industry: selectedOption ? selectedOption.value : null,
            subIndustry: ''
        }));
    };

    const handleSubIndustryChange = (selectedOption) => {
        setSelectedSubIndustry(selectedOption ? selectedOption.label : null);
        setNewsLetterDetails(prevDetails => ({
            ...prevDetails,
            subIndustry: selectedOption ? selectedOption.label : null
        }));
    };

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const Weeks = ['First', 'Second', 'Third', 'Fourth'];
    const months = ['First', 'Second', 'Third'];

    const handleDayChange = (e) => {
        const { value } = e.target;
        setNewsLetterDetails(prevDetails => ({
            ...prevDetails,
            sendDay: value,
        }));
    };

    const handleWeekChange = (e) => {
        const { value } = e.target;
        setNewsLetterDetails(prevDetails => ({
            ...prevDetails,
            week: value,
        }));
    };

    const handleMonthChange = (e) => {
        const { value } = e.target;
        setNewsLetterDetails(prevDetails => ({
            ...prevDetails,
            month: value,
        }));
    };
    return (
        <div className='industry-and-frequency'>
            {/* <p>{JSON.stringify(newsLetterDetails)}</p> */}
            <div className='row'>
            <div className='col-sm-6'>
                <div className='industry-select'>
                    <label>Industry</label>
                    <Select
                        options={industryOptions}
                        onChange={handleIndustryChange}
                        value={industryOptions.find(option => option.label === selectedIndustry)}
                        placeholder="Select Industry"
                        isClearable
                        className="custom-select"
                    />
                </div>
            </div>
            <div className='col-sm-6'>
                {selectedIndustry && (
                    <div className='sub-industry-select'>
                        <label>Sub-Industry</label>
                        <Select
                            options={subIndustryOptions}
                            onChange={handleSubIndustryChange}
                            value={subIndustryOptions.find(option => option.value === selectedSubIndustry)}
                            placeholder="Select Sub-Industry"
                            isClearable
                            className="custom-select"
                        />
                    </div>
                )}
            </div>
        </div>
            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <label>Frequency</label>
                    <Form>
                        <div key={``} className="mt-1">
                            <Form.Check
                                inline
                                label="Monthly"
                                name="Frequency"
                                type='radio'
                                value='Monthly'
                                id={`inline-radio-1`}
                                onChange={(e) => setNewsLetterDetails(prevDetails => ({ ...prevDetails, frequency: e.target.value }))}
                            />
                            <Form.Check
                                inline
                                label="Quarterly"
                                name="Frequency"
                                type='radio'
                                value='Quarterly'
                                id={`inline-radio-2`}
                                onChange={(e) => setNewsLetterDetails(prevDetails => ({ ...prevDetails, frequency: e.target.value }))}
                            />
                        </div>
                    </Form>
                </div>
            </div>
            <div className='senDate-tab'>
                <div className='row mt-3'>
                    <div className='col-sm-3'>
                        <label>Day of the Week</label>
                        <Form>
                            {days.map((day, index) => (
                                <div key={index} className="">
                                    <Form.Check
                                        inline
                                        label={day}
                                        name="sendDay"
                                        type='radio'
                                        value={day}
                                        id={`day-radio-${index}`}
                                        onChange={handleDayChange}
                                        checked={newsLetterDetails.sendDay === day}
                                    />
                                </div>
                            ))}
                        </Form>
                    </div>
                    {newsLetterDetails.sendDay !== '' && (
                        <div className='col-sm-3'>
                            <label>Week</label>
                            <Form>
                                {Weeks.map((week, index) => (
                                    <div key={index} className="">
                                        <Form.Check
                                            inline
                                            label={week}
                                            name="week"
                                            type='radio'
                                            value={week}
                                            id={`week-radio-${index}`}
                                            onChange={handleWeekChange}
                                            checked={newsLetterDetails.week === week}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </div>
                    )}
                    {newsLetterDetails.frequency === 'Quarterly' && newsLetterDetails.sendDay !== '' && (
                        <div className='col-sm-3'>
                            <label>Month</label>
                            <Form>
                                {months.map((month, index) => (
                                    <div key={index} className="">
                                        <Form.Check
                                            inline
                                            label={month}
                                            name="sendMonth"
                                            type='radio'
                                            value={month}
                                            id={`month-radio-${index}`}
                                            onChange={handleMonthChange}
                                            checked={newsLetterDetails.month === month}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default IndustryAndFrequency;
