import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";

const Email = ({settingType}) => {
    const navigate = useNavigate();
    const [fields, setFields] = useState([{ domain: '', email: '' }]);  
    
    const handleAddMore = () => {
        setFields([...fields, { domain: '', email: '' }]);
    };

    const handleRemove = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };

    const handleChange = (index, e) => {
        const newFields = [...fields];
        newFields[index][e.target.name] = e.target.value;
        setFields(newFields);
    };

    const handleSubmit = async () => {
        try {
            // Prepare the data to be sent to the API
            const details = fields.map((field) => ({
                domain: field.domain,
                email: field.email,
            }));
            console.log(details);
            // Send the POST request
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/othersetting`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    details,
                    alias: settingType, // Pass the email form data to the API
                }),
            });

            const data = await response.json();

            // Check if data is successfully stored
            if (data._id) {
                alert('Data stored successfully!');
            } else {
                alert('Something went wrong!');
            }
        } catch (error) {
            alert('Error storing data!');
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {  
        // Function to fetch data from the API
        const fetchProgramDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/othersetting?platformAlias=${settingType}`, {
                    method: 'get',
                    credentials: 'include'
                });
                const data = await response.json();
                const detailsData = data.map((item) => item.details).flat();
                setFields(detailsData); // Store the fetched data in state

            } catch (error) {
                console.error('Error fetching program details:', error);
            }
        };

        // Call the fetch function
        fetchProgramDetails();
    }, [settingType]);
    return (
        <div
            className="overflow-hidden position-relative"
            style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '91vh'
            }}>
            <div className="container">
                <div className="row justify-content-center" style={{ paddingTop: '20px' }}>
                    <h3>Email</h3>
                    <br />
                    <br />
                    {fields.map((field, index) => (
                        <div key={index} className="form-group" style={{ marginBottom: '20px' }}>
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        name="domain"
                                        value={field.domain}
                                        onChange={(e) => handleChange(index, e)}
                                        className="form-control"
                                        placeholder="Domain"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="email"
                                        name="email"
                                        value={field.email}
                                        onChange={(e) => handleChange(index, e)}
                                        className="form-control"
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button
                                        onClick={() => handleRemove(index)}
                                        className="btn btn-danger"
                                        disabled={fields.length === 1}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                            <p>Verificatiion Status : {field.verificationStatus}</p>
                        </div>
                    ))}
                    <button onClick={handleAddMore} className="btn btn-primary" style={{ marginRight: '10px' }}>
                        Add More
                    </button> <br></br><br></br>
                    <button onClick={handleSubmit} className="btn btn-success">
                        Submit
                    </button>
                </div>
            </div>
            <div className="position-absolute" style={{ bottom: '60px', left: '32px' }}>

            </div>
        </div>
    );
};

export default Email;
