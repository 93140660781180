import React from 'react';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { useGlobal } from 'context/GlobalProvider';
import RenderCards from './RenderCards';
import { MdDoNotDisturb } from 'react-icons/md';
const QualifyTab = ({handleChannelModel}) => {
    const { LeadJourneyTabWiseData } = useGlobal();
    const [leadList=[],leadchannels=[],leaddecision=[],qualifiedList=[]]=LeadJourneyTabWiseData?.QualifyTab?.cards;    
    const getQualifyData=[
        {CardData:leadList,CardType:'r_list'},
        {CardData:leadchannels,CardType:'r_reminder'},
        {CardData:leaddecision,CardType:'lead_score'},
        {CardData:qualifiedList,CardType:'qualifiedlist'},
    ];
    const register_connectionIds=LeadJourneyTabWiseData?.QualifyTab?.connectionIds;
    return (
        <Xwrapper>
            <div className='row main-card-div qualify-tab'>
                {getQualifyData.map( (getlead,index) => (
                    <RenderCards
                        key={`qualify${index}`}
                        CardData={getlead.CardData} 
                        CardType={getlead.CardType} 
                        handleChannelModel={handleChannelModel}
                    />
                ))}
            </div>
            {register_connectionIds.length && register_connectionIds.map((connection, index) => (
                <Xarrow
                key={`arrows-${index}`}
                start={`box-${connection.source}`}
                end={`box-${connection.target}`}
                startAnchor={`${connection.startAnchor}`}
                endAnchor={`${connection.endAnchor}`}
                color="#B3B3B3"
                strokeWidth={2}
                headSize={6}
                curveness={0.1}
                path={"grid"}
                labels={connection.enabled===false?<MdDoNotDisturb color='red'></MdDoNotDisturb>:''}
            />
            ))}
        </Xwrapper>
    );
}

export default QualifyTab;
