import React,{useEffect, useState} from 'react';
import {ReactComponent as AiIcon} from 'components/Assets/AiIcon.svg';
import { useGlobal } from 'context/GlobalProvider';
import ExpectationCards from './ExpectationCards';
import { Button, FormControl } from 'react-bootstrap';
import { FaRegEdit } from 'react-icons/fa';
const ExpectationTab = ({eventType,initialMedia}) => {
	const {EventDetails,setEventDetails}=useGlobal();
    const [editStatus,seteditStatus]=useState(EventDetails.exLeads>0?true:false);
    useEffect(() => {
        const updateMediaBasedOnType = (type) => {
            return initialMedia.map(channel => {            
                if(type === "b2bevent"){
                    if (['email', 'linkedin_ad', 'whatsapp'].includes(channel.name)) {
                        return { ...channel, enabled: true };
                    }
                    else {
                        return { ...channel, enabled: false };
                    }
                }
                else if(type === "b2cevent"){
                    if (['meta_ad', 'whatsapp', 'google_ad'].includes(channel.name)) {
                        return { ...channel, enabled: true };
                    }
                    else{
                        return { ...channel, enabled: false };
                    }
                }
                else {
                    return channel;
                }
            });
        };
        if(EventDetails?.channelDetails?.length===0){
            const eventBasedChannels=updateMediaBasedOnType(eventType);
            setEventDetails({...EventDetails,channelDetails:[...eventBasedChannels]}); 
        }
        else{
            setEventDetails((prev)=>{return {...EventDetails,channelDetails:[...prev.channelDetails]}}); 
        }
    }, [ eventType, initialMedia]);
    
    function formatNumber(num) {
        if (num >= 1e9) {
            return (num / 1e9).toFixed(1) + 'B';
        } else if (num >= 1e6) {
            return (num / 1e6).toFixed(1) + 'M';
        } else if (num >= 1e3) {
            return (num / 1e3).toFixed(1) + 'K';
        } else {
            return num.toString();
        }
    }      	  
    const handleTotalBudget = async (totalbudget) => {
        setEventDetails({...EventDetails,exBudgets:totalbudget}); 
    }
    const handleTotalLeads = async (totallead) => {
        setEventDetails({...EventDetails,exLeads:totallead}); 
    }
    return (
        <div style={{fontSize:"10px"}}>
            <div style={{ display: 'flex', marginTop: '-10px' }}>
                <AiIcon style={{height:'20px',width:'20px',color:'#7280f9'}}></AiIcon>
                <div className="gradient-text"> Recommended by Camp AI</div>
            </div>
            <ExpectationCards formatNumber={formatNumber} editStatus={editStatus}></ExpectationCards>
            <hr></hr>
            <div className="row justify-content-start">
                <div className="col-md-3">
                    <div className="card shadow-sm mb-4">
                        <div className="card-body p-2">
                        <p className="card-title ps-3 d-flex align-items-center">
                            <>Total Leads</>
                            <Button variant='link' size='sm' className='p-0 ps-1' onClick={()=>seteditStatus(false)}> <FaRegEdit size={10}></FaRegEdit> </Button> 
                        </p>
                        <p className="card-text h6 text-success ps-3 d-flex align-items-center">  
                            <FormControl className='me-2'  value={EventDetails.exLeads} size='sm' 
                                onChange={(e)=>handleTotalLeads(e.target.value)} style={{width:'100px'}} type='number' min={1} 
                                onBlur={()=>{seteditStatus(true)}}
                                disabled={editStatus}
                                >
                            </FormControl>
                            $
                        </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card shadow-sm mb-4">
                        <div className="card-body p-2">
                        <p className="card-title ps-3">Total Budget</p>
                        <p className="card-text h6 text-success ps-3 d-flex align-items-center">
                            <FormControl className='me-2 ' value={EventDetails.exBudgets} size='sm' 
                                onInput={(e)=>handleTotalBudget(e.target.value)} style={{width:'100px'}} type='number' min={1}>         
                            </FormControl>
                            $   
                        </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card shadow-sm mb-4">
                        <div className="card-body p-2">
                        <p className="card-title ps-3">Cost/Lead</p>
                        <hr className='m-2'></hr>
                        <p className="card-text h6 text-success ps-3">	
                        {
                            isNaN(EventDetails.exBudgets / EventDetails.exLeads) || EventDetails.exLeads <= 0
                            ? '0.00 $'
                            : `${formatNumber((EventDetails.exBudgets / EventDetails.exLeads).toFixed(2))} $`
                        }
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpectationTab;
