import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { BiAlignMiddle } from "react-icons/bi";
import { FaTableColumns } from "react-icons/fa6";

const SearchBar = ({
    searchTerm,
    onSearchChange,
    filterType,
    onFilterTypeChange,
    filterValues,
    setFilterValues,
    data,
    selectedFilterValue,
    onFilterValueChange,
    additionalColumns,
    onAddColumn,
    showAddColumn = false, // Default to true if not provided
    filterOptions = [], // Dynamic filter options
}) => (
    <div className="px-0">
        {/* Search Input */}
        <IoSearchOutline id="search-iconmaster" />

        <input
            className="master-search"
            type="text"
            placeholder="Search by Names,Email,Number..."
            value={searchTerm}
            onChange={onSearchChange}
            style={{ marginRight: "10px" }}
        />

        {/* Select Filter Dropdown */}
        {filterOptions.length > 0 && (
            <>
            <BiAlignMiddle id="master-select" />
            <select
                className="master-select"
                onChange={(e) => {
                    const selectedType = e.target.value;
                    onFilterTypeChange(e);

                    if (selectedType) {
                        if (data && data.length > 0) {
                            const values = [
                                ...new Set(
                                    data
                                        .map((item) => item[selectedType] || "")
                                        .filter((value) => value.trim() !== "")
                                ),
                            ];

                            if (values.length === 0) {
                                alert(`No data available for the selected filter: ${selectedType}`);
                            }

                            setFilterValues(values);
                        } else {
                            alert("No data available to filter.");
                            setFilterValues([]);
                        }
                    } else {
                        setFilterValues([]);
                    }
                }}
                value={filterType}
            >
                <option value="">Select Filter</option>
                {filterOptions.map((filter) => (
                    <option key={filter.value} value={filter.value}>
                        {filter.label}
                    </option>
                ))}
            </select>
            </>
        )}
        {/* Select Value Dropdown */}
        {filterType && filterValues.length > 0 && (
            <select
                className="master-select2"
                onChange={onFilterValueChange}
                value={selectedFilterValue}
                style={{ marginRight: "10px" }}
            >
                <option value="">Select Value</option>
                {filterValues.map((value) => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                ))}
            </select>
        )}

        {/* Add Column Dropdown */}
        {showAddColumn && (
            <>
                <FaTableColumns id="master-addcolmn" />
                <select
                    className="master-addcolmn"
                    onChange={(e) => {
                        if (e.target.value) {
                            onAddColumn(e.target.value);
                        }
                    }}
                >
                    <option value="">Add Column</option>
                    {additionalColumns.map((col) => (
                        <option key={col} value={col}>
                            {col.replace("_", " ")}
                        </option>
                    ))}
                </select>
            </>
        )}
    </div>
);

export default SearchBar;
