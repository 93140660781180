import React, { useState, useRef, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Select from 'react-select';
import uploadCsv from '../../../components/Assets/upload.png'
import csvIcon from '../../../components/Assets/csv.png'
import csvCancel from '../../../components/Assets/csv-cancel.png'
import FailedList from '../../../components/FailedList';
import { MdCancel } from "react-icons/md";
import DataGridTable from '../../../components/DataGrid';
const types = [
    { name: 'CRM', type: 'crm' },
    { name: 'CSV', type: 'csv' },
    { name: 'None', type: '' }
];
const AttendModel = ({ attendModel, handleCloseAttendModel, programId, fetchProgramDetails, channelData }) => {
    const [pro, setPro] = useState(0);
    const [success, setSuccess] = useState(0);
    const [successMsg, setSuccessMsg] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const fileInputRef = useRef(null);
    const [recipientCount, setRecipientCount] = useState(0);
    const [preview, setPreview] = useState(false);
    const [previewShow, setPreviewShow] = useState(false);
    const [segmentId, setSegmentId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialRows] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(0);
    const [activeTab, setActiveTab] = useState('Setup Drip Email');
    const [selectedCrm, setSelectedCrm] = useState(null);
    const [searchLoading, setSearchLoading] = useState(false);
    const [successType, setSuccessType] = useState(null);
    const User = JSON.parse(localStorage.getItem('user'));
    const [subDisabeled, setSubDisabled] = useState(true);
    const [failedRows, setFailedRows] = useState([]);
    const [failedColumns, setFailedColumns] = useState([]);
    const [failed, setFailed] = useState(false);

    const updateCardCountsList = async() =>{
        try {
            const response = axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/updateCardCountsList`,
                {programId},
                {
                    withCredentials: true,
                }
            );
            if(response.status=200){
                console.log(response.data);
                await fetchProgramDetails();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpload = async (file) => {
        setPro(10);
        console.log(file);
        const validMimeTypes = ['text/csv', 'application/vnd.ms-excel'];
        const filename=file.name.trim().toLowerCase();
        if (!file || !validMimeTypes.includes(file.type) || !filename.endsWith('.csv')) {
            alert("Please upload a valid CSV file.");
            setPro(0);
            return;
        }
        setFileName(file.name);
        setPro(20);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'csv');
        formData.append('programId', programId);
        setPro(30);
        try {
            setPro(50);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/uploadCSV_AttendList`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    withCredentials: true,
                }
            );
            setPro(70);
            if (response.status === 200) {
                const data = response.data;
                setSuccessMsg(data.data);
                setPro(100);
                setSuccessType('csv');
                setSuccess(1);
                updateCardCountsList();
                getRecipientListCount(data.segmentId);
                setSegmentId(data.segmentId);
                setPreview(true);
                
                
                console.log('Upload Success:', data);
                setFailedColumns([
                    { field: 'id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                    { field: 'reason', headerName: 'Failed Reason', width: 300 }
                ]);
                setFailedRows(data.failedData);
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            } else {
                setSuccess(2);
                setPro(0);
            }
        } catch (error) {
            console.log('Error uploading file:', error);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
            setPro(0);
            setSuccess(2);
        }
    };


    const [fileName, setFileName] = useState('');
    const [dragActive, setDragActive] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const file = e.dataTransfer.files[0];
        handleUpload(file);
    };

    const onInputChange = (e) => {
        const file = e.target.files[0];
        handleUpload(file);
    };
    const handleViewFailedList = (type) => {
        setFailed(true);
    }

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);

        if (value === '') {
            // Reset to the original rows if search is cleared
            setRows(initialRows);
        } else {
            const filteredRows = initialRows.filter((row) =>
                Object.values(row).some((val) =>
                    String(val).toLowerCase().includes(value)
                )
            );
            setRows(filteredRows);
        }
    };

    const options = types.map((item) => ({
        value: item.type,
        label: item.name,
    }));
    const handlePreview = async (type) => {
        let id;
        if (segmentId === '') {
            id = channelData.cardId;
        } else {
            id = segmentId;
        }

        setPreviewShow(true);
        try {
            const segmentList = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/getLeadSegmentListType`, { type, id }, { withCredentials: true });
            const response = segmentList.data;

            if (response && response.length > 0) {
                const formattedRows = response.map(item => {
                    const countryCode = item.leadData.country_code;
                    const mobile = item.leadData.mobile;

                    // Logic to format mobile number conditionally
                    let formattedMobile = '';
                    if (countryCode && mobile) {
                        formattedMobile = `+${countryCode}-${mobile}`;
                    } else if (mobile) {
                        formattedMobile = mobile; // Just display mobile if countryCode is null
                    } else if (countryCode) {
                        formattedMobile = `+${countryCode}`; // Just display country code if mobile is null
                    }

                    return {
                        ...item.leadData,
                        segment_id: item.segment_id,
                        lead_id: item.lead_id,
                        formattedMobile,
                    };
                });

                setRows(formattedRows);
                setInitialRows(formattedRows);
                setError(false)
                setColumns([
                    { field: '_id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                ]);
            } else {
                setRows([]);
                setError('No data found for this segment.');
            }
        } catch (error) {
            console.log(error);
        }
    }




    const [crmLoading, setCrmLoading] = useState(false);
    const handleImportCrm = async () => {
        console.log('hello platform');
    };

    const getRecipientListCount = async (sid) => {
        try {
            const list = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/getRecipentCounts/${sid}`, { withCredentials: true });
            if (list.data.count) {
                setRecipientCount(list.data.count)
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (channelData.name === 'register_attend_list_label' && channelData.cardId !== '0' && channelData.cardId !== undefined) {
            getRecipientListCount(channelData.cardId);
        }
    }, [channelData]);

    const handleDownload = () => {
        if (!process.env.REACT_APP_PUBLIC_URL) {
            alert('.env file is missing');
            return
        }
        const filePath = `${process.env.REACT_APP_PUBLIC_URL}/demo-files/Upload contacts to Camp -demo.csv`;

        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'Upload contacts to Camp -demo.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };
    const [prompt, setPrompt] = useState('get leads where firstname starts with B and is present in leadsegment list more than 5 times');
    const handleInputChange = (event) => {
        setPrompt(event.target.value);
    };

    return (
        <Modal
            size="xl"
            show={attendModel}
            onHide={handleCloseAttendModel}
            aria-labelledby="example-modal-sizes-title-lg"
            className="custom-emailChannel-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <h6>Attend List {`( ${recipientCount} )`}</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="attend-tab" >
                    <div className='row'>
                        <div className='col-sm-2'>
                            {previewShow === false 
                                ? <button onClick={() => {
                                    handlePreview('')
                                    setPreviewShow(!previewShow)
                                }} style={{ padding: '5px 10px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Preview List</button>
                                :
                                <button onClick={() => setPreviewShow(!previewShow)} style={{ padding: '5px 10px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Close</button>
                            }
                        </div>
                    </div>
                    {previewShow ?
                        <>
                            {error ? (
                                <p>No data found</p>
                            ) : (
                                <div style={{ height: 450, width: '100%' }}>

                                    <div className="search-wrapper mb-2" style={{ width: '100%', display: 'flex' }}>
                                        <div className=''>
                                            <input
                                                type="text"
                                                className="grid-search"
                                                placeholder="search "
                                                value={searchText}
                                                onChange={handleSearch}
                                            />
                                        </div>
                                        <div className=''>
                                            <Select
                                                style={{ width: 400 }}
                                                placeholder="Select type"
                                                options={options}  // Provide transformed options
                                                onChange={(option) => handlePreview(option.value)}  // Use onChange to get the selected value
                                            />
                                        </div>
                                    </div>
                                    <DataGridTable
                                        rows={rows}
                                        columns={columns}
                                    />
                                </div>
                            )}
                        </> :
                        <>
                            {failed ?
                                <div className='mt-4'>
                                    <button onClick={() => setFailed(false)} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', fontFamily: 'Poppins' }}><MdCancel /></button>
                                    <FailedList rows={failedRows} columns={failedColumns} />
                                </div>
                                :
                                <div className='row mt-4'>
                                    <div className='col-sm-4'>
                                        <div className='upload-csv'>
                                            <div className='csv-header'>
                                                <div className='row'>
                                                    <div className='col-sm-7 pt-1'>
                                                        <h6>Upload CSV</h6>
                                                    </div>
                                                    <div className='col-sm-5'>
                                                        <button className='csv-download' onClick={handleDownload}>Download CSV Format</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`csv-body ${dragActive ? 'drag-active' : ''}`}
                                                onDragOver={handleDragOver}
                                                onDragEnter={handleDragEnter}
                                                onDragLeave={handleDragLeave}
                                                onDrop={handleDrop}
                                            >
                                                <div className='upload-div'>
                                                    <img src={uploadCsv} alt='upload-csv' />
                                                    <p className='drop-upload'>Drop Your Files Here</p>
                                                    <p>or</p>
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        onChange={onInputChange}
                                                        accept=".csv"
                                                        style={{ display: 'none' }}
                                                        id="file-upload"
                                                    />

                                                    <label htmlFor="file-upload" className="upload-button">
                                                        Upload File
                                                    </label>
                                                </div>
                                            </div>

                                            {pro > 0 &&
                                                <div className='csv-footer'>
                                                    <p>Uploaded File</p>
                                                    <div className='csv-card'>
                                                        <div className='csv-icon'>
                                                            <img src={csvIcon} alt='csv-icon' />
                                                        </div>
                                                        <div className='csv-progress'>
                                                            <p>{fileName}</p>

                                                            <ProgressBar now={pro} label={`${pro}%`} className='mt-3' />

                                                        </div>
                                                        <div className='csv-cancel'>
                                                            <img src={csvCancel} alt='csv-icon' onClick={() => setPro(0)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>

                                        {success === 1 && successType === 'csv' && (
                                            <div className="success-card">
                                                <h6>Contacts Uploaded !</h6>
                                                <ul>
                                                    <li><strong>Created : </strong> {successMsg.uploaded}</li>
                                                    <li><strong>Updated : </strong> {successMsg.existingUpdated}</li>
                                                    <li><strong>Failed : </strong> {successMsg.failed} <button onClick={() => handleViewFailedList('csv')} style={{ color: 'red' }}>View Failed List</button></li>
                                                    <li><strong>Existing - Segment: </strong> {successMsg.existingLeadSegment}</li>
                                                </ul>
                                            </div>
                                        )}
                                        {success === 2 && successType === 'csv' && (
                                            <div className="error-card">
                                                <h6>Failed  to Upload</h6>
                                                <p>{errorMsg}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </>
                    }

                </div>
            </Modal.Body>
        </Modal >
    )
}

export default AttendModel