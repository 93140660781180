import { useGlobal } from 'context/GlobalProvider';
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const ChannelModel = () => {
    const {modalState,toggleModal,channelData}=useGlobal();
    return (
        <div>
            <Modal
                show={modalState.channelModel}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={()=>toggleModal('channelModel')}
                dialogClassName="custom-channel-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='ad-icon'>
                            <img src={channelData.icon} alt='google ads'/>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{channelData.name}</h4>
                    <p>
                        {channelData.paragrap}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='model-btn' onClick={() => {
                                    window.open(`${window.location.origin}/setting`, '_blank'); // Opens the login URL in a new tab
                                  }}>Connect</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ChannelModel;
