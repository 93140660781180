import axios from 'axios';
import CommonTable from 'components/CommonTable';
import { useGlobal } from 'context/GlobalProvider';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { BiArrowBack } from 'react-icons/bi';
import { showToast } from 'screens/LeadGeneration/Helpers/ToastHelper';
import { Active, Draft, No, Paused, Yes } from 'screens/Journey/journeyModels/DynamicButtons';
import { IoSettings } from 'react-icons/io5';
import BuilderLoader from 'screens/Journey/journeyModels/BuilderLoader';
import NameCell from 'screens/Journey/journeyModels/NameCell';

function LeadFormModal({ programId }) {
    const {toggleModal,modalState,accountDetails, getCardByName,channelData} = useGlobal();
    const [cardData,setcardData]=useState(null);
    const [tableData,settableData]=useState([]);
    const [LeadFields,setLeadFields]=useState([]);
    const [selectedData,setselectedData]=useState({formId:null,campaignId:null,segmentId:null});
    const [Platform,setPlatform]=useState({});
    const [Formdata,setFormdata]=useState();
    const [mappedCount,setmappedCount]=useState(null);
    const [formValues, setFormValues] = useState({});
    const [loading,setloading]=useState(true);
    
    const leadlist=getCardByName('lead_list_label');
    const handleLeadformClose = () => {
        toggleModal('LeadFormModal');
        setselectedData({formId:null,campaignId:null,segmentId:null});
        setloading(true);
    }
    useEffect(() => {
        const handleFormMap=(form)=>{
            if(Platform.name==='google_ad')
                setselectedData({formId:form.assetId,campaignId:form.campaign});
            else if(Platform.name==='linkedin_ad')
                setselectedData({formId:form.assetId});
            else if(Platform.name==='meta_ad')
                setselectedData({formId:form.assetId});
        }
        if(channelData.name && modalState.LeadFormModal){
            let platform={name:'',formApi:'',columns:[]};
            if(channelData.name==='lead_google_ad_form'){
                platform.name='google_ad';
                platform.formApi='/api/google/getGoogleForms';
                platform.subscriptionurl='/api/google/leadform/subscribe';
                platform.columns = [
                    { field: 'id', headerName: 'S.No', flex: 1 },
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 1,
                        headerClassName: 'custom-header',
                        renderCell: (params) => <NameCell name={params.value} /> // Use the NameCell component
                    },
                    {
                        field: 'status',
                        headerName: 'Status',
                        flex: 1,
                        headerClassName: 'custom-header',
                        renderCell: (params) => {
                            switch (params.value) {
                                case 'ELIGIBLE':
                                  return <Active />;
                                case 'NOT_ELIGIBLE':
                                  return <p className='paused-tag'>Not eligble</p>;
                                case 'PAUSED':
                                  return <Paused />;
                                case '':
                                  return <Draft />;
                                default:
                                  return null;
                              }
                        }
                    },
                    { field: 'campaign', headerName: 'Campaign', flex: 1, headerClassName: 'custom-header' },
                    {
                        field: "action",
                        headerName: "Action",
                        flex: 1,
                        headerClassName: 'custom-header',
                        renderCell: (params) => {
                          return <button className='btn btn-success btn-sm cfs-5 text-light' onClick={()=>handleFormMap(params.row)}><IoSettings></IoSettings> Map leads</button>;
                        }
                    },
                    {
                        field: 'subscribed', headerName: 'Subscribed', flex: 1, headerClassName: 'custom-header',
                        renderCell:(params)=>{
                            return (
                                params.value ? <Yes /> : <No />
                            );
                        }
                    }
                ];
            }
            else if(channelData.name==='lead_linkedin_ad_form'){
                platform.name='linkedin_ad';
                platform.formApi='/api/linkedInPro/getLeadForms';
                platform.subscriptionurl='/api/linkedinpro/leadform/subscribe';
                platform.columns  = [
                    { field: 'id', headerName: 'S.No', flex: 1 },
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 1,
                        headerClassName: 'custom-header',
                        renderCell: (params) => <NameCell name={params.value} /> // Use the NameCell component
                    },
                    {
                        field: 'status',
                        headerName: 'Status',
                        flex: 1,
                        headerClassName: 'custom-header',
                        renderCell: (params) => {
                            switch (params.value) {
                                case 'Active':
                                    return <Active />;
                                case 'Inactive':
                                    return <p className='paused-tag'>Not Active</p>;
                                default:
                                    return null;
                            }
                        }
                    },
                    {
                        field: "action",
                        headerName: "Action",
                        flex: 1,
                        headerClassName: 'custom-header',
                        renderCell: (params) => {
                            return <button className='btn btn-success btn-sm cfs-5 text-light' onClick={() => handleFormMap(params.row)}><IoSettings></IoSettings> Map leads</button>;
                        }
                    },
                    {
                        field: 'subscribed', headerName: 'Subscribed', flex: 1, headerClassName: 'custom-header',
                        renderCell: (params) => {
                            return (
                                params.value ? <Yes /> : <No />
                            );
                        }
                    }
                    // { field: 'type', headerName: 'Type', flex: 1, headerClassName: 'custom-header' }
                ];
            }
            else if(channelData.name==='lead_meta_ad_form'){
                platform.name='meta_ad';
                platform.formApi='/api/facebook/getForms';
                platform.subscriptionurl='/api/facebook/leadform/subscribe';
                platform.columns  = [
                    { field: 'id', headerName: 'S.No', flex: 1 },
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 1,
                        headerClassName: 'custom-header',
                        renderCell: (params) => <NameCell name={params.value} /> // Use the NameCell component
                    },
                    { field: 'pageid', headerName: 'Page Id', flex: 1, headerClassName: 'custom-header' },
                    {
                        field: "action",
                        headerName: "Action",
                        flex: 1,
                        headerClassName: 'custom-header',
                        renderCell: (params) => {
                          return <button className='btn btn-success btn-sm cfs-5 text-light' onClick={()=>handleFormMap(params.row)}><IoSettings></IoSettings> Map leads</button>;
                        }
                    },
                    {
                        field: 'subscribed', headerName: 'Subscribed', flex: 1, headerClassName: 'custom-header',
                        renderCell:(params)=>{
                            return (
                                params.value ? <Yes /> : <No />
                            );
                        }
                    }
                ];
            }
            setPlatform(platform);
            const CardData=getCardByName(platform.name);
            if(CardData.channel){
                setcardData(CardData);
            }
        }
        return ()=>{
            setcardData(null);
            setselectedData({formId:null,campaignId:null,segmentId:null});
        }
    }, [Platform.name, channelData.name, getCardByName, modalState.LeadFormModal,tableData]);
    useEffect(() => {
        const fetchforms = async () => {
            if (!programId) {
                console.error("Invalid or missing programId");
                return;
            }
            const SelectedIds=cardData.selectedData.reduce((acc,item)=>{
                acc.push(item.id);
                return acc;
            },[]);
            settableData([]);
            if(modalState.LeadFormModal)
            try {
                const formdata = await fetch(`${process.env.REACT_APP_BASE_URL_API}${Platform.formApi}`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        campaignIds: [...SelectedIds],
                    })
                });
                if (!formdata.ok) {
                    showToast('Connect atleast one campaign with a form','warning');
                    return;
                    // throw new Error("Failed to fetch Google forms");
                }
                const formdataJson = await formdata.json();
                setloading(false);  
                if (Array.isArray(formdataJson) && formdataJson.length > 0) {
                    const formattedData = formdataJson.map((form, index) => {
                        const subscription = form.subscription.find(sub => sub.selectedSegment === leadlist.cardId);
                        const subscribed = subscription && subscription.mappedValues && Object.keys(subscription.mappedValues).length > 0;
                        return({
                        id: index,
                        name: form.formName,
                        status: form.formStatus,
                        campaign: form.campaignId,
                        assetId: form.formId,
                        subscribed: !!subscribed,
                        pageid:form.pageId,
                        })
                    });
                    settableData(formattedData)
                    setFormdata(formdataJson);
                } else {
                    showToast('Connect atleast one campaign with a form','warning');
                    return;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        if(cardData)
            fetchforms();
    }, [modalState.LeadFormModal, cardData, programId, Platform.formApi]);
    
    useEffect(() => {
        const fetchSegmentData = async () => {
          try {
            const leadfields = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/getColumns`, {
                withCredentials: true
            });
            setLeadFields(leadfields.data);
          } catch (err) {
            console.log(err.message);
          };
        };
        fetchSegmentData();
    }, []);
    useEffect(() => {
        let mappedFields = [];
    
        const handleMapping= ()=>{
            if (Platform.name==='google_ad') {
                // Platform 1: `selectedData`
                Formdata.filter((form) => form.campaignId === selectedData.campaignId && form.formId === selectedData.formId
                ).forEach((form) => {
                    const fields = form.formContent.fields || [];
                    const customFields = form.formContent.customQuestionFields || [];
        
                    const mappings=fields.map((field) => {
                        const isFieldAvailable = getfieldvalue(field.inputType);
                        return ({
                            inputType: field.inputType,
                            campfield: isFieldAvailable,
                            status: !!isFieldAvailable,
                            label: field.inputType ? field.inputType.replace(/_/g, ' ') : '',
                        });
                    });
                    const custommappings=customFields.map((field) => {
                        const isFieldAvailable = getfieldvalue(
                            field.customQuestionText || 'Unspecified'
                        );
                        return ({
                            inputType: field.customQuestionText,
                            campfield: isFieldAvailable,
                            status: !!isFieldAvailable,
                            label: field.customQuestionText
                                ? field.customQuestionText.replace(/_/g, ' ')
                                : '',
                        });
                    });
                    mappedFields.push(...mappings,...custommappings);
                });
            } else if (Platform.name==='meta_ad') {
                // Platform 2: `SelectedFormId`
                    Formdata.filter(form => form.formId === selectedData.formId).forEach((form) => {
                        const fields = form?.formContent;
                        const mappings = fields.map((field) => {
                            const isfieldAvailable = getfieldvalue(field.key);
                            const inputType=field.key;
                            return ({
                                inputType: inputType,
                                campfield: isfieldAvailable,
                                status: isfieldAvailable ? true : false,
                                label:field.label,
                            });
                        });
                        mappedFields.push(...mappings);
                    });
            } else if (Platform.name==='linkedin_ad') {
                Formdata.filter((form) => form.formId === selectedData.formId).forEach((form) => {
                    const fields = form.formContent || [];
                    const mappings=fields.map((field) => {
                        const isFieldAvailable = getfieldvalue(field.predefinedField || field.label);
                        return ({
                            inputType: field.predefinedField || field.label,
                            campfield: isFieldAvailable,
                            status: !!isFieldAvailable,
                            questionId: field.questionId,
                            label: field.name,
                        });
                    });
                    mappedFields.push(...mappings);
                });
            }

        }
        if(Formdata){
            handleMapping();
            const mappedCount = mappedFields.filter((field) => field.status).length;
            setFormValues(mappedFields);
            setmappedCount(mappedCount);
        }
    }, [Formdata, Platform.name, selectedData]);
    
    const searchLeadFields = (inputType) => {
        const matchedField = LeadFields.data.find((field) => field.alias === inputType);
        return matchedField ? matchedField._id : '';
    };
    const getfieldvalue = (inputType) => {
        const normalizedInput = inputType.replace(/_/g, '').toLowerCase();
        switch (normalizedInput) {
            case 'fullname':
            case 'firstname':
                return searchLeadFields('firstname') || '';
            case 'lastname':
                return searchLeadFields('lastname') || '';
            case 'email':
            case 'workemail':
                return searchLeadFields('email') || '';
            case 'phonenumber':
            case 'workphone':
                return searchLeadFields('phone') || '';
            case 'postalcode':
            case 'postcode':
            case 'zipcode':
                return searchLeadFields('zipcode') || '';
            case 'streetaddress':
                return searchLeadFields('address1') || '';
            case 'city':
                return searchLeadFields('city') || '';
            case 'region':
            case 'state':
                return searchLeadFields('state') || '';
            case 'country':
                return searchLeadFields('country') || '';
            case 'companyname':
                return searchLeadFields('company') || '';
            case 'gender':
                return searchLeadFields('gender') || '';
            default:
                return '';
        }
    };
    
    let accountImage, accountName, accountId;
    if (accountDetails && accountDetails.length > 0) {
        const metaAccount = accountDetails.find(item => item.platformName === Platform.name);
        if (metaAccount) {
            accountImage = metaAccount.accountImage;
            accountName = metaAccount.accountName;
            accountId = metaAccount.accountId;
        }
    }
    const handleInputChange = (fieldInputType, value, key) => {
        // console.log(fieldInputType, value);
        setFormValues((prevValues) => {
            const updatedValues = prevValues.map((item, index) =>
                index === key
                    ? { ...item, campfield: value, fieldInputType,status:true }
                    : item
            );
            const mappedCount = updatedValues.filter(field => field.status).length;
            setmappedCount(mappedCount);
            return updatedValues;
        });
    };
    const handlesubscribe = async()=>{
        const subscriptionData = {
            selectedFormId: selectedData.formId,
            selectedSegment: leadlist.cardId,
            mappedValues: formValues,
            selectedCampaignId: selectedData.campaignId,
            programId:programId
        };
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL_API}${Platform.subscriptionurl}`, subscriptionData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            });
            const selectedData={
                builder:'reachBuilder',
                programId:programId, 
                cardName:channelData.name,
                cardType:'form',
            };
            await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedCardbytype`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });
            handleLeadformClose();
            // console.log('Subscription successful:', response.data);
        } catch (error) {
            console.error('Error subscribing:', error.response ? error.response.data : error.message);
        }
    }
    return (
        <Modal
        show={modalState.LeadFormModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleLeadformClose}
        className="custom-leadData-modal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='ad-details'>
                    <p className='ad-details-p'>Ad Accounts Details</p>
                    <div className='ad-profile-card'>
                        <div className='ad-profile-avatar'>
                            <img src={accountImage} alt='profile pic' />
                        </div>
                        <div className='ad-profile-details'>
                            <p><span>{accountName}</span>&nbsp; id : {accountId}</p>
                            <p></p>
                        </div>
                    </div>
                    {selectedData?.formId &&(
                        <button className='btn btn-link fw-bold' onClick={()=>{setselectedData((prev)=>({...prev,formId:null}))}}><BiArrowBack></BiArrowBack> Go back</button>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!loading?(
                <>
                    {selectedData?.formId ?
                        <>
                            <h5 className='list-head'>Subscribe form</h5>
                            <div style={{ width: '100%', height: '320px' }}>
                                <div  style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                                    <h4 className='text-primary'>Lead Map</h4>
                                    <div className='row align-items-center mt-3'>
                                        <div className='col-md-2'>
                                            <p className='text-dark'></p>
                                        </div>
                                        <div className='col-md-6'>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <span className='d-flex'><p>out of {formValues.length} fields {mappedCount} are mapped.</p>
                                            {formValues.length-mappedCount>0?(<p className='m-0'>{formValues.length-mappedCount} is pending.</p>):null}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>Channel Form fields</div>
                                        <div className='col-md-6'>Camp Fields</div>
                                    </div>
                                    {formValues.map((field, idx) => (
                                        <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <input
                                            className='form-control'
                                            placeholder={field.inputType||field.customquestion}
                                            readOnly
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <select
                                            className={`form-select ${field.campfield?'border-green':'border-red'}`}
                                            defaultValue={getfieldvalue(field.inputType||field.customquestion)}
                                            disabled={field.inputType==="EMAIL"?true:false}
                                            onChange={(e) => handleInputChange(field.inputType, e.target.value,idx)}
                                            key={idx}
                                            >
                                            <option value="" disabled>
                                                Select an option...
                                            </option>
                                            {LeadFields.data.map((leadfield) => {
                                                const maxLength = 90;
                                                const truncatedName = leadfield.label.length > maxLength 
                                                ? leadfield.label.substring(0, maxLength) + '...' 
                                                : leadfield.label;
                                                return (
                                                <option key={leadfield._id} value={leadfield._id}>
                                                    {truncatedName}
                                                </option>
                                                );
                                            })}
                                            </select>
                                        </div>
                                        </div>
                                    ))}
                                    <h6 className='mt-3'>Note: If you are unable to find fields to map, please setup the custom fields from settings</h6>
                                </div>
                                <button className='btn btn-success text-light text-end float-end' onClick={handlesubscribe}>
                                Subscribe
                                </button>
                            </div>
                        </>
                        :
                        <>
                            <h5 className='list-head'>List of Forms</h5>
                            <div style={{ width: '100%' }}>
                                <div style={{ width: '100%', height: '320px' }}>
                                    <CommonTable data={tableData} columns={Platform.columns}></CommonTable>
                                </div>
                            </div>
                        </>
                    }
                </>
            ):(<BuilderLoader></BuilderLoader>)}
            </Modal.Body>
        </Modal>
    )
}

export default LeadFormModal