import React, { useState, useEffect } from 'react';
import logo from '../components/Assets/Color.png';
import profile_img from '../components/Assets/profile_img.jpg';
import { FiHome, FiBell } from 'react-icons/fi';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { useGlobal } from '../context/GlobalProvider';
import Greeting from './Greeting';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function NavbarComp() {
  const { logout } = useGlobal();
  const user = JSON.parse(localStorage.getItem('user'));
  const [data, setData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [selectedProgramId, setSelectedProgramId] = useState('');
  const [viewingIssues, setViewingIssues] = useState(false); 

   // Check Program Validations...
   useEffect(() => {
    const fetchProgramValidation = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/programValidation/checkValidation`, {
                method: 'GET',
                credentials: 'include',
            });
            // Check if the response is OK (status code in range 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if(data.message){
              console.log(data.message);
              return
            }
            setData(data); // Set the correct data
        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };
    // Call the fetch function
    if (window.location.href.includes("journey"));
    // fetchProgramValidation();  
  }, []);

  
  const handleNotificationClick = () => {
    setShowModal(true);
    setViewingIssues(false);
  };

  const handleProgramClick = (programId) => {
    const program = data.validationResults.find(p => p.programId === programId);
    if (program) {
      setSelectedIssues(program.issues);
      setSelectedProgramId(programId);
    }
    setViewingIssues(true); 
  };

  const handleBackClick = () => {
    setViewingIssues(false); 
    setSelectedIssues([]);
    setSelectedProgramId('');
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedIssues([]);
    setSelectedProgramId('');
    setViewingIssues(false); 
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 shadow-sm main-navbar">
      <div className="container-fluid">

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-start align-items-center">
            <li className="nav-item d-flex flex-column">
              <a className="nav-link" href="/home"><FiHome size={24}></FiHome></a>
            </li>
            <li className='nav-item'>
              <span><b><Greeting user={user} /></b></span>
            </li>
          </ul>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-center'>
            <li className='nav-item'>
              <button className="navbar-brand btn btn-link" href="#">
                <img src={logo} alt="Logo" className="d-inline-block align-text-top" height={'30px'} />
              </button>
            </li>
          </ul>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-end'>
            <li className='nav-item'>
              <div className="d-flex align-items-center">
                <button className="btn btn-link" type="button">
                  <IoIosHelpCircleOutline className='me-3 d-none d-lg-block' size={22}></IoIosHelpCircleOutline>
                </button>
                <button className='btn btn-link' type='button' onClick={handleNotificationClick}>
                  <FiBell className='me-3 d-none d-lg-block'></FiBell>
                </button>
                <div className="dropdown d-flex align-items-center">
                  <img className='img-fluid rounded-circle' src={profile_img} style={{ height: "30px" }} alt='profile_img'></img>
                  <button className="btn btn-link dropdown-toggle d-flex justify-content-center align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className='text-start'>
                      <span className="d-none d-lg-inline">{user?.username ? user.username : 'Guest'}</span>
                      <div className='fs-smaller'>{user?.role ? user.role : 'User'}</div>
                    </div>
                  </button>
                  <ul className="dropdown-menu" style={{ left: ' -50px' }} aria-labelledby="dropdownMenuButton">
                    <li><button className="dropdown-item">Profile</button></li>
                    <li><button className="dropdown-item" onClick={logout}>Logout</button></li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Notification Issues</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.validationResults?.length > 0 && <>
              {!viewingIssues ? (
              <ul className="program-list">
                {data.validationResults.map(result => (
                  <li key={result.programId} onClick={() => handleProgramClick(result.programId)} className="program-item">
                    Your Program {result.programId} get Paused, Click here
                  </li>
                ))}
              </ul>
            ) : (
              <div>
                <h5>Issues for Program ID: {selectedProgramId}</h5>
                <ul className="issue-list">
                  {selectedIssues.map((issue, index) => (
                    <li key={index} className="issue-item">{issue}</li>
                  ))}
                </ul>
                <Button variant="secondary" onClick={handleBackClick} className="back-button">
                  Back to List
                </Button>
                <a href={`${process.env.REACT_APP_PUBLIC_URL}/journey/${selectedProgramId}`} target="_blank" rel="noopener noreferrer">
                    Go to Program Journey
                </a>
              </div>
            )}
          </>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </nav>
  );
}

export default NavbarComp;
