
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import EmailEditor from 'react-email-editor';
import { useParams, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoSave } from "react-icons/io5";
import { MdPreview } from "react-icons/md";
import Select from 'react-select';

// import Form from 'react-bootstrap/Form';

const options = [
  {
    label: 'reach',
    value: 'reach'
  },
  {
    label: 'register',
    value: 'register'
  },
  {
    label: 'attend',
    value: 'attend'
  },
  {
    label: 'Event Program',
    value: 'event_program'
  },
  {
    label: 'News Letter',
    value: 'news_letter'
  },
  {
    label: 'Lead Gen Program',
    value: 'lead_gen'
  },
  {
    label: 'Get Leads',
    value: 'get_leads'
  },
  {
    label: 'Qualify',
    value: 'qualify'
  },
  {
    label: 'Send to Sales',
    value: 'send_to_sales'
  },

]

const MyEmailTemplateEditor = ({ journey_id, programId, getEmails, handleOnlyPreview, cardName, setEmailData, setEmails, emails, emailData }) => {
  const emailEditorRef = useRef(null);
  const [preview, setPreview] = useState(false);
  const { id } = useParams();
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate()
  const fetchTemplates = async () => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/getMyTemplatesByType/${cardName}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then(response => response.json())
        .then(data => setTemplates(data))
        .catch(error => console.error('Error fetching templates:', error));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchTemplates();
    if (id) {
      loadTemplate(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/mytemplate/${id}`);
    }
    if (journey_id) {
      loadTemplate(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/myclonetemplate/${journey_id}`);
    }
  }, [id]);



  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (unlayer) {
      if (preview) {
        unlayer.hidePreview();
        setPreview(false);
      } else {
        unlayer.showPreview('desktop');
        setPreview(true);
      }
    } else {
      console.error('Editor instance is not available.');
    }
  };

  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data);
  };

  const onLoad = (unlayer) => {
    console.log('onLoad', unlayer);
    unlayer.addEventListener('design:loaded', onDesignLoad);
    unlayer.loadDesign();

    unlayer.registerCallback('image', (file, done) => {
      const formData = new FormData();
      formData.append('file', file.attachments[0]);

      fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/upload-image`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.url) {
            const fullUrl = `${process.env.REACT_APP_BASE_URL_API}${data.url}`;
            done({ progress: 100, url: fullUrl });
          } else {
            done({ progress: 0, error: 'Image upload failed' });
          }
        })
        .catch(error => {
          console.error('Upload failed:', error);
          done({ progress: 0, error: 'Image upload failed' });
        });
    });
  };

  const onReady = () => {
    console.log('Editor is ready');
    // const editorContainer = emailEditorRef.current;
    // if (editorContainer) {
    //   editorContainer.style.height = `calc(100vh - 60px)`;
    // }
  };



  const handleChangeTemplate = async (selectedId) => {
    try {
      // Ensure a template is selected
      if (selectedId !== '') {
        // Ensure the journey_id is available
        if (journey_id) {
          // Send POST request to change the template
          const change = await axios.post(
            `${process.env.REACT_APP_BASE_URL_API}/emailBuilder/changeTemplate`,
            { currentTemplateId: journey_id, selectedId, programId },
            { withCredentials: true }
          );

          // If the change is successful, load the templates
          if (change.data) {
            console.log('Template changed successfully:', change.data);

            // Load templates based on the available ids
            if (id) {
              loadTemplate(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/mytemplate/${id}`);
            }
            if (journey_id) {
              loadTemplate(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/myclonetemplate/${journey_id}`);
            }
          }
        } else {
          console.log('Journey email ID is not found');
        }
      } else {
        console.log('Selected template ID is invalid');
      }
    } catch (error) {
      // Log any errors that occur during the API request
      console.error('Error changing template:', error);
    }
  };

  const [lgShow, setLgShow] = useState(false);
  const [emailDetails, setEmailDetails] = useState({
    emailName: '',
    order: '',
    tags: [],
    defaultCta: ''
  })

  const loadTemplate = (url) => {
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer.loadDesign(data.json);

        setEmailDetails({
          emailName: data.templateName,
          order: data.order,
          tags: data.tags,
          defaultCta: data.defaultCta
        })
      })
      .catch(error => console.error('Error loading template:', error));
  };

  const saveDesign = () => {
    const unlayer = emailEditorRef.current?.editor;
    if (emailDetails.defaultCta === '') {
      alert('please select default cta')
      return
    }
    if (unlayer) {
      unlayer.saveDesign((json) => {
        unlayer.exportHtml((data) => {
          const { html } = data;
          let updateId = id || journey_id;
          let url = updateId === id
            ? `${process.env.REACT_APP_BASE_URL_API}/emailBuilder/update-template`
            : `${process.env.REACT_APP_BASE_URL_API}/emailBuilder/update-clone-template`;

          fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              html,
              json,
              updateId,
              programId,
              ...emailDetails
            }),
          })
            .then(response => response.json())
            .then(data => {
              console.log('Template saved successfully:', data);
              alert('Template saved successfully!');
              if (journey_id) {
                handleOnlyPreview(journey_id);
                if (journey_id) {
                  const updatedEmails = emailData.map(email =>
                    email.emailId === journey_id
                      ? { ...email, templateName: emailDetails.emailName }
                      : email
                  );
                  setEmailData(updatedEmails);
                  setEmails(updatedEmails);
                }
              }
              setLgShow(false)
            })
            .catch(error => {
              console.error('Error saving template:', error);
              alert('An error occurred while saving the template.');
            });
        });
      });
    } else {
      console.error('Editor instance is not available.');
    }
  };





  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailDetails({
      ...emailDetails,
      [name]: value
    });
    if (journey_id) {
      const updatedEmails = emailData.map(email =>
        email.emailId === journey_id
          ? { ...email, templateName: value }
          : email
      );
      setEmailData(updatedEmails);
      setEmails(updatedEmails);
    }
  };
  const handleTagsChange = (selectedOptions) => {
    setEmailDetails({
      ...emailDetails,
      tags: selectedOptions.map(option => option.value)
    });
  };

  const handleDefaultCta = (e) => {
    const { name, value } = e.target;
    console.log(name, value);

    setEmailDetails({
      ...emailDetails,
      [name]: value
    })
  }

  return (
    <div className='container-fluid'>
      <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Email Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formGridEmail">
            <Form.Label>Email Name</Form.Label>
            <Form.Control
              type="text"
              name="emailName"
              placeholder="Enter name of email"
              value={emailDetails.emailName}
              onChange={handleInputChange}
            />
          </Form.Group>

          {!journey_id &&
            <>
              <Form.Group controlId="formGridOrder">
                <Form.Label>Order</Form.Label>
                <Form.Select name="order" value={emailDetails.order} onChange={handleInputChange}>
                  <option value="">Choose...</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formGridOrder">
                <Form.Label>Default CTA</Form.Label>
                <Form.Select name="defaultCta" value={emailDetails.defaultCta} onChange={handleDefaultCta}>
                  <option value="">Choose...</option>
                  <option value="Book a Seat">Book a Seat</option>
                  <option value="Register Now">Register Now</option>
                  <option value="Enrol Now">Enrol Now</option>
                  <option value="Last Chance">Last Chance</option>
                  <option value="Read More">Read More</option>
                </Form.Select>
              </Form.Group>


              <Form.Group controlId="formGridTags">
                <Form.Label>Tags</Form.Label>
                <Select
                  isMulti
                  name="tags"
                  options={options}
                  value={options.filter(option => emailDetails.tags.includes(option.value))}
                  onChange={handleTagsChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>

            </>
          }

          <button className='btn btn-primary m-3' onClick={saveDesign}>Update</button>
        </Modal.Body>
      </Modal>
      <div className='row'>
        <div className='col-sm-4'>
          {journey_id &&
            <Form.Select aria-label="Default select example" onChange={(e) => handleChangeTemplate(e.target.value)}>
              <option value=''>Change Template</option>
              {templates.map((temp, index) => (
                <option key={index} value={temp._id}>
                  {temp.templateName}
                </option>
              ))}
            </Form.Select>}
        </div>
        <div className='col-sm-8' style={{ display: 'flex' }}>
          <button className='sv-button-email' onClick={togglePreview}>
            <span>{preview ? 'Hide' : 'Show'} Preview</span>
          </button>
          <button className='sv-button-email' onClick={() => setLgShow(true)}>
            <span>Save</span>
          </button>
          {!journey_id && <button className='sv-button-email' onClick={() => navigate('/myEmailTemplates')}>
            <span>Close</span>
          </button>}
        </div>
      </div>



      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
        style={{height:'calc(100vh - 60px)'}}
        options={{
          version: 'latest',
          appearance: {
            theme: 'modern_dark',
            panels: {
              tools: {
                dock: 'left'
              }
            }
          },
          mergeTags: {
            event_name: {
              name: 'Event Name',
              value: '{{event_name}}'
            },
            event_date_and_time: {
              name: 'Event Date And Time',
              value: '{{event_d_t}}'
            },
            event_link: {
              name: 'Event Link',
              value: '{{event_link}}'
            },
            event_venue: {
              name: 'Event Venue',
              value: '{{event_venue}}'
            },
            event_speaker: {
              name: 'Event Speakers',
              value: '{{event_speakers}}'
            },
            first_name: {
              name: 'First Name',
              value: '{{first_name}}'
            },
            last_name: {
              name: 'last_name',
              value: '{{last_name}}'
            },
            company: {
              name: 'Company',
              value: '{{company}}'
            },
            CTA: {
              name: 'CTA',
              value: '{{CTA}}'
            },
            Unsubscribe:{
              name:'Unsubscribe',
              value:'{{unsubscribe}}'
            },
            lastestBlogs:{
              name:'Lates Blog',
              value:'{{latest_blog}}'
            },
            lastestBlogsHeading:{
              name:'Lates Blog Heading',
              value:'{{latest_blog_heading}}'
            },
            lastestBlogsContent: {
                name: 'Lates Blog Content',
                value: '{{latest_blog_content}}'
            },
            IndustryTrends: {
                name: 'Industry Trends',
                value: '{{industry_trends}}'
            }
          },
          fonts: {
            showDefaultFonts: true,
            customFonts: [
              {
                label: "Andale Mono",
                value: "andale mono,times"
              },
              {
                label: "Arial",
                value: "arial,helvetica,sans-serif"
              },
              {
                label: "Arial Black",
                value: "arial black,avant garde,arial"
              },
              {
                label: "Book Antiqua",
                value: "book antiqua,palatino"
              },
              {
                label: "Comic Sans MS",
                value: "comic sans ms,sans-serif"
              },
              {
                label: "Courier New",
                value: "courier new,courier"
              },
              {
                label: "Georgia",
                value: "georgia,palatino"
              },
              {
                label: "Helvetica",
                value: "helvetica,sans-serif"
              },
              {
                label: "Impact",
                value: "impact,chicago"
              },
              {
                label: "Symbol",
                value: "symbol"
              },
              {
                label: "Tahoma",
                value: "tahoma,arial,helvetica,sans-serif"
              },
              {
                label: "Terminal",
                value: "terminal,monaco"
              },
              {
                label: "Times New Roman",
                value: "times new roman,times"
              },
              {
                label: "Trebuchet MS",
                value: "trebuchet ms,geneva"
              },
              {
                label: "Verdana",
                value: "verdana,geneva"
              },
              {
                label: "Lobster Two",
                value: "'Lobster Two',cursive",
                url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700"
              },
              {
                label: "Playfair Display",
                value: "'Playfair Display',serif",
                url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700"
              },
              {
                label: "Rubik",
                value: "'Rubik',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Rubik:400,700"
              },
              {
                label: "Source Sans Pro",
                value: "'Source Sans Pro',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700"
              },
              {
                label: "Open Sans",
                value: "'Open Sans',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700"
              },
              {
                label: "Crimson Text",
                value: "'Crimson Text',serif",
                url: "https://fonts.googleapis.com/css?family=Crimson+Text:400,700"
              },
              {
                label: "Montserrat",
                value: "'Montserrat',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Montserrat:400,700"
              },
              {
                label: "Old Standard TT",
                value: "'Old Standard TT',serif",
                url: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700"
              },
              {
                label: "Lato",
                value: "'Lato',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Lato:400,700"
              },
              {
                label: "Raleway",
                value: "'Raleway',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Raleway:400,700"
              },
              {
                label: "Cabin",
                value: "'Cabin',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Cabin:400,700"
              },
              {
                label: "Pacifico",
                value: "'Pacifico',cursive",
                url: "https://fonts.googleapis.com/css?family=Pacifico"
              }
            ]
          },
          specialLinks: [
            {
              name: 'Manage account',
              href: 'https://[my-account]/',
              target: '_self',
            },
            {
              name: 'Frequently used',
              specialLinks: [
                {
                  name: 'Subscribe',
                  href: '[subscribe]',
                  target: '_blank',
                },
                {
                  name: 'Unsubscribe',
                  href: '[unsubscribe]',
                  target: '_blank',
                }
              ]
            }
          ]
        }}
      />
    </div>
  );
};

export default MyEmailTemplateEditor;