import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import NoMatch from './404';
import MainJourney from './Journey';
import NewslettersJourney from './NewsLetter/Journey/NewslettersJourney';
import CommonLoader from '../components/CommonLoader';
import LeadJourney from './LeadGeneration/LeadJourney';
function JourneyBytype() {
    const { programId } = useParams();
    const [ProgramType, setProgramType] = useState('');
    const [loading, setisloading] = useState(true);
    useEffect(() => {
        const fetchprogram = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programDetails/${programId}`, {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            setProgramType(data.programType);
            setisloading(false);
        };
        fetchprogram();
    }, [])
    return (
        <>
            {!loading ?
                <>
                    {
                        ProgramType === 'event' ? (<MainJourney></MainJourney>)
                            : ProgramType === 'lead' ? (<LeadJourney></LeadJourney>)
                                : ProgramType === 'newsletter' ? (<NewslettersJourney />)
                                    : (<NoMatch></NoMatch>)
                    }
                </>
                : (<CommonLoader/>)
            }
        </>
    )
}

export default JourneyBytype