import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";

const Sociallink = ({ settingType }) => {
    const navigate = useNavigate();
    const [fields, setFields] = useState([]);

    const handleAddMore = () => {
        setFields([...fields, { linkName: '', linkLogo: '', linkUrl: '', uploadedLogo: '' }]);
    };

    const handleRemove = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };

    const handleChange = (index, e) => {
        const { name, value, files } = e.target;
        const newFields = [...fields];

        if (name === 'linkLogo' && files.length > 0) {
            newFields[index][name] = files[0]; // Save the selected file
        } else {
            newFields[index][name] = value; // For other fields
        }

        setFields(newFields);
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
    
            fields.forEach((field, index) => {
                formData.append(`linkName_${index}`, field.linkName);
                formData.append(`linkUrl_${index}`, field.linkUrl);
                if (field.linkLogo) {
                    formData.append('linkLogo', field.linkLogo); // Add file
                }
            });
    
            formData.append('alias', settingType);
    
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/othersetting`, {
                method: 'POST',
                credentials: 'include',
                body: formData
            });
    
            const data = await response.json();
            if (data._id) {
                alert('Data stored successfully!');
            } else {
                alert('Something went wrong!');
            }
        } catch (error) {
            alert('Error storing data!');
            console.error('Error submitting data:', error);
        }
    };

    useEffect(() => {
        const fetchProgramDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/othersetting?platformAlias=${settingType}`, {
                    method: 'get',
                    credentials: 'include',
                });
                const data = await response.json();

                const detailsData = data.map((item) => 
                    item.details.map((detail) => ({
                        linkName: detail.linkName,
                        linkLogo: '', // Placeholder for new uploads
                        linkUrl: detail.linkUrl,
                        uploadedLogo: detail.linkLogo, // Existing image URL
                    }))
                ).flat();

                setFields(detailsData);
            } catch (error) {
                console.error('Error fetching program details:', error);
            }
        };

        fetchProgramDetails();
    }, [settingType]);

    return (
        <div
            className="overflow-hidden position-relative"
            style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '91vh',
            }}
        >
            <div className="container">
                <div className="row justify-content-center" style={{ paddingTop: '20px' }}>
                    <h3>Social Links</h3>
                    <br />
                    <br />
                    {fields.map((field, index) => (
                        <div key={index} className="form-group" style={{ marginBottom: '20px' }}>
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        name="linkName"
                                        value={field.linkName}
                                        onChange={(e) => handleChange(index, e)}
                                        className="form-control"
                                        placeholder="linkName"
                                    />
                                </div>
                                <div className="col-md-4">
                                    {field.uploadedLogo && (
                                        <div>
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL_API}/otherSettingimg/${field.uploadedLogo}`}
                                                alt="Uploaded Logo"
                                                style={{ maxWidth: '100px', marginBottom: '10px' }}
                                            />

                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        name="linkLogo"
                                        onChange={(e) => handleChange(index, e)}
                                        className="form-control"
                                        placeholder="Upload new logo"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        name="linkUrl"
                                        value={field.linkUrl}
                                        onChange={(e) => handleChange(index, e)}
                                        className="form-control"
                                        placeholder="linkUrl"
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button
                                        onClick={() => handleRemove(index)}
                                        className="btn btn-danger"
                                        disabled={fields.length === 1}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                    <button onClick={handleAddMore} className="btn btn-primary" style={{ marginRight: '10px' }}>
                        Add More
                    </button>{" "}
                    <br />
                    <br />
                    <button onClick={handleSubmit} className="btn btn-success">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Sociallink;
