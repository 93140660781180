import { useState, useEffect } from "react";
import "./LoginScreenUpdated.css";
import Vector from '../../components/Assets/Vector.png';
import Groupimg from '../../components/Assets/Groupimg.png';
import Group from '../../components/Assets/Group.png';
import loginGif from '../../components/Assets/Login Gif.gif'
import Ai from '../../components/Assets/login-ai.svg';
import EyeIcon from '../../components/Assets/eye.svg';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import '../../components/CSS/login.css';
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [user,setuser] = useState(localStorage.getItem('user')||null);

  const handleEyeIconClick = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // if cookie token still doesnot expired then redirect to home page 
  }, [])

  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  })
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };
  const handleLogin = async () => {
    setLoading(true);
    try {
      if (credentials.username !== '' && credentials.password !== '') {
        const login = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/login/loginUser`, { credentials, rememberMe }, {
          withCredentials: true
        });
        const res = login.data;
        if (res.result === 1) {
          localStorage.setItem('user', JSON.stringify(res.user));
          setLoading(false);
          toast.success('Login Successfull..', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          })
          setTimeout(() => {
            navigate('/program');
          }, 1500);
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          });
          setLoading(false);
        }
      } else {
        toast.error('Please enter username and password', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error('Login failed Please try again later', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      console.error('Login error:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkLogin = async () => {
      try {
        if (user) {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/login/checkUser`, {}, {
            withCredentials: true
          });
          if (response.data.isAuthenticated) {
            navigate('/program');
          }
        }
      } catch (error) {
        console.error('Error during token check:', error);
      }
    };

    checkLogin();
  }, [navigate]);

  const texts = ['Start your new Journey with', 'Build Automated Workflows with', 'Grow Your Business with'];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [animation, setAnimation] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  useEffect(() => {
    const intervalTime = 2000;
    const animationTime = 500;

    const changeTextInterval = setInterval(() => {
      setAnimation('slide-up-out');
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setAnimation('slide-up-in');
      }, animationTime);
    }, intervalTime);

    return () => clearInterval(changeTextInterval);
  }, [texts.length]);


  return (
    // <div className="login-screen-updated">
    //   <div className="login-screen-updated-child" />
    //   <ToastContainer />
    //   <div className="group-parent">
    //     <img className="group-icon" src={Group} alt="Group" />
    //     <div className="welcome-back">Welcome Back</div>
    //     <div className="please-enter-your">Please enter your details</div>
    //     <div className="username-parent">
    //       <div className="username">Username</div>
    //       <input
    //         className="group-child"
    //         type="text"
    //         placeholder="Enter username"
    //         name="username"
    //         value={credentials.username}
    //         onChange={handleInputChange}
    //       />
    //       <div className="password">Password</div>
    //       <div className="password-input-container">
    //         <input
    //           className="group-item"
    //           type={showPassword ? "text" : "password"}
    //           placeholder="Enter Password"
    //           name="password"
    //           value={credentials.password}
    //           onChange={handleInputChange}
    //         />
    //         <img
    //           className="eye-icon"
    //           src={EyeIcon}
    //           alt="Eye Icon"
    //           onClick={handleEyeIconClick}
    //         />
    //       </div>
    //       <div className="rectangle-parent" onClick={handleLogin}>
    //         <button className="group-inner" disabled={loading}> {loading ? 'loging...' : 'login'}</button>
    //       </div>
    //       <div className="remember-me">
    //         <input type="checkbox" id="remember-me" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
    //         <label htmlFor="remember-me" >Remember me</label>
    //       </div>
    //       <div className="forgot-password">Forgot Password?</div>

    //       <div className="by-logging-in-container">
    //         <p className="by-logging-last mt-2" >
    //           by logging in Camp, you agree to Camp Automation’s
    //         </p>
    //         <a href="/terms-and-conditions" className="terms-and-conditions">Terms and Conditions</a>
    //       </div>
    //       <div className="copyright-2024-camp ">
    //         Copyright 2024 Camp Automation. All Rights Reserved.
    //       </div>
    //     </div>
    //   </div>
    //   <img className="login-screen-updated-item" alt="Group" src={Groupimg} />
    //   <div className="component-1">
    //     <div className="start-your-new-container">
    //       <p className="by-logging-in">Start your new Journey with</p>
    //     </div>
    //   </div>
    //   <div className="ai-parent">
    //     <div className="ai">AI</div>
    //     <img className="group-icon2" src={Vector} alt="Vector" />
    //   </div>
    // </div>
    <div className="login-main container-fluid">
      <ToastContainer />
      <div className="row">
        <div className="col-sm-6 left-div-login">
          <div className="animation-content">
            <div className="animation-text-div">
              <h3 className={`animated-text ${animation}`}>{texts[currentTextIndex]}</h3>
            </div>
            <img src={Ai} alt="login-ai logo" />
            <div className="back-animation">
              <img src={loginGif}  alt="animation gif"/>
            </div>
          </div>
        </div>
        <div className="col-sm-6 right-div-login">
          <div className="group-parent">
            <img className="group-icon" src={Group} alt="Group" />
            <div className="welcome-back">Welcome Back</div>
            <div className="please-enter-your">Please enter your details</div>
            <div className="username-parent">
              <div className="username">Username</div>
              <input
                className="group-child login-input"
                type="text"
                placeholder="Enter username"
                name="username"
                value={credentials.username}
                onChange={handleInputChange}
              />
              <div className="password">Password</div>
              <div className="password-input-container">
                <input
                  className="group-item login-input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  name="password"
                  value={credentials.password}
                  onChange={handleInputChange}
                />
                <img
                  className="eye-icon"
                  src={EyeIcon}
                  alt="Eye Icon"
                  onClick={handleEyeIconClick}
                />
              </div>
              <div className="rectangle-parent" onClick={handleLogin}>
                <button className="group-inner" disabled={loading}> {loading ? 'loging...' : 'login'}</button>
              </div>
              <div className="remember-me">
                <input type="checkbox" id="remember-me" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                <label htmlFor="remember-me" >Remember me</label>
              </div>
              <div className="forgot-password">Forgot Password?</div>

              <div className="by-logging-in-container">
                <p className="by-logging-last mt-2" >
                  by logging in Camp, you agree to Camp Automation’s
                </p>
                <a href="/terms-and-conditions" className="terms-and-conditions">Terms and Conditions</a>
              </div>
              <div className="copyright-2024-camp ">
                Copyright 2024 Camp Automation. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;