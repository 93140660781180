import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi';
import Select from 'react-select';

const typeOperators = {
    text: [
        { label: 'Equals', value: 'eq' },
        { label: 'Not Equals', value: 'neq' },
        { label: 'Is Empty', value: 'empty' },
        { label: 'Is Not Empty', value: 'notEmpty' },
        { label: 'Like', value: 'like' },
        { label: 'Not Like', value: 'notLike' },
        { label: 'Starts With', value: 'startsWith' },
        { label: 'Ends With', value: 'endsWith' },
        { label: 'Contains', value: 'contains' },
    ],
    tel: [
        { label: 'Equals', value: 'eq' },
        { label: 'Not Equals', value: 'neq' },
        { label: 'Is Empty', value: 'empty' },
        { label: 'Is Not Empty', value: 'notEmpty' },
        { label: 'Starts With', value: 'startsWith' },
        { label: 'Ends With', value: 'endsWith' },
    ],
    select: [
        { label: 'Equals', value: 'eq' },
        { label: 'Not Equals', value: 'neq' },
        { label: 'Is Empty', value: 'empty' },
        { label: 'Is Not Empty', value: 'notEmpty' },
    ],
    multiselect: [
        { label: 'Is Empty', value: 'empty' },
        { label: 'Is Not Empty', value: 'notEmpty' },
    ],
    bool: [
        { label: 'Equals', value: 'eq' },
        { label: 'Not Equals', value: 'neq' },
    ],
    date: [
        { label: 'Is Empty', value: 'empty' },
        { label: 'Is Not Empty', value: 'notEmpty' },
    ],
    number:[
        { label: 'Equals', value: 'eq' },
        { label: 'Greater Than', value: 'gt' },
        { label: 'Greater Than or Equal', value: 'gte' },
        { label: 'Less Than', value: 'lt' },
        { label: 'Less Than or Equal', value: 'lte' }
    ],
    default:[
        { label: 'Equals', value: 'eq' },
        { label: 'Not Equals', value: 'neq' },
        { label: 'Is Empty', value: 'empty' },
        { label: 'Is Not Empty', value: 'notEmpty' },
        { label: 'Like', value: 'like' },
        { label: 'Not Like', value: 'notLike' },
        { label: 'Starts With', value: 'startsWith' },
        { label: 'Ends With', value: 'endsWith' },
        { label: 'Contains', value: 'contains' },
    ]
};
const normalizeType=(type)=>{
    if (null === type) {
        return;
    }
    if (type === 'number') {
        type = 'number';
    } else if (['country', 'timezone', 'region', 'locale'].includes(type)) {
        type = 'select';
    } else if (['lookup',  'text', 'email', 'url', 'email', 'tel'].includes(type)) {
        type = 'text';
    } else if (type === 'datetime') {
        type = 'date';
    } else if (![Object.keys(typeOperators)].includes(type)) {
        type = 'default';
    }
    return type;
}
const FilterBuilder = ({filters,setFilters}) => {
    const handleOperatorChange = (selectedOption, index) => {
        const updatedFilters = [...filters];
        updatedFilters[index].operator = selectedOption;
        if(selectedOption.value==='empty'|| selectedOption.value==='notEmpty'){
            updatedFilters[index].value = '';
        }
        setFilters((prevState) => ({
            ...prevState,
            Filter: updatedFilters,
        }));
    };

    const handleValueChange = (e, index) => {
        const updatedFilters = [...filters];
        updatedFilters[index].value = e.target.value;
        setFilters((prevState) => ({
            ...prevState,
            Filter: updatedFilters,
        }));
    };
    const handleRemovefilter = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters((prevState) => ({
          ...prevState,
          Filter: updatedFilters,
        }));
    };
    return (
        <>
        {console.log(filters.length,filters)
        }
            {filters.length>0?(
                <>
                    {filters?.map((filter,index)=>(
                        <div className='row align-items-center' key={`filter${index}`}>
                            <div className='col-md-2'>
                                {filter.label}
                            </div>
                            <div className='col-md-4'>
                                <Select
                                    // defaultValue={}
                                    value={filter.operator||[]}
                                    name="colors"
                                    options={typeOperators[normalizeType(filter.type)]}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e)=>{handleOperatorChange(e,index)}}
                                />
                            </div>
                            <div className='col-md-4'>
                                <Form.Control
                                    value={filter.value||''}
                                    placeholder={['notEmpty', 'empty'].includes(filter?.operator?.value)?"":'Enter filter value'}
                                    name="name"
                                    onChange={(e) => handleValueChange(e, index)}
                                    disabled={['notEmpty', 'empty'].includes(filter?.operator?.value)}
                                />
                            </div>
                            <div className='col-md-2'>
                                <Button variant='link' onClick={()=>handleRemovefilter(index)}>
                                    <BiTrash></BiTrash>
                                </Button>
                                
                            </div>

                        </div>
                    ))}
                </>
            ):(
                <div>No filters</div>
            )}
        </>
    );
}

export default FilterBuilder;