import React from 'react'
import { BsEye } from 'react-icons/bs';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useGlobal } from '../context/GlobalProvider';
function InfoTabOverall({tabs,TabwiseValidation}) {
	const {activeTab,setactiveTab,currentStep, currentSubStep,handleNextSubStep,handlePreviousSubStep}=useGlobal();
    const tabchange = (direction) => {
        if (direction === 'up' && activeTab > 0) {
            setactiveTab(activeTab - 1);
        } else if (direction === 'down' && activeTab < 3) {
            setactiveTab(activeTab + 1);
        }
    };

    return (
        <div className="col-md-10">
            <div className="position-relative">
                <div style={{ height: '63vh' }} className="bg-light rounded  border border-secondary border-2 overflow-hidden position-relative">
                    <div className="container ms-3 pt-4">
                        <div className="row align-items-center">
                            <div className="col-md-11">
                                <div className="mb-0 alert bg-secondary text-success p-2 fw-bold">{tabs[activeTab].name}</div>
                            </div>
                            <div className="col-md-1 text-center">
                                <button className="rounded-circle btn btn-secondary custom-hover" style={{ minWidth: 'unset' }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop">
                                    <BsEye strokeWidth={0.5}></BsEye>
                                </button>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-11">
                                {tabs[activeTab]?.tabContent}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column" style={{ right: '15px', position: 'absolute', bottom: '15px' }}>
                    <button
                        className="btn btn-light border rounded-circle"
                        style={{ width: '41px', height: '41px' }}
                        onClick={() => {
                        if (currentStep === 0 && currentSubStep === 0) 
                            return;
                        handlePreviousSubStep();
                        tabchange('up');

                        }}
                        disabled={currentStep === 0 && currentSubStep === 0}
                    >
                        <IoIosArrowUp></IoIosArrowUp>
                    </button>
                    <button
                        className="btn btn-light border rounded-circle mt-3"
                        style={{ width: '41px', height: '41px' }}
                        onClick={() => {
                            if (TabwiseValidation) { //change to function
                                handleNextSubStep();
                                tabchange('down');
                            }
                        }}
                        disabled={activeTab===tabs.length-1}
                    >
                        <IoIosArrowDown></IoIosArrowDown>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default InfoTabOverall;
