import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";
import SearchFilter from "./listModels/SearchBar";
import TableComponent from "./listModels/Table";
import Pagination from "./listModels/Pagination";

const Lead = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([
        "firstname",
        "lastname",
        "email",
        "mobile",
        "country",
        "state",
        "city",
        "linkedin_url_personal",
        "date_added",
        "join_time",
    ]);
    const [additionalColumns, setAdditionalColumns] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterValues, setFilterValues] = useState([]);
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        const fetchData = async () => {
            const api = await axios.post(
                `${process.env.REACT_APP_BASE_URL_API}/api/masterData/getLeadMasterList`,
                {},
                { withCredentials: true }
            );
            const response = api.data;
            setData(response);

            if (response.length > 0) {
                const allColumns = Object.keys(response[0]);
                setAdditionalColumns(allColumns.filter((col) => !selectedColumns.includes(col)));
            }
        };

        fetchData();
    }, [selectedColumns]);

    const filteredData = data.filter((row) => {
        const matchesSearch = searchTerm
            ? Object.values(row).some((value) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            : true;
        const matchesFilter =
            filterType && selectedFilterValue
                ? row[filterType]?.toLowerCase() === selectedFilterValue.toLowerCase()
                : true;

        return matchesSearch && matchesFilter;
    });

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    return (
        <div className="app">
            <NavbarComp />
            <div className="content overflow-hidden">
                <h3 className="fw-bold">Leads</h3>
                {/* Back to Menu Button */}
                <button
                    onClick={() => navigate("/menus")}
                    style={{
                        padding: "5px 15px",
                        backgroundColor: "#007BFF",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                    }}
                >
                    Back to Menu
                </button>
                <div className="container-fluid px-4 pt-3">
                    <SearchFilter
                        searchTerm={searchTerm}
                        onSearchChange={(e) => setSearchTerm(e.target.value)}
                        filterType={filterType}
                        onFilterTypeChange={(e) => setFilterType(e.target.value)}
                        filterValues={filterValues}
                        selectedFilterValue={selectedFilterValue}
                        onFilterValueChange={(e) => setSelectedFilterValue(e.target.value)}
                        additionalColumns={additionalColumns}
                        onAddColumn={(col) => setSelectedColumns([...selectedColumns, col])}
                    />
                    <TableComponent
                        data={paginatedData}
                        selectedColumns={selectedColumns}
                        selectedRows={selectedRows}
                        onRowSelect={(id) =>
                            setSelectedRows((prev) =>
                                prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
                            )
                        }
                        onHeaderSelect={(e) =>
                            setSelectedRows(e.target.checked ? data.map((row) => row._id) : [])
                        }
                        onColumnClick={(id) => (window.location.href = `/masterData/contact/${id}`)}
                    />
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        onPageChange={setCurrentPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Lead;
