import React, { useState, useEffect } from "react";
import  Xarrow,{ Xwrapper } from 'react-xarrows';
import { useGlobal } from 'context/GlobalProvider';
import RenderCardGroup from "screens/LeadGeneration/Journey/RenderCardGroup";


const GetLeadTab = ({ handleChannelModel }) => {
    const { LeadJourneyTabWiseData, AnimationMode, ToggleAnimation } = useGlobal();
    const [renderIndex, setRenderIndex] = useState(0);
    const [channelDatas=[],registrationDatas=[],formsDatas=[],registrationListDatas=[]]=LeadJourneyTabWiseData?.GetLeadTab?.cards; 
    const connectionIds=LeadJourneyTabWiseData?.GetLeadTab?.connectionIds;
    const getLeadData=[
        {CardData:channelDatas,CardType:'channel-list'},
        {CardData:registrationDatas,CardType:'registration-page'},
        {CardData:formsDatas,CardType:'form-list'},
        {CardData:registrationListDatas,CardType:'registration-list'},
    ];
    const handleRenderComplete = () => {
        setRenderIndex((prevIndex) => prevIndex + 1);
    };
    useEffect(() => {
        if (renderIndex >= getLeadData.length) {
            if(AnimationMode){
                const timer = setTimeout(() => {
                    setRenderIndex((prev) => prev + 1);
                    ToggleAnimation(false);
                  }, 2000);
                  return () => clearTimeout(timer);
            }
            else if(renderIndex<=getLeadData.length){
                setRenderIndex((prev) => prev + 1);
            }

        }
    }, [AnimationMode, ToggleAnimation, getLeadData.length, renderIndex]);
    return (
        <Xwrapper>
            <div className='reach-tab'>
                <div className='row main-card-div'>
                    {getLeadData.map((getlead,index) => renderIndex >= index && (
                        <RenderCardGroup 
                            key={index}
                            CardData={getlead.CardData} 
                            CardType={getlead.CardType} 
                            groupIndex={renderIndex} 
                            onRenderComplete={handleRenderComplete}
                            handleChannelModel={handleChannelModel}
                            animation={AnimationMode}
                            ToggleAnimation={ToggleAnimation}
                        />
                    ))}
                </div>

                {renderIndex > 4 &&connectionIds.length && connectionIds.map((connection, index) => (
                    <Xarrow
                        key={index}
                        start={`box-${connection.source}`}
                        end={`box-${connection.target}`}
                        startAnchor='right'
                        endAnchor='left'
                        color="#B3B3B3"
                        strokeWidth={2}
                        headSize={6}
                        curveness={0.1}
                        path={"grid"}
                    />
                ))}
            </div>
        </Xwrapper>
    );
};

export default GetLeadTab;
