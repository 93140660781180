import React from 'react'

const CommonLoader = () => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div className="builder-loader"></div>
        </div>
    )
}

export default CommonLoader