import axios from "axios";
import { useEffect, useState } from "react";

const fetchMediaData = async (alias) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/labelIcon?alias=${alias}`,{withCredentials: true});
        return response.data;
    } catch (error) {
        console.error(`Error fetching data for ${alias}:`, error);
        return null; // Return null if there's an error
    }
};
const useMediaData = ({initialMedia,variable}) => {
    const [mediaData, setMediaData] = useState([]);
    useEffect(() => {
        const fetchAllMediaData = async () => {
            const promises = initialMedia.map(async (media) => {
                const data = await fetchMediaData(media[variable]);
                if (data) {
                    return {
                        ...media,
                        displayName: data.name,
                        logo: `/assets/socialIcons/${data.icon}`,
                        no_campaigns_label:data.selectedLabel||'',
                        expected_label:data.countLabel1||'',
                        reach_label:data.countLabel2||''
                    };
                } else {
                    return media;
                }
            });
            const updatedMediaData = await Promise.all(promises);
            setMediaData(updatedMediaData);
        };

        fetchAllMediaData();
    }, []);

    return mediaData;
};
export default useMediaData;