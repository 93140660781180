import React from 'react';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { useGlobal } from 'context/GlobalProvider';
import RenderCards from './RenderCards';
const SalesTab = ({ handleChannelModel }) => {
    const { LeadJourneyTabWiseData } = useGlobal();
    const [qualifiedList=[],assignTosales=[],LeadProgress=[],orderList=[]]=LeadJourneyTabWiseData?.SalesTab?.cards;    
    const getSalesData=[
        {CardData:qualifiedList,CardType:''},
        {CardData:assignTosales,CardType:'registration-page'},
        {CardData:LeadProgress,CardType:'form-list'},
        {CardData:orderList,CardType:'registration-list'},
    ];
    const salesTabConnectionIds=LeadJourneyTabWiseData?.SalesTab?.connectionIds;
    return (
        <Xwrapper>
            <div className='attend-tab'>
                <div className='row main-card-div'>
                    {getSalesData.map((getlead,index) =>(
                    <RenderCards
                        key={index}
                        CardData={getlead.CardData} 
                        CardType={getlead.CardType} 
                        handleChannelModel={handleChannelModel}
                    />
                    ))}
                </div>
                {salesTabConnectionIds.map((connection, index) => (
                    <Xarrow
                        key={index}
                        start={`box-${connection.source}`}
                        end={`box-${connection.target}`}
                        startAnchor='right'
                        endAnchor='left'
                        color="#B3B3B3"
                        strokeWidth={2}
                        headSize={6}
                        curveness={0.1}
                        path={"grid"}
                    />
                ))}
            </div>
        </Xwrapper>
    );
}
export default SalesTab;