import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import DataGridTable from '../../../components/DataGrid';
import connector from '../../../components/Assets/Connector line.svg'
import { MdMoreVert } from "react-icons/md";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { CgMathPlus } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import uploadCsv from '../../../components/Assets/upload.png'
import csvIcon from '../../../components/Assets/csv.png'
import csvCancel from '../../../components/Assets/csv-cancel.png'
import FailedList from '../../../components/FailedList';
import { GrClone } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegClone } from "react-icons/fa6";

const types = [
    { name: 'CRM', type: 'crm' },
    { name: 'CSV', type: 'csv' },
    { name: 'None', type: '' }
];

const WhatsappChannelModel = ({ WhatsappChannelDataModel, closeWhatsappChannelDataModel, channelData, programId, setWhatsappChannelDataModel, fetchProgramDetails, programDetails }) => {
    const [whatsapps, setWhatsapps] = useState([]);
    const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
    const [whatsappData, setWhatsappData] = useState([]);
    const [recipientCount, setRecipientCount] = useState(0);
    const [preview, setPreview] = useState(false);
    const [previewShow, setPreviewShow] = useState(false);
    const [segmentId, setSegmentId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialRows] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(0);
    const [activeTab, setActiveTab] = useState('Setup Drip Email');
    const [selectedCrm, setSelectedCrm] = useState(null);
    const [pro, setPro] = useState(0);
    const [success, setSuccess] = useState(0);
    const [successMsg, setSuccessMsg] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [successType, setSuccessType] = useState(null);
    const User = JSON.parse(localStorage.getItem('user'));
    const [subDisabeled, setSubDisabled] = useState(true);
    const [failedRows, setFailedRows] = useState([]);
    const [failedColumns, setFailedColumns] = useState([]);
    const [failed, setFailed] = useState(false);
    const [senderList, setSenderList] = useState([]);
    const [templateList, setTemplateList] = useState([]);

    // Handle template selection change
    const handleTemplateChange = async (whatsappId) => {
        try {
            // Call the API to clone the email
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/sendwhatsapp/changeWhatsappTemplate`, {
                method: 'POST', // Use POST method to create a new record
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    programId,
                    templateId: whatsappId,
                }),
            });

            const data = await response.json();

            if (data.result === 1 && data.clonedTemplate) {
                // Create the cloned template with the new ID
                const clonedTemplate = {
                    whatsappId: data.clonedTemplate._id, // Assign the new ID from the API response
                    templateName: data.clonedTemplate.templateName, // Ensure the template name is copied
                    sendDate: selectedWhatsapp.sendDate,
                    status: true,
                };
                setSelectedWhatsapp(clonedTemplate);
                console.log("Current Templates: ", whatsapps);
                // Find the index of the selected template in the `whatsapps` array
                const selectedIndex = whatsapps.findIndex(w => w.whatsappId === selectedWhatsapp.whatsappId);
                if (selectedIndex !== -1) {
                    // Create a new array with the cloned template replacing the selected template
                    const updatedTemplates = [...whatsapps];
                    updatedTemplates[selectedIndex] = clonedTemplate; // Replace at the same index

                    // Update the state with the modified templates array
                    setWhatsapps(updatedTemplates);
                    setWhatsappData(updatedTemplates);

                    console.log("Updated Templates: ", updatedTemplates);
                } else {
                    console.error("Selected WhatsApp not found in the templates list.");
                }
            } else {
                console.error('Error cloning email:', data);
            }
        } catch (error) {
            console.error('API call failed:', error);
        }

        console.log("selectedWhatsapp : ", selectedWhatsapp);
        console.log("whatsappId : ", whatsappId);
    };

    useEffect(() => {
        const getAllWhatsappTemp = async () => {
            try {
                const api = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/sendWhatsapp/getTemplates`, { withCredentials: true });
                const dataAll = api.data.templates.map(whatsapp => ({
                    whatsappId: whatsapp.tempSid,
                    templateName: whatsapp.name,
                    sendDate: whatsapp.sendDate || '',
                    status: true,
                }));

                setTemplateList(dataAll);
            } catch (error) {
                console.log(error);
            }
        };
        getAllWhatsappTemp();
    }, []);

    // Fetch the sender list
    useEffect(() => {
        const fetchSenderList = async () => {
            try {
                // Make the GET request
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/platform?platformAlias=sendWhatsapp`, {
                    method: 'GET',
                    credentials: 'include',
                });

                // Handle the response from the API
                if (response.ok) {
                    const result = await response.json();
                    console.log('API response:', result);

                    // Assuming result is an array and we are interested in the tokenOrKey of the first object
                    if (result.length > 0 && result[0].tokenOrKey) {
                        // Split the tokenOrKey string into an array
                        const tokenArray = result[0].tokenOrKey.split(',');
                        setSenderList(tokenArray); // Store the array in state
                    } else {
                        console.error('No valid tokenOrKey found in API response');
                    }
                } else {
                    console.error('Error in API response:', response.statusText);
                }
            } catch (error) {
                console.error('Error making API request:', error);
            }
        };
        // Call the fetch function
        fetchSenderList();
    }, []);

    const handleSelect = (crmId, crmAlias) => {
        if (selectedCrm !== null && selectedCrm.id === crmId) {
            setSelectedCrm(null);
        } else {
            setSelectedCrm({ id: crmId, alias: crmAlias });
        }
    };
    const getWhatsapp = async () => {
        //Recommended Dates
        let scheduledDates = [];
        try {
            // Make the GET request
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/getProgramRecommendedDates/${programId}`, {
                method: 'GET',
                credentials: 'include',
            });

            // Handle the response from the API
            if (response.ok) {
                const result = await response.json();
                if (channelData.builder === "reachBuilder") {
                    // Get the differenceInDays from the API response
                    const { startDate, differenceInDays } = result;

                    // Divide differenceInDays by the length of the emailData array
                    const daysPerEmail = Math.floor(differenceInDays / 3); // Ensures the result is an integer
                    const start = new Date(startDate);

                    // Run a loop 3 times to generate the dates
                    scheduledDates = Array.from({ length: 3 }).map((_, index) => {
                        const sendDate = new Date(start);
                        sendDate.setDate(start.getDate() + (index * daysPerEmail));  // Schedule each email

                        // Get the hours and minutes
                        const hours = ('0' + sendDate.getHours()).slice(-2); // Add leading zero for hours
                        const minutes = ('0' + sendDate.getMinutes()).slice(-2); // Add leading zero for minutes

                        // Format the date as "yyyy-mm-ddTHH:mm"
                        const formattedDate =
                            sendDate.getFullYear() + '-' +  // Get the full year
                            ('0' + (sendDate.getMonth() + 1)).slice(-2) + '-' + // Add leading zero for month
                            ('0' + sendDate.getDate()).slice(-2) + 'T' +  // Add leading zero for day and 'T' for time
                            hours + ':' + // Add the hours
                            minutes;      // Add the minutes

                        return formattedDate;
                    });

                    console.log("scheduledDates : ", scheduledDates);
                } else if (channelData.builder === "registerBuilder") {
                    const eventStartDate = result.endDate;
                    const startDate = new Date(eventStartDate);

                    scheduledDates = Array.from({ length: 3 }).map((_, index) => {
                        let sendDate;

                        if (index === 0) {
                            return "Immediate on form Submission";
                        } else if (index === 1) {
                            sendDate = new Date(startDate);
                            sendDate.setDate(sendDate.getDate() - 2);
                        } else if (index === 2) {
                            sendDate = new Date(startDate);
                            sendDate.setDate(sendDate.getDate() - 1);
                        }
                        const formattedDate =
                            sendDate.getUTCFullYear() + '-' +
                            ('0' + (sendDate.getUTCMonth() + 1)).slice(-2) + '-' +
                            ('0' + sendDate.getUTCDate()).slice(-2) + 'T' +
                            ('0' + sendDate.getUTCHours()).slice(-2) + ':' +
                            ('0' + sendDate.getUTCMinutes()).slice(-2);

                        return formattedDate;
                    });
                    console.log("scheduledDates for register tab : ", scheduledDates);
                }

            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/sendWhatsapp/getWhatsapps`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    programId: programId,
                    eventName: programDetails.eventName,
                    cardName: channelData.name,
                    builder: channelData.builder
                }),
            });

            const api = await response.json();
            const emailPromises = api.map(async (whatsapp, index) => {
                let sendWhatsappDate;
                if (channelData.builder === "reachBuilder" || channelData.builder === "registerBuilder") {
                    sendWhatsappDate = whatsapp.sendDate ? whatsapp.sendDate : (index < 3 ? scheduledDates[index] : '');
                }
                else {
                    sendWhatsappDate = whatsapp.sendDate ? whatsapp.sendDate : '';
                }
                let dateLogic;
                if (index === 0) {
                    dateLogic = 'This Email will be Sented On Registration Form Submit'
                } else if (index === 1) {
                    dateLogic = 'This Email will be Sented On before two day of program'
                } else if (index === 2) {
                    dateLogic = 'This Email will be Sented On before one day of program'
                } else {
                    dateLogic = ''
                }
                return {
                    whatsappId: whatsapp.whatsappId,
                    templateName: whatsapp.templateName,
                    sendDate: sendWhatsappDate, //email.sendDate ? email.sendDate : '',
                    status: whatsapp.status ?? true,
                    formSubmit: index === 0 ? true : false,
                    dateLogic: dateLogic
                };
            });

            // Step 2: Wait for all the promises to resolve
            const data = await Promise.all(emailPromises);


            setWhatsapps(data);
            setWhatsappData(data);
            if (data.length > 0) {
                setSelectedWhatsapp(data[0]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getRecipientListCount = async (segmentId) => {
        try {
            const list = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/getRecipentCounts/${segmentId}`, { withCredentials: true });
            if (list.data.count) {
                setRecipientCount(list.data.count)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [crms, setCrms] = useState([]);
    const fetchCrmPlatForms = async () => {
        try {
            const crm = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/getCrmList`, { withCredentials: true });
            if (crm.data.length > 0) {
                setCrms(crm.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (channelData.name === 'whatsapp' && channelData.cardId !== '0' && channelData.cardId !== undefined) {
            getRecipientListCount(channelData.cardId);
        }
        if (channelData.name === 'whatsapp' || channelData.name === 'register_whatsapp') {
            getWhatsapp();
        }
        if (channelData.name === 'whatsapp') {
            fetchCrmPlatForms();
        }
    }, [channelData]);
    const [crmLoading, setCrmLoading] = useState(false);
    const handleImportCrm = async () => {
        setCrmLoading(true);
        try {
            if (selectedCrm && selectedCrm.alias) {
                const payload = {
                    type: 'crm',
                    programId: programId,
                    channel: channelData.name,
                    builder: channelData.builder,
                    alias: selectedCrm.alias,
                    segmentData: {
                        name: 'Hello Tony Stark',
                        created_by: User.id,
                        created_by_user: User.username,
                        description: 'Testing this description',
                        alias: 'Hello Tony Stark'
                    }
                };
                const importApi = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/importLeads`, payload, { withCredentials: true });
                if (importApi.status === 200) {
                    const importApiData = importApi.data;
                    setSuccessMsg(importApiData.data);
                    setSuccess(1);
                    setCrmLoading(false);
                    setSegmentId(importApiData.segmentId);
                    getRecipientListCount(importApiData.segmentId);
                    setSuccessType('crm');
                    setFailedColumns([
                        { field: '_id', headerName: 'ID', width: 150 },
                        { field: 'firstname', headerName: 'First Name', width: 150 },
                        { field: 'lastname', headerName: 'Last Name', width: 150 },
                        { field: 'email', headerName: 'Email', width: 200 },
                        { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                        { field: 'country', headerName: 'Country', width: 100 },
                        { field: 'state', headerName: 'State', width: 100 },
                        { field: 'city', headerName: 'City', width: 100 },
                        { field: 'company', headerName: 'Company', width: 100 },
                        { field: 'designation', headerName: 'Designation', width: 100 },
                        { field: 'industry', headerName: 'Industry', width: 100 },
                        { field: 'reason', headerName: 'Failed Reason', width: 300 }
                    ]);
                    setFailedRows(importApiData.failedData);
                } else {
                    setSuccess(2);
                    setPro(0);
                    setCrmLoading(false);
                }
            } else {
                console.log("No CRM selected");
            }
        } catch (error) {
            console.error("Error importing CRM data:", error);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
            setSuccess(2);
            setCrmLoading(false);
        }
    };


    const handleWhatsappClick = (whatsapp) => {
        setSelectedWhatsapp(whatsapp);
    };

    const handleSave = async () => {
        console.log('Saved Data:', whatsappData);
        console.log('Channel Data:', channelData);
        // Check if any email data has empty subject or sendDate
        const invalidWhatsapps = whatsappData.filter(whatsapp => !whatsapp.sendDate);

        if (invalidWhatsapps.length > 0) {
            console.error('Validation failed: Some Whatsapps have missing send date');
            alert('Please ensure all Whatsapp have a send date before saving.');
            return; // Exit the function without making the API call
        }

        // return
        const selectedData = {
            programId: programId,
            selectedChannel: channelData.name,
            builder: channelData.builder,
            saveData: whatsappData
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedChannel`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });

            if (response.ok) {
                const result = await response.json();
                //console.log('API response:', result);
                closeWhatsappChannelDataModel();
                await fetchProgramDetails();
            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };
    const [fileName, setFileName] = useState('');
    const [dragActive, setDragActive] = useState(false);
    const handleUpload = async (file) => {
        // console.log(channelData);
        setPro(10);
        const validMimeTypes = ['text/csv', 'application/vnd.ms-excel'];
        const filename = file.name.trim().toLowerCase();
        if (!file || !validMimeTypes.includes(file.type) || !filename.endsWith('.csv')) {
            alert("Please upload a valid CSV file.");
            setPro(0);
            return;
        }
        setFileName(file.name);
        setPro(20);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('channel', channelData.name);
        formData.append('programId', programId);
        formData.append('builder', channelData.builder);
        formData.append('type', 'csv');
        formData.append('platformType', 'whatsapp');
        formData.append('segmentData', JSON.stringify({
            name: 'whatsapp',
            created_by: User.id,
            created_by_user: User.username,
            description: 'Testing this description',
            alias: 'HelloWhatsapp'
        }));
        setPro(30);
        try {
            setPro(50);
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL_API}/lead/uploadCSV`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    withCredentials: true
                }
            );
            setPro(70);
            if (response.status === 200) {
                const data = response.data;
                setSuccessMsg(data.data);
                setPro(100);
                setSuccess(1);
                await fetchProgramDetails();
                getRecipientListCount(data.segmentId);
                setSegmentId(data.segmentId);
                setPreview(true);
                setSuccessType('csv');
                console.log('Upload Success:', data);
                setFailedColumns([
                    { field: 'id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                    { field: 'reason', headerName: 'Failed Reason', width: 300 }
                ]);
                setFailedRows(data.failedData);
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            } else {
                setSuccess(2);
                setPro(0);
            }
        } catch (error) {
            console.log('Error uploading file:', error);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
            setPro(0);
            setSuccess(2);
        }
    };


    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const file = e.dataTransfer.files[0];
        handleUpload(file); // Validate and process the dropped file
    };

    const onInputChange = (e) => {
        const file = e.target.files[0];
        handleUpload(file);
    };


    const [whatsappEdit, setWhatsappEdit] = useState(false);
    const [whatsappTempPreview, setWhatsappTempPreview] = useState('');
    const handleEditEmailTemplate = async (whatsappId) => {
        if (whatsappId !== '') {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/sendwhatsapp/getSingleWhatsapp?whatsappId=${whatsappId}`, {
                    method: 'GET',
                    credentials: 'include',

                });
                const data = await response.json();
                if (data && data.length > 0) {
                    // Access the first template's body field
                    const whatsappBody = data[0].body;
                    setWhatsappEdit(true);
                    setWhatsappTempPreview(whatsappBody);
                    //alert(whatsappBody);  // This will display: "Your event is coming up on {{1}}"
                }
            } catch (error) {
                alert('Error storing data!');
                console.error('Error fetching data:', error);
            }
        } else {
            alert('Data is missing');
            setWhatsappEdit(false);
        }
    }
    const handleSendDateChange = (newDate) => {
        const updatedSelectedWhatsapp = { ...selectedWhatsapp, sendDate: newDate };

        const updatedWhatsapp = whatsappData.map(whatsapp =>
            whatsapp.whatsappId === selectedWhatsapp.whatsappId
                ? { ...whatsapp, sendDate: newDate }
                : whatsapp
        );


        setSelectedWhatsapp(updatedSelectedWhatsapp);
        setWhatsappData(updatedWhatsapp);
        setWhatsapps(updatedWhatsapp);
    };

    const handleDownload = () => {
        if (!process.env.REACT_APP_PUBLIC_URL) {
            alert('.env file is missing');
            return
        }
        const filePath = `${process.env.REACT_APP_PUBLIC_URL}/demo-files/Upload contacts to Camp -demo.csv`;

        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'Upload contacts to Camp -demo.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    const handlePreview = async (type) => {
        let id;
        if (segmentId === '') {
            id = channelData.cardId;
        } else {
            id = segmentId;
        }

        setPreviewShow(true);
        try {
            const segmentList = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/getLeadSegmentListType`, { type, id }, { withCredentials: true });
            const response = segmentList.data;

            if (response && response.length > 0) {
                const formattedRows = response.map(item => {
                    const countryCode = item.leadData.country_code;
                    const mobile = item.leadData.mobile;

                    // Logic to format mobile number conditionally
                    let formattedMobile = '';
                    if (countryCode && mobile) {
                        formattedMobile = `+${countryCode}-${mobile}`;
                    } else if (mobile) {
                        formattedMobile = mobile; // Just display mobile if countryCode is null
                    } else if (countryCode) {
                        formattedMobile = `+${countryCode}`; // Just display country code if mobile is null
                    }

                    return {
                        ...item.leadData,
                        segment_id: item.segment_id,
                        lead_id: item.lead_id,
                        formattedMobile,
                    };
                });

                setRows(formattedRows);
                setInitialRows(formattedRows);
                setError(false)
                setColumns([
                    { field: '_id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                ]);
            } else {
                setRows([]);
                setError('No data found for this segment.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);

        if (value === '') {
            // Reset to the original rows if search is cleared
            setRows(initialRows);
        } else {
            const filteredRows = initialRows.filter((row) =>
                Object.values(row).some((val) =>
                    String(val).toLowerCase().includes(value)
                )
            );
            setRows(filteredRows);
        }
    };


    const options = types.map((item) => ({
        value: item.type,
        label: item.name,
    }));
    const [prompt, setPrompt] = useState('get leads where firstname starts with B and is present in leadsegment list more than 5 times');
    const handleInputChange = (event) => {
        setPrompt(event.target.value);
    };
    const [query, setQuery] = useState('');
    const handleSearchNQL = async () => {
        setSearchLoading(true);
        try {
            const promptApi = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/NaturalQueryLanguage`, { prompt }, { withCredentials: true });

            if (promptApi.status === 200) {
                const queryRes = promptApi.data;
                setQuery(queryRes.message)
                setSearchLoading(false);
            }
        } catch (error) {
            console.error(error);
            setSearchLoading(false);
        }
    };
    const [activeTooltip, setActiveTooltip] = useState(null);

    const toggleTooltip = (whatsappId) => {
        setActiveTooltip((prevState) => (prevState === whatsappId ? null : whatsappId));
    };

    const [ctaValue, setCtaValue] = useState('');
    const [showInput, setShowInput] = useState(false);
    const ctaRef = useRef();






    const handleViewFailedList = (type) => {
        setFailed(true);
    }

    const handleAddNewEmail = async () => {
        try {
            // Call the API to clone the email
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/sendwhatsapp/addNewWhatsapps`, {
                method: 'POST', // Use POST method to create a new record
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    programId,
                }),
            });

            const data = await response.json();

            if (data.result === 1) {
                // Create the cloned template with the new ID
                const clonedTemplate = {
                    whatsappId: data.clonedTemplateIds, // Assign the new ID from API response
                    templateName: `New Whatsapp`, // Ensure the subject line is copied
                    sendDate: `New Whatsapp`,
                    status: true,
                };
                // Create the updated array once
                const updatedTemplates = [...whatsapps, clonedTemplate];
                // Update the state with the new cloned email
                setWhatsapps(updatedTemplates);
                setWhatsappData(updatedTemplates);
            } else {
                console.error('Error cloning email:', data);
            }
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            // Hide the tooltip after cloning
            setActiveTooltip(null);
        }
    };

    const handleDelete = (whatsappId) => {
        setWhatsapps(prevEmails => prevEmails.filter(email => email.whatsappId !== whatsappId));
        setWhatsappData(prevEmails => prevEmails.filter(email => email.whatsappId !== whatsappId));
        setActiveTooltip(null);
    };

    const handleClone = async (template) => {
        try {
            // Call the API to clone the email
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/sendwhatsapp/cloneWhatsapp`, {
                method: 'POST', // Use POST method to create a new record
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    whatsappId: template.whatsappId
                }),
            });

            const data = await response.json();

            if (data.result === 1) {
                // Create the cloned template with the new ID
                const clonedTemplate = {
                    ...template,
                    whatsappId: data.id, // Assign the new ID from API response
                    templateName: `Clone - ${template.templateName}`, // Change the template name
                    subject: template.subject, // Ensure the subject line is copied
                };

                setWhatsapps(prevEmails => [...prevEmails, clonedTemplate]);
                setWhatsappData(prevEmails => [...prevEmails, clonedTemplate]);
            } else {
                console.error('Error cloning email:', data);
            }
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            setActiveTooltip(null);
        }
    };


    const [moveEmail, setMoveEmail] = useState(null);

    const handleMoveStart = (email) => {
        setMoveEmail(email);
        setActiveTooltip(null)
    };

    const handleMoveEnd = (targetEmail) => {
        if (!moveEmail) return;

        const moveIndex = whatsapps.findIndex(email => email.whatsappId === moveEmail.whatsappId);
        const targetIndex = whatsapps.findIndex(email => email.whatsappId === targetEmail.whatsappId);

        const updatedEmails = [...whatsapps];
        [updatedEmails[moveIndex], updatedEmails[targetIndex]] = [updatedEmails[targetIndex], updatedEmails[moveIndex]];

        setWhatsapps(updatedEmails);
        setWhatsappData(updatedEmails);
        setMoveEmail(null);
        setActiveTooltip(null)
    };
    const handleCancelMove = () => {
        setMoveEmail(null);
        setActiveTab(null)
    }
    return (
        <div>
            <Modal
                size="xl"
                show={WhatsappChannelDataModel}
                onHide={closeWhatsappChannelDataModel}
                aria-labelledby="example-modal-sizes-title-lg"
                className="custom-emailChannel-modal"
            >
                <Modal.Header closeButton>
                    <nav className='emailChannel-tab'>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setActiveTab('Setup Drip Email')}>Drip Whatsapp</button>
                            {channelData.name === 'whatsapp' && <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setActiveTab('Recipient List')}>Recipient List ( {recipientCount} )</button>}
                        </div>
                    </nav>
                </Modal.Header>
                <Modal.Body>

                    <div className='' style={{ display: 'flex', width: '100%' }}>
                        <div className='' style={{ width: '80%', background: '#E6F4F5', padding: '10px 30px' }}>
                            <b>Setup Whatsapp</b>
                        </div>
                        <div className='' style={{ width: '10%', }}>
                            {activeTab === 'Recipient List' &&
                                <>
                                    {previewShow === false
                                        ? <button onClick={() => {
                                            handlePreview('')
                                            setPreviewShow(!previewShow)
                                        }} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Preview List</button> : <button onClick={() => setPreviewShow(!previewShow)} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Close</button>
                                    }
                                </>
                            }
                        </div>
                        <div className='' style={{ width: '10%', }}>
                            <button onClick={handleSave} style={{ marginLeft: '10px', padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Save & Close</button>
                        </div>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            {whatsapps.length > 0 &&
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='email-flow-div'>
                                            <div className='flow-header d-flex'>
                                                <div className='header-text'>
                                                    <h6>Flow Preview</h6>
                                                </div>
                                                <div className='header-buttons'>
                                                    <button>Edit Flow</button>
                                                    <button className='add-new-email' onClick={handleAddNewEmail}>Add New</button>
                                                </div>
                                            </div>
                                            <div className='email-flow mt-4'>
                                                {whatsapps?.length > 0 && whatsapps.map((template, index) => (
                                                    <React.Fragment key={template.whatsappId}>
                                                        <div
                                                            className={`email-flow-card ${selectedWhatsapp && selectedWhatsapp.whatsappId === template.whatsappId ? 'selected' : ''}`}
                                                            onClick={() => handleWhatsappClick(template)}
                                                        >
                                                            {moveEmail && moveEmail.whatsappId !== template.whatsappId ? (
                                                                <div className="move-here" onClick={() => handleMoveEnd(template)}>
                                                                    <CgMathPlus />
                                                                </div>
                                                            )
                                                                :
                                                                <>
                                                                    <div className='row'>
                                                                        <div className='col-sm-2'>
                                                                            <div className='icon-email'>
                                                                                <svg width="25" height="25" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#028897">
                                                                                    <path
                                                                                        d="M16.032 0c-8.837 0-16 7.163-16 16 0 2.824.739 5.573 2.142 8.008l-2.167 7.992 8.257-2.165c2.361 1.285 5.017 1.961 7.768 1.961 8.837 0 16-7.163 16-16 0-8.837-7.163-16-16-16zm0 29.438c-2.546 0-5.03-.675-7.188-1.956l-.515-.303-5.591 1.465 1.489-5.437-.336-.559c-1.328-2.213-2.032-4.757-2.032-7.348 0-7.317 5.952-13.271 13.272-13.271 7.318 0 13.271 5.953 13.271 13.271-.001 7.318-5.954 13.271-13.271 13.271zm7.346-9.734c-.402-.201-2.373-1.175-2.742-1.307-.368-.134-.636-.201-.904.201-.268.4-1.036 1.307-1.269 1.576-.234.268-.468.301-.87.1-.402-.201-1.692-.621-3.224-1.978-1.191-1.063-1.998-2.376-2.233-2.778-.234-.402-.025-.618.177-.819.184-.183.402-.469.603-.703.201-.234.268-.402.402-.67.134-.268.067-.502-.034-.703-.1-.201-.902-2.176-1.236-2.978-.268-.636-.538-.669-.804-.678-.201-.009-.435-.01-.669-.01-.234 0-.603.085-.92.402-.302.302-1.204 1.176-1.204 2.877s1.232 3.331 1.403 3.558c.168.235 2.425 3.707 5.876 5.196.822.355 1.463.567 1.963.725.826.263 1.577.226 2.17.138.661-.099 2.038-.831 2.325-1.633.286-.802.286-1.487.201-1.633-.083-.146-.301-.235-.503-.335z"
                                                                                        fill={selectedWhatsapp && selectedWhatsapp.whatsappId === template.whatsappId ? 'white' : '#028897'}
                                                                                    />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-sm-8'>
                                                                            <div className='email-info'>
                                                                                <p>Send Whatsapp {template.no}</p>
                                                                                <p className="template-name" style={{ cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top" title={template.templateName} >{template.templateName}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-sm-2 tooltip-container-flow' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            {moveEmail && moveEmail.whatsappId === template.whatsappId ?
                                                                                <div className='close-move' onClick={handleCancelMove}><RxCross2 /></div>
                                                                                : <button className='email-more' onClick={() => toggleTooltip(template.whatsappId)}>
                                                                                    <MdMoreVert />
                                                                                </button>}

                                                                            {activeTooltip === template.whatsappId && (
                                                                                <div className='tooltip-options'>
                                                                                    <ul>
                                                                                        <li onClick={() => handleClone(template)}><FaRegClone /> Clone</li>
                                                                                        <li onClick={() => handleMoveStart(template)}><GrClone /> Move</li>
                                                                                        <li onClick={() => handleDelete(template.whatsappId)} style={{ color: 'red' }}><RiDeleteBin6Line /> Delete</li>
                                                                                    </ul>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>



                                                        {index !== whatsapps.length - 1 && (
                                                            <>
                                                                <div className='connector-line'>
                                                                    <img src={connector} alt='connector' />
                                                                </div>
                                                                <div className='remove-div'>
                                                                    <div className='remove-card'>
                                                                        <h6>Remove Registered from flow</h6>
                                                                    </div>
                                                                </div>
                                                                <div className='connector-line'>
                                                                    <img src={connector} alt='connector' />
                                                                </div>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3 email-sub'>
                                        <div className='email-sub-card'>
                                            <div className='sub-header'>
                                                <svg width="25" height="25" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#028897">
                                                    <path d="M16.032 0c-8.837 0-16 7.163-16 16 0 2.824.739 5.573 2.142 8.008l-2.167 7.992 8.257-2.165c2.361 1.285 5.017 1.961 7.768 1.961 8.837 0 16-7.163 16-16 0-8.837-7.163-16-16-16zm0 29.438c-2.546 0-5.03-.675-7.188-1.956l-.515-.303-5.591 1.465 1.489-5.437-.336-.559c-1.328-2.213-2.032-4.757-2.032-7.348 0-7.317 5.952-13.271 13.272-13.271 7.318 0 13.271 5.953 13.271 13.271-.001 7.318-5.954 13.271-13.271 13.271zm7.346-9.734c-.402-.201-2.373-1.175-2.742-1.307-.368-.134-.636-.201-.904.201-.268.4-1.036 1.307-1.269 1.576-.234.268-.468.301-.87.1-.402-.201-1.692-.621-3.224-1.978-1.191-1.063-1.998-2.376-2.233-2.778-.234-.402-.025-.618.177-.819.184-.183.402-.469.603-.703.201-.234.268-.402.402-.67.134-.268.067-.502-.034-.703-.1-.201-.902-2.176-1.236-2.978-.268-.636-.538-.669-.804-.678-.201-.009-.435-.01-.669-.01-.234 0-.603.085-.92.402-.302.302-1.204 1.176-1.204 2.877s1.232 3.331 1.403 3.558c.168.235 2.425 3.707 5.876 5.196.822.355 1.463.567 1.963.725.826.263 1.577.226 2.17.138.661-.099 2.038-.831 2.325-1.633.286-.802.286-1.487.201-1.633-.083-.146-.301-.235-.503-.335z" />
                                                </svg>
                                                <h6
                                                    className=""
                                                    style={{
                                                        cursor: 'pointer',
                                                        whiteSpace: 'nowrap',  // Prevents text from wrapping to the next line
                                                        overflow: 'hidden',    // Ensures content is clipped
                                                        textOverflow: 'ellipsis', // Adds ellipsis (...) when text overflows
                                                        maxWidth: '200px',     // Adjust the width as needed
                                                    }}
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={selectedWhatsapp.templateName}  // Tooltip with the full name
                                                >
                                                    {selectedWhatsapp.templateName}
                                                </h6>
                                            </div>
                                            <div className='sub-body'>

                                                <div className='input'>
                                                    <Form.Group className="mb-3" controlId="formGridAddress1">

                                                        <Form.Label>Select Sender</Form.Label>
                                                        <select className='form-select'>
                                                            {senderList.map((sender, index) => (
                                                                <option key={index} value={sender.trim()}>
                                                                    {sender.trim()}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <Form.Label>Change Template</Form.Label>
                                                        <select className='form-select' onChange={(e) => handleTemplateChange(e.target.value)}>
                                                            {templateList.map((template, index) => (
                                                                template.whatsappId && template.templateName ? (
                                                                    <option key={index} value={template.whatsappId.trim()}>
                                                                        {template.templateName.trim()}
                                                                    </option>
                                                                ) : null
                                                            ))}
                                                        </select>
                                                        <Form.Label>Send Date</Form.Label>
                                                        {channelData.builder !== "registerBuilder" && <Form.Control type="datetime-local" placeholder="Pick a Date" value={selectedWhatsapp.sendDate} onChange={(e) => handleSendDateChange(e.target.value)} min={new Date().toISOString().slice(0, 16)} />}
                                                        {channelData.builder === "registerBuilder" && <p style={{ color: '#028897', fontSize: '10px' }}>{selectedWhatsapp.dateLogic + " " + selectedWhatsapp.sendDate}</p>}
                                                        <button
                                                            className='btn btn-primary mt-2 edit-preview'
                                                            onClick={() => handleEditEmailTemplate(selectedWhatsapp.whatsappId)}
                                                        >
                                                            Preview
                                                        </button>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-5 email-builder-show'>
                                        {whatsappEdit && (
                                            <>
                                                <button onClick={() => setWhatsappEdit(!whatsappEdit)} className='email-builder-close'>Close</button>
                                                {/* Display the value of whatsappTempPreview */}
                                                <p>{whatsappTempPreview}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            {previewShow ?
                                <>
                                    {error ? (
                                        <p>No data found</p>
                                    ) : (
                                        <div style={{ height: 450, width: '100%' }}>

                                            <div className="search-wrapper mb-2" style={{ width: '100%', display: 'flex' }}>
                                                <div className=''>
                                                    <input
                                                        type="text"
                                                        className="grid-search"
                                                        placeholder="search "
                                                        value={searchText}
                                                        onChange={handleSearch}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <Select
                                                        style={{ width: 400 }}
                                                        placeholder="Select type"
                                                        options={options}  // Provide transformed options
                                                        onChange={(option) => handlePreview(option.value)}  // Use onChange to get the selected value
                                                    />
                                                </div>
                                            </div>
                                            <DataGridTable
                                                rows={rows}
                                                columns={columns}
                                            />
                                        </div>
                                    )}
                                </> :
                                <>
                                    {failed ?
                                        <>
                                            <button onClick={() => setFailed(false)} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', fontFamily: 'Poppins' }}>Close</button>
                                            <FailedList rows={failedRows} columns={failedColumns} />
                                        </>
                                        :
                                        <div className='row mt-4'>
                                            <div className='col-sm-4'>
                                                <div className='upload-csv'>
                                                    <div className='csv-header'>
                                                        <div className='row'>
                                                            <div className='col-sm-7 pt-1'>
                                                                <h6>Upload CSV</h6>
                                                            </div>
                                                            <div className='col-sm-5'>
                                                                <button className='csv-download' onClick={handleDownload}>Download CSV Format</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`csv-body ${dragActive ? 'drag-active' : ''}`}
                                                        onDragOver={handleDragOver}
                                                        onDragEnter={handleDragEnter}
                                                        onDragLeave={handleDragLeave}
                                                        onDrop={handleDrop}
                                                    >
                                                        <div className='upload-div'>
                                                            <img src={uploadCsv} alt='upload-csv' />
                                                            <p className='drop-upload'>Drop Your Files Here</p>
                                                            <p>or</p>
                                                            <input
                                                                type="file"
                                                                ref={fileInputRef}
                                                                onChange={onInputChange}
                                                                accept=".csv"
                                                                style={{ display: 'none' }}
                                                                id="file-upload"
                                                            />

                                                            <label htmlFor="file-upload" className="upload-button">
                                                                Upload File
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {pro > 0 &&
                                                        <div className='csv-footer'>
                                                            <p>Uploaded File</p>
                                                            <div className='csv-card'>
                                                                <div className='csv-icon'>
                                                                    <img src={csvIcon} alt='csv-icon' />
                                                                </div>
                                                                <div className='csv-progress'>
                                                                    <p>{fileName}</p>

                                                                    <ProgressBar now={pro} label={`${pro}%`} className='mt-3' />

                                                                </div>
                                                                <div className='csv-cancel'>
                                                                    <img src={csvCancel} alt='csv-icon' onClick={() => setPro(0)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>

                                                {success === 1 && successType === 'csv' && (
                                                    <div className="success-card">
                                                        <h6>Contacts Uploaded !</h6>
                                                        <ul>
                                                            <li><strong>Created : </strong> {successMsg.uploaded}</li>
                                                            <li><strong>Updated : </strong> {successMsg.existingUpdated}</li>
                                                            <li><strong>Failed : </strong> {successMsg.failed} <button onClick={() => handleViewFailedList('csv')} style={{ color: 'red' }}>View Failed List</button></li>
                                                            <li><strong>Existing - Segment: </strong> {successMsg.existingLeadSegment}</li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {success === 2 && successType === 'csv' && (
                                                    <div className="error-card">
                                                        <h6>Failed  to Upload</h6>
                                                        <p>{errorMsg}</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='crm-div'>
                                                    <div className='crm-header'>
                                                        <h6>Connect to Your CRM/Data Provider</h6>
                                                        <p>Select your CRM/Data Provider</p>
                                                    </div>
                                                    <div className='crm-body'>
                                                        <Tabs
                                                            defaultActiveKey="CRM"
                                                            id="justify-tab-example"
                                                            className="mb-3"
                                                            justify
                                                        >
                                                            <Tab eventKey="CRM" title="CRM">
                                                                <div className='row crm-card-main'>
                                                                    {crms.length > 0 && crms.map((crm, index) => (
                                                                        <div className='col-sm-6 mt-2' key={index}>
                                                                            <div
                                                                                className={`crm-card ${selectedCrm && selectedCrm.id === crm._id ? 'selected' : ''}`}
                                                                                onClick={() => handleSelect(crm._id, crm.alias)}
                                                                            >
                                                                                <p>{crm.name}</p>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </Tab>

                                                            <Tab eventKey="Data Provider" title="Data Provider">
                                                                Tab content for Profile
                                                            </Tab>
                                                        </Tabs>
                                                    </div>
                                                    <div className='crm-footer'>
                                                        <button
                                                            className='crm-import'
                                                            onClick={handleImportCrm}
                                                            disabled={crmLoading || !selectedCrm}
                                                        >
                                                            {crmLoading ? (
                                                                <>
                                                                    <span className='spinner-border-crm spinner-border-sm mr-2' role='status' aria-hidden='true'></span>
                                                                    &nbsp;Importing Data...
                                                                </>
                                                            ) : (
                                                                'Import Data'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                {success === 1 && successType === 'crm' && (
                                                    <div className="success-card">
                                                        <h6>Contacts Uploaded !</h6>
                                                        <ul>
                                                            <li><strong>Created : </strong> {successMsg.uploaded}</li>
                                                            <li><strong>Updated : </strong> {successMsg.existingUpdated}</li>
                                                            <li><strong>Failed : </strong> {successMsg.failed} <button onClick={() => handleViewFailedList('crm')} style={{ color: 'red' }}>View Failed List</button></li>
                                                            <li><strong>Existing - Segment: </strong> {successMsg.existingLeadSegment}</li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {success === 2 && successType === 'crm' && (
                                                    <div className="error-card">
                                                        <h6>Failed  to Upload</h6>
                                                        <p>{errorMsg}</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='crm-div'>
                                                    <div className='crm-header'>
                                                        <h6>Existing Master Database</h6>
                                                        <p>Choose from the master database</p>
                                                    </div>
                                                    <div className='crm-body'>
                                                        <textarea
                                                            value={prompt}
                                                            onChange={handleInputChange}
                                                            className='n-textarea'
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className='crm-footer'>
                                                        <button
                                                            className='crm-import'
                                                            onClick={handleSearchNQL}
                                                        >
                                                            {searchLoading ? (
                                                                <>
                                                                    <span className='spinner-border-crm spinner-border-sm mr-2' role='status' aria-hidden='true'></span>
                                                                    &nbsp;Searching....
                                                                </>
                                                            ) : (
                                                                'Search'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                <p>{query}</p>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default WhatsappChannelModel;
