import React from 'react'
import { useGlobal } from 'context/GlobalProvider';
import IndustryDropDown from './IndustryDropDown';
function ProgramDetailTab() {
	const {EventDetails,setEventDetails}=useGlobal();
	return (
		<>
		<div className="row mb-3">
			<div className="col-md-6">
				<label className="form-label">Product/Service Name</label>
				<input name="servicename" value={EventDetails.eventName||''} onChange={(e) => setEventDetails({...EventDetails,eventName:e.target.value})} type="text" className="form-control" placeholder="Product/Service Name" />
				<label className="form-label mt-3">Nature of business</label>
				<div>
					<div className="form-check form-check-inline">
						<input
						className="form-check-input"
						type="radio"
						name="eventType"
						id="b2bevent"
						value="b2bevent"
						checked={EventDetails.eventType === "b2bevent"}
						onChange={() => setEventDetails({...EventDetails,eventType:'b2bevent',channelDetails:[]})}
						/>
						<label className="form-check-label" htmlFor="b2bevent">B2B</label>
					</div>
					<div className="form-check form-check-inline">
						<input
						className="form-check-input"
						type="radio"
						name="eventType"
						id="b2cevent"
						value="b2cevent"
						checked={EventDetails.eventType === "b2cevent"}
						onChange={() => setEventDetails({...EventDetails,eventType:'b2cevent',channelDetails:[]})}
						/>
						<label className="form-check-label" htmlFor="b2cevent">B2C</label>
					</div>
				</div>
			</div>
			<div className='col-md-6'>
				<label className="form-label">Product/Service Url</label>
				<input name="serviceUrl" value={EventDetails.serviceUrl||''} onChange={(e) => setEventDetails({...EventDetails,serviceUrl:e.target.value})} type="text" className="form-control" placeholder="Product/Service Url" />
			</div>
		</div>
        <div className='row mt-3'>
			<IndustryDropDown></IndustryDropDown>
		</div>
		</>

	)
}

export default ProgramDetailTab