import {
    DataGrid,
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';

function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    // console.log(className);

    return (
        <MuiPagination
            color="success"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}

        />
    );
}
function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const CommonTable = ({data,columns}) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialRows] = useState([]);
    
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = initialRows.filter((row) =>
            row.name.toLowerCase().includes(value)
        );
        setRows(filteredRows);
    };
    useEffect(()=>{
        setLoading(true);
        if(data){
            setInitialRows(data);
            setRows(data);
            setLoading(false)
        }
    },[data])
    return (
        <>
            <div className='row' style={{ margin: '12px 0px' }}>
                <div className="col-sm-3 search-wrapper">
                    <CiSearch className='search-icon' />
                    <input
                        type="text"
                        className="grid-search"
                        placeholder="search by Form name"
                        value={searchText}
                        onChange={handleSearch}
                    />
                </div>
            </div>
            {initialRows.length > 0 &&
                <>
                    <DataGrid
                        disableRowSelectionOnClick
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowHeight={45}
                        autoHeight
                        pagination
                        disableColumnMenu={true}
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        initialState={{
                            ...rows.initialState,
                            pagination: { paginationModel: { pageSize: 5 } },
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        loading={loading}
                        slots={{
                            pagination: CustomPagination,
                        }}
                        columnHeaderHeight={40}
                    />
                </>
            }
        </>
    )
}
export default CommonTable