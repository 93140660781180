import React, { useState, useEffect } from "react";
import Xarrow, { Xwrapper } from 'react-xarrows';
import { useGlobal } from '../../../context/GlobalProvider';
import RenderCardGroup from "./RenderCardCompLetter";

const Reach = ({ handleChannelModel, fetchProgramDetails, programDetails }) => {
    const { newsLetterDatas, registrationDatas, newsLetterConnectsIds, AnimationMode, ToggleAnimation } = useGlobal();
    const [renderIndex, setRenderIndex] = useState(0);
    const handleRenderComplete = () => {
        setRenderIndex((prevIndex) => prevIndex + 1);
    };
    // if(renderIndex>=4){
    //     ToggleAnimation(false);   
    // }
    useEffect(() => {
        if (renderIndex >= 1) {
            if (AnimationMode) {
                const timer = setTimeout(() => {
                    setRenderIndex((prev) => prev + 1);
                    ToggleAnimation(false);
                }, 2000);
                return () => clearTimeout(timer);
            }
            else if (renderIndex < 5) {
                setRenderIndex((prev) => prev + 1);
            }

        }
    }, [renderIndex]);
    // console.log(AnimationMode,'render time');
    return (
        <Xwrapper>
            <div className='reach-tab' >
                <div className='row main-card-div'>
                    {renderIndex >= 0 && (
                        <RenderCardGroup
                            CardData={newsLetterDatas}
                            CardType={'channel-list'}
                            groupIndex={0}
                            onRenderComplete={handleRenderComplete}
                            handleChannelModel={handleChannelModel}
                            fetchProgramDetails={fetchProgramDetails}
                            animation={AnimationMode}
                            ToggleAnimation={ToggleAnimation}
                            programDetails={programDetails}
                        />
                    )}
                </div>
                {renderIndex >= 1 && newsLetterConnectsIds?.length > 0 &&
                    newsLetterConnectsIds.map((connection, index) => {
                        const isSpecialSource = ["4", "8", "12"].includes(connection.source); // Check for specific sources
                        return (
                            <Xarrow
                            key={index}
                            start={`box-${connection.source}`} 
                            end={`box-${connection.target}`}   
                            startAnchor={
                                isSpecialSource 
                                    ? "bottom" 
                                    : connection.source > 4 && connection.source < 8 
                                        ? "left" 
                                        : "right"
                            }
                            endAnchor={
                                isSpecialSource 
                                    ? "top" 
                                    : connection.source > 4 && connection.source < 8 
                                        ? "right" 
                                        : "left"
                            }
                            color="#B3B3B3"
                            strokeWidth={2}
                            headSize={6}
                            curveness={0.1}
                            path="grid"
                        />
                        
                        );
                    })
                }

            </div>
        </Xwrapper>
    );
};

export default Reach;
