import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { MdUploadFile } from "react-icons/md";
const LogoAndBanner = ({ newsLetterDetails, setNewsLetterDetails, setLogoAndBanner }) => {
    const [logoType, setLogoType] = useState('');
    const [bannerType, setBannerType] = useState('');
    const [previewLogo, setPreviewLogo] = useState(false);
    const [bannerPreview, setBannerPreview] = useState(null);
    const [logo, setLogo] = useState(null);
    const [bannerImage, setBannerImage] = useState(false);

    const [newsLetterOptions, setNewsLetterOptions] = useState([]);
    const [prompt, setPrompt] = useState('');
    const [ai, setAi] = useState('Pixels');
    useEffect(() => {
        if (logoType !== '' && logoType === 'Get From Settings') {
            // Fetch logo from settings
            alert('Settings still not implemented, please upload manually');
            setNewsLetterDetails(prevDetails => ({ ...prevDetails, logo: '' }));
            setLogoAndBanner(prev => ({ ...prev, logo: false }));
        }else if(logoType==='Upload'){
            setLogoAndBanner(prev => ({ ...prev, logo: true }));
        }
    }, [logoType]);
    useEffect(() => {
        if (bannerType !== '' && bannerType === 'Use Template Banner') {
            setNewsLetterDetails(prevDetails => ({ ...prevDetails, newsletterBanners: '' }));
            setLogoAndBanner(prev => ({ ...prev, banner: false }));
        }else if(bannerType === 'Upload Banner'){
            setLogoAndBanner(prev => ({ ...prev, banner: true }));
        }
    }, [bannerType]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogo(file);
            setNewsLetterDetails(prevDetails => ({ ...prevDetails, logo: file }));
            setLogoAndBanner(prev => ({ ...prev, logo: true }));
            setPreviewLogo(true);
        }
    };
    const handleBannerChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setBannerImage(file);
            setNewsLetterDetails(prevDetails => ({ ...prevDetails, newsletterBanners: file }));
            setLogoAndBanner(prev => ({ ...prev, banner: true }));
            setBannerPreview(true);
        }
    };
    const handleFileReset = () => {
        setLogo(null);
        setPreviewLogo(false);
        setNewsLetterDetails(prevDetails => ({ ...prevDetails, logo: '' }));
    };
    const handleFileResetBanner = () => {
        setBannerImage(null);
        setBannerPreview(false);
        setNewsLetterDetails(prevDetails => ({ ...prevDetails, newsletterBanners: '' }));
    };
    const fetchName = async () => {
        const NamePrompt = `A professional and modern NewsLetter Name for the industry is ${newsLetterDetails.industry} & sub-industry is ${newsLetterDetails.subIndustry}`;
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/ai/getNewsLetterName`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt: NamePrompt,
                }),
                credentials: 'include'
            });

            const data = await response.json();
            if (data.response) {
                setNewsLetterOptions(data.response);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (newsLetterDetails.industry !== '' && newsLetterDetails.subIndustry !== null) {
            fetchName();
        }
    }, [newsLetterDetails.subIndustry]);

    return (
        <div className='logo-and-banner'>
            <div className='row'>
                <div className='col-sm-4'>
                    <div className='newsletter-name'>
                        <label htmlFor='newsletter-name'>Newsletter Name</label>

                        <input
                            type='text'
                            id='newsletter-name'
                            className='form-control'
                            placeholder='Newsletter Name'
                            value={newsLetterDetails.newsLetterName}
                            onChange={(e) =>
                                setNewsLetterDetails({ ...newsLetterDetails, newsLetterName: e.target.value })
                            }
                        />
                        <label htmlFor='newsletter-options' style={{fontSize:'10px'}}>Select Newsletter Name Generated by Ai</label>
                        <div className='newsletter-options'>
                            
                            {newsLetterOptions?.map((name, index) => (
                                <button
                                    key={index}
                                    className={`btn btn-sm  ${newsLetterDetails.newsLetterName === name ? 'btn-gradient' : 'btn-outline-gradient'}`}
                                    onClick={() =>
                                        setNewsLetterDetails({ ...newsLetterDetails, newsLetterName: name })
                                    }
                                >
                                    {name}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className='logo-section mt-1'>
                        <label>Logo</label>
                        <Form>
                            <div className="m-3">
                                <Form.Check
                                    inline
                                    label="Get From Settings"
                                    name="logo"
                                    type="radio"
                                    value="Get From Settings"
                                    id="inline-radio-1"
                                    onChange={(e) => setLogoType(e.target.value)}
                                />
                                <Form.Check
                                    inline
                                    label="Upload"
                                    name="logo"
                                    type="radio"
                                    value="Upload"
                                    id="inline-radio-2"
                                    onChange={(e) => setLogoType(e.target.value)}
                                />
                            </div>
                        </Form>
                        {logoType === 'Upload' && !previewLogo && (

                            <label className="custum-file-upload" htmlFor="file">
                                <div className="icon-upload">
                                    <MdUploadFile />
                                </div>
                                <div className="text">
                                    <span>Click to upload logo</span>
                                </div>
                                <input
                                    type="file"
                                    id="file"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                />
                            </label>
                        )}

                        {previewLogo && logo && (
                            <>
                                <div className="logo-preview">
                                    <img
                                        src={logo !== '' && URL.createObjectURL(logo)}
                                        alt="logo"
                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                    /><br />
                                </div>
                                <button onClick={handleFileReset}>Remove</button>
                            </>
                        )}
                    </div>
                </div>
                <div className='col-sm-6'>
                    <label>Banner</label>
                    <Form>
                        <div className="m-3">
                            <Form.Check
                                inline
                                label="Use Template Banner"
                                name="banner"
                                type="radio"
                                value="Use Template Banner"
                                id="inline-radio-1"
                                onChange={(e) => setBannerType(e.target.value)}
                            />
                            <Form.Check
                                inline
                                label="Upload"
                                name="banner"
                                type="radio"
                                value="Upload Banner"
                                id="inline-radio-2"
                                onChange={(e) => setBannerType(e.target.value)}
                            />
                        </div>
                    </Form>
                    {bannerType === "Upload Banner" && !bannerPreview && <label className="custum-file-upload-banner" htmlFor="file">
                        <div className="icon-upload">
                            <MdUploadFile />
                        </div>
                        <div className="text">
                            <span>Click to Banner Image (600x400)</span>
                        </div>
                        <input
                            type="file"
                            id="file"
                            onChange={handleBannerChange}
                            accept="image/*"
                        />
                    </label>}
                    {bannerPreview && bannerImage && (
                        <>
                            <div className="logo-preview">
                                <img
                                    src={bannerImage !== '' && URL.createObjectURL(bannerImage)}
                                    alt="logo"
                                    style={{ width: '400px', height: '250px' }}
                                /><br />
                            </div>
                            <button onClick={handleFileResetBanner}>Remove</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LogoAndBanner;
