import React from 'react';

const TabContent = ({ activeTab, tabContents }) => {
    return (
        <>
            <div className='row'>
                <div className="col-md-12 d-flex justify-content-between" style={{background: 'rgb(230, 244, 245)', padding: "10px 30px"}}>
                    <b>{tabContents?.find((item)=>item?.id===activeTab)?.name}</b>
                    {tabContents?.find((item)=>item?.id===activeTab)?.customButtons}
                </div>
            </div>
            <div className="tab-content" id="nav-tabContent">
                {tabContents.map((content) => (
                <div
                    key={content.id}
                    className={`tab-pane fade ${activeTab === content.id ? 'show active' : ''}`}
                    id={`nav-${content.id}`}
                    role="tabpanel"
                    aria-labelledby={`nav-${content.id}-tab`}
                >
                    {content.component}
                </div>
                ))}
            </div>
        </>
    );
};

export default TabContent;
