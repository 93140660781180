import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import BuilderLoader from '../../Journey/journeyModels/BuilderLoader';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { MdDragIndicator } from "react-icons/md";
import { FaCaretDown } from "react-icons/fa";
const DRAG_TYPE = 'u-row-container';

const SectionCard = ({ html }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DRAG_TYPE,
        item: { html },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <>
            <div
                ref={drag}
                className="section-card"
                style={{ opacity: isDragging ? 0.8 : 1 }}
            >
                <div
                    className="contentPreview"
                    dangerouslySetInnerHTML={{ __html: html }}
                />
                <div className='drag-icon'>
                    <MdDragIndicator />
                </div>
            </div>
        </>
    );
};

const DraggableContainer = ({ containerHtml, index, updateSection, deleteSection, saveSection, isSidebarOpen, handleTextSelection, setShowToolbar }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: DRAG_TYPE,
        drop: (item) => updateSection(index, item.html, true),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const handleContentChange = (e) => {
        const updatedHtml = e.target.innerHTML;
        updateSection(index, updatedHtml, false);
    };
    const [buttonState, setButtonState] = useState("idle"); 

    const handleSave = async () => {
        setButtonState("loading"); 
        setShowToolbar(false)
        try {
            await saveSection(index, contentRef.current.innerHTML); 
            setButtonState("success"); 
            setTimeout(() => setButtonState("idle"), 1000); 
        } catch (error) {
            console.error("Save failed", error);
            setButtonState("idle"); 
        }
    };
    const contentRef = useRef();
    return (
        <div
            ref={drop}
            className={`u-row-container-wrapper ${isOver ? 'highlight' : ''}`}
        >
            <div className="container-wrapper">
                {isSidebarOpen && (
                    <>
                        <button
                            className="delete-icon"
                            onClick={() => deleteSection(index)}
                        >
                            <MdDelete />
                        </button>
                        <button
                            className={`save-icon ${buttonState}`}
                            onClick={handleSave}
                            disabled={buttonState === "loading"} 
                        >
                            {buttonState === "success" && <span className="checkmark">✔</span>}
                            {buttonState === "idle" && "Save"}
                        </button>

                    </>

                )}
                <div
                    // onMouseUp={handleTextSelection}
                    ref={contentRef}
                    onClick={handleTextSelection}
                    contentEditable={isSidebarOpen}
                    suppressContentEditableWarning={isSidebarOpen}
                    dangerouslySetInnerHTML={{ __html: containerHtml }}
                    // onBlur={(e) => handleTextSelection(e, index)}
                    className={isSidebarOpen ? "editable-container" : ''}
                />
            </div>
        </div>
    );
};


const BottomDropZone = ({ updateSection }) => {

    const [{ isOver }, drop] = useDrop(() => ({
        accept: DRAG_TYPE,
        drop: (item) => updateSection(null, item.html, false),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <div
            ref={drop}
            className={`bottom-drop-zone ${isOver ? 'highlight' : ''}`}
        >
            Drop Section Here
        </div>
    );
};

const TemplateEditor = ({ sectionsArray, updateSection, deleteSection, saveSection, isSidebarOpen, bodyStyles }) => {
    const [showToolbar, setShowToolbar] = useState(false);
    const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
    const [textColor, setTextColor] = useState("#000000");
    const [bgColor, setBgColor] = useState("#ffffff");
    const editorRef = useRef();
    const mergeTags = ["{{FirstName}}", "{{LastName}}", "{{Email}}"];
    const handleTextSelection = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0 && !selection.isCollapsed) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            setToolbarPosition({ top: rect.top - 80, left: rect.left });
            setShowToolbar(true);
        } else {
            setShowToolbar(false);
        }
    };
    const applyFormatting = (command, value = null) => {
        document.execCommand(command, false, value);
    };

    const toggleBold = () => {
        applyFormatting("bold");
    };

    const addLink = () => {
        const url = prompt("Enter the URL:");
        if (url) {
            applyFormatting("createLink", url);
        }
    };
    const insertMergeTag = (tag) => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            range.deleteContents();
            range.insertNode(document.createTextNode(tag));
        }
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                editorRef.current && !editorRef.current.contains(event.target) 
            ) {
                setShowToolbar(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
        <div className={`template ${isSidebarOpen ? "editor" : ""} editor-border`} ref={editorRef} style={bodyStyles}>
            {(showToolbar && isSidebarOpen) && (
                <div
                    className="toolbar"
                    style={{
                        top: `${toolbarPosition.top}px`,
                        left: `${toolbarPosition.left}px`,
                        position: "absolute",
                        zIndex: 1000,
                    }}
                >
                    <button className="bold-btn" onClick={toggleBold}>B</button>
                    <button className="italic-btn" onClick={() => applyFormatting("italic")}>I</button>
                    <button className="underline-btn" onClick={() => applyFormatting("underline")}>U</button>

                    <div className="color-picker">
                        <label>
                            Text Color:
                            <input
                                type="color"
                                value={textColor}
                                onChange={(e) => {
                                    setTextColor(e.target.value);
                                    applyFormatting("foreColor", e.target.value);
                                }}
                            />
                        </label>
                    </div>

                    <div className="color-picker">
                        <label>
                            BG Color:
                            <input
                                type="color"
                                value={bgColor}
                                onChange={(e) => {
                                    setBgColor(e.target.value);
                                    applyFormatting("backColor", e.target.value);
                                }}
                            />
                        </label>
                    </div>

                    <button className="link-btn" onClick={addLink}>Link</button>
                    <button className="unlink-btn" onClick={() => applyFormatting("unlink")}>Unlink</button>

                    <select
                        className="merge-tags-dropdown"
                        onChange={(e) => insertMergeTag(e.target.value)}
                        defaultValue=""
                    >
                        <option value="" disabled>Merge Tags</option>
                        {mergeTags.map((tag, index) => (
                            <option key={index} value={tag}>
                                {tag}
                            </option>
                        ))}
                    </select>
                    <button className="unlink-btn" onClick={() => setShowToolbar(false)}>Close</button>
                </div>
            )}
            {sectionsArray.map((containerHtml, index) => (
                <DraggableContainer
                    key={index}
                    index={index}
                    containerHtml={containerHtml}
                    updateSection={updateSection}
                    deleteSection={deleteSection}
                    saveSection={saveSection}
                    isSidebarOpen={isSidebarOpen}
                    handleTextSelection={handleTextSelection}
                    setShowToolbar={setShowToolbar}
                />
            ))}
            {isSidebarOpen && <BottomDropZone updateSection={updateSection} />}
        </div>
        </>
    );
};

const LettersModel = ({ lettersModel, channelData, closeLettersModel, programId, fetchProgramDetails, programDetails }) => {
    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [emailSubjects, setEmailSubjects] = useState({});
    const [emailData, setEmailData] = useState([]);
    const [recipientCount, setRecipientCount] = useState(0);
    const [activeTab, setActiveTab] = useState('Setup News Letters');
    const [subDisabeled, setSubDisabled] = useState(true);
    const [senderList, setSenderList] = useState([]);
    const [section, setSection] = useState(null);
    const [sections, setSections] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [sectionLoader, setSectionLoader] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const fetchTemplates = async () => {
        try {
            fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/getMyTemplatesByType/${channelData.cardType}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => response.json())
                .then(data => {
                    setTemplates(data);
                })
                .catch(error => console.error('Error fetching templates:', error));
        } catch (error) {
            console.log(error);
        }
    }
    const fetchCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/sections/getSectionsCategory`, {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            if (data) {
                setCategories(data);
            } else {
                console.log('Failed to fetch category list');
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const fetchSectionsByCategory = async (id, programId, emailId) => {
        setSectionLoader(true);
        try {
            setCategoryId(id)
            fetch(`${process.env.REACT_APP_BASE_URL_API}/sections/getSections/${id}/${emailId}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => response.json())
                .then(data => {
                    setSections(data)
                    setSectionLoader(false);
                })
                .catch(error => console.error('Error fetching templates:', error));
        } catch (error) {
            console.log(error);
        }
    }
    // Fetch the sender list
    useEffect(() => {
        const fetchSenderList = async () => {
            try {
                // Make the GET request
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/platform?platformAlias=sendEmail`, {
                    method: 'GET',
                    credentials: 'include',
                });

                // Handle the response from the API
                if (response.ok) {
                    const result = await response.json();

                    // Assuming result is an array and we are interested in the tokenOrKey of the first object
                    if (result.length > 0 && result[0].tokenOrKey) {
                        // Split the tokenOrKey string into an array
                        const tokenArray = result[0].tokenOrKey.split(',');
                        setSenderList(tokenArray); // Store the array in state
                    } else {
                        console.error('No valid tokenOrKey found in API response');
                    }
                } else {
                    console.error('Error in API response:', response.statusText);
                }
            } catch (error) {
                console.error('Error making API request:', error);
            }
        };
        // Call the fetch function
        fetchSenderList();
        fetchCategories();
    }, []);

    const [emailLoading, setEmailLoading] = useState(false);
    const getEmails = async () => {
        setEmailLoading(true);

        const fetchSubjectLine = async (emailId) => {

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/ai/getSubjectLine`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    const subjectLine = JSON.parse(result.response);
                    return subjectLine;
                } else {
                    console.error('Error in fetching subject line:', response.statusText);
                }
            } catch (error) {
                console.error('Error in making subject line API request:', error);
            }
            return '';
        };


        try {
            const api = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/getEmails`, { programId, eventName: programDetails.eventName, cardName: channelData.name, builder: channelData.builder }, { withCredentials: true });
            const emailPromises = api.data.map(async (email, index) => {
                let scoreResponse;
                let subjectLine;
                if (!(email.subject)) {
                    subjectLine = await fetchSubjectLine(email._id);
                }
                if (!(email.subjectScore)) {
                    const score = await axios.get(`https://sendcheckit.com/api/?subject=${subjectLine}`);
                    scoreResponse = score.data;
                }
                return {
                    emailId: email.emailId,
                    templateName: email.templateName,
                    subject: email.subject ? email.subject : '{{first_name}}, ' + subjectLine, // Use fetched subject line or fallback
                    subjectScore: email.subjectScore ? email.subjectScore : scoreResponse.rating,
                    subjectScoreDetails: email.subjectScoreDetails ? email.subjectScoreDetails : { letter_rating: scoreResponse.letter_rating, scannability_score: scoreResponse.scannability_score, flesch_grade: scoreResponse.flesch_grade, sentiment: scoreResponse.sentiment },
                    sendDate: email.sendDate, //email.sendDate ? email.sendDate : '',
                    status: email.status ?? true,
                    no: email.no ? email.no : '',
                    customCTA: email.customCTA ?? '',
                    alert: email.alert ?? 7,
                };
            });

            const data = await Promise.all(emailPromises);
            setEmails(data);
            setEmailData(data);

            if (data.length > 0) {
                setSelectedEmail(data[0]);
                fetchTemplate(data[0].emailId)
                setEmailSubjects(data.reduce((acc, email) => {
                    acc[email.emailId] = email.subject;
                    return acc;
                }, {}));
            }
            setEmailLoading(false);
        } catch (error) {
            console.log(error);
        }
    };



    const [crms, setCrms] = useState([]);
    const fetchCrmPlatForms = async () => {
        try {
            const crm = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/getCrmList`, { withCredentials: true });
            if (crm.data.length > 0) {
                setCrms(crm.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (channelData.cardType === 'letters') {
            getEmails();
            fetchTemplates();
        }
        if (channelData.name === 'email') {
            fetchCrmPlatForms();
        }

    }, [channelData]);

    const debounceRef = useRef(null);
    const handleSubjectChange = (e) => {
        const newSubject = e.target.value;

        if (selectedEmail) {
            setEmailSubjects({
                ...emailSubjects,
                [selectedEmail.emailId]: newSubject
            });
            const updatedData = emailData.map(email =>
                email.emailId === selectedEmail.emailId
                    ? { ...email, subject: newSubject, subjectScore: newSubject === '' ? 0 : email.subjectScore } // Reset score if subject is empty
                    : email
            );
            setEmailData(updatedData);
            setSelectedEmail(updatedData.find(email => email.emailId === selectedEmail.emailId));
            setEmails(updatedData);
        }

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }
        if (newSubject === '') {
            return
        }
        debounceRef.current = setTimeout(async () => {
            try {
                const scoreApi = await axios.get(`https://sendcheckit.com/api/?subject=${newSubject}`);
                const response = scoreApi.data;

                // Update the email data and set the selected email
                const updatedData = emailData.map(email =>
                    email.emailId === selectedEmail.emailId
                        ? { ...email, subject: newSubject, subjectScore: response.rating, subjectScoreDetails: { letter_rating: response.letter_rating, scannability_score: response.scannability_score, flesch_grade: response.flesch_grade, sentiment: response.sentiment } }
                        : email
                );
                setEmailData(updatedData);
                setSelectedEmail(updatedData.find(email => email.emailId === selectedEmail.emailId));
                setEmails(updatedData);
            } catch (error) {
                console.log(error);
            }
        }, 500);
    };

    const handleSave = async () => {
        const invalidEmails = emailData.filter(email => !email.subject || !email.sendDate);

        if (invalidEmails.length > 0) {
            console.error('Validation failed: Some emails have missing subject or send date');
            alert('Please ensure all emails have a subject and a send date before saving.');
            return; // Exit the function without making the API call
        }
        // return;
        const selectedData = {
            programId: programId,
            selectedChannel: channelData.name,
            builder: channelData.builder,
            saveData: emailData
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedChannel`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });

            if (response.ok) {
                const result = await response.json();
                const updateTemp = await handleSaveTemplate(emailData[0].emailId);
                if (!updateTemp) {
                    return toast.error('Details Saved successfully , Failed to update letter', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                }
                closeLettersModel();
                setIsSidebarOpen(false);
                await fetchProgramDetails();
                toast.success("Updated Successfully & News Letter is Ready", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };


    const handleSendDateChange = (newDate) => {
        const updatedSelectedEmail = { ...selectedEmail, sendDate: newDate };
        const updatedEmails = emailData.map(email =>
            email.emailId === selectedEmail.emailId
                ? { ...email, sendDate: newDate }
                : email
        );
        setSelectedEmail(updatedSelectedEmail);
        setEmailData(updatedEmails);
        setEmails(updatedEmails);
    };



    // Email Editor code
    const [sectionsArray, setSectionsArray] = useState([]);
    const [template, setTemplate] = useState('');
    const [bodyStyles, setBodyStyles] = useState({});
    const fetchTemplate = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/myclonetemplate/${id}`, {
                method: 'GET',
                credentials: 'include',
            })
            const data = await response.json();
            setTemplate(data.html);
            const parser = new DOMParser();
            const doc = parser.parseFromString(data.html, 'text/html');
            const body = doc.querySelector('body');
            const styleString = body?.getAttribute('style') || '';
            const styles = styleString.split(';').reduce((acc, style) => {
                const [key, value] = style.split(':').map((s) => s.trim());
                if (key && value) {
                    // Convert CSS property to camelCase for React
                    const camelCaseKey = key.replace(/-([a-z])/g, (_, char) => char.toUpperCase());
                    acc[camelCaseKey] = value;
                }
                return acc;
            }, {});
            setBodyStyles(styles);
            const containers = Array.from(doc.querySelectorAll('.u-row-container'));
            setSectionsArray(containers.map(container => container.outerHTML));
        } catch (error) {
            console.error('Error fetching template:', error);
        }
    };
    const deleteSection = (index) => {
        if (index === sectionsArray.length - 1) {
            toast.error('This Section cannot be Deleted', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        } else if (sectionsArray[index]) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(sectionsArray[index], 'text/html');
            if (doc.querySelector('img[alt="Logo"]')) {
                toast.error('This Section cannot be Deleted', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
                return;
            }
        }
        setSectionsArray((prevSections) => prevSections.filter((_, i) => i !== index));
    };
    const saveSection = (index, newHtml) => {
        updateSection(index, newHtml, false);
    };
    const updateSection = (index, newHtml, isDropInsideContainer = false) => {
        setSectionsArray((prevSections) => {
            const updatedSections = [...prevSections];
            if (index === sectionsArray.length - 1) {
                toast.error('This Section cannot be replaced', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
                return prevSections;
            }
            if (updatedSections[index]) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(updatedSections[index], 'text/html');
                if (doc.querySelector('img[alt="Logo"]')) {
                    toast.error('This Section cannot be replaced', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                    return prevSections;
                }
            }
            const parser = new DOMParser();
            const doc = parser.parseFromString(newHtml, 'text/html');
            const containerContent = doc.querySelector('.u-row-container')?.innerHTML;
            const wrappedHtml = `
            <div class="u-row-container" style="padding: 0px;background-color: transparent">
                ${containerContent}
            </div>
        `;
            if (isDropInsideContainer) {
                updatedSections[index] = wrappedHtml;
            } else if (index !== null) {
                updatedSections[index] = wrappedHtml;
            } else {
                updatedSections.splice(-1, 0, wrappedHtml);
            }
            return updatedSections;
        });
    };

    const handleSaveTemplate = async (id) => {
        try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(template, 'text/html');

            const containers = Array.from(doc.querySelectorAll('.u-row-container'));
            containers.forEach((container) => {
                container.remove(); // Remove each u-row-container
            });

            const bodyTd = doc.querySelector('#u_body td[style*="vertical-align: top"]');
            if (bodyTd) {
                sectionsArray.forEach((sectionHtml) => {
                    bodyTd.innerHTML += sectionHtml;
                });
            } else {
                console.error('No valid <td> found inside #u_body');
            }
            const updatedHtml = doc.documentElement.outerHTML;
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/update-clone-template-letter`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    updatedHtml,
                    id,
                }),
            })
            const data = await response.json();
            if (data.templateId) {
                return true
            }
            return false;
        } catch (error) {
            console.error('Error saving template:', error);
            return false;
        }
    };
    // Email editor code end

    const handleChangeTemplate = async (selectedId) => {
        try {
            if (selectedId !== '') {
                const change = await axios.post(
                    `${process.env.REACT_APP_BASE_URL_API}/emailBuilder/changeTemplateLetter`,
                    { currentTemplateId: selectedEmail.emailId, selectedId, programId },
                    { withCredentials: true }
                );
                if (change.data) {
                    getEmails()
                } else {
                    toast.error('Failed to change template', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                }
            } else {
                toast.error('Invalid Template', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            }
        } catch (error) {
            console.error('Error changing template:', error);
        }
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const generateScoreHtml = (letter, rating) => (
        <div>
            <div className={`bubble small ${getBubbleColor(letter)}`}>
                {letter}
            </div>
            <div className="points">
                <strong style={{ lineHeight: '2' }}>{rating}</strong> Points
            </div>
        </div>
    );

    const generateScannabiltyHtml = (scannabilityScore) => (
        <div>
            <div className={`bubble small ${getScannabilityColor(scannabilityScore)}`}>
                <small>{Math.round(scannabilityScore)}</small>
            </div>
            <div className="points">
                <strong style={{ lineHeight: '2' }}>{Math.round(scannabilityScore)}/10</strong>
            </div>
        </div>
    );

    const generateReadingGradeHtml = (fleschGrade) => (
        <div>
            <div className={`bubble small ${getReadingGradeColor(fleschGrade)}`}>
                <small>{fleschGrade}</small>
            </div>
            <div className="points">
                <strong style={{ lineHeight: '2' }}>{fleschGrade}</strong>
            </div>
        </div>
    );

    const generateSentimentHtml = (sentiment) => {
        const sentimentData = getSentimentData(sentiment);
        return (
            <div>
                <div
                    className={`bubble small ${sentimentData.colorClass}`}
                    style={{
                        fontSize: '50px',
                        lineHeight: '1.1',
                        borderWidth: '0px',
                    }}
                >
                    {sentimentData.emoji}
                </div>
                <div className="points">
                    <strong style={{ lineHeight: '2' }}>{sentimentData.text}</strong>
                </div>
            </div>
        );
    };

    // Helper functions for determining styles and data
    const getBubbleColor = (letter) => {
        if (letter === 'A' || letter === 'A+') return 'green';
        if (letter === 'B') return 'orange';
        if (letter === 'C') return 'red';
        return 'grey';
    };

    const getScannabilityColor = (score) => {
        if (score < 5) return 'green';
        if (score >= 5 && score < 7) return 'orange';
        if (score >= 7) return 'red';
        return 'grey';
    };

    const getReadingGradeColor = (grade) => {
        if (grade <= 5) return 'green';
        if (grade > 5 && grade <= 8) return 'orange';
        if (grade > 8) return 'red';
        return 'grey';
    };

    const getSentimentData = (sentiment) => {
        switch (sentiment) {
            case 'positive':
                return { emoji: '😊', text: 'Positive', colorClass: 'green' };
            case 'neutral':
                return { emoji: '😐', text: 'Neutral', colorClass: 'grey' };
            case 'negative':
                return { emoji: '😔', text: 'Negative', colorClass: 'red' };
            default:
                return { emoji: '', text: 'Unknown', colorClass: 'grey' };
        }
    };
    return (
        <div>
            <Modal
                size="xl"
                show={lettersModel}
                onHide={() => {
                    closeLettersModel();
                    setIsSidebarOpen(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                className="custom-emailChannel-modal"
            >
                {/* <Modal.Header closeButton>

                </Modal.Header> */}
                <Modal.Body>
                    <>
                        <div className='' style={{ display: 'flex', width: '100%' }}>
                            <div className='' style={{ width: '80%', background: '#E6F4F5', padding: '10px 30px' }}>
                                <b>{activeTab}</b>
                            </div>
                            <div className='model-btn-top' style={{ width: '10%', }}>
                                <button onClick={handleSave} style={{ marginLeft: '10px', padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', fontFamily: 'Poppins' }}>Save & Close</button>
                            </div>
                            <div className='model-btn-top' style={{ width: '10%', }}>
                                <button onClick={() => {
                                    closeLettersModel();
                                    setIsSidebarOpen(false);
                                }} style={{ marginLeft: '10px', padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', fontFamily: 'Poppins' }}>Cancel</button>
                            </div>
                        </div>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                {emailLoading ?
                                    <BuilderLoader />
                                    :
                                    <>
                                        {emails.length > 0 &&
                                            (
                                                <DndProvider backend={HTML5Backend}>
                                                    <div className={`row main-container mt-3 ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                                                        {!isSidebarOpen && <div className='col-sm-1'></div>}
                                                        <div className={`col-sm-${!isSidebarOpen ? '5' : '2'} email-sub`} >
                                                            <div className='email-sub-card' style={{ width: '100%' }}>
                                                                <Form.Select aria-label="Default select example" className='mb-2' onChange={(e) => handleChangeTemplate(e.target.value)}>
                                                                    <option value=''>Change Template</option>
                                                                    {templates?.map((temp, index) => (
                                                                        <option key={index} value={temp._id}>
                                                                            {temp.templateName}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                                <div className='sub-header'>
                                                                    <svg width="23" height="14" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M26.46 0.00616786C26.546 -0.00205595 26.6325 -0.00205595 26.7185 0.00616786L15.1523 9.94078C14.8042 10.1496 14.4059 10.2599 14 10.2599C13.5941 10.2599 13.1958 10.1496 12.8477 9.94078L1.28154 0.00616786C1.3675 -0.00205595 1.45404 -0.00205595 1.54 0.00616786H26.46ZM28 15.7185C27.9993 15.9195 27.9588 16.1184 27.8809 16.3037C27.803 16.489 27.6892 16.6571 27.5461 16.7983C27.4029 16.9394 27.2333 17.0508 27.0469 17.1261C26.8605 17.2014 26.661 17.2391 26.46 17.2369H1.54C1.33898 17.2391 1.13953 17.2014 0.953132 17.1261C0.766735 17.0508 0.597076 16.9394 0.45393 16.7983C0.310784 16.6571 0.196979 16.489 0.119073 16.3037C0.0411672 16.1184 0.00069904 15.9195 0 15.7185V1.52463C0.00133992 1.24503 0.0795797 0.971193 0.226154 0.733091L12.0723 10.8831L12.1423 10.9369C12.6907 11.2816 13.3253 11.4644 13.9731 11.4644C14.6208 11.4644 15.2554 11.2816 15.8038 10.9369L15.8738 10.8831L27.72 0.733091C27.8856 0.965512 27.9826 1.23977 28 1.52463V15.7185Z" fill='#028897' />
                                                                    </svg>
                                                                    <h6
                                                                        className=""
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            maxWidth: `${isSidebarOpen ? '200px' : '100%'}`,
                                                                        }}
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        title={selectedEmail.templateName}
                                                                    >
                                                                        {selectedEmail.templateName}
                                                                    </h6>
                                                                </div>
                                                                <div className='sub-body'>
                                                                    <p>Subject Line</p>
                                                                    <h1></h1>
                                                                    <div className='input'>
                                                                        <div className='row'>
                                                                            <div className='col-sm-10'>
                                                                                <Form.Group className="mt-1 mb-1" controlId="formGridAddress1">
                                                                                    <Form.Control type="text" placeholder="Subject Line" className='subject-line' value={emailSubjects[selectedEmail.emailId] || ''} onChange={handleSubjectChange} disabled={subDisabeled} />
                                                                                </Form.Group>
                                                                            </div>
                                                                            <div className='col-sm-2 edit-sub-div'>
                                                                                <FaRegEdit className='edit-sub' onClick={() => setSubDisabled(false)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className='score-details'>
                                                                            <button
                                                                                className="sub-score"
                                                                                style={{
                                                                                    backgroundColor: selectedEmail.subjectScore >= 90 ? '#29A148' :
                                                                                        selectedEmail.subjectScore >= 80 && selectedEmail.subjectScore <= 89 ? 'orange' :
                                                                                            'red',
                                                                                    color: 'white',
                                                                                }}
                                                                                onClick={() => setShowDetails(!showDetails)}
                                                                            >
                                                                                Score : {selectedEmail.subjectScore} &nbsp;&nbsp;<FaCaretDown />
                                                                            </button>
                                                                            {showDetails && (
                                                                                <div className="score-details-tab">
                                                                                    {/* Overall Score */}
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <h5>Overall Score</h5>
                                                                                        </div>
                                                                                        <div className="col-md-12">{generateScoreHtml(selectedEmail.subjectScoreDetails.letter_rating, selectedEmail.subjectScore)}</div>
                                                                                    </div>
                                                                                    <hr />

                                                                                    {/* Scannability */}
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <h5>Scannability <span style={{ fontSize: 'smaller' }}>(lower is better)</span></h5>
                                                                                        </div>
                                                                                        <div className="col-md-12">{generateScannabiltyHtml(selectedEmail.subjectScoreDetails.scannability_score)}</div>
                                                                                    </div>
                                                                                    <hr />

                                                                                    {/* Reading Grade */}
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <h5>Reading Grade <span style={{ fontSize: 'smaller' }}>(lower is better)</span></h5>
                                                                                        </div>
                                                                                        <div className="col-md-12">{generateReadingGradeHtml(selectedEmail.subjectScoreDetails.flesch_grade)}</div>
                                                                                    </div>
                                                                                    <hr />

                                                                                    {/* Sentiment */}
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <h5>Sentiment</h5>
                                                                                        </div>
                                                                                        <div className="col-md-12">{generateSentimentHtml(selectedEmail.subjectScoreDetails.sentiment)}</div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className='mb-3 mt-3'>
                                                                            <p>Select Sender</p>
                                                                            <select className="mb-3 form-select">
                                                                                {senderList.map((sender, index) => (
                                                                                    <option key={index} value={sender.trim()}>
                                                                                        {sender.trim()}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <Form.Group className="mb-3" controlId="formGridAddress1">
                                                                            <Form.Label>Send Date</Form.Label>
                                                                            {channelData.builder !== "registerBuilder" && channelData.builder !== "attendBuilder" &&
                                                                                <Form.Control
                                                                                    type="datetime-local"
                                                                                    placeholder="Pick a Date"
                                                                                    value={selectedEmail.sendDate}
                                                                                    onChange={(e) => handleSendDateChange(e.target.value)}
                                                                                    min={new Date().toISOString().slice(0, 16)}
                                                                                />}
                                                                            <p style={{ color: '#028897', fontSize: '10px' }}> {selectedEmail.sendDate}</p>
                                                                        </Form.Group>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`col-sm-${isSidebarOpen ? '5' : '5'} email-builder-show view-template`}>
                                                            <button className="hamburger-menu" onClick={toggleSidebar}>
                                                                {isSidebarOpen ? "X" : "☰"}
                                                            </button>
                                                                <TemplateEditor
                                                                    sectionsArray={sectionsArray}
                                                                    updateSection={updateSection}
                                                                    deleteSection={deleteSection}
                                                                    saveSection={saveSection}
                                                                    isSidebarOpen={isSidebarOpen}
                                                                    bodyStyles={bodyStyles}
                                                                />
                                                        </div>
                                                        {isSidebarOpen && <div className='col-sm-5 email-builder-show'>
                                                            <div className='row'>
                                                                <div className='col-sm-4'>
                                                                    <h6>Sections</h6>
                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    <div className='col-sm-8'>
                                                                        <select
                                                                            value={categoryId}
                                                                            onChange={(e) => fetchSectionsByCategory(e.target.value, programId, selectedEmail.emailId)}
                                                                            className='form-control m-2'
                                                                        >
                                                                            <option value="" disabled>Select a category</option>
                                                                            {categories?.map((category) => (
                                                                                <option key={category._id} value={category._id}>
                                                                                    {category.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {sectionLoader ? <BuilderLoader /> : sections.length > 0 && sections?.map((data, index) => (
                                                                <SectionCard key={index} html={data.html} />
                                                            ))}
                                                        </div>}
                                                    </div>
                                                </DndProvider>
                                            )
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default LettersModel;
	