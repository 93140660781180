import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import I_icon from 'components/Assets/i_icon_ads.png';
import { DataGrid } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { CiSearch } from "react-icons/ci";
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { FaFilter } from "react-icons/fa";
import { Active, Paused, Draft, Yes, No } from 'screens/Journey/journeyModels/DynamicButtons';
import { AiOutlineCaretDown } from "react-icons/ai";
import NameCell from 'screens/Journey/journeyModels/NameCell';
import { RxCross2 } from "react-icons/rx";
import defaultPic from 'components/Assets/default.webp'
import { useGlobal } from 'context/GlobalProvider';
import useClipboard from 'screens/LeadGeneration/Hooks/CopyText';
const columns = [
    { field: 'id', headerName: 'S.No', flex: 1 },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        headerClassName: 'custom-header',
        renderCell: (params) => <NameCell name={params.value} /> // Use the NameCell component
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        headerClassName: 'custom-header',
        renderCell: (params) => {
            return (
                params.value === 'active' || params.value === 'enabled' ? (
                    <Active />
                ) : params.value === 'draft' ? (
                    <Draft />
                ) : params.value === 'removed' ? (
                    <button className='draft-tag text-red' style={{ color: '#EA4335', borderColor: '#EA4335', background: '#fce8e7' }}>Removed</button>
                ) : (
                    <Paused />
                )
            );
        }
    },
    { field: 'type', headerName: 'Type', flex: 1, headerClassName: 'custom-header' },
    { field: 'objectives', headerName: 'Objectives', flex: 2, headerClassName: 'custom-header' },
    {
        field: 'form', headerName: 'Form', flex: 1, headerClassName: 'custom-header',
        renderCell: (params) => {
            return (
                params.value === 'Yes' ? <Yes /> : <No />
            );
        }
    },
    // {
    //     field:'cta',headerName:'CTA',flex:1, headerClassName: 'custom-header',
    // }
];

function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
        <MuiPagination
            color="success"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}

        />
    );
}
function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}
const ChannelDataModel = ({ programId, pageUrl }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);

    const [initialRows, setInitialRows] = useState([]);
    const [createNewLink, setCreateNewLink] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [accountId, setAccountId] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountImage, setAccountImage] = useState('');
    const {modalState,toggleModal,accountDetails,channelData}=useGlobal();
    const clipboard=useClipboard();
    useEffect(() => {
        const fetchAccountDetails = async () => {
            setAccountId('000000000');
            setAccountName('Account Name');
            setAccountImage(defaultPic);
            if (accountDetails && accountDetails.length > 0) {
                const Account = accountDetails.find(item => item.platformName === channelData.channel);
                if (Account) {
                    setAccountId(Account.accountId);
                    setAccountName(Account.accountName);
                    setAccountImage(Account.accountImage);
                }
            }
        };
        // Call the fetch function
        fetchAccountDetails();
    }, [accountDetails,channelData.channel]);


    useEffect(() => {
        const fetchCampaignDetails = async () => {
            try {
    
                const apiCallURL = channelData.apiCallUrl;
                if (apiCallURL === undefined || apiCallURL === '') {
                    return;
                }
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/${apiCallURL}`, {
                    method: 'GET',
                    credentials: 'include',
                });
                const payload = {
                    programId: programId,
                    channel: channelData.channel,
                    builder: channelData.builder,
    
                }
                const checkExistingSelectedIdResponse = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/checkExistingSelectedId`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                    credentials: 'include',
                });
    
                const checkExistingSelectedIdData = await checkExistingSelectedIdResponse.json();
                const existingIds = checkExistingSelectedIdData.ids || [];
    
                const data = await response.json();
                const formattedData = data.map(campaign => ({
                    id: campaign.campaignId,
                    name: campaign.campaignName,
                    status: campaign.campaignStatus.toLowerCase(),
                    type: "Lead",
                    objectives: campaign.campaignObjective,
                    form: (campaign?.hasLeadForm || campaign.campaignObjective === "LEAD_GENERATION" || campaign.campaignObjective === "Lead generation" || campaign.campaignObjective === "OUTCOME_LEADS") ? "Yes" : "No", // Conditional check for form field
                    selected: existingIds.includes(campaign.campaignId),
                    // cta:campaign.campaign_cta.map(cta=>cta.url)
                }));
                const defaultSelectedRows = formattedData.filter(row => row.selected).map(row => row.id);
                setSelectedRows(defaultSelectedRows);
                setInitialRows(formattedData);
                setRows(formattedData);
            } catch (error) {
                console.error('Error fetching program details:', error);
            }
        };
        setCreateNewLink(channelData.createNewLink);
        setSelectedChannel(channelData.channel);
        // Function to fetch data from the API
        fetchCampaignDetails();
        // Call the fetch function

    }, [channelData, programId]);

    const filteredColumns = selectedChannel === 'Google Ads'
        ? columns
        : columns.filter(column => column.field !== 'type');
    const handleCreateNewLinkClick = () => {
        if (createNewLink) {
            window.open(createNewLink, '_blank');
        } else {
            alert('Link is not set!');
        }
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = initialRows.filter((row) =>
            row.name.toLowerCase().includes(value)
        );
        setRows(filteredRows);
    };



    const [filter, setFilter] = useState('');
    const [filterType, setFilterType] = useState('');
    const [filterForm, setFilterForm] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleFilter = (value) => {
        setFilter(value);
    };
    const applyFilters = (filterBy, value) => {
        if (filterBy === 'type') setFilterType(value);
        if (filterBy === 'status') setFilterStatus(value);
        if (filterBy === 'form') setFilterForm(value);

        // Apply all active filters
        let filteredRows = initialRows;

        if (filterType !== '' || (filterBy === 'type' && value !== '')) {
            filteredRows = filteredRows.filter(
                (row) => row.type === (filterBy === 'type' ? value : filterType)
            );
        }
        if (filterStatus !== '' || (filterBy === 'status' && value !== '')) {
            filteredRows = filteredRows.filter(
                (row) => row.status === (filterBy === 'status' ? value : filterStatus)
            );
        }
        if (filterForm !== '' || (filterBy === 'form' && value !== '')) {
            filteredRows = filteredRows.filter(
                (row) => row.form === (filterBy === 'form' ? value : filterForm)
            );
        }

        setRows(filteredRows);
        setFilter('');
        const updatedSelectedRows = filteredRows.filter(row => row.selected).map(row => row.id);
        setSelectedRows(updatedSelectedRows);
        setDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const resetFilters = () => {
        setFilterType('');
        setFilterStatus('');
        setFilterForm('');
        setFilter('');
        setRows(initialRows);
        const defaultSelectedRows = initialRows.filter(row => row.selected).map(row => row.id);
        // console.log(defaultSelectedRows);

        setSelectedRows(defaultSelectedRows);
        setDropdownOpen(false);
    };



    const [selected, setSelected] = useState(false);
    const [viewSelected, setViewSelected] = useState([]);
    const handleSelectedRows = () => {
        setSelected(true);

        const selectedData = rows.filter((row) => selectedRows.includes(row.id));
        setViewSelected(selectedData);
    };
    const closeChannelDataModel = () => {
        toggleModal('ChannelDataModel');
        setDropdownOpen();
        resetFilters();
        setSelected(false);
        setShowURL(true);
        setMessage(0);
        setConnect(false);
    }

    const handleCopyLink = () => {
        const url = `${pageUrl}?source=${channelData.channel}`;
        clipboard(url,'Link');
    };

    // const fetchProgramValidation = async () => {
    //     try {

    //         const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/programValidation/checkValidation`, {
    //             method: 'GET',
    //             credentials: 'include',
    //         });

    //         const data = await response.json();
    //         return true
    //     } catch (error) {
    //         console.error('Error fetching performance data:', error);
    //     }
    // };
    const [message, setMessage] = useState(0);

    const [showURL, setShowURL] = useState(true);
    const [connect, setConnect] = useState(false);
    const callBackImpressions = async () => {
        setMessage(1);
        let apiURL = "";
        if (selectedChannel === "meta_ad") {
            apiURL = `${process.env.REACT_APP_BASE_URL_API}/api/facebook/getImpressions`;
        } else if (selectedChannel === "google_ad") {
            apiURL = `${process.env.REACT_APP_BASE_URL_API}/api/google/getImpressions`;
        } else if (selectedChannel === "linkedin_ad") {
            apiURL = `${process.env.REACT_APP_BASE_URL_API}/api/linkedInPro/getImpressions`;
        } else {
            apiURL = "";
        }

        const response = await fetch(`${apiURL}`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await response.json();
        if (data) {
            setMessage(2);
            // await fetchProgramValidation();
            return true
        }
    }

    const handleConnectClick = async () => {
        const selectChannelData = rows.filter((row) => selectedRows.includes(row.id));
        if (selectChannelData.length === 0) {
            alert('Please select a campaign');
            return;
        }
        
        const selData = selectChannelData.map(chl => ({
            id: chl.id,
            name: chl.name,
            objectives: chl.objectives || '',
            form: chl.form || 0,
            status: chl.status || '',
            type: chl.type
        }));

        // Check if no channels are selected
        if (selData && selData.length === 0) {
            alert('Please select campaign');
            return;
        }

        const selectedData = {
            programId: programId,
            builder: channelData.builder,
            selectedChannel: selectedChannel,
            totalAcImpressions: 0, //totalAcImpressions,
            saveData: selData
        };
        // console.log(selectedData);
        try {
            // Make the POST request
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/leadgen/saveSelectedChannel`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData), // Send the data as JSON
            });

            // Handle the response from the API
            if (response.ok) {
                const result = await response.json();
                //console.log('API response:', result);
                setShowURL(false);
                setConnect(true);
                // await fetchProgramValidation();
            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };
    

    const formatChannelName = (channel) => {
        // Check if channel is valid
        if (!channel || typeof channel !== 'string') {
            return ''; // Return an empty string or a default value if channel is not valid
        }
        return channel
            .replace(/_/g, ' ') // Replace underscores with spaces
            .split(' ') // Split into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
            .join(' '); // Join the words back together
    };



    const handleDone = async () => {
        try {
            setShowURL(false);
            await callBackImpressions();
        } catch (error) {
            console.log(error);
        }
    }

    const handleConnectClose = () => {
        setShowURL(true);
        setMessage(0);
        toggleModal('ChannelDataModel');
        setConnect(false);
    }
    
    return (
        <Modal
            show={modalState.ChannelDataModel}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={closeChannelDataModel}
            className="custom-channelData-modal"
        >
            {selected ?
                <>
                    <Modal.Header closeButton>
                        <div className='selected-header' style={{ display: 'flex', width: '100%' }}>
                            <div className='selected-one' style={{ width: '90%' }}>
                                <p className='ad-details-p'>Selected Google Ad Campaigns <img src={I_icon} /></p>
                            </div>
                            <div className='selected-two' style={{ width: '10%' }}>
                                <button className='selected-header-close' style={{ float: 'right' }} onClick={() => setSelected(false)}><RxCross2 />&nbsp;Close</button>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ width: '100%', height: '80vh' }}>
                            <DataGrid
                                checkboxSelection
                                disableRowSelectionOnClick
                                rows={viewSelected}
                                columns={columns}
                                pageSize={5}
                                rowHeight={45}
                                pagination
                                disableColumnMenu={true}
                                page={page}
                                onPageChange={(newPage) => setPage(newPage)}
                                initialState={{
                                    ...rows.initialState,
                                    pagination: { paginationModel: { pageSize: 10 } },
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25]}
                                loading={loading}
                                slots={{
                                    pagination: CustomPagination,
                                }}
                                columnHeaderHeight={40}
                                onRowSelectionModelChange={(ids) => {
                                    setSelectedRows(ids);
                                }}
                                rowSelectionModel={selectedRows}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </>
                :
                //if selected is false
                <>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" className='ad-details'>
                            <p className='ad-details-p'>Ad Accounts Details</p>
                            <div className='ad-profile-card'>
                                <div className='ad-profile-avatar'>
                                    <img src={accountImage} alt='profile pic' />
                                </div>
                                <div className='ad-profile-details'>
                                    <p><span>{accountName}</span>&nbsp; id : {accountId}</p>
                                    <p></p>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!connect && (showURL ?
                            <>
                                <h5 className='list-head pt-4'>Registration Page URL</h5>
                                <div className='footer-input'>
                                    <div className='url-input'>
                                        <input type='text' className='page-url pe-none' value={pageUrl} readOnly />
                                    </div>
                                    <div className='copy-btn'>
                                        <button onClick={handleCopyLink}><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 0.8V17.6C24 18.042 23.642 18.4 23.2 18.4H20C19.558 18.4 19.2 18.042 19.2 17.6C19.2 17.158 19.558 16.8 20 16.8H22.4V1.6H7.2V4C7.2 4.44201 6.84201 4.8 6.4 4.8C5.95799 4.8 5.6 4.44201 5.6 4V0.8C5.6 0.35799 5.95799 0 6.4 0H23.2C23.642 0 24 0.35799 24 0.8ZM17.6 5.6H0.8C0.35799 5.6 0 5.95799 0 6.4V23.2C0 23.642 0.35799 24 0.8 24H17.6C18.042 24 18.4 23.642 18.4 23.2V6.4C18.4 5.95799 18.042 5.6 17.6 5.6Z" fill="#028897" />
                                        </svg>
                                        </button>
                                    </div>
                                </div>
                                <p>Copy the page url page and add in campain cta </p>
                                <div className='grid-btn' style={{ float: 'right' }}>
                                    <button className='grid-btn-cancel' onClick={handleDone}>Done</button>
                                </div>
                            </>
                            :
                            <>
                                <h5 className='list-head'>List of {formatChannelName(selectedChannel)} Campaigns</h5>
                                <div style={{ width: '100%' }}>
                                    <div style={{ width: '100%', height: '320px' }}>
                                        <div className='row' style={{ margin: '12px 0px' }}>
                                            <div className="col-sm-3 search-wrapper">
                                                <CiSearch className='search-icon' />
                                                <input
                                                    type="text"
                                                    className="grid-search"
                                                    placeholder="search by campaign name"
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                            </div>

                                            <div className='col-sm-4 grid-btn'>
                                                <div>
                                                    <button
                                                        id="filter-dropdown"
                                                        className="btn btn-primary"
                                                        onClick={toggleDropdown}
                                                    >
                                                        <FaFilter style={{ fontSize: '12px', paddingTop: '-8px' }} /> &nbsp; Filter<AiOutlineCaretDown style={{ transform: `rotate(${dropdownOpen ? '0deg' : '270deg'})` }} />
                                                    </button>

                                                    {dropdownOpen && (
                                                        <div
                                                            className="dropdown-menu show"
                                                            style={{ position: 'absolute', marginLeft: '14px', borderRadius: '2px' }}
                                                        >
                                                            {filter === '' && (
                                                                <>
                                                                    <div className='header-clear-filter' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <h6 className="dropdown-header">Add Filters </h6>
                                                                        {(filterType || filterStatus || filterForm) && <span style={{ marginLeft: 'auto', color: '#888', color: '#028897', cursor: 'pointer', fontSize: '12px', padding: '6px' }} onClick={() => resetFilters()}>Clear All</span>}
                                                                    </div>
                                                                    <a className="dropdown-item" onClick={() => handleFilter('type')} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        Type
                                                                        <span style={{ marginLeft: 'auto', color: '#888' }}>{filterType == "" ? '>' : filterType}</span>
                                                                    </a>

                                                                    <a className="dropdown-item" onClick={() => handleFilter('status')} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        Status
                                                                        <span style={{ marginLeft: 'auto', color: '#888' }}>{filterStatus == "" ? '>' : filterStatus}</span>
                                                                    </a>

                                                                    <a className="dropdown-item" onClick={() => handleFilter('form')} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        Form
                                                                        <span style={{ marginLeft: 'auto', color: '#888' }}>{filterForm == "" ? '>' : filterForm}</span>
                                                                    </a>

                                                                </>
                                                            )}
                                                            {filter === 'type' && (
                                                                <>
                                                                    <h6 className="dropdown-header">Type</h6>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => applyFilters('type', 'lead')}
                                                                    >
                                                                        Lead
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => applyFilters('type', 'contact')}
                                                                    >
                                                                        Contact
                                                                    </a>
                                                                </>
                                                            )}

                                                            {filter === 'status' && (
                                                                <>
                                                                    <h6 className="dropdown-header">Status</h6>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => applyFilters('status', 'active')}
                                                                    >
                                                                        Active
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => applyFilters('status', 'paused')}
                                                                    >
                                                                        Paused
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => applyFilters('status', 'draft')}
                                                                    >
                                                                        Draft
                                                                    </a>
                                                                </>
                                                            )}

                                                            {filter === 'form' && (
                                                                <>
                                                                    <h6 className="dropdown-header">Form</h6>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => applyFilters('form', 'Yes')}
                                                                    >
                                                                        Yes
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => applyFilters('form', 'No')}
                                                                    >
                                                                        No
                                                                    </a>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <button onClick={handleSelectedRows}>view Selected </button>
                                            </div>
                                            <div className='col-sm-2'></div>
                                            <div className='col-sm-3'>
                                                <div className='grid-btn' style={{ float: 'right' }}>
                                                    <button onClick={handleCreateNewLinkClick}>Create New</button>
                                                    <button className='grid-btn-cancel' onClick={handleConnectClick}>Connect</button>
                                                </div>
                                            </div>
                                        </div>
                                        {initialRows.length > 0 &&
                                            <>
                                                <DataGrid
                                                    checkboxSelection
                                                    disableRowSelectionOnClick
                                                    rows={rows}
                                                    columns={filteredColumns}
                                                    pageSize={5}
                                                    rowHeight={45}
                                                    autoHeight
                                                    pagination
                                                    disableColumnMenu={true}
                                                    page={page}
                                                    onPageChange={(newPage) => setPage(newPage)}
                                                    initialState={{
                                                        ...rows.initialState,
                                                        pagination: { paginationModel: { pageSize: 5 } },
                                                        columns: {
                                                            columnVisibilityModel: {
                                                                id: false,
                                                            },
                                                        },
                                                    }}
                                                    pageSizeOptions={[5, 10, 25]}
                                                    loading={loading}
                                                    slots={{
                                                        pagination: CustomPagination,
                                                    }}
                                                    columnHeaderHeight={40}
                                                    onRowSelectionModelChange={(ids) => {
                                                        setSelectedRows(ids);
                                                    }}
                                                    rowSelectionModel={selectedRows}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                            </>)
                        }

                        {connect &&
                            <>
                                {message === 2 && <p>Connected Successfully</p>}
                                {message === 1 && <p>cron is running once cron is completed card will get updated</p>}
                                <div className='grid-btn' style={{ float: 'right' }}>
                                    <button className='grid-btn-cancel' onClick={handleConnectClose}>Close</button>
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {!showURL && !connect &&
                            <>
                                <h5 className='list-head pt-4'>Registration Page URL</h5>
                                <div className='footer-input'>
                                    <div className='url-input'>
                                        <input type='text' className='page-url pe-none' value={pageUrl} readOnly />
                                    </div>
                                    <div className='copy-btn'>
                                        <button onClick={handleCopyLink}><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 0.8V17.6C24 18.042 23.642 18.4 23.2 18.4H20C19.558 18.4 19.2 18.042 19.2 17.6C19.2 17.158 19.558 16.8 20 16.8H22.4V1.6H7.2V4C7.2 4.44201 6.84201 4.8 6.4 4.8C5.95799 4.8 5.6 4.44201 5.6 4V0.8C5.6 0.35799 5.95799 0 6.4 0H23.2C23.642 0 24 0.35799 24 0.8ZM17.6 5.6H0.8C0.35799 5.6 0 5.95799 0 6.4V23.2C0 23.642 0.35799 24 0.8 24H17.6C18.042 24 18.4 23.642 18.4 23.2V6.4C18.4 5.95799 18.042 5.6 17.6 5.6Z" fill="#028897" />
                                        </svg>
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    </Modal.Footer>
                </>
            }

        </Modal>
    );
};
export default ChannelDataModel;
