import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";
import SearchFilter from "./listModels/SearchBar";
import TableComponent from "./listModels/Table";
import Pagination from "./listModels/Pagination";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { RxDownload } from "react-icons/rx";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const Form = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([
        "formName",
        "formfill",
    ]);
    const [additionalColumns, setAdditionalColumns] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterValues, setFilterValues] = useState([]);
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formId, setFormId] = useState('');
    const [formName, setFormName] = useState('');
    const [formContent, setFormContent] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const api = await axios.post(
                `${process.env.REACT_APP_BASE_URL_API}/api/masterData/getFormMasterList`,
                {},
                { withCredentials: true }
            );
            const response = api.data;
            setData(response);

            if (response.length > 0) {
                const allColumns = Object.keys(response[0]);
                setAdditionalColumns(allColumns.filter((col) => !selectedColumns.includes(col)));
            }
        };

        fetchData();
    }, [selectedColumns]);

    const filteredData = data.filter((row) => {
        const matchesSearch = searchTerm
            ? Object.values(row).some((value) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            : true;
        const matchesFilter =
            filterType && selectedFilterValue
                ? row[filterType]?.toLowerCase() === selectedFilterValue.toLowerCase()
                : true;

        return matchesSearch && matchesFilter;
    });

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );
    // ------------------------ export to excel --------------------------------------------
    const handleExport = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Forms");
        const columns = selectedColumns.map((col) => ({
            header: col,
            key: col,
            width: 20,
        }));
        worksheet.columns = columns;

        const exportData = selectedRows.length > 0
            ? data.filter((row) => selectedRows.includes(row._id))
            : data;
        exportData.forEach((row) => {
            const rowData = {};
            selectedColumns.forEach((col) => {
                rowData[col] = row[col] || "";
            });
            worksheet.addRow(rowData);
        });

        //------- stlyes for the excel ----
        worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell) => {
                cell.font = { name: "Arial", size: 10 };
                cell.alignment = { vertical: "middle", horizontal: "center" };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                if (rowNumber === 1) {
                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FFD3E4CD" },
                    };
                }
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        saveAs(blob, "Forms.xlsx");
    };

    const handleColumnClick = (id) => {
        const selectedAsset = data.find((row) => row._id === id);
        if (selectedAsset) {
            setFormId(selectedAsset._id);  // Store the asset ID
            setFormName(selectedAsset.formName); 
            setFormContent(selectedAsset.html); 
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="app">
            <NavbarComp />
            <div className="container-fluid content overflow-hidden">
                <h3 className="fw-bold master-heading">Forms</h3>
                {/* Back to Menu Button */}

                <button
                    onClick={() => navigate("/menus")}
                    style={{
                        padding: "5px 15px",
                        backgroundColor: "#048998",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        float: 'right'
                    }}
                >
                    Back to Menu
                </button>
                {/* ---- export button ---- */}
                <button
                    onClick={handleExport}
                    style={{
                        padding: "5px 15px",
                        backgroundColor: "#048998",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        float: 'right',
                        marginRight: '10px'
                    }}
                >
                   <RxDownload id="contactexcel-icon" />
                   Export
                </button>
                <div className="container-fluid px-0 pt-2">
                    <SearchFilter
                        searchTerm={searchTerm}
                        onSearchChange={(e) => setSearchTerm(e.target.value)}
                        filterType={filterType}
                        onFilterTypeChange={(e) => setFilterType(e.target.value)}
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                        data={data}
                        selectedFilterValue={selectedFilterValue}
                        onFilterValueChange={(e) => setSelectedFilterValue(e.target.value)}
                        additionalColumns={additionalColumns}
                        onAddColumn={(col) => setSelectedColumns([...selectedColumns, col])}
                        showAddColumn={false} 
                        filterOptions={[
                            { value: "programId", label: "Program" },
                        ]}
                    />
                    <TableComponent
                        data={paginatedData}
                        selectedColumns={selectedColumns}
                        selectedRows={selectedRows}
                        onRowSelect={(id) =>
                            setSelectedRows((prev) =>
                                prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
                            )
                        }
                        onHeaderSelect={(e) =>
                            setSelectedRows(e.target.checked ? data.map((row) => row._id) : [])
                        }
                        onColumnClick={(id) => handleColumnClick(id)}
                    />
                    {/* Modal */}
                    <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="asset-name-modal"
                        aria-describedby="asset-name-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '50%',
                                height: '80vh',
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 2,
                                }}
                            >
                                    <h4>{formName}</h4>
                                <Button
                                    onClick={handleCloseModal}
                                    sx={{ minWidth: 'auto', p: 0 }}
                                    aria-label="Close"
                                >
                                    ✖
                                </Button>
                            </Box>
                            {/* Scrollable Content */}
                            <Box
                                sx={{
                                    flex: 1,
                                    overflowY: 'auto', // Enables vertical scrolling
                                    pr: 2, // Adds padding to prevent content clipping on scroll
                                }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: formContent }} />
                            </Box>
                        </Box>
                    </Modal>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        onPageChange={setCurrentPage}
                    />
                </div>
            </div>
        </div>
    );
}

export default Form