import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import NavbarComp from "../../components/NavbarComp";
import HeaderComp from '../../components/HeaderComp';
import ProgressStepper from "../EventProgram/ProgressStepper";
import OutcomeStyle from "../../components/CSS/Outcome.css"

import * as XLSX from 'xlsx';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Button } from 'react-bootstrap';
import { MdOutlineArrowBack } from "react-icons/md";
import Guage from './SemiCircleProgressBar ';

import ModalComponent from "./ModalComponent"

import PerformanceChannel from './PerformanceChannel'
import CostChannel from './CostChannel'

import FlowChannel from './FlowChannel'
import google from '../../components/Assets/google-fow.svg'
import meta from '../../components/Assets/meta-flow.svg'
import mail from '../../components/Assets/mail-flow.svg'
import linkdin from '../../components/Assets/linkdin-flow.svg'
import pdf from '../../components/Assets/pdf-outcome.svg'
import excel from '../../components/Assets/excel-outcome.svg'
import WhatsApp from '../../components/Assets/socialIcons/Whatsapp.png'

import { MdOutlineFileDownload } from "react-icons/md";

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
// import { WhatsApp } from '@mui/icons-material';

// import { Button } from 'react-bootstrap';    

// const DownloadComponent = () => {
// Function to capture the div and download it as a PDF

// }

// --------------------------------------------------------
// import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// --------------------------------------------------------


const steps = [
    {
        label: '1',
        name: 'step 1',
        className: 'step1 custom-step',
        subSteps: [
            { label: 'Step 1.1' },
            { label: 'Step 1.2' },
            { label: 'Step 1.3' },
            { label: 'Step 1.4' },
        ],
        stepHead: 'Basic Information',
        stepText: 'Provide essential details about your business and event to enhance marketing automation with our AI.'
    },
    {
        label: '2',
        name: 'step 2',
        className: 'step2 custom-step',
        subSteps: [
            { label: 'Step 2.1' },
            { label: 'Step 2.2' },
            { label: 'Step 2.3' },
            { label: 'Step 2.4' },
        ],
        stepHead: 'Journey',
        stepText: 'Guide, remind, and follow up with attendees through every event stage.'
    },
    {
        label: '3',
        name: 'step 3',
        className: 'step3 custom-step',
        subSteps: [
            { label: 'Step 3.1' },
            { label: 'Step 3.2' },
            { label: 'Step 3.3' },
            { label: 'Step 3.4' },
        ],
        stepHead: 'Results/Outcomes',
        stepText: 'Evaluate success and impact from event awareness to attendance and follow-up communication.'
    },
];
const Outcome = () => {
    const { programId } = useParams();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [currentSubStep, setCurrentSubStep] = useState(0);
    const [performanceData, setPerformanceData] = useState(null);
    const [Channelwisedata, setChannelwisedata] = useState([]);
    const contentRef = useRef();


    useEffect(() => {
        const fetchPerformanceData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/outcome/performanceAllView/${programId}`, {
                    method: 'GET',
                    credentials: 'include',
                });

                // Check if the response is OK (status code in range 200-299)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setPerformanceData(data); // Set the correct data
            } catch (error) {
                console.error('Error fetching performance data:', error);
            }
        };
        // Call the fetch function
        fetchPerformanceData();
    }, [programId]);

    const handleFinish = () => {
        navigate(`/program}`);
    }

    // tabs----------
    const [key, setKey] = useState('Performance');
    const [isChannelView, setIsChannelView] = useState(false); // State to track Channel View

    const toggleChannelView = () => {
        setIsChannelView((prev) => !prev); // Toggle channel view state
    };

    // reach
    const reachClicks = performanceData?.performance.reach.reachclicks || 0;
    const reachImpressions = performanceData?.performance.reach.reachimmpressions || 0;
    const maxMetric = Math.max(reachClicks, reachImpressions);
    const clicksPercentage = maxMetric ? (reachClicks / maxMetric) * 100 : 0;
    const impressionsPercentage = maxMetric ? (reachImpressions / maxMetric) * 100 : 0;
    // Register
    const registerClick = performanceData?.performance.register.registerformfillEx || 0;
    const registerImpressions = performanceData?.performance.register.registerformfillAc || 0;
    const maxMetric1 = Math.max(registerClick, registerImpressions);
    const clicksPercentage1 = maxMetric1 ? (registerClick / maxMetric1) * 100 : 0;
    const impressionsPercentage1 = maxMetric1 ? (registerImpressions / maxMetric1) * 100 : 0;
    // Attend
    const attende = performanceData?.performance.attend.attendeeList || 0;
    const nonattende = performanceData?.performance.attend.nonAttendeeList || 0;
    const maxMetric2 = Math.max(attende, nonattende);
    const clicksPercentage2 = maxMetric2 ? (attende / maxMetric2) * 100 : 0;
    const impressionsPercentage2 = maxMetric2 ? (nonattende / maxMetric2) * 100 : 0;
    // cost reach
    const costImpression = performanceData?.cost.reach.costPerImpressions || 0;
    const costClick = performanceData?.cost.reach.costPerClicks || 0;
    const maxMetric3 = Math.max(costImpression, costClick);
    const clicksPercentage3 = maxMetric3 ? (costImpression / maxMetric3) * 100 : 0;
    const impressionsPercentage3 = maxMetric3 ? (costClick / maxMetric3) * 100 : 0;
    // cost Register
    const registerImpression = performanceData?.cost.register.expectedFormFills || 0;
    const registerClicks = performanceData?.cost.register.actualFormFills || 0;
    const maxMetric4 = Math.max(registerImpression, registerClick);
    const clicksPercentage4 = maxMetric3 ? (registerImpression / maxMetric4) * 100 : 0;
    const impressionsPercentage4 = maxMetric3 ? (registerClicks / maxMetric4) * 100 : 0;
    // cost attend
    const attendImpression = performanceData?.cost.attendee.expectedAttendeePerCost || 0;
    const attendClicks = performanceData?.cost.attendee.actualAttendeePerCost || 0;
    const maxMetric5 = Math.max(attendImpression, attendClicks);
    const clicksPercentage5 = maxMetric5 ? (attendImpression / maxMetric5) * 100 : 0;
    const impressionsPercentage5 = maxMetric5 ? (attendClicks / maxMetric5) * 100 : 0;
    // flow register
    const clicksImpression = performanceData?.flow.reachToRegister.clicksImpression || 0;
    const registerList = performanceData?.flow.reachToRegister.registerList || 0;
    const maxMetric6 = Math.max(clicksImpression, registerList);
    const clicksPercentage6 = maxMetric6 ? (clicksImpression / maxMetric6) * 100 : 0;
    const impressionsPercentage6 = maxMetric6 ? (registerList / maxMetric6) * 100 : 0;
    // flow attend
    const registerFlowList = performanceData?.flow.registerToAttend.registerList || 0;
    const attendList = performanceData?.flow.registerToAttend.attendList || 0;
    const maxMetric7 = Math.max(registerFlowList, attendList);
    const clicksPercentage7 = maxMetric7 ? (registerFlowList / maxMetric7) * 100 : 0;
    const impressionsPercentage7 = maxMetric7 ? (attendList / maxMetric7) * 100 : 0;
    // flow sales
    const attendFlowList = performanceData?.flow.attendToSales.attendList || 0;
    const crmPushed = performanceData?.flow.attendToSales.crmPushed || 0;
    const maxMetric8 = Math.max(attendFlowList, crmPushed);
    const clicksPercentage8 = maxMetric8 ? (attendFlowList / maxMetric8) * 100 : 0;
    const impressionsPercentage8 = maxMetric8 ? (crmPushed / maxMetric8) * 100 : 0;

    // const percentage = 2.05; // You can dynamically set this value

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prevProgress + 5;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    const [showModal, setShowModal] = useState(false);
    const [channel, setChannel] = useState('');
    const handleShowModal = (channel) => {
        setChannel(channel);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);

    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [costdata1, setcostdata1] = useState([]);
    const [costdata2, setcostdata2] = useState([]);
    const [costdata3, setcostdata3] = useState([]);

    useEffect(() => {
        const fetchChannelData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/outcome/performanceChannelView/${programId}`, {
                    method: 'GET',
                    credentials: 'include',
                });

                // Check if the response is OK (status code in range 200-299)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data) {
                    setChannelwisedata(data);
                    // Map the data to match the data1 structure
                    const mappedData1 = data.map((row) => {
                        return {
                            name: row.channelLabel,
                            Projected: row.totalImpressions || 0,
                            Actual: row.totalClicks || 0,
                        };
                    });
                    console.log('mapped data', mappedData1);

                    // Update state with the mapped data
                    setData1(mappedData1);

                    const mappedData2 = data.map((row) => {
                        return {
                            name: row.channelLabel,
                            Projected: row.exRegistrations || 0,
                            Actual: row.actualFormFills || 0,
                        };
                    });
                    // Update state with the mapped data
                    setData2(mappedData2);

                    //-------------------------------------------------
                    const mappedData3 = data.map((row) => {
                        return {
                            name: row.channelLabel,
                            Projected: row.attended || 0,
                            Actual: row.notattended || 0,
                        };
                    });
                    setData3(mappedData3);

                    const mappedcostdata1 = data.map((row) => {
                        return {
                            name: row.channelLabel,
                            Projected: row.cpi || 0,
                            Actual: row.cpc || 0,
                        };
                    });
                    // Update state with the mapped data
                    setcostdata1(mappedcostdata1);


                    const mappedcostdata2 = data.map((row) => {
                        return {
                            name: row.channelLabel,
                            Projected: row.exBudget || 0,
                            Actual: row.exCostperregister || 0,
                        };
                    });
                    // Update state with the mapped data 
                    setcostdata2(mappedcostdata2);



                    const mappedcostdata3 = data.map((row) => {
                        return {
                            name: row.channelLabel,
                            Projected: row.totalcost || 0,
                            Actual: row.exCostperregister || 0,
                        };
                    });
                    // Update state with the mapped data
                    setcostdata3(mappedcostdata3);

                }
            } catch (error) {
                console.error('Error fetching performance data:', error);
            }
        };
        // Call the fetch function
        fetchChannelData();
    }, [programId]);

    const calculateOverallPerformance = (data) => {
        const validItems = data.filter(item => item.Projected && item.Projected !== 0); // Filter out items with zero or undefined Projected values

        if (validItems.length === 0) return "0.0"; // Fallback if no valid items

        const sumPercentage = validItems.reduce((sum, item) => {

            return sum + ((item.Actual) / item.Projected) * 100;
        }, 0);

        return (sumPercentage / validItems.length).toFixed(1);
    };

    // Usage for each dataset
    const overallPerformance1 = calculateOverallPerformance(data1);
    const overallPerformance2 = calculateOverallPerformance(data2);
    const overallPerformance3 = calculateOverallPerformance(data3);
    const overallPerformance4 = calculateOverallPerformance(costdata1);
    const overallPerformance5 = calculateOverallPerformance(costdata2);
    const overallPerformance6 = calculateOverallPerformance(costdata3);

    // console.log(Channelwisedata);

    // const emailData=Channelwisedata.find(item=>item.channelName==='email');
    // const whatsappData=Channelwisedata.find(item=>item.channelName==='whatsapp');
    // const googleData=Channelwisedata.find(item=>item.channelName==='google');
    // const metaData=Channelwisedata.find(item=>item.channelName==='meta');
    // const linkedinData=Channelwisedata.find(item=>item.channelName==='linkedin');
    //Flow channel register
    const gaugeData = Channelwisedata.map((row) => {
        let image;
        if (row.channelName === 'google') {
            image = google;
        } else if (row.channelName === 'whatsapp') {
            image = WhatsApp;
        } else if (row.channelName === 'linkedin') {
            image = linkdin;
        } else if (row.channelName === 'email') {
            image = mail;
        } else if (row.channelName === 'meta') {
            image = meta;
        }
        return {
            percentage: row?.clicks_formfills,
            registered: row?.actualFormFills || 0,
            total: row?.totalClicks || 0,
            image: image //---------------------need to change --------cpm-------
        };
    });
    //Flow channel attend
    const gaugeDataAttend = Channelwisedata.map((row) => {
        let image;
        if (row.channelName === 'google') {
            image = google;
        } else if (row.channelName === 'whatsapp') {
            image = WhatsApp;
        } else if (row.channelName === 'linkedin') {
            image = linkdin;
        } else if (row.channelName === 'email') {
            image = mail;
        } else if (row.channelName === 'meta') {
            image = meta;
        }
        return {
            percentage: row?.formfills_attend,
            registered: row?.attended || 0,
            total: row?.actualFormFills || 0,
            image: image //---------------------need to change --------cpm-------
        };
    });
    //Flow channel sales
    const gaugeDataSales = Channelwisedata.map((row) => {
        let image;
        if (row.channelName === 'google') {
            image = google;
        } else if (row.channelName === 'whatsapp') {
            image = WhatsApp;
        } else if (row.channelName === 'linkedin') {
            image = linkdin;
        } else if (row.channelName === 'email') {
            image = mail;
        } else if (row.channelName === 'meta') {
            image = meta;
        }
        return {
            percentage: row?.attend_pushtocrm,
            registered: row?.attended || 0,
            total: row?.pushedtocrm || 0,
            image: image //---------------------need to change --------cpm-------
        };
    });

    // Function to handle downloading Excel



    const downloadPDF = () => {
        const pdf = new jsPDF();
        const imgWidth = 190;
        const pageHeight = 295;

        // Helper function to capture and add divs to PDF
        const captureAndAddToPdf = (elementIds, addNewPage) => {
            const elements = elementIds.map(id => document.getElementById(id));

            // Create a temporary container to append elements
            const tempContainer = document.createElement("div");
            tempContainer.style.position = "fixed";
            tempContainer.style.top = "-9999px"; // Place off-screen
            tempContainer.style.left = "0";
            tempContainer.style.display = "inline-block"; // inline to stack horizontally

            elements.forEach(element => {
                const clone = element.cloneNode(true);
                tempContainer.appendChild(clone);
            });

            document.body.appendChild(tempContainer);

            // Capture the combined div content
            return html2canvas(tempContainer).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                if (addNewPage) pdf.addPage();
                pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
                document.body.removeChild(tempContainer);
            });
        };

        // Capture and add pages in sequence
        captureAndAddToPdf(["page1", "page2", "page3", "page4", "page5"], false).then(() => {
            return captureAndAddToPdf(["page6"], true);
        }).then(() => {
            pdf.save("Results and Outcome.pdf");
        });
    };

    // Function to handle downloading Excel
    // ---------------------------------------------------------------------

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Set column widths for better readability
        worksheet.columns = Array(12).fill({ width: 30 });

        // Header rows for performance section
        const headerRow = ['Performance Report '];
        const headerRow1 = ['Reach', '', '', 'Register', '', '', 'Attend', '', ''];
        const headerRow2 = [
            `Impressions - ${reachImpressions}`, '<- Reach ->', `Click - ${reachClicks}`,
            `Expected Registration - ${registerClick}`, '<- Register ->', `Actual Registration - ${registerImpressions}`,
            `Attendees - ${attende}`, '<- Attend ->', `Non-Attend-${nonattende}`
        ];
        const headerRow3 = ['Channel View', '', '', 'Channel View', '', '', 'Channel View', '', ''];
        const headerRow4 = [
            'Channel', 'Impressions', 'Click', 'Channel', 'Expected', 'Actual', 'Channel', 'Attendee', 'Non-Attendee'
        ];

        worksheet.addRow(headerRow);
        worksheet.addRow(headerRow1);
        worksheet.addRow(headerRow2);
        worksheet.addRow([]); // Empty row
        worksheet.addRow(headerRow3);
        worksheet.addRow(headerRow4);
        [1].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
                cell.font = { size: 18, color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
            });
        });

        [2].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });
        [3].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDBDBDB' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });
        ['A3', 'D3', 'G3'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).alignment = { horizontal: 'right' };
        });
        ['C3', 'F3', 'I3'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).alignment = { horizontal: 'left' };
        });
        ['B3', 'E3', 'H3'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' }
            };
            worksheet.getCell(cellAddress).font = {
                bold: true,
                color: { argb: "FFFFFFFF" }
            };
        });

        worksheet.mergeCells('A1:I1');
        worksheet.mergeCells('A2:C2');
        worksheet.mergeCells('D2:F2');
        worksheet.mergeCells('G2:I2');
        worksheet.mergeCells('A5:C5');
        worksheet.mergeCells('D5:F5');
        worksheet.mergeCells('G5:I5');

        const performanceDataRows = data1.map((row, index) => [
            row.name, row.Projected, row.Actual,
            data2[index] ? data2[index].name : '', data2[index] ? data2[index].Projected : '', data2[index] ? data2[index].Actual : '',
            data3[index] ? data3[index].name : '', data3[index] ? data3[index].Projected : '', data3[index] ? data3[index].Actual : ''
        ]);

        performanceDataRows.forEach(row => worksheet.addRow(row));

        // Apply styles and alternate row colors to performance data rows
        performanceDataRows.forEach((_, index) => {
            const row = worksheet.getRow(index + 7);
            row.eachCell(cell => {
                cell.alignment = { horizontal: 'center' };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: index % 2 === 0 ? 'FFFFFFFF' : 'FFFFFFF0' }
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });

        // Footer for performance section
        const footerRowPerformance = [
            `Overall Performance: ${calculateOverallPerformance(data1)}`, '', '',
            `Overall Performance: ${calculateOverallPerformance(data2)}`, '', '',
            `Overall Performance: ${calculateOverallPerformance(data3)}`, '', ''
        ];
        worksheet.addRow(footerRowPerformance);
        worksheet.mergeCells('A12:C12');
        worksheet.mergeCells('D12:F12');
        worksheet.mergeCells('G12:I12');


        ['A12', 'D12', 'G12'].forEach(cellAddress => {
            const cell = worksheet.getCell(cellAddress);

            // Set text color to white, background color to black, and font size to 18
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF000000' } };  // Black background
            cell.font = { color: { argb: 'FFFFFFFF' }, size: 12 };  // White text and font size 18
            cell.alignment = { horizontal: 'center', vertical: 'middle' };  // Center alignment

        });

        const headerRows = [5, 6]; // Add more row numbers as needed

        headerRows.forEach(rowNumber => {
            worksheet.getRow(rowNumber).eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        // Separate section for cost data
        worksheet.addRow([]);
        worksheet.addRow([]);

        const costheaderRow = ['Cost Report'];
        const costHeaderRow1 = ['Reach', '', '', 'Register', '', '', 'Attend', '', ''];
        const costHeaderRow2 = [
            `Cost per Impressions - ${costImpression}$`, '<- Reach ->', `Cost per Click - ${costClick}$`,
            `Expected Cost Registration - ${registerImpression}$`, '<- Register ->', `Actual Cost Registration - ${registerClicks}$`,
            `Total cost per attendee - ${attendImpression}$`, '<- Attend ->', `Actual cost per attendee - ${attendClicks}$`
        ];
        const costHeaderRow3 = ['Channel View', '', '', 'Channel View', '', '', 'Channel View', '', ''];
        const costHeaderRow4 = [
            'Channel', 'Cost/Impressions', 'Cost/Click', 'Channel', 'Cost/Expected', 'Cost/Actual', 'Channel', 'Total/Cost', 'Actual Cost/Attendee'
        ];
        worksheet.addRow(costheaderRow);
        worksheet.addRow(costHeaderRow1);
        worksheet.addRow(costHeaderRow2);
        worksheet.addRow([]); // Empty row
        worksheet.addRow(costHeaderRow3);
        worksheet.addRow(costHeaderRow4);

        [15].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
                cell.font = { size: 18, color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
            });
        });

        [16].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });
        [17].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDBDBDB' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });
        [19].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });
        [20].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });
        ['A17', 'D17', 'G17'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).alignment = { horizontal: 'right' };
        });
        ['C17', 'F17', 'I17'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).alignment = { horizontal: 'left' };
        });
        ['B17', 'E17', 'H17'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' } // Black color background
            };
            worksheet.getCell(cellAddress).font = {
                bold: true,
                color: { argb: "FFFFFFFF" }
            };
        });

        worksheet.mergeCells('A15:I15');
        worksheet.mergeCells('A16:C16');
        worksheet.mergeCells('D16:F16');
        worksheet.mergeCells('G16:I16');
        worksheet.mergeCells('A19:C19');
        worksheet.mergeCells('D19:F19');
        worksheet.mergeCells('G19:I19');

        // Cost data rows in a single row
        const costDataRows = costdata1.map((row, index) => [
            row.name, row.Projected, row.Actual,
            costdata2[index] ? costdata2[index].name : '', costdata2[index] ? costdata2[index].Projected : '', costdata2[index] ? costdata2[index].Actual : '',
            costdata3[index] ? costdata3[index].name : '', costdata3[index] ? costdata3[index].Projected : '', costdata3[index] ? costdata3[index].Actual : '',
            // costdata3[index] ? costdata3[index].name : '', costdata3[index].Projected : costdata3[index].Actual
        ]);

        costDataRows.forEach(row => worksheet.addRow(row));

        // Apply styles and alternate row colors to cost data rows
        costDataRows.forEach((_, index) => {
            const row = worksheet.getRow(index + 21);
            row.eachCell(cell => {
                cell.alignment = { horizontal: 'center' };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: index % 2 === 0 ? 'FFFFFFFF' : 'FFFFFFF0' }
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });
        // Applying borders to the entire table
        worksheet.eachRow(row => {
            row.eachCell(cell => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });
        // Footer for cost data section
        const footerRowCost = [
            `Overall Performance: ${calculateOverallPerformance(costdata1)}`, '', '',
            `Overall Performance: ${calculateOverallPerformance(costdata2)}`, '', '',
            `Overall Performance: ${calculateOverallPerformance(costdata3)}`, '', ''
        ];
        worksheet.addRow(footerRowCost);
        worksheet.mergeCells('A26:C26');
        worksheet.mergeCells('D26:F26');
        worksheet.mergeCells('G26:I26');


        ['A26', 'D26', 'G26'].forEach(cellAddress => {
            const cell = worksheet.getCell(cellAddress);

            // Set text color to white, background color to black, and font size to 18
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF000000' } };  // Black background
            cell.font = { color: { argb: 'FFFFFFFF' }, size: 12 };  // White text and font size 18
            cell.alignment = { horizontal: 'center', vertical: 'middle' };  // Center alignment

        });


        // Separate section for cost data
        worksheet.addRow([]);
        worksheet.addRow([]);

        // Add Headers for the Combined Table
        const combinedHeaderRow1 = ['Flow Report'];
        const combinedHeaderRow3 = ['Reach', '', '', 'Register', '', '', 'Attend', '', ''];
        const combinedHeaderRow4 = [
            `Clicks- ${clicksImpression}`, `<- Progress = ${performanceData?.flow.reachToRegister.reachClickPercentage}% ->`, `Registration - ${registerList}$`,
            `Registration - ${registerList}$`, `<- Progress = ${performanceData?.flow.registerToAttend.attendRegisterPercentage}% ->`, `Attendee - ${attendList}$`,
            `Attendee - ${attendFlowList}$`, `<- Progress = ${performanceData?.flow.registerToAttend.attendRegisterPercentage}% ->`, `Sales - ${crmPushed}$`
        ];
        const combinedHeaderRow5 = ['Channel View', '', '', '', 'Channel View', '', '', 'Channel View', '', ''];
        const combinedHeaderRow2 = [
            'Channel', 'No of Registered', 'No of Vists', 'Total Percentage',
            'Attendee', 'Registration', 'Total Percentage',
            'Contact Sent to CRM', 'Attendee', 'Total Percentage'
        ];

        // Add Headers to Worksheet
        worksheet.addRow(combinedHeaderRow1);
        worksheet.addRow(combinedHeaderRow3);
        worksheet.addRow(combinedHeaderRow4);
        worksheet.addRow([]); // Empty row
        worksheet.addRow(combinedHeaderRow5);


        worksheet.addRow(combinedHeaderRow2);

        [29].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
                cell.font = { size: 18, color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
            });
        });

        [30].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });
        [31].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDBDBDB' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });

        ['A31', 'D31', 'G31'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).alignment = { horizontal: 'right' };
        });
        ['C31', 'F31', 'I31'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).alignment = { horizontal: 'left' };
        });
        ['B31', 'E31', 'H31'].forEach(cellAddress => {
            worksheet.getCell(cellAddress).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' } // Black color background
            };
            worksheet.getCell(cellAddress).font = {
                bold: true,
                color: { argb: "FFFFFFFF" }
            };
        });

        [33].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });
        [34].forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
                cell.font = { color: { argb: 'FF000000' }, bold: true };
                cell.alignment = { horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });


        worksheet.mergeCells('A29:I29');
        worksheet.mergeCells('A33:D33');
        worksheet.mergeCells('E33:G33');
        worksheet.mergeCells('H33:J33');
        worksheet.mergeCells('A30:C30');
        worksheet.mergeCells('D30:F30');
        worksheet.mergeCells('G30:I30');


        // Map Data for the Three Tables
        const channelDataRows1 = Channelwisedata.map(data => [
            data.channelLabel || 'N/A',
            data.actualFormFills || 0,
            data.totalClicks || 0,
            data.clicks_formfills || 0
        ]);

        const channelDataRows2 = Channelwisedata.map(data => [
            data.attended || 0,
            data.actualFormFills || 0,
            data.formfills_attend || 0
        ]);

        const channelDataRows3 = Channelwisedata.map(data => [
            data.pushedtocrm || 0,
            data.attended || 0,
            data.attend_pushtocrm || 0
        ]);

        // Combine Rows for the Three Tables
        const maxRows = Math.max(channelDataRows1.length, channelDataRows2.length, channelDataRows3.length);
        for (let i = 0; i < maxRows; i++) {
            worksheet.addRow([
                ...(channelDataRows1[i] || ['', '', '', '']),
                ...(channelDataRows2[i] || ['', '', '', '']),
                ...(channelDataRows3[i] || ['', '', '', ''])
            ]);
        }

        // Style Combined Data Rows
        const startRow = worksheet.lastRow.number - maxRows + 1;
        const endRow = worksheet.lastRow.number;

        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
            const row = worksheet.getRow(rowIndex);
            row.eachCell(cell => {
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        }
        // Applying borders to the entire table
        worksheet.eachRow(row => {
            row.eachCell(cell => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        // Export workbook
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'Results & Outcomes.xlsx');
    };

    // ---------------------------------------------------------------------


    return (
        <div className='app'>
            <NavbarComp />
            <div className="content overflow-hidden">
                <HeaderComp></HeaderComp>
                <div className="container-fluid px-4 pt-3">
                    {/* Modal Component */}
                    <ModalComponent show={showModal} channel={channel} handleClose={handleCloseModal} />
                    <div id="content-to-download" ref={contentRef} className='row'>

                        <div className="col-sm-12">

                            <div id="page1" className="row">
                                <h4>Performance</h4>
                                <h4>All View</h4>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5">
                                        <Card.Header className="card-name">
                                            Reach <br />
                                            <span className="header-content">Number of Audience Reach</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Title>
                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{reachImpressions}</span>
                                                        <span className="card-num">{reachClicks}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-text-prg">Impressions</span>
                                                        <span className="card-text-prg">Clicks</span>
                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={impressionsPercentage}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={clicksPercentage}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />

                                                    </ProgressBar>
                                                </div>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5">
                                        <Card.Header className="card-name">
                                            Register <br />
                                            <span className="header-content">Number of People Registered for Event</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Title>

                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{registerClick}</span>
                                                        <span className="card-num">{registerImpressions}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-text-prg">Expected Registration</span>
                                                        <span className="card-text-prg"><p href="" onClick={handleShowModal}>Actual Registration</p></span>
                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={clicksPercentage1}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={impressionsPercentage1}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />
                                                    </ProgressBar>
                                                </div>

                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5">
                                        <Card.Header className="card-name">
                                            Attend <br />
                                            <span className="header-content">Number of People Attended Event</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Title>
                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{attende}</span>
                                                        <span className="card-num">{nonattende}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <span className="card-text-prg">Attendees</span>
                                                        <span className="card-text-prg"><p onClick={handleShowModal}>Non-Attendees</p> </span>

                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={clicksPercentage2}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={impressionsPercentage2}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />
                                                    </ProgressBar>
                                                </div>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>


                            {/*-----------------------  */}

                            <div id="page2" className="row">
                                <h4>Channel View</h4>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                        <Card.Header className="card-name">
                                            Reach <br />
                                            <span className="header-content">Number of Audience Reach</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Card.Title>
                                                <PerformanceChannel data={data1} projectedLabel="Impression"
                                                    actualLabel="Click" overallPerformance={overallPerformance1} showIndividualPercentages={true} />
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                        <Card.Header className="card-name">
                                            Register <br />
                                            <span className="header-content">Number of People Registered for Event</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Card.Title>
                                                <PerformanceChannel data={data2} projectedLabel="Expected"
                                                    actualLabel="Actual" overallPerformance={overallPerformance2} showIndividualPercentages={true} />

                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                        <Card.Header className="card-name">
                                            Attend <br />
                                            <span className="header-content">Number of People Attended Event</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Card.Title>
                                                <PerformanceChannel data={data3} projectedLabel="Attendee"
                                                    actualLabel="Non-Attendee" overallPerformance={overallPerformance3} showIndividualPercentages={true} />


                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>


                            {/* ----- cost ----- */}

                            <div id="page3" className="row">
                                <h4>Cost</h4>
                                <h4>All View</h4>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5">
                                        <Card.Header className="card-name">
                                            Reach <br />
                                            <span className="header-content">Number of Audience Reach</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Title>
                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{costImpression}$</span>
                                                        <span className="card-num">{costClick}$</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-text-prg">Cost Per Impression</span>
                                                        <span className="card-text-prg">Cost Per Click</span>
                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={clicksPercentage3}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={impressionsPercentage3}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />

                                                    </ProgressBar>
                                                </div>

                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5">
                                        <Card.Header className="card-name">
                                            Register <br />
                                            <span className="header-content">Number of People Registered for Event</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Title>

                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{registerImpression}$</span>
                                                        <span className="card-num">{registerClicks}$</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-text-prg">Expected Cost Per Registration</span>
                                                        <span className="card-text-prg">Actual Cost Per Registration</span>
                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={clicksPercentage4}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={impressionsPercentage4}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />

                                                    </ProgressBar>
                                                </div>


                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5">
                                        <Card.Header className="card-name">
                                            Attend <br />
                                            <span className="header-content">Number of People Attended Event</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Title>
                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{attendImpression}$</span>
                                                        <span className="card-num">{attendClicks}$</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-text-prg">Total cost per attendee</span>
                                                        <span className="card-text-prg">Actual cost per attendee</span>
                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={clicksPercentage5}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={impressionsPercentage5}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />

                                                    </ProgressBar>
                                                </div>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>


                            <div id="page4" className="row">
                                <h4>Channel View</h4>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                        <Card.Header className="card-name">
                                            Cost/Reach <br />
                                            <span className="header-content">Number of Audience Reach</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Card.Title>
                                                {/* <CostChannel data4={data4} /> */}
                                                <PerformanceChannel data={costdata1} overallLabel="Average Cost For Each Channel" projectedLabel="Cost/Impression"
                                                    actualLabel="Cost/Click" overallPerformance={overallPerformance4} />

                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                        <Card.Header className="card-name">
                                            Cost/Registration <br />
                                            <span className="header-content">Number of People Registered for Event</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Card.Title>
                                                {/* <CostChannel data4={data5} /> */}
                                                <PerformanceChannel data={costdata2} overallLabel="Average Cost For Form Fills" projectedLabel="Cost/Expected"
                                                    actualLabel="Cost/Actual" overallPerformance={overallPerformance5} />

                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                        <Card.Header className="card-name">
                                            Cost/Attendance <br />
                                            <span className="header-content">Number of People Attended Event</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Card.Title>
                                                {/* <CostChannel data4={data6} /> */}
                                                <PerformanceChannel data={costdata3} overallLabel="Average Cost For Attendee" projectedLabel="Total/Cost"
                                                    actualLabel="Actual Cost/Attendee" overallPerformance={overallPerformance6} />



                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            {/* ----- Flow ----- */}

                            <div id="page5" className="row">
                                <h4>Flow</h4>
                                <h4>All View</h4>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" >
                                        <Card.Header className="card-name">
                                            Reach to Register <br />
                                            <span className="header-content">Number of People Reached vs Registered</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Title>


                                                {/* <SemiCircleProgressBar progress={progress} /> */}
                                                <Guage progress={performanceData?.flow.reachToRegister.reachClickPercentage} />


                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{clicksImpression}$</span>
                                                        <span className="card-num">{registerList}$</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-text-prg">Clicks</span>
                                                        <span className="card-text-prg">Registration</span>
                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={clicksPercentage6}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={impressionsPercentage6}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />

                                                    </ProgressBar>
                                                </div>

                                               

                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" >
                                        <Card.Header className="card-name">
                                            Register to Attend <br />
                                            <span className="header-content">Number of People Registered & Attended</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Guage progress={performanceData?.flow.registerToAttend.attendRegisterPercentage} />
                                            <Card.Title>
                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{registerFlowList}$</span>
                                                        <span className="card-num">{attendList}$</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-text-prg">Registration</span>
                                                        <span className="card-text-prg">Attende</span>
                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={clicksPercentage7}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={impressionsPercentage7}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />

                                                    </ProgressBar>
                                                </div>




                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5 ">
                                        <Card.Header className="card-name">
                                            Attend to Sales <br />
                                            <span className="header-content">Attended People Sent to Sales</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <Guage progress={performanceData?.flow.attendToSales.crmAttendee} />
                                            <Card.Title>
                                                {/*<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
<CustomCircularProgress percentage={percentage} /> 
</div>*/}
                                                <div className="progress-info">
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-num">{attendFlowList}$</span>
                                                        <span className="card-num">{crmPushed}$</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <span className="card-text-prg">Attendee</span>
                                                        <span className="card-text-prg">Sales</span>
                                                    </div>
                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                        <ProgressBar
                                                            now={clicksPercentage8}
                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                            key={2}
                                                        />
                                                        <ProgressBar
                                                            now={impressionsPercentage8}
                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                            key={1}
                                                        />

                                                    </ProgressBar>
                                                </div>

                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                            <div id="page6" className="row">
                                <h4>Channel View</h4>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '700px' }}>
                                        <Card.Header className="card-name">
                                            Reach to Register <br />
                                            <span className="header-content">Number of People Reached vs Registered</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'
                                        //  style={{ height: '400px',overflowY: 'auto' ,scrollbarWidth: 'none' }}
                                        >
                                            <Card.Title>
                                                {gaugeData.map((data, index) => (
                                                    <FlowChannel
                                                        key={index}
                                                        percentage={data.percentage}
                                                        registered={data.registered}
                                                        total={data.total}
                                                        image={data.image}
                                                    />
                                                ))}
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '700px' }}>
                                        <Card.Header className="card-name">
                                            Register to Attend <br />
                                            <span className="header-content">Number of People Registered & Attended</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Card.Title>
                                                {gaugeDataAttend.map((data, index) => (
                                                    <FlowChannel
                                                        key={index}
                                                        percentage={data.percentage}
                                                        registered={data.registered}
                                                        total={data.total}
                                                        image={data.image}
                                                    />
                                                ))}

                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card-shadow mb-5" style={{ height: '700px' }}>
                                        <Card.Header className="card-name">
                                            Attend to Sales <br />
                                            <span className="header-content">Attended People Sent to Sales</span>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Card.Title>
                                                {gaugeDataSales.map((data, index) => (
                                                    <FlowChannel
                                                        key={index}
                                                        percentage={data.percentage}
                                                        registered={data.registered}
                                                        total={data.total}
                                                        image={data.image}
                                                    />
                                                ))}


                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="row justify-content-around">
                        <div className="col-md-2">
                            <div style={{ height: '63vh' }} className="bg-light rounded border border-secondary border-2">
                                <ProgressStepper
                                    steps={steps}
                                    currentStep={2}
                                    currentSubStep={0}
                                    setCurrentStep={setCurrentStep}
                                    setCurrentSubStep={setCurrentSubStep}
                                />
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div style={{ height: '66vh' }} className="bg-light rounded  border border-secondary border-2 overflow-hidden position-relative">
                                <div className="container ms-3 pt-4">
                                    <div className="row align-items-center">
                                        <div className="col-md-11">
                                            <div className="mb-0 alert bg-secondary text-success p-2 fw-bold" style={{ fontWeight: '600', fontFamily: 'Poppins', fontSize: '16px' }}>Results and Outcome</div>
                                        </div>
                                        <div className='col-md-1'>
                                            <div className="dropdown">
                                                <button className="btn btn-secondary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <MdOutlineFileDownload style={{ fontSize: '25px', color: '#028897' }} />
                                                </button>
                                                <ul className="dropdown-menu download-outcome mt-1" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item pdf-download" onClick={downloadPDF} href="#"><img src={pdf} alt='pdf' /> &nbsp; Pdf</a></li>
                                                    <li><a className="dropdown-item excel-download"  onClick={exportToExcel} href="#"><img src={excel} alt='excl' /> &nbsp;Excel</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row me-3' style={{ overflow: 'auto', height: '400px', scrollbarWidth: 'none' }}>
                                        <div className="col-sm-12">
                                            {/* tabs */}
                                            <div className="outcome-header">
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => {
                                                        setKey(k);
                                                        setIsChannelView(false); // Reset channel view when changing tabs
                                                    }}
                                                    className="outcome-tabs"
                                                >
                                                    <Tab eventKey="Performance" title="Performance" className='performance-border'>
                                                        {/* Button for Channel View */}
                                                        <div className="row ">
                                                            <div className="col-sm-10"></div>
                                                            <div className="col-sm-2" >
                                                                <Button className='channel-back' onClick={toggleChannelView}
                                                                    style={{
                                                                        border: '1px solid #028897',
                                                                        backgroundColor: '#E6F3F5',
                                                                        color: '#028897',

                                                                    }}>
                                                                    {isChannelView ? (<> <MdOutlineArrowBack /> Back </>) : ("Channel View")}
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        {/* Performance Content */}
                                                        {!isChannelView && (
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5">
                                                                        <Card.Header className="card-name">
                                                                            Reach <br />
                                                                            <span className="header-content">Number of Audience Reach</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Card.Title>
                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{reachImpressions}</span>
                                                                                        <span className="card-num">{reachClicks}</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-text-prg">Impressions</span>
                                                                                        <span className="card-text-prg">Clicks</span>
                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>
                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5">
                                                                        <Card.Header className="card-name">
                                                                            Register <br />
                                                                            <span className="header-content">Number of People Registered for Event</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Card.Title>

                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{registerClick}</span>
                                                                                        <span className="card-num">{registerImpressions}</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-text-prg">Expected Registration</span>
                                                                                        <span className="card-text-prg"><p href="" onClick={() => handleShowModal('register_list_label')}>Actual Registration</p></span>
                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage1}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage1}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>



                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5">
                                                                        <Card.Header className="card-name">
                                                                            Attend <br />
                                                                            <span className="header-content">Number of People Attended Event</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Card.Title>

                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{attende}</span>
                                                                                        <span className="card-num">{nonattende}</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <span className="card-text-prg"><p onClick={() => handleShowModal('non_attend_list_label')}>Attendees</p> </span>
                                                                                        <span className="card-text-prg"><p onClick={() => handleShowModal('non_attend_list_label')}>Non-Attendees</p> </span>

                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage2}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage2}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>
                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* Channel View Content */}
                                                        {isChannelView && (
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Reach <br />
                                                                            <span className="header-content">Number of Audience Reach</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'>
                                                                            <Card.Title>
                                                                                <PerformanceChannel data={data1} projectedLabel="Impression"
                                                                                    actualLabel="Click" overallPerformance={overallPerformance1} showIndividualPercentages={true} />
                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Register <br />
                                                                            <span className="header-content">Number of People Registered for Event</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'>
                                                                            <Card.Title>
                                                                                <PerformanceChannel data={data2} projectedLabel="Expected"
                                                                                    actualLabel="Actual" overallPerformance={overallPerformance2} showIndividualPercentages={true} />

                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Attend <br />
                                                                            <span className="header-content">Number of People Attended Event</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'>
                                                                            <Card.Title>
                                                                                <PerformanceChannel data={data3} projectedLabel="Attendee"
                                                                                    actualLabel="Non-Attendee" overallPerformance={overallPerformance3} showIndividualPercentages={true} />


                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* <div className='row'>
                                                            <div className='col-sm-11'></div>
                                                            <div className='col-sm-1'>
                                                                <button onClick={downloadPDF} >Download button</button>
                                                            </div>
                                                        </div> */}
                                                       


                                                    </Tab>
                                                    <Tab eventKey="Cost" title="Cost">
                                                        {/* cost card */}
                                                        <div className="row ">
                                                            <div className="col-sm-10"></div>
                                                            <div className="col-sm-2">
                                                                <Button className='channel-back' onClick={toggleChannelView}
                                                                    style={{
                                                                        border: '1px solid #028897',
                                                                        backgroundColor: '#E6F3F5',
                                                                        color: '#028897',

                                                                    }}>
                                                                    {isChannelView ? (<> <MdOutlineArrowBack /> Back </>) : ("Channel View")}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {!isChannelView && (
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5">
                                                                        <Card.Header className="card-name">
                                                                            Reach <br />
                                                                            <span className="header-content">Number of Audience Reach</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Card.Title>
                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{costImpression} $</span>
                                                                                        <span className="card-num">{costClick}$</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-text-prg">Cost Per Impression</span>
                                                                                        <span className="card-text-prg">Cost Per Click</span>
                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage3}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage3}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>

                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5">
                                                                        <Card.Header className="card-name">
                                                                            Register <br />
                                                                            <span className="header-content">Number of People Registered for Event</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Card.Title>

                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{registerImpression}$</span>
                                                                                        <span className="card-num">{registerClicks}$</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-text-prg">Expected Cost Per Registration</span>
                                                                                        <span className="card-text-prg">Actual Cost Per Registration</span>
                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage4}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage4}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>


                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5">
                                                                        <Card.Header className="card-name">
                                                                            Attend <br />
                                                                            <span className="header-content">Number of People Attended Event</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Card.Title>
                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{attendImpression}$</span>
                                                                                        <span className="card-num">{attendClicks}$</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-text-prg">Total cost per attendee</span>
                                                                                        <span className="card-text-prg">Actual cost per attendee</span>
                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage5}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage5}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>
                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {/* Channel View Content */}
                                                        {isChannelView && (
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Cost/Reach <br />
                                                                            <span className="header-content">Number of Audience Reach</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'>
                                                                            <Card.Title>
                                                                                {/* <CostChannel data4={data4} /> */}
                                                                                <PerformanceChannel data={costdata1} overallLabel="Average Cost For Each Channel" projectedLabel="Cost/Impression"
                                                                                    actualLabel="Cost/Click" overallPerformance={overallPerformance4} />

                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Cost/Registration <br />
                                                                            <span className="header-content">Number of People Registered for Event</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'>
                                                                            <Card.Title>
                                                                                {/* <CostChannel data4={data5} /> */}
                                                                                <PerformanceChannel data={costdata2} overallLabel="Average Cost For Form Fills" projectedLabel="Cost/Expected"
                                                                                    actualLabel="Cost/Actual" overallPerformance={overallPerformance5} />

                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ height: '400px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Cost/Attendance <br />
                                                                            <span className="header-content">Number of People Attended Event</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'>
                                                                            <Card.Title>
                                                                                {/* <CostChannel data4={data6} /> */}
                                                                                <PerformanceChannel data={costdata3} overallLabel="Average Cost For Attendee" projectedLabel="Total/Cost"
                                                                                    actualLabel="Actual Cost/Attendee" overallPerformance={overallPerformance6} />



                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Tab>
                                                    <Tab eventKey="Flow" title="Flow">
                                                        <div className="row ">
                                                            <div className="col-sm-10"></div>
                                                            <div className="col-sm-2">
                                                                <Button className='channel-back' onClick={toggleChannelView}
                                                                    style={{
                                                                        border: '1px solid #028897',
                                                                        backgroundColor: '#E6F3F5',
                                                                        color: '#028897',

                                                                    }}
                                                                >
                                                                    {isChannelView ? (<> <MdOutlineArrowBack /> Back </>) : ("Channel View")}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {!isChannelView && (
                                                            // flow card
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" >
                                                                        <Card.Header className="card-name">
                                                                            Reach to Register <br />
                                                                            <span className="header-content">Number of People Reached vs Registered</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Card.Title>


                                                                                {/* <SemiCircleProgressBar progress={progress} /> */}
                                                                                <Guage progress={performanceData?.flow.reachToRegister.reachClickPercentage} />


                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{clicksImpression}$</span>
                                                                                        <span className="card-num">{registerList} $</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-text-prg">Clicks</span>
                                                                                        <span className="card-text-prg">Registration</span>
                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage6}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage6}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>



                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" >
                                                                        <Card.Header className="card-name">
                                                                            Register to Attend <br />
                                                                            <span className="header-content">Number of People Registered & Attended</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Guage progress={performanceData?.flow.registerToAttend.attendRegisterPercentage} />
                                                                            <Card.Title>
                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{registerFlowList}$</span>
                                                                                        <span className="card-num">{attendList} $</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-text-prg">Registration</span>
                                                                                        <span className="card-text-prg">Attendee</span>
                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage7}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage7}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>




                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5 ">
                                                                        <Card.Header className="card-name">
                                                                            Attend to Sales <br />
                                                                            <span className="header-content">Attended People Sent to Sales</span>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Guage progress={performanceData?.flow.attendToSales.crmAttendee} />
                                                                            <Card.Title>
                                                                                {/*<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                                                                     <CustomCircularProgress percentage={percentage} /> 
                                                                                </div>*/}
                                                                                <div className="progress-info">
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-num">{attendFlowList}$</span>
                                                                                        <span className="card-num">{crmPushed} $</span>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">

                                                                                        <span className="card-text-prg">Attendee</span>
                                                                                        <span className="card-text-prg">Sales</span>
                                                                                    </div>
                                                                                    <ProgressBar style={{ height: '12px' }} className="mt-2">
                                                                                        <ProgressBar
                                                                                            now={clicksPercentage8}
                                                                                            style={{ height: '12px', backgroundColor: '#81CDD5' }}
                                                                                            key={2}
                                                                                        />
                                                                                        <ProgressBar
                                                                                            now={impressionsPercentage8}
                                                                                            style={{ height: '12px', backgroundColor: '#DDDDDD' }}
                                                                                            key={1}
                                                                                        />

                                                                                    </ProgressBar>
                                                                                </div>

                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {/* Channel View Content flow*/}
                                                        {isChannelView && (
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ minHeight: '500px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Reach to Register <br />
                                                                            <span className="header-content">Number of People Reached vs Registered</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'
                                                                        //  style={{ height: '400px',overflowY: 'auto' ,scrollbarWidth: 'none' }}
                                                                        >
                                                                            <Card.Title>
                                                                                {gaugeData.map((data, index) => (
                                                                                    <FlowChannel
                                                                                        key={index}
                                                                                        percentage={data.percentage}
                                                                                        registered={data.registered}
                                                                                        total={data.total}
                                                                                        image={data.image}
                                                                                        type={1}
                                                                                    />
                                                                                ))}
                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ minHeight: '500px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Register to Attend <br />
                                                                            <span className="header-content">Number of People Registered & Attended</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'>
                                                                            <Card.Title>
                                                                                {gaugeDataAttend.map((data, index) => (
                                                                                    <FlowChannel
                                                                                        key={index}
                                                                                        percentage={data.percentage}
                                                                                        registered={data.registered}
                                                                                        total={data.total}
                                                                                        image={data.image}
                                                                                        type={2}
                                                                                    />
                                                                                ))}

                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Card className="card-shadow mb-5" style={{ minHeight: '500px' }}>
                                                                        <Card.Header className="card-name">
                                                                            Attend to Sales <br />
                                                                            <span className="header-content">Attended People Sent to Sales</span>
                                                                        </Card.Header>
                                                                        <Card.Body className='p-0'>
                                                                            <Card.Title>
                                                                                {gaugeDataSales.map((data, index) => (
                                                                                    <FlowChannel
                                                                                        key={index}
                                                                                        percentage={data.percentage}
                                                                                        registered={data.registered}
                                                                                        total={data.total}
                                                                                        image={data.image}
                                                                                        type={3}
                                                                                    />
                                                                                ))}


                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Tab>


                                                </Tabs>
                                            </div>


                                        </div>
                                        <div className="col-sm-2">

                                        </div>

                                        {/* <table style={{ width: '100%', border: '1px',height: '50vh' }}>
                                            <tr>
                                                <td>All View</td>
                                                <td><b>Perfomance</b></td>
                                                <td><b>Cost</b></td>
                                                <td><b>Flow</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>Reach</b></td>
                                                <td>{performanceData?.performance.reach.reachclicks} clicks,<br></br> {performanceData?.performance.reach.reachimmpressions} impressions</td>
                                                <td>${performanceData?.cost.reach.costPerImpressions} per impression,<br></br> ${performanceData?.cost.reach.costPerClicks} per click</td>
                                                <td>{performanceData?.flow.reachToRegister.reachClickPercentage}% reach-to-register
                                                    <br></br>
                                                    {performanceData?.flow.reachToRegister.clicksImpression} clicksImpression 
                                                    &nbsp;&&nbsp;
                                                    {performanceData?.flow.reachToRegister.registerList} registerList
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Register</b></td>
                                                <td>{performanceData?.performance.register.registerformfillEx} expected form fills,<br></br> {performanceData?.performance.register.registerformfillAc} actual form fills</td>
                                                <td>${performanceData?.cost.register.expectedFormFills} expected cost per form fill,<br></br> ${performanceData?.cost.register.actualFormFills} actual cost per form fill</td>
                                                <td>{performanceData?.flow.registerToAttend.attendRegisterPercentage}% register-to-attend
                                                    <br></br>
                                                    {performanceData?.flow.registerToAttend.registerList} registerList 
                                                    &nbsp;&&nbsp;
                                                    {performanceData?.flow.registerToAttend.attendList} attendList
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Attend</b></td>
                                                <td>{performanceData?.performance.attend.attendeeList} attendees,<br></br> {performanceData?.performance.attend.nonAttendeeList} non-attendees</td>
                                                <td>${performanceData?.cost.attendee.expectedAttendeePerCost} expected cost per attendee,<br></br> ${performanceData?.cost.attendee.actualAttendeePerCost} actual cost per attendee</td>
                                                <td>{performanceData?.flow.attendToSales.crmAttendee}% CRM attendee push
                                                    <br></br>
                                                    {performanceData?.flow.attendToSales.attendList} attendList 
                                                    &nbsp;&&nbsp;
                                                    {performanceData?.flow.attendToSales.crmPushed} crmPushed
                                                </td>
                                            </tr>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end my-3">
                        <div className="col-md-6">
                            <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                                <button
                                    className="btn btn-secondary me-2 btn-full"
                                    onClick={() => navigate(`/journey/${programId}`)}
                                    style={{ border: '2px solid #028897' }}
                                    type="button"
                                >
                                    Back
                                </button>
                                <button
                                    className={`btn text-white btn-full btn-success`}
                                    onClick={handleFinish}
                                    type="button"
                                >
                                    Finish
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Outcome;
