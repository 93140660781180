import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, Grid } from '@mui/material';
import BasicSpeedDial from "../components/SpeedDial";
import Email from "./Setting/email";
import Onlineevent from "./Setting/Onlineevent";
import Brand from "./Setting/Brand";
import CRM from "./Setting/CRM";
import Dataprovider from "./Setting/Dataprovider";
import Otherplatform from "./Setting/Otherplatform";
import Meetingsh from "./Setting/Meetingsh";
import Admanager from "./Setting/Admanager";
import Trackingscript from "./Setting/Trackingscript";
import Customfeild from "./Setting/Customfeild";
import Sociallink from "./Setting/Sociallink";
import NavbarComp from '../components/NavbarComp';
import '../components/CSS/settings.css'

const Setting = () => {
    const [selectedTab, setSelectedTab] = useState('onlineevent'); // state for active tab
    const settingList = [
        {
            name: 'Online Event Settings',
            alias: 'onlineevent',
        },
        {
            name: 'Email Settings',
            alias: 'email',
        },
        {
            name: 'Brand Settings',
            alias: 'brand',
        },
        {
            name: 'CRM Settings',
            alias: 'crm',
        },
        {
            name: 'Data Providers Settings',
            alias: 'dataprovider',
        },
        {
             name: 'Meeting Sceduler Settings',
             alias: 'meetingsceduler',
        },
        /*{
            name: 'Other Platform Settings',
            alias: 'otherplatform',
        }, */
        {
            name: 'Ads Manager Settings',
            alias: 'adsmanager',
        },
        {
            name: 'Custom Fields Settings',
            alias: 'customfeild',
        },      
        /*{
            name: 'Asset Settings',
            alias: 'asset',
        },*/ 
        {
            name: 'Tracking Script Settings',
            alias: 'tracking',
        },
        {
            name: 'Social Links Settings',
            alias: 'sociallink',
        },
    ];

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue); // update selected tab
    };

    return (
        <div className="app">
            <NavbarComp />
            <div className="content overflow-hidden ">
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {/* Left Side - Tab List */}
                    <Box className="setting-left-tab" sx={{ width: 300 }}>
                        <div className='settings-list'>
                            <Tabs

                                orientation="vertical"
                                value={selectedTab}
                                onChange={handleTabChange}
                                aria-label="Settings tabs"
                            // sx={{ borderRight: 1, borderColor: 'divider' }}
                            >
                                {settingList.map((setting, index) => (
                                    <Tab

                                        key={setting.alias}
                                        label={setting.name}
                                        value={setting.alias} // Use index or platform.id as the tab value
                                    />
                                ))}
                            </Tabs>
                        </div>
                    </Box>

                    {/* Right Side - Content Based on Tab Selection */}
                    <Box sx={{ flexGrow: 1, padding: 2 }}>
                        {/* Render content based on the selected tab */}
                        <div className='setting-right-tab'>
                            {selectedTab === 'email' && <Email settingType={selectedTab} />}
                            {selectedTab === 'onlineevent' && <Onlineevent settingType={selectedTab} />}
                            {selectedTab === 'brand' && <Brand settingType={selectedTab} />}
                            {selectedTab === 'crm' && <CRM settingType={selectedTab} />}
                            {selectedTab === 'dataprovider' && <Dataprovider settingType={selectedTab} />}
                            {selectedTab === 'otherplatform' && <Otherplatform settingType={selectedTab} />}
                            {selectedTab === 'meetingsceduler' && <Meetingsh settingType={selectedTab} />}
                            {selectedTab === 'adsmanager' && <Admanager settingType={selectedTab} />}
                            {selectedTab === 'customfeild' && <Customfeild settingType={selectedTab} />}
                            {selectedTab === 'tracking' && <Trackingscript settingType={selectedTab} />}
                            {selectedTab === 'sociallink' && <Sociallink settingType={selectedTab} />}
                            {/* ----------discard and save------------- */}
                            <div className="row">
                                <div className="col-sm-12 discard-backbtn">
                                    <button type="button" className="discard">Discard</button>
                                    <button type="button" className="save-setting">Save</button>
                                </div>
                            </div>
                        </div>

                    </Box>
                </Box>
            </div>
            <div className="position-absolute" style={{ bottom: '60px', left: '32px' }}>
                <BasicSpeedDial></BasicSpeedDial>
            </div>
        </div>
    );
};

export default Setting;
