import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CommonLoader from '../CommonLoader';
import { useGlobal } from "../../context/GlobalProvider";
import { io } from "socket.io-client";

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const {setSocket} = useGlobal();

    const socketRef = useRef(null); // To track the socket instance

    useEffect(() => {
        // Check if socket already exists and is connected
        if(isAuthenticated)
        if (!socketRef.current) {
            const tempSocket = io(`${process.env.REACT_APP_BASE_URL_API}`, {
                query: {},
                transports:["websocket"],
                secure:true
            });

            tempSocket.on('connect', () => {
                console.log('Connected to socket server');
            });

            tempSocket.on('disconnect', () => {
                console.log('Disconnected from socket server');
            });

            socketRef.current = tempSocket; // Store socket in ref
            setSocket(tempSocket); // Update global socket
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                setSocket(null);
                socketRef.current = null;
                console.log('Socket disconnected on component unmount');
            }
        };
    }, [isAuthenticated, setSocket]);
    useEffect(() => {
        const checkLogin = async () => {
            try {
                const user = localStorage.getItem('user'); 
                if (user) {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/login/checkUser`, {}, {
                        withCredentials: true, 
                    });

                    if (response.data.isAuthenticated) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        navigate('/');
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                setIsAuthenticated(false);
                navigate('/');
            }
        };

        checkLogin();
    }, [navigate]);

    if (!isAuthenticated) {
        return <CommonLoader/>;
    }

    return <>{children}</>;
};

export default AuthProvider;
