import React, { useEffect, useState } from 'react';
import NavbarComp from '../../../components/NavbarComp';
import '../../../components/CSS/journey.css'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { BiPlus } from "react-icons/bi";
import { LuMinus } from "react-icons/lu";
import { useGlobal } from '../../../context/GlobalProvider';
import { TbZoomReset } from "react-icons/tb";
import BuilderLoader from '../../Journey/journeyModels/BuilderLoader';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import starcircle from '../../../components/Assets/starcircle.svg'
import metaIcon from '../../../components/Assets/meta.svg';
import Letters from './Letters';
import RecipietList from './RecipietList'
import LettersModel from './LettersModel';
import { ToastContainer, toast, Bounce } from 'react-toastify';
const NewslettersJourney = () => {
  const { programId } = useParams();
  const { newsLetterDatas, setNewsLetterDatas, newsLetterConnectsIds, setNewsLetterConnectsIds, tabLoading, setTabLoading, resetTransformRef, AnimationMode } = useGlobal();
  const navigate = useNavigate();
  const [programDetails, setProgramDetails] = useState({});
  const [recipeintModel, setRecipientModel] = useState(false);
  const handleCloseRecipientModel = () => {
    setRecipientModel(false);
  }
  const [channelData, setChannelData] = useState({});
  const handleCancelClick = () => {
    setTabLoading(false);
    navigate(`/LettexrJournyEdit/${programId}`);
  }
  const [lettersModel, setLetterModel] = useState(false);
  const closeLettersModel = () => {
    setLetterModel(false);
  }
  const handleSaveClick = async () => {
    //navigate(`/JourneyEdit/${programId}`);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/changeProgramStatus`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          programId: programId,
          statusType: 'save',
        }),
      });
      const data = await response.json();
      if (data._id !== "") {
        alert('JourneyData Saved successfully!');
        //window.location.reload(); // Refresh the page after successful submission
      }
      else {
        alert('Something went wrong!');
      }
    } catch (error) {
      alert('Error storing data!');
      console.error('Error fetching data:', error);
    }
  }
  const handleRunClick = async () => {
    //navigate(`/JourneyEdit/${programId}`);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/${programId}`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();

      const failedCards = {
        letters: [],
      };
      const currentDateTime = new Date();

      const isSenddate = data.newsletterBuilder.every(item => {
        if (['letters'].includes(item.cardType)) {
          return item.selectedData.every(data => {
            const sendDate = new Date(data.sendDate);
            if (sendDate <= currentDateTime) {
              const msg = `${item.cardName} has Invalid Dates.`;
              failedCards.letters.push(msg);
              return false;
            }
            return true;
          });
        }
        return true;
      });
      const subscribers = data.newsletterBuilder.filter(item => item.isEnabled).every(item => {
        if (['nl_subscribers'].includes(item.cardName)) {
          if (item.setupStatus !== '1') {
            const msg = `${item.cardName} has Setup Incomplete.`;
            failedCards.letters.push(msg);
            return false;
          }
          return true;
        }
        return true
      });
      // console.log('data',data.newsletterBuilder);
      // return 

      let statusMessage;
      if (isSenddate && subscribers) {
        statusMessage = 'yes';
      } else {
        statusMessage = 'no';
      }

      if (statusMessage === 'yes') {
        try {
          const updateResponse = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/changeProgramStatus`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              programId: programId,
              statusType: 'run',
            }),
          });

          const updateData = await updateResponse.json();

          if (updateData._id !== "") {
            alert('Your Program is published now!');
            // window.location.reload(); // Uncomment if needed
          } else {
            alert('Something went wrong!');
          }
        } catch (error) {
          alert('Error storing data!');
          console.error('Error fetching data:', error);
        }
      } else {
        let errorMessage = 'Program cannot be published due to the following issues:\n';
        if (failedCards.letters.length) errorMessage += `Letters : ${failedCards.letters.join(', ')} \n`;
        //if (failedCards.dateInvalid.length) errorMessage += `Invalid Date : ${failedCards.dateInvalid.join(', ')}\n Card with invalid send dates`;

        alert(errorMessage);
      }

    } catch (error) {
      console.error('Error fetching program details:', error);
    }
  }



  const fetchProgramDetails = async () => {
    setTabLoading(false);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/${programId}`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();

      // General formatter function for channel types
      const getNearestUpcomingNewsletter = (items) => {
        const currentDate = new Date();
        let nearestNewsletter = null;

        items.forEach(item => {
          if (Array.isArray(item.selectedData) && item.cardType === 'letters') {
            item.selectedData.forEach(data => {
              const sendDate = new Date(data.sendDate);
              if (sendDate > currentDate && (!nearestNewsletter || sendDate < new Date(nearestNewsletter.sendDate))) {
                nearestNewsletter = data; // Update the nearest newsletter
              }
            });
          }
        });

        return nearestNewsletter;
      };

      const formatData = (items, builder, cardType, icon) => {
        const nearestNewsletter = getNearestUpcomingNewsletter(items);
        return items.map(item => ({
          channel: item.cardName,
          no_campains: Array.isArray(item.selectedData) &&
            (cardType === 'channel' || cardType === 'r_reminder' || item.cardName === 'attend_email' || item.cardName === 'non_attend_email')
            ? `${item.selectedData.length}`
            : "",
          actualSendDate: item.selectedData[0]?.sendDate ?? '',
          expected: item.projectedCount,
          reach: item.actualCount,
          icon: icon,
          id: item.childConnector,
          status: item.setupStatus,
          isEnabled: item.isEnabled,
          builder: builder,
          cardType: item.cardType,
          cardId: item.cardId,
          alert: nearestNewsletter && item.selectedData.some(data => data.sendDate === nearestNewsletter.sendDate)
            ? nearestNewsletter.alert
            : null
        }));
      };



      const letters = formatData(data.newsletterBuilder, 'newsletterBuilder', "letters", metaIcon);
      const rearrangeData = (data, groupSize = 4) => {
        const result = [];
        for (let i = 0; i < data.length; i += groupSize) {
          const group = data.slice(i, i + groupSize);
          result.push(...(Math.floor(i / groupSize) % 2 === 1 ? group.reverse() : group));
        }
        return result;
      };
      function processCards(data) {
        const currentDate = new Date(); 
        const sortedData = data.sort((a, b) => new Date(a.actualSendDate || 0) - new Date(b.actualSendDate || 0));
        let updatedData = [...sortedData];
      
        const firstCard = updatedData[1];
        if (firstCard.cardType === "letters") {
          const firstCardDate = new Date(firstCard.actualSendDate);
          if (firstCardDate > currentDate) {
            updatedData = updatedData.filter((_, i) => i !== updatedData.length - 1);
            updatedData = reassignIds(updatedData);
            return updatedData;
          }
          if (firstCardDate < currentDate && firstCard.status === "1") {
            updatedData = updatedData.filter((_, i) => i !== 1); 
            updatedData = reassignIds(updatedData);
            return updatedData;
          }
        }
      
        const lastCard = updatedData[updatedData.length - 1];
        if (lastCard.cardType === "letters") {
          const lastCardDate = new Date(lastCard.actualSendDate);
          const daysToGo = (lastCardDate - currentDate) / (1000 * 60 * 60 * 24);
          if (lastCardDate >= currentDate && daysToGo <= 30) {
            updatedData = updatedData.filter((_, i) => i !== 1); 
            updatedData = reassignIds(updatedData);
            return updatedData;
          }
        }
        return updatedData;
      }
      function reassignIds(data) {
        return data.map((card, index) => ({
          ...card,
          id: (index + 1).toString(), 
        }));
      }


      const hideLetter =processCards(letters);
      // const hideLetter =letters;
      const groupedData = rearrangeData(hideLetter, 4);
      const createLinks = (letters) => {
        const links = [];

        for (let i = 0; i < letters.length - 1; i++) {
          links.push({ source: letters[i].id, target: letters[i + 1].id });
        }

        return links;
      };
 
      let newLetterLinks = createLinks(hideLetter);
      setNewsLetterDatas(groupedData);
      setNewsLetterConnectsIds(newLetterLinks);
      if (AnimationMode) {
        setTimeout(() => {
          setTabLoading(true);
        }, 2000);
      } else {
        setTabLoading(true);
      }
    } catch (error) {
      console.error('Error fetching program details:', error);
    }
  };

  const fetchLetterDetails = () => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programDetails/${programId}`, { credentials: 'include', })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setProgramDetails(data)
          }
        });

    } catch (error) {
      console.log(error);
    }
  }

  const updateCardCounts = async () => {
    try {
      // Update Reach data
      await fetch(`${process.env.REACT_APP_BASE_URL_API}/updateCounts/lettersTab/${programId}`, { method: 'GET', credentials: 'include', })
        .then(response => response.json())
        .catch(err => console.log(err))
    } catch (error) {
      console.error('Error fetching program details:', error);
    }
  };

  useEffect(() => {
    const cardCountUpdation = async () => {
      await updateCardCounts();
      fetchLetterDetails()
      fetchProgramDetails();
    }
    cardCountUpdation();
  }, [programId]);

  const handleChannelModel = async (channel, cardType, cardId, builder, isEnabled) => {
    await resetTransformRef.current();
    if (cardType === 'letters' && isEnabled === false) {
      toast.error('This Letter will enabled Before 7 days of sending & You will get Alert Email', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return;
    }
    if (channel === 'nl_subscribers') {
      setRecipientModel(true);
      setChannelData({
        name: channel,
        paragrap: 'Connect Your Whatsapp Account',
        builder: builder,
        cardType,
        cardId
      });
    }
    if (cardType === 'letters') {
      setLetterModel(true);
      setChannelData({
        name: channel,
        paragrap: 'Connect Your Whatsapp Account',
        builder: builder,
        cardType,
        cardId
      });
    }
  }


  return (
    <div className='journey'>
      <NavbarComp />
      <ToastContainer></ToastContainer>

      <RecipietList recipeintModel={recipeintModel} handleCloseRecipientModel={handleCloseRecipientModel} programId={programId} fetchProgramDetails={fetchProgramDetails} channelData={channelData} />
      <LettersModel lettersModel={lettersModel} channelData={channelData} closeLettersModel={closeLettersModel} programId={programId} fetchProgramDetails={fetchProgramDetails} programDetails={programDetails} />
      <div className='flow-main'>
        <div className='flow-div'>
          <TransformWrapper
            initialScale={1}
            minScale={0.5}
            maxScale={1.5}
            centerOnInit={true}
            wheel={{ disabled: true }}
          >
            {
              ({ zoomIn, zoomOut, resetTransform }) => {
                resetTransformRef.current = resetTransform;
                return (
                  <>
                    <div className='tab-button'>
                      <button
                        className={`tab-btn ${true ? 'active' : ''}`}
                      >
                        NL {programDetails.frequency + " " + programDetails.newsLetterName}
                      </button>
                    </div>
                    <TransformComponent>
                      <div className='main-tabs' style={{ width: '100%' }}>
                        <div className='tabs-div'>
                          <div className='row tabs'>
                            <div className='col-sm-1 d-flex align-items-center justify-content-start'></div>
                            <div className='col-sm-10'>
                              <div className='custom-tab'>
                                {tabLoading ? <Letters handleChannelModel={handleChannelModel} fetchProgramDetails={fetchProgramDetails} programDetails={programDetails} /> : !AnimationMode && <BuilderLoader />}
                              </div>
                            </div>
                            <div className='col-sm-1 d-flex align-items-center justify-content-end'></div>
                          </div>
                        </div>
                      </div>
                    </TransformComponent>

                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='end-buttons'>
                          <button className='end-cancel' onClick={handleCancelClick}>Cancel</button>
                          <button className='end-save' onClick={handleSaveClick}>Save</button>
                          <button className='end-cancel' onClick={handleRunClick}>Run Program</button>
                        </div>
                        <div className='start-buttons'>
                          <div className='zoom-div'>
                            <button className="slider-button" onClick={() => zoomIn()}><BiPlus /></button>
                            <button className="slider-button" onClick={() => resetTransformRef.current()}><TbZoomReset /></button>
                            <button className="slider-button" onClick={() => zoomOut()}><LuMinus /></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }}
          </TransformWrapper>
        </div>

        {AnimationMode && (
          <section className='text-animation'>
            <div className={`animation-journey ${tabLoading ? 'move-to-top-right' : ''}`}>
              {!tabLoading &&
                <div className='stars mb-5'>
                  <div className='star-one'>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.30468 0.980382C8.40067 0.154838 9.59933 0.154837 9.69532 0.980381L9.81268 1.98981C10.172 5.07998 12.5276 7.56224 15.5947 8.08273L16.9332 8.30987C17.7104 8.44175 17.7104 9.55825 16.9332 9.69013L15.5947 9.91727C12.5276 10.4378 10.172 12.92 9.81268 16.0102L9.69532 17.0196C9.59933 17.8452 8.40067 17.8452 8.30468 17.0196L8.18732 16.0102C7.82804 12.92 5.47244 10.4378 2.4053 9.91727L1.06683 9.69013C0.289646 9.55825 0.289644 8.44175 1.06682 8.30987L2.4053 8.08273C5.47244 7.56224 7.82804 5.07998 8.18732 1.98981L8.30468 0.980382Z" fill="url(#paint0_linear_4088_16896)" />
                      <defs>
                        <linearGradient id="paint0_linear_4088_16896" x1="9" y1="-3" x2="9" y2="21" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#9482FB" />
                          <stop offset="1" stopColor="#317DF4" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className='star-two'>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.30468 0.980382C8.40067 0.154838 9.59933 0.154837 9.69532 0.980381L9.81268 1.98981C10.172 5.07998 12.5276 7.56224 15.5947 8.08273L16.9332 8.30987C17.7104 8.44175 17.7104 9.55825 16.9332 9.69013L15.5947 9.91727C12.5276 10.4378 10.172 12.92 9.81268 16.0102L9.69532 17.0196C9.59933 17.8452 8.40067 17.8452 8.30468 17.0196L8.18732 16.0102C7.82804 12.92 5.47244 10.4378 2.4053 9.91727L1.06683 9.69013C0.289646 9.55825 0.289644 8.44175 1.06682 8.30987L2.4053 8.08273C5.47244 7.56224 7.82804 5.07998 8.18732 1.98981L8.30468 0.980382Z" fill="url(#paint0_linear_4088_16896)" />
                      <defs>
                        <linearGradient id="paint0_linear_4088_16896" x1="9" y1="-3" x2="9" y2="21" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#9482FB" />
                          <stop offset="1" stopColor="#317DF4" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className='star-three'>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.30468 0.980382C8.40067 0.154838 9.59933 0.154837 9.69532 0.980381L9.81268 1.98981C10.172 5.07998 12.5276 7.56224 15.5947 8.08273L16.9332 8.30987C17.7104 8.44175 17.7104 9.55825 16.9332 9.69013L15.5947 9.91727C12.5276 10.4378 10.172 12.92 9.81268 16.0102L9.69532 17.0196C9.59933 17.8452 8.40067 17.8452 8.30468 17.0196L8.18732 16.0102C7.82804 12.92 5.47244 10.4378 2.4053 9.91727L1.06683 9.69013C0.289646 9.55825 0.289644 8.44175 1.06682 8.30987L2.4053 8.08273C5.47244 7.56224 7.82804 5.07998 8.18732 1.98981L8.30468 0.980382Z" fill="url(#paint0_linear_4088_16896)" />
                      <defs>
                        <linearGradient id="paint0_linear_4088_16896" x1="9" y1="-3" x2="9" y2="21" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#9482FB" />
                          <stop offset="1" stopColor="#317DF4" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              }
              <div className='animation-flex'>
                {tabLoading && <img src={starcircle} />}&nbsp;&nbsp;<h6 className='pt-2'>Camp AI is building the Journey, Please wait...</h6>
              </div>
            </div>
          </section>
        )}

      </div>
    </div>
  );
};

export default NewslettersJourney;
