import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import NavbarComp from "../../../components/NavbarComp";
import HeaderComp from '../../../components/HeaderComp';
import ProgressStepper from "../../EventProgram/ProgressStepper";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import pdf from '../../../components/Assets/pdf-outcome.svg'
import excel from '../../../components/Assets/excel-outcome.svg'
import Carousel from 'react-bootstrap/Carousel';
import { CgArrowsExpandRightAlt } from "react-icons/cg";
import Modal from 'react-bootstrap/Modal';
import { MdOutlineFileDownload } from "react-icons/md";
import DataGridTable from '../../../components/DataGrid';
import { PieChart, Pie, Tooltip, ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { IoIosPeople } from "react-icons/io";
import { PiEyeFill } from "react-icons/pi";
import { PiMouseLeftClickDuotone } from "react-icons/pi";
import { MdUnsubscribe } from "react-icons/md";
import { BsSendCheck } from "react-icons/bs";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ComposedChart, BarChart, Bar } from 'recharts';
const getGradientFromCount = (count, totalCount) => {
  const percentage = (count / totalCount) * 100;
  if (percentage > 80) {
    return "radial-gradient(circle, rgba(255,0,0,0.8) 0%, rgba(255, 68, 0, 0.3) 50%, rgba(255,165,0,0.3) 100%)"; // Red to Orange
  } else if (percentage > 60) {
    return "radial-gradient(circle, rgba(255,140,0,0.8) 0%, rgba(255, 166, 0, 0.3) 50%, rgba(255,255,0,0.3) 100%)"; // Dark Orange to Yellow
  } else if (percentage > 40) {
    return "radial-gradient(circle, rgba(255,215,0,0.8) 0%, rgba(255, 255, 0, 0.25) 50%, rgba(173,255,47,0.3) 100%)"; // Yellow to Greenish Yellow
  } else if (percentage > 20) {
    return "radial-gradient(circle, rgba(200, 255, 47, 0.82) 0%, rgba(126, 252, 0, 0.29) 50%, rgba(0, 255, 128, 0.17) 100%)"; // Greenish Yellow to Light Green
  }
  return "radial-gradient(circle, rgba(0,255,0,0.8) 0%, rgba(0, 255, 128, 0.39) 50%, rgba(0, 255, 255, 0.18) 100%)"; // Green to Cyan
};


const getRandomShape = () => {
  const borderTopLeft = Math.random() * 70 + 30;
  const borderTopRight = Math.random() * 70 + 30;
  const borderBottomLeft = Math.random() * 70 + 30;
  const borderBottomRight = Math.random() * 70 + 30;
  const variation = Math.random() * 20 - 10;

  const adjustedTopLeft = Math.min(100, Math.max(0, borderTopLeft + variation));
  const adjustedTopRight = Math.min(100, Math.max(0, borderTopRight - variation));
  const adjustedBottomLeft = Math.min(100, Math.max(0, borderBottomLeft + variation));
  const adjustedBottomRight = Math.min(100, Math.max(0, borderBottomRight - variation));
  return `${adjustedTopLeft}% ${adjustedTopRight}% ${adjustedBottomRight}% ${adjustedBottomLeft}%`;
};
const steps = [
  {
    label: '1',
    name: 'step 1',
    className: 'step1 custom-step',
    subSteps: [
      { label: 'Step 1.1' },
      { label: 'Step 1.2' },
      { label: 'Step 1.3' },
      { label: 'Step 1.4' },
    ],
    stepHead: 'Basic Information',
    stepText: 'Provide essential details about your business and event to enhance marketing automation with our AI.'
  },
  {
    label: '2',
    name: 'step 2',
    className: 'step2 custom-step',
    subSteps: [
      { label: 'Step 2.1' },
      { label: 'Step 2.2' },
      { label: 'Step 2.3' },
      { label: 'Step 2.4' },
    ],
    stepHead: 'Journey',
    stepText: 'Guide, remind, and follow up with attendees through every event stage.'
  },
  {
    label: '3',
    name: 'step 3',
    className: 'step3 custom-step',
    subSteps: [
      { label: 'Step 3.1' },
      { label: 'Step 3.2' },
      { label: 'Step 3.3' },
      { label: 'Step 3.4' },
    ],
    stepHead: 'Results/Outcomes',
    stepText: 'Evaluate success and impact from event awareness to attendance and follow-up communication.'
  },
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const LetterOutcome = () => {
  const { programId } = useParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [performanceData, setPerformanceData] = useState(null);
  const [letters, setLetters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flowData, setFlowData] = useState(null);
  const [pieOS, setPieOS] = useState([
    { name: 'Open', value: 0, fill: '#028897' },
    { name: 'Sent', value: 0, fill: '#e6f4f5' },
  ]);
  const [pieCO, setPieCO] = useState([
    { name: 'Clicks', value: 0, fill: '#028897' },
    { name: 'Opens', value: 0, fill: '#e6f4f5' },
  ]);
  const [pieSC, setPieSC] = useState([
    { name: 'Sent', value: 0, fill: '#e6f4f5' },
    { name: 'Clicks', value: 0, fill: '#028897' },
  ]);

  const [graphData, setGraphData] = useState();
  const [clickGraph, setClicksGraph] = useState();
  useEffect(() => {
    const fetchPerformanceData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/outcome/letters/${programId}`, {
          method: 'GET',
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.letters.length > 0) {
          setLetters(data.letters);
          setLoading(false);

        }
      } catch (error) {
        console.error('Error fetching performance data:', error);
      }
    };
    fetchPerformanceData();
  }, []);

  useEffect(() => {
    const fetchflowGraph = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/outcome/lettersFlowGraph/${programId}`, {
          method: 'GET',
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data) {
          setGraphData(data.recipientData);
          setClicksGraph(data.clicksOpenData);
          setFlowData(data.flow);
          setPieOS([
            { name: 'Sent', value: data.flow.reach, fill: '#028897' },
            { name: 'Open', value: data.flow.open, fill: '#e6f4f5' },
          ]);
          setPieCO([
            { name: 'Clicks', value: data.flow.clicks, fill: '#028897' },
            { name: 'Opens', value: data.flow.open, fill: '#e6f4f5' },
          ]);
          setPieSC([
            { name: 'Sent', value: data.flow.reach, fill: '#028897' },
            { name: 'Clicks', value: data.flow.clicks, fill: '#e6f4f5' },
          ]);
        }
      } catch (error) {
        console.error('Error fetching flow graph data:', error);
      }
    };
    fetchflowGraph();
  }, []);



  const handleFinish = () => {
    navigate(`/program`);
  }
  const [key, setKey] = useState('Performance');
  const [expandedLatter, setExpandedLetter] = useState();
  const [show, setShow] = useState(false);
  const handleExpand = (data) => {
    setExpandedLetter(data);
    setShow(true);
  }

  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [error, setError] = useState(null);
  const [columns, setColumns] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const handleViewClickedData = async (e, url, emailId) => {
    setShowTable(false);
    if (!show) {
      alert('open expand view to clicked data');
      return
    }
    try {
      const api = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/outcome/clickedUsers`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: url,
          emailId: emailId
        }),
      });
      const response = await api.json();
      if (response && response.length > 0) {
        setRows(response);
        setInitialRows(response);
        setError(false)
        setColumns([
          { field: '_id', headerName: 'ID', width: 150 },
          { field: 'firstname', headerName: 'First Name', width: 150 },
          { field: 'lastname', headerName: 'Last Name', width: 150 },
          { field: 'email', headerName: 'Email', width: 200 },
          { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
          { field: 'country', headerName: 'Country', width: 100 },
          { field: 'state', headerName: 'State', width: 100 },
          { field: 'city', headerName: 'City', width: 100 },
          { field: 'company', headerName: 'Company', width: 100 },
          { field: 'designation', headerName: 'Designation', width: 100 },
          { field: 'industry', headerName: 'Industry', width: 100 },
        ]);
        setShowTable(true);
        const buttonRect = e.target.getBoundingClientRect();
        const tooltipTable = document.querySelector('.tooltip-table');

        if (tooltipTable) {
          tooltipTable.style.position = 'absolute';
          tooltipTable.style.top = `${buttonRect.bottom + window.scrollY + 10}px`;
          tooltipTable.style.left = `${buttonRect.bottom + window.scrollY - 200}px`;;
          tooltipTable.style.zIndex = '1001';
        }
      } else {
        setRows([]);
        setError('No data found for this segment.');
      }
    } catch (error) {
      console.log(error);
    }
  }
  const tooltipRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTable(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const addHeatMapButtons = (iframe, heatMapData, totalCount, emailId) => {
    if (!iframe || !iframe.contentWindow) return;
    const iframeDocument = iframe.contentWindow.document;
    heatMapData.forEach((heatmapItem) => {
      const matchingLinks = iframeDocument.querySelectorAll(`a[href="${heatmapItem.url}"]`);
      const count = heatmapItem.count;
      const gradient = getGradientFromCount(count, totalCount);
      const shape = getRandomShape();
      const percentage = (count / totalCount) * 100;
      const size = Math.min(percentage, 50);
      matchingLinks.forEach((link) => {
        const button = document.createElement('div');
        button.style.position = 'absolute';
        button.style.top = `${link.getBoundingClientRect().top + window.scrollY}px`;
        button.style.left = `${link.getBoundingClientRect().left + window.scrollX}px`;
        button.style.zIndex = '1000';
        button.style.background = gradient;
        button.style.borderRadius = shape;
        button.style.fontSize = '10px';
        button.style.color = 'black';
        button.style.width = `${size}px`;
        button.style.height = `${size}px`;
        button.style.display = 'flex';
        button.style.alignItems = 'center';
        button.style.justifyContent = 'center';
        button.style.cursor = 'pointer';
        button.style.transform = `scale(${2 + (count > 100 ? 200 : count) / 50})`;
        button.style.transition = "all 0.3s ease-in-out";
        button.innerText = `${heatmapItem.count}`;
        button.className = 'heatmap-button';
        button.addEventListener('click', (e) => {
          handleViewClickedData(e, heatmapItem.url, emailId);
        });

        iframeDocument.body.appendChild(button);
      });
    });
  }

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const flowRef = useRef();
  const downloadPDF = async () => {
    const pdf = new jsPDF();
    const imgWidth = 190; // Width in mm for A4 size PDF
    const pageHeight = 295; // Height in mm for A4 size PDF
    const marginTop = 10; // Top margin
    const marginLeft = 10; // Left margin

    if (flowRef.current) {
      // Use html2canvas to capture the element
      const canvas = await html2canvas(flowRef.current);
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height preserving aspect ratio

      let position = marginTop;

      // Add the captured image to the PDF
      pdf.addImage(imgData, "PNG", marginLeft, position, imgWidth, imgHeight);

      // Check if content overflows and add new pages
      if (imgHeight > pageHeight - marginTop) {
        let remainingHeight = imgHeight;
        position = pageHeight - marginTop;

        while (remainingHeight > 0) {
          pdf.addPage();
          remainingHeight -= pageHeight - marginTop;
          const sourceY = canvas.height - remainingHeight * (canvas.height / imgHeight);
          const clippedCanvas = await html2canvas(flowRef.current, {
            height: canvas.height - sourceY,
          });
          const clippedImgData = clippedCanvas.toDataURL("image/png");
          pdf.addImage(clippedImgData, "PNG", marginLeft, marginTop, imgWidth, imgHeight);
        }
      }

      // Save the PDF
      pdf.save("News_Letter_Outcome.pdf");
    } else {
      console.error("Element reference is not available.");
    }
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex); // Update active index when slide changes
  };

  return (
    <div className='app'>
      <NavbarComp />
      <div className="content overflow-hidden">
        <HeaderComp></HeaderComp>
        <div className="container-fluid px-4 pt-3">
          <div className="row justify-content-around">
            <div className="col-md-2">
              <div style={{ height: '68vh' }} className="bg-light rounded border border-secondary border-2">
                <ProgressStepper
                  steps={steps}
                  currentStep={2}
                  currentSubStep={0}
                  setCurrentStep={setCurrentStep}
                  setCurrentSubStep={setCurrentSubStep}
                />
              </div>
            </div>
            <div className="col-md-10">
              <div style={{ height: '70vh' }} className="bg-light rounded  border border-secondary border-2 overflow-hidden position-relative">
                <div className="container ms-3 pt-4">
                  <div className="row align-items-center">
                    <div className="col-md-11">
                      <div className="mb-0 alert bg-secondary text-success p-2 fw-bold" style={{ fontWeight: '600', fontFamily: 'Poppins', fontSize: '16px' }}>Results and Outcome</div>
                    </div>
                    <div className='col-md-1'>
                      <div className="dropdown">
                        <button className="btn btn-secondary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <MdOutlineFileDownload style={{ fontSize: '25px', color: '#028897' }} />
                        </button>
                        <ul className="dropdown-menu download-outcome mt-1" aria-labelledby="dropdownMenuButton1">
                          <li onClick={() => downloadPDF()}><a className="dropdown-item pdf-download" href="#"><img src={pdf} alt='pdf' /> &nbsp; Pdf</a></li>
                          <li><a className="dropdown-item excel-download" href="#"><img src={excel} alt='excl' /> &nbsp;Excel</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='row me-3' style={{ overflow: 'auto', height: '400px', scrollbarWidth: 'none' }}>
                    <div className="col-sm-12">
                      <div className="outcome-header">
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => {
                            setKey(k);
                          }}
                          className="outcome-tabs"
                        >
                          <Tab eventKey="Performance" title="Performance" className='performance-border'>
                            {loading ? 'loading... ' :
                              <Carousel
                                interval={null}
                                className="custom-carousel"
                                prevLabel="Go to Previous Slide"
                                nextLabel="Go to Next Slide"
                                prevIcon={<span className="custom-prev-icon"><TbPlayerTrackNextFilled /></span>}
                                nextIcon={<span className="custom-next-icon"><TbPlayerTrackNextFilled /></span>}
                                onSelect={handleSelect}
                              >
                                {letters.length > 0 && letters.map((data, index) => (
                                  <Carousel.Item key={index}>
                                    <div style={{ height: '330px', width: '100%' }}>
                                      <div className="row" style={{ height: '330px' }}>
                                        <div className='col-sm-1'></div>
                                        <div className="col-sm-3 nd-main">
                                          <div className="newsletter-details">
                                            <div className="newsletter-details-row">
                                              <div className="newsletter-details-cell">
                                                <h3>{data.letterName}</h3>
                                              </div>
                                            </div>
                                            <div className="newsletter-details-row">
                                              <div className="newsletter-details-cell">
                                                <h4>Send Date: {data.sendDate}</h4>
                                              </div>
                                            </div>
                                            <div className="newsletter-details-row">
                                              <div className="newsletter-details-cell">
                                                <h4>Total Subscribers Received: {data.totalReceived}</h4>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-7 preview-template" >
                                          {(data.html !== '' && activeIndex === index) ?
                                            <>
                                              <iframe
                                                style={{ width: "100%", height: "100%", border: "none" }}
                                                srcDoc={data.html}
                                                title="HTML Preview"
                                                onLoad={(e) => addHeatMapButtons(e.target, data.heatMapData, data.totalCount, data.emailId)}
                                              />
                                              {data &&
                                                <div className='expand-icon' >
                                                  <CgArrowsExpandRightAlt onClick={() => handleExpand(data)} />
                                                </div>
                                              }</>
                                            : 'This newsletter still not active or not has been sented'}
                                        </div>
                                        <div className='col-sm-1'></div>
                                      </div>
                                    </div>
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            }
                          </Tab>
                          <Tab eventKey="Flow" title="Flow">
                            {flowData &&
                              <div className='row' ref={flowRef}>
                                <div className='col-sm-4'>
                                  <div className='flow-numbers'>
                                    <h6>Total Subscribers</h6>
                                    <h3>{flowData.subscribers}</h3>
                                    <div className="pie">
                                      <ResponsiveContainer width="100%" height={150}>
                                        <BarChart data={graphData}>
                                          <defs>
                                            <linearGradient id="colorProjected" x1="0" y1="0" x2="0" y2="1">
                                              <stop offset="0%" stopColor="#028897" />
                                              <stop offset="100%" stopColor="#045A64" />
                                            </linearGradient>
                                          </defs>
                                          <CartesianGrid strokeDasharray="4 4" vertical={false} />
                                          <XAxis dataKey="name" style={{ fontSize: '10px' }} />
                                          <Tooltip />
                                          <Bar dataKey="count" barSize={60} name="Count" fill="url(#colorProjected)" radius={[5, 5, 0, 0]} />
                                        </BarChart>
                                      </ResponsiveContainer>
                                    </div>
                                    <p>Total subscribers in for this program</p>
                                    <div className='flow-number-icon'>
                                      <IoIosPeople />
                                    </div>
                                  </div>
                                </div>
                                <div className='col-sm-4'>
                                  <div className='flow-numbers'>
                                    <h6>Total Sent <b>{flowData.reach}</b></h6>
                                    <h6>Total Open <strong>{flowData.open}</strong></h6>
                                    <div className="pie">
                                      <ResponsiveContainer width="100%" height={150}>
                                        <PieChart>
                                          <Pie
                                            activeIndex={activeIndex}
                                            data={pieOS}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={50}
                                            outerRadius={70}
                                            dataKey="value"
                                            onMouseEnter={onPieEnter}
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                          />
                                          <Tooltip />
                                        </PieChart>
                                      </ResponsiveContainer>
                                    </div>
                                    <p>{flowData.open} Total people that Opened your email</p>
                                    <div className='flow-number-icon'>
                                      <BsSendCheck />
                                    </div>
                                  </div>
                                </div>
                                <div className='col-sm-4'>
                                  <div className='flow-numbers'>
                                    <h6>Total Sent <strong>{flowData.reach}</strong></h6>
                                    <h6>Total Click <strong>{flowData.clicks}</strong></h6>
                                    <div className="pie">
                                      <ResponsiveContainer width="100%" height={150}>
                                        <PieChart>
                                          <Pie
                                            activeIndex={activeIndex}
                                            data={pieSC}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={50}
                                            outerRadius={70}
                                            dataKey="value"
                                            onMouseEnter={onPieEnter}
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                          />
                                          <Tooltip />
                                        </PieChart>
                                      </ResponsiveContainer>
                                    </div>
                                    <p>{flowData.clicks} Total people that clicked a link in your email</p>
                                    <div className='flow-number-icon'>
                                      <PiMouseLeftClickDuotone />
                                    </div>
                                  </div>
                                </div>
                                <div className='col-sm-4'>
                                  <div className='flow-numbers'>
                                    <h6>Total UnSubscribers</h6>
                                    <h3>{flowData.unsubscribe}</h3>
                                    <div className="pie">
                                      <ResponsiveContainer width="100%" height={150}>
                                        <BarChart data={graphData}>
                                          <defs>
                                            <linearGradient id="colorProjected" x1="0" y1="0" x2="0" y2="1">
                                              <stop offset="0%" stopColor="#028897" />
                                              <stop offset="100%" stopColor="#045A64" />
                                            </linearGradient>
                                          </defs>
                                          <CartesianGrid strokeDasharray="4 4" vertical={false} />
                                          <XAxis dataKey="name" style={{ fontSize: '10px' }} />
                                          <Tooltip />
                                          <Bar dataKey="unsubscribes" barSize={60} name="Count" fill="url(#colorProjected)" radius={[5, 5, 0, 0]} />
                                        </BarChart>
                                      </ResponsiveContainer>
                                    </div>
                                    <p>Total UnSubscribers in for this program</p>
                                    <div className='flow-number-icon'>
                                      <MdUnsubscribe />
                                    </div>
                                  </div>
                                </div>
                                <div className='col-sm-8'>
                                  <div className='flow-numbers'>
                                    <h6>Total Clicks <strong>{flowData.clicks}</strong></h6>
                                    <h6>Total Opens <strong>{flowData.open}</strong></h6>
                                    <div className="pie">
                                      <ResponsiveContainer width="100%" height={150}>
                                        <PieChart>
                                          <Pie
                                            activeIndex={activeIndex}
                                            data={pieCO}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={50}
                                            outerRadius={70}
                                            dataKey="value"
                                            onMouseEnter={onPieEnter}
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                          />
                                          <Tooltip />
                                        </PieChart>
                                      </ResponsiveContainer>
                                    </div>
                                    <p>{flowData.open} Total people that Opened your email</p>
                                    <div className='flow-number-icon'>
                                      <PiEyeFill />
                                    </div>
                                  </div>
                                </div>
                                <div className='col-sm-6'>
                                  {(clickGraph.length > 0 && graphData.length > 0) &&
                                    <div className='flow-graph-number'>
                                      <h6>Interactions over time</h6>
                                      <ResponsiveContainer minWidth="100%" minHeight="300px">
                                        <LineChart
                                          width={500}
                                          height={300}
                                          data={clickGraph}
                                          margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                          }}
                                        >
                                          <CartesianGrid strokeDasharray="3 3" />
                                          <XAxis dataKey="letter" />
                                          <YAxis />
                                          <Tooltip />
                                          <Legend />
                                          <Line type="monotone" dataKey="clicks" stroke="#028897" activeDot={{ r: 8 }} />
                                          <Line type="monotone" dataKey="open" stroke="black" activeDot={{ r: 8 }} />
                                        </LineChart>
                                      </ResponsiveContainer>
                                    </div>}
                                </div>
                                <div className='col-sm-6'>
                                  {(clickGraph.length > 0 && graphData.length > 0) &&
                                    <div className='flow-graph-number'>
                                      <h6>Subscribers over time</h6>
                                      <ResponsiveContainer minWidth="100%" minHeight="300px">
                                        {/* <LineChart
                                          width={500}
                                          height={300}
                                          data={graphData}
                                          margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                          }}
                                        >
                                          <CartesianGrid strokeDasharray="3 3" />
                                          <XAxis dataKey="name" />
                                          <YAxis />
                                          <Tooltip />
                                          <Legend />
                                          <Line type="monotone" dataKey="count" stroke="#028897" activeDot={{ r: 8 }} />
                                          <Line type="monotone" dataKey="unsubscribes" stroke="#FB4141" activeDot={{ r: 8 }} />
                                          <Line type="monotone" dataKey="total" stroke="#131010" activeDot={{ r: 8 }} />
                                        </LineChart> */}
                                        <ComposedChart
                                          data={graphData}
                                          margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                          }}
                                        >
                                          <CartesianGrid stroke="#f5f5f5" />
                                          <XAxis dataKey="name" scale="band" />
                                          <YAxis />
                                          <Tooltip />
                                          <Legend payload={[
                                            { value: 'Count', type: 'line', color: '#028897' },
                                            { value: 'Unsubscribes', type: 'line', color: '#FB4141' },
                                            { value: 'Total', type: 'line', color: '#131010' }
                                          ]} />
                                          <Bar dataKey="count" barSize={10} fill="#028897" />
                                          <Bar dataKey="unsubscribes" barSize={10} fill="#FB4141" />
                                          <Bar dataKey="total" barSize={10} fill="#131010" />
                                          <Line type="monotone" dataKey="count" stroke="#028897" activeDot={{ r: 8 }} />
                                          <Line type="monotone" dataKey="unsubscribes" stroke="#FB4141" activeDot={{ r: 8 }} />
                                          <Line type="monotone" dataKey="total" stroke="#131010" activeDot={{ r: 8 }} />
                                        </ComposedChart>
                                      </ResponsiveContainer>
                                    </div>}
                                </div>
                              </div>
                            }
                          </Tab>
                        </Tabs>

                      </div>
                    </div>
                    <div className="col-sm-2">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-end my-3">
            <div className="col-md-6">
              <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-secondary me-2 btn-full"
                  onClick={() => navigate(`/journey/${programId}`)}
                  style={{ border: '2px solid #028897' }}
                  type="button"
                >
                  Back
                </button>
                <button
                  className={`btn text-white btn-full btn-success`}
                  onClick={handleFinish}
                  type="button"
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>News Letter {show && expandedLatter.letterName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show > 0 &&
            <div className="row" style={{ height: '330px' }}>
              <div className="col-sm-4 nd-main">
                <div className="newsletter-details">
                  <div className="newsletter-details-row">
                    <div className="newsletter-details-cell">
                      <h3>{expandedLatter.letterName}</h3>
                    </div>
                  </div>
                  <div className="newsletter-details-row">
                    <div className="newsletter-details-cell">
                      <h4>Send Date: {expandedLatter.sendDate}</h4>
                    </div>
                  </div>
                  <div className="newsletter-details-row">
                    <div className="newsletter-details-cell">
                      <h4>Total Subscribers Received: {expandedLatter.totalReceived}</h4>
                    </div>
                  </div>
                  <div className="newsletter-details-row">
                    <div className="newsletter-details-cell">
                      <h4>Total Opened: {expandedLatter.totalOpened}</h4>
                    </div>
                  </div>
                  <div className="newsletter-details-row">
                    <div className="newsletter-details-cell">
                      <h4>Open Rate: {(expandedLatter.totalOpened / expandedLatter.totalReceived) * 100} %</h4>
                    </div>
                  </div>
                  <div className="newsletter-details-row">
                    <div className="newsletter-details-cell">
                      <h4>Total Clicked: {expandedLatter.TotalClicked}</h4>
                    </div>
                  </div>
                  <div className="newsletter-details-row">
                    <div className="newsletter-details-cell">
                      <h4>CTR: {expandedLatter.CTR} %</h4>
                    </div>
                  </div>
                  <div className="newsletter-details-row">
                    <div className="newsletter-details-cell">
                      <h4>CTOR: {expandedLatter.CTOR} %</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-8" style={{ position: 'relative' }}>
                <iframe
                  style={{ width: "100%", height: "100%", border: "none" }}
                  srcDoc={expandedLatter.html}
                  title="HTML Preview"
                  onLoad={(e) => addHeatMapButtons(e.target, expandedLatter.heatMapData, expandedLatter.totalCount, expandedLatter.emailId)}
                />
                <div className="tooltip-table" style={{ position: 'obsolute', background: 'white', width: '800px' }} ref={tooltipRef}>

                  {(rows.length && showTable) &&
                    <>
                      <button className='btn btn-primary' onClick={() => setShowTable(false)}>Close</button>
                      <DataGridTable
                        rows={rows}
                        columns={columns}
                      />
                    </>}
                </div>
              </div>

            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LetterOutcome;
