import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DataGrid } from '@mui/x-data-grid';

const CrmModel = ({ crmModel, handleCloseCrmModel, fetchProgramDetails, channelData, programDetails, programId,builder }) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [count, setCount] = useState(null)

    const fetchSegmentData = async () => {
        if (channelData.cardId !== '') {
            try {
                setRows([]);
                setError(true);
                const api = await axios.post(
                    `${process.env.REACT_APP_BASE_URL_API}/lead/getLeadSegmentList`,
                    { segmentId: channelData.cardId },
                    { withCredentials: true }
                );
                const response = api.data;
                if (response && response.length > 0) {
                    const formattedRows = response.map((item) => {
                        const countryCode = item.leadData.country_code;
                        const mobile = item.leadData.mobile;

                        let formattedMobile = '';
                        if (countryCode && mobile) {
                            formattedMobile = `+${countryCode}-${mobile}`;
                        } else if (mobile) {
                            formattedMobile = mobile;
                        } else if (countryCode) {
                            formattedMobile = `+${countryCode}`;
                        }

                        return {
                            ...item.leadData,
                            segment_id: item.segment_id,
                            lead_id: item.lead_id,
                            date_added: item.date_added,
                            formattedMobile,
                        }
                    });
                    setRows(formattedRows);
                    setFilteredRows(formattedRows);
                    setError(false);
                    setColumns([
                        { field: '_id', headerName: 'ID', width: 150 },
                        { field: 'firstname', headerName: 'First Name', width: 150 },
                        { field: 'lastname', headerName: 'Last Name', width: 150 },
                        { field: 'email', headerName: 'Email', width: 200 },
                        { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                        { field: 'country', headerName: 'Country', width: 100 },
                        { field: 'state', headerName: 'State', width: 100 },
                        { field: 'city', headerName: 'City', width: 100 },
                        { field: 'company', headerName: 'Company', width: 100 },
                        { field: 'designation', headerName: 'Designation', width: 100 },
                        { field: 'industry', headerName: 'Industry', width: 100 },
                        { field: 'date_added', headerName: 'Created', width: 100 },
                    ]);
                } else {
                    setRows([]);
                    setFilteredRows([]);
                    setError('No data found for this segment.');
                }
            } catch (error) {
                console.log('Error fetching segment data:', error);
                setError('Failed to fetch data.');
            }
        }
    };
    const [crms, setCrms] = useState([]);
    const [selectedCrm, setSelectedCrm] = useState('');
    const fetchCrmPlatForms = async () => {
        try {
            const crm = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/getCrmList`, { withCredentials: true });
            if (crm.data.length > 0) {
                setCrms(crm.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (channelData.name === 'attend_crm' || channelData.name === 'non_attend_crm') {
            fetchSegmentData();
            fetchCrmPlatForms();
        }
    }, [channelData]);

    useEffect(() => {
        if (fromDate && toDate) {
            const filtered = rows.filter((row) => {
                const rowDate = new Date(row.date_added);
                return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
            });
            setFilteredRows(filtered);
        } else {
            setFilteredRows(rows);
        }
    }, [fromDate, toDate, rows]);



    const handlePushCrm = async () => {
        if (selectedCrm === '') {
            alert('please select crm platform');
            return
        }
        setCount(null);
        if (selectedIds.length > 0) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL_API}/api/zoho/attendPushToCrm`,
                    { leadIds: selectedIds, cardName: channelData.name, programId, selectedCrm,builder},
                    { withCredentials: true }
                );
                setCount(response.data)
                await fetchProgramDetails();
                console.log('Push CRM Response:', response.data);
            } catch (error) {
                console.log('Error pushing data to CRM:', error);
            }
        } else {
            alert('No IDs selected');
        }
    };

    return (
        <Modal
            size="xl"
            show={crmModel}
            onHide={()=>{
                handleCloseCrmModel()
                setSelectedCrm('')
            }}
            aria-labelledby="example-modal-sizes-title-lg"
            className="custom-emailChannel-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {programDetails !== '' && (
                        <h5 className='segment-header'>
                            Push To Crm : {programDetails.eventName}
                        </h5>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error ? (
                    <p>No data in {channelData.name === 'attend_crm' ?'Attend List' :'Non Attend List'} list please upload Attend List</p>
                ) : (
                    <>
                        <div className="filter-div">
                            <div className='row m-2'>
                                <div className='col-sm-2'></div>
                                <div className='col-sm-3'>
                                    <label>Select CRM platform</label>
                                    <select className="form-control" value={selectedCrm} onChange={(e) => setSelectedCrm(e.target.value)}>
                                        <option value=''>select crm</option>
                                        {crms?.map((data, i) => (
                                            <option key={i} value={data.alias}>{data.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-sm-3'>

                                </div>
                                <div className='col-sm-4'>

                                    {selectedCrm !== '' &&
                                        <div className="list-buttons">
                                            <button onClick={handlePushCrm}>Push to CRM</button>
                                            {count && <button >pushed : {count.count} totalPushed : {count.total}</button>}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 450, width: '100%' }}>
                            <DataGrid
                                rows={filteredRows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                checkboxSelection
                                onRowSelectionModelChange={(ids) => {
                                    setSelectedIds(ids);
                                }}
                                rowSelectionModel={selectedIds}
                                getRowId={(row) => row._id}
                                disableSelectionOnClick
                            />
                        </div>

                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CrmModel;
