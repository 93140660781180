import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import MyEmailTemplateEditor from '../../EmailBuilder/MyEmailTemplateEditor';
import { CiSearch } from "react-icons/ci";
import connector from '../../../components/Assets/Connector line.svg'
import { MdMoreVert } from "react-icons/md";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { CgMathPlus } from "react-icons/cg";
import { FaRegClone } from "react-icons/fa6";
import DataGridTable from '../../../components/DataGrid';
import { FaRegEdit } from "react-icons/fa";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import uploadCsv from '../../../components/Assets/upload.png'
import csvIcon from '../../../components/Assets/csv.png'
import csvCancel from '../../../components/Assets/csv-cancel.png'
import FailedList from '../../../components/FailedList';
import { MdCancel } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { GrClone } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import BuilderLoader from './BuilderLoader'

const types = [
    { name: 'CRM', type: 'crm' },
    { name: 'CSV', type: 'csv' },
    { name: 'None', type: '' }
];

const EmailChannelModel = ({ EmailChannelDataModel, channelData, closeEmailChannelDataModel, programId, fetchProgramDetails, programDetails }) => {
    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [emailSubjects, setEmailSubjects] = useState({});
    const [emailData, setEmailData] = useState([]);
    const [recipientCount, setRecipientCount] = useState(0);
    const [preview, setPreview] = useState(false);
    const [previewShow, setPreviewShow] = useState(false);
    const [segmentId, setSegmentId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialRows] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(0);
    const [activeTab, setActiveTab] = useState('Setup Drip Email');
    const [selectedCrm, setSelectedCrm] = useState(null);
    const [pro, setPro] = useState(0);
    const [success, setSuccess] = useState(0);
    const [successMsg, setSuccessMsg] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [successType, setSuccessType] = useState(null);
    const User = JSON.parse(localStorage.getItem('user'));
    const [subDisabeled, setSubDisabled] = useState(true);
    const [failedRows, setFailedRows] = useState([]);
    const [failedColumns, setFailedColumns] = useState([]);
    const [failed, setFailed] = useState(false);
    const [senderList, setSenderList] = useState([]);
    // Fetch the sender list
    useEffect(() => {
        const fetchSenderList = async () => {
            try {
                // Make the GET request
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/platform?platformAlias=sendEmail`, {
                    method: 'GET',
                    credentials: 'include',
                });

                // Handle the response from the API
                if (response.ok) {
                    const result = await response.json();
                    console.log('API response:', result);

                    // Assuming result is an array and we are interested in the tokenOrKey of the first object
                    if (result.length > 0 && result[0].tokenOrKey) {
                        // Split the tokenOrKey string into an array
                        const tokenArray = result[0].tokenOrKey.split(',');
                        setSenderList(tokenArray); // Store the array in state
                    } else {
                        console.error('No valid tokenOrKey found in API response');
                    }
                } else {
                    console.error('Error in API response:', response.statusText);
                }
            } catch (error) {
                console.error('Error making API request:', error);
            }
        };
        // Call the fetch function
        fetchSenderList();
    }, []);

    const handleSelect = (crmId, crmAlias) => {
        if (selectedCrm !== null && selectedCrm.id === crmId) {
            setSelectedCrm(null);
        } else {
            setSelectedCrm({ id: crmId, alias: crmAlias });
        }
    };
    const [emailLoading, setEmailLoading] = useState(false);
    const getEmails = async () => {
        setEmailLoading(true);

        const fetchSubjectLine = async (emailId) => {

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/ai/getSubjectLine`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    const subjectLine = JSON.parse(result.response);
                    return subjectLine;
                } else {
                    console.error('Error in fetching subject line:', response.statusText);
                }
            } catch (error) {
                console.error('Error in making subject line API request:', error);
            }
            return '';
        };

        //Recommended Dates
        let scheduledDates = [];
        try {
            // Make the GET request
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/getProgramRecommendedDates/${programId}`, {
                method: 'GET',
                credentials: 'include',
            });

            // Handle the response from the API
            if (response.ok) {
                const result = await response.json();
                // Get the differenceInDays from the API response
                if (channelData.builder === "reachBuilder") {
                    const { startDate, differenceInDays } = result;

                    // Divide differenceInDays by the length of the emailData array
                    const daysPerEmail = Math.floor(differenceInDays / 3); // Ensures the result is an integer
                    const start = new Date(startDate);

                    // Run a loop 3 times to generate the dates
                    scheduledDates = Array.from({ length: 3 }).map((_, index) => {
                        const sendDate = new Date(start);
                        sendDate.setDate(start.getDate() + (index * daysPerEmail));  // Schedule each email

                        // Get the hours and minutes
                        const hours = ('0' + sendDate.getHours()).slice(-2); // Add leading zero for hours
                        const minutes = ('0' + sendDate.getMinutes()).slice(-2); // Add leading zero for minutes

                        // Format the date as "yyyy-mm-ddTHH:mm"
                        const formattedDate =
                            sendDate.getFullYear() + '-' +  // Get the full year
                            ('0' + (sendDate.getMonth() + 1)).slice(-2) + '-' + // Add leading zero for month
                            ('0' + sendDate.getDate()).slice(-2) + 'T' +  // Add leading zero for day and 'T' for time
                            hours + ':' + // Add the hours
                            minutes;      // Add the minutes

                        return formattedDate;
                    });
                    console.log("scheduledDates : ", scheduledDates);
                } else if (channelData.builder === "registerBuilder") {
                    const eventStartDate = result.endDate;
                    const startDate = new Date(eventStartDate);

                    scheduledDates = Array.from({ length: 3 }).map((_, index) => {
                        let sendDate;

                        if (index === 0) {
                            return "Immediate on form Submission";
                        } else if (index === 1) {
                            sendDate = new Date(startDate);
                            sendDate.setDate(sendDate.getDate() - 2);
                        } else if (index === 2) {
                            sendDate = new Date(startDate);
                            sendDate.setDate(sendDate.getDate() - 1);
                        }

                        const formattedDate =
                            sendDate.getUTCFullYear() + '-' +
                            ('0' + (sendDate.getUTCMonth() + 1)).slice(-2) + '-' +
                            ('0' + sendDate.getUTCDate()).slice(-2) + 'T' +
                            ('0' + sendDate.getUTCHours()).slice(-2) + ':' +
                            ('0' + sendDate.getUTCMinutes()).slice(-2);

                        return formattedDate;
                    });
                    console.log("scheduledDates for register tab : ", scheduledDates);
                } else if (channelData.builder === "attendBuilder") {
                    const { eventEndDate } = result;
                    const startDate = new Date(eventEndDate);

                    scheduledDates = Array.from({ length: 2 }).map((_, index) => {
                        let sendDate;

                        if (index === 0) {
                            sendDate = new Date(startDate);
                        } else if (index === 1) {
                            sendDate = new Date(startDate);
                            sendDate.setUTCDate(sendDate.getUTCDate() + 7);
                        }

                        const formattedDate =
                            sendDate.getUTCFullYear() + '-' +
                            ('0' + (sendDate.getUTCMonth() + 1)).slice(-2) + '-' +
                            ('0' + sendDate.getUTCDate()).slice(-2) + 'T' +
                            ('0' + sendDate.getUTCHours()).slice(-2) + ':' +
                            ('0' + sendDate.getUTCMinutes()).slice(-2);

                        return formattedDate;
                    });

                    console.log("scheduledDates for attend tab : ", scheduledDates);
                }

            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }

        try {
            const api = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/getEmails`, { programId, eventName: programDetails.eventName, cardName: channelData.name, builder: channelData.builder }, { withCredentials: true });
            const emailPromises = api.data.map(async (email, index) => {
                let scoreResponse;
                let subjectLine;
                if (!(email.subject)) {
                    subjectLine = await fetchSubjectLine(email._id);
                }
                if (!(email.subjectScore)) {
                    const score = await axios.get(`https://sendcheckit.com/api/?subject=${subjectLine}`);
                    scoreResponse = score.data;
                }
                let sendEmailDate;
                // Return the constructed object
                if (channelData.builder === "reachBuilder" || channelData.builder === "registerBuilder" || channelData.builder === "attendBuilder") {
                    sendEmailDate = email.sendDate ? email.sendDate : (index < 3 ? scheduledDates[index] : '');
                }
                else {
                    sendEmailDate = email.sendDate ? email.sendDate : '';
                }
                let dateLogic;
                if (channelData.builder === "registerBuilder") {
                    if (index === 0) {
                        dateLogic = 'This Email will be Sented On Registration Form Submit'
                    } else if (index === 1) {
                        dateLogic = 'This Email will be Sented On before two day of program'
                    } else if (index === 2) {
                        dateLogic = 'This Email will be Sented On before one day of program'
                    } else {
                        dateLogic = ''
                    }
                }
                if (channelData.builder === "attendBuilder") {
                    if (index === 0) {
                        dateLogic = 'This Email will be Sented On program completed immideatly'
                    } else if (index === 1) {
                        dateLogic = 'This Email will be Sented On after one week of program'
                    } else {
                        dateLogic = ''
                    }
                }

                return {
                    emailId: email.emailId,
                    templateName: email.templateName,
                    subject: email.subject ? email.subject : subjectLine, // Use fetched subject line or fallback
                    subjectScore: email.subjectScore ? email.subjectScore : scoreResponse.rating,
                    sendDate: sendEmailDate, //email.sendDate ? email.sendDate : '',
                    status: email.status ?? true,
                    no: email.no ? email.no : '',
                    customCTA: email.customCTA ?? '',
                    formSubmit: index === 0 ? true : false,
                    dateLogic: dateLogic
                };
            });

            // Step 2: Wait for all the promises to resolve
            const data = await Promise.all(emailPromises);
            setEmails(data);
            setEmailData(data);
            console.log(data);

            if (data.length > 0) {
                setSelectedEmail(data[0]);
                setEmailSubjects(data.reduce((acc, email) => {
                    acc[email.emailId] = email.subject;
                    return acc;
                }, {}));
            }
            setEmailLoading(false);
            console.log(selectedEmail);
        } catch (error) {
            console.log(error);
        }
    };

    const getRecipientListCount = async (sid) => {
        try {
            const list = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/getRecipentCounts/${sid}`, { withCredentials: true });
            if (list.data.count) {
                setRecipientCount(list.data.count)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [crms, setCrms] = useState([]);
    const fetchCrmPlatForms = async () => {
        try {
            const crm = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/getCrmList`, { withCredentials: true });
            if (crm.data.length > 0) {
                setCrms(crm.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (channelData.name === 'email' && channelData.cardId !== '0' && channelData.cardId !== undefined) {
            getRecipientListCount(channelData.cardId);
        }
        if (channelData.name === 'email' || channelData.name === 'register_email' || channelData.name === 'attend_email' || channelData.name === 'non_attend_email') {
            getEmails();
        }
        if (channelData.name === 'email') {
            fetchCrmPlatForms();
        }

    }, [channelData]);


    const [crmLoading, setCrmLoading] = useState(false);
    const handleImportCrm = async () => {
        setCrmLoading(true);
        try {
            if (selectedCrm && selectedCrm.alias) {
                const payload = {
                    type: 'crm',
                    programId: programId,
                    channel: channelData.name,
                    builder: channelData.builder,
                    alias: selectedCrm.alias,
                    segmentData: {
                        name: 'Hello Tony Stark',
                        created_by: User.id,
                        created_by_user: User.username,
                        description: 'Testing this description',
                        alias: 'Hello Tony Stark'
                    }
                };
                const importApi = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/importLeads`, payload, { withCredentials: true });
                if (importApi.status === 200) {
                    const importApiData = importApi.data;
                    setSuccessMsg(importApiData.data);
                    setSuccess(1);
                    setSuccessType('crm');
                    setCrmLoading(false);
                    setSegmentId(importApiData.segmentId);
                    getRecipientListCount(importApiData.segmentId);

                    setFailedColumns([
                        { field: '_id', headerName: 'ID', width: 150 },
                        { field: 'firstname', headerName: 'First Name', width: 150 },
                        { field: 'lastname', headerName: 'Last Name', width: 150 },
                        { field: 'email', headerName: 'Email', width: 200 },
                        { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                        { field: 'country', headerName: 'Country', width: 100 },
                        { field: 'state', headerName: 'State', width: 100 },
                        { field: 'city', headerName: 'City', width: 100 },
                        { field: 'company', headerName: 'Company', width: 100 },
                        { field: 'designation', headerName: 'Designation', width: 100 },
                        { field: 'industry', headerName: 'Industry', width: 100 },
                        { field: 'reason', headerName: 'Failed Reason', width: 300 }
                    ]);
                    setFailedRows(importApiData.failedData);
                } else {
                    setSuccess(2);
                    setPro(0);
                    setCrmLoading(false);
                }
            } else {
                console.log("No CRM selected");
            }
        } catch (error) {
            console.error("Error importing CRM data:", error);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
            setSuccess(2);
            setCrmLoading(false);
        }
    };

    const handleEmailClick = (email) => {
        console.log(selectedEmail);
        console.log(emailSubjects)

        setCtaValue('');
        setEmailEdit(false);
        setSelectedEmail(email);

        setSubDisabled(true);
    };



    const debounceRef = useRef(null);
    const handleSubjectChange = (e) => {
        const newSubject = e.target.value;

        if (selectedEmail) {
            setEmailSubjects({
                ...emailSubjects,
                [selectedEmail.emailId]: newSubject
            });
            // Update the emailData state, even if the subject is cleared
            const updatedData = emailData.map(email =>
                email.emailId === selectedEmail.emailId
                    ? { ...email, subject: newSubject, subjectScore: newSubject === '' ? 0 : email.subjectScore } // Reset score if subject is empty
                    : email
            );
            setEmailData(updatedData);
            setSelectedEmail(updatedData.find(email => email.emailId === selectedEmail.emailId));
            setEmails(updatedData);
        }

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }
        if (newSubject === '') {
            return
        }
        debounceRef.current = setTimeout(async () => {
            try {
                const scoreApi = await axios.get(`https://sendcheckit.com/api/?subject=${newSubject}`);
                const response = scoreApi.data;
                console.log('Score:', response.rating);

                // Update the email data and set the selected email
                const updatedData = emailData.map(email =>
                    email.emailId === selectedEmail.emailId
                        ? { ...email, subject: newSubject, subjectScore: response.rating }
                        : email
                );
                setEmailData(updatedData);
                setSelectedEmail(updatedData.find(email => email.emailId === selectedEmail.emailId));
                setEmails(updatedData);
            } catch (error) {
                console.log(error);
            }
        }, 500);
    };

    const handleSave = async () => {
        const invalidEmails = emailData.filter(email => !email.subject || !email.sendDate);

        if (invalidEmails.length > 0) {
            console.error('Validation failed: Some emails have missing subject or send date');
            alert('Please ensure all emails have a subject and a send date before saving.');
            return; // Exit the function without making the API call
        }
        setEmailEdit(false);
        // return;
        const selectedData = {
            programId: programId,
            selectedChannel: channelData.name,
            builder: channelData.builder,
            saveData: emailData
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedChannel`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });

            if (response.ok) {
                const result = await response.json();
                console.log('API response:', result);
                closeEmailChannelDataModel();
                await fetchProgramDetails();
            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };
    const [fileName, setFileName] = useState('');
    const [dragActive, setDragActive] = useState(false);


    const handleUpload = async (file) => {
        // console.log(channelData);
        setPro(10);
        const validMimeTypes = ['text/csv', 'application/vnd.ms-excel'];
        const filename = file.name.trim().toLowerCase();
        if (!file || !validMimeTypes.includes(file.type) || !filename.endsWith('.csv')) {
            alert("Please upload a valid CSV file.");
            setPro(0);
            return;
        }
        setFileName(file.name);
        setPro(20);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('channel', channelData.name);
        formData.append('programId', programId);
        formData.append('type', 'csv');
        formData.append('platformType', 'email');
        formData.append('builder', channelData.builder);
        formData.append('segmentData', JSON.stringify({
            name: 'Hello Tony Stark',
            created_by: User.id,
            created_by_user: User.username,
            description: 'Testing this description',
            alias: 'Hello Tony Stark'
        }));
        setPro(30);
        try {
            setPro(50);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/uploadCSV`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    withCredentials: true,
                }
            );
            setPro(70);
            if (response.status === 200) {
                const data = response.data;
                setSuccessMsg(data.data);
                setPro(100);
                setSuccess(1);
                await fetchProgramDetails();
                getRecipientListCount(data.segmentId);
                setSegmentId(data.segmentId);
                setPreview(true);
                setSuccessType('csv');
                console.log('Upload Success:', data);
                setFailedColumns([
                    { field: 'id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                    { field: 'reason', headerName: 'Failed Reason', width: 300 }
                ]);
                setFailedRows(data.failedData);
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            } else {
                setSuccess(2);
                setPro(0);
            }
        } catch (error) {
            console.log('Error uploading file:', error);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
            setPro(0);
            setSuccess(2);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const file = e.dataTransfer.files[0];
        handleUpload(file); // Validate and process the dropped file
    };

    const onInputChange = (e) => {
        const file = e.target.files[0];
        handleUpload(file);
    };

    const [emailHtml, setEmailHtml] = useState('');
    const handleOnlyPreview = (id) => {
        try {
            fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/myclonetemplate/${id}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => response.json())
                .then(data => {
                    setEmailHtml(data.html)
                })
                .catch(error => console.error('Error loading template:', error));
        } catch (error) {
            console.log(error);
        }
    }

    const [emailEdit, setEmailEdit] = useState(false);
    const handleEditEmailTemplate = (id) => {
        if (id !== '') {
            setEmailHtml('')
            handleOnlyPreview(selectedEmail.emailId);
            if (emailEdit) {
                setEmailEdit(false);
                setTimeout(() => {
                    setEmailEdit(true);
                }, 1000);
            } else {
                setEmailEdit(true);
            }
        } else {
            alert('id is missing');
            setEmailEdit(false);
        }
    }
    const handleSendDateChange = (newDate) => {
        const updatedSelectedEmail = { ...selectedEmail, sendDate: newDate };
        const updatedEmails = emailData.map(email =>
            email.emailId === selectedEmail.emailId
                ? { ...email, sendDate: newDate }
                : email
        );
        setSelectedEmail(updatedSelectedEmail);
        setEmailData(updatedEmails);
        setEmails(updatedEmails);
    };

    const handleDownload = () => {
        if (!process.env.REACT_APP_PUBLIC_URL) {
            alert('.env file is missing');
            return
        }
        const filePath = `${process.env.REACT_APP_PUBLIC_URL}/demo-files/Upload contacts to Camp -demo.csv`;

        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'Upload contacts to Camp -demo.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    const handlePreview = async (type) => {
        let id;
        if (segmentId === '') {
            id = channelData.cardId;
        } else {
            id = segmentId;
        }

        setPreviewShow(true);
        try {
            const segmentList = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/getLeadSegmentListType`, { type, id }, { withCredentials: true });
            const response = segmentList.data;

            if (response && response.length > 0) {
                const formattedRows = response.map(item => {
                    const countryCode = item.leadData.country_code;
                    const mobile = item.leadData.mobile;

                    // Logic to format mobile number conditionally
                    let formattedMobile = '';
                    if (countryCode && mobile) {
                        formattedMobile = `+${countryCode}-${mobile}`;
                    } else if (mobile) {
                        formattedMobile = mobile; // Just display mobile if countryCode is null
                    } else if (countryCode) {
                        formattedMobile = `+${countryCode}`; // Just display country code if mobile is null
                    }

                    return {
                        ...item.leadData,
                        segment_id: item.segment_id,
                        lead_id: item.lead_id,
                        formattedMobile,
                    };
                });

                setRows(formattedRows);
                setInitialRows(formattedRows);
                setError(false)
                setColumns([
                    { field: '_id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                ]);
            } else {
                setRows([]);
                setError('No data found for this segment.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);

        if (value === '') {
            // Reset to the original rows if search is cleared
            setRows(initialRows);
        } else {
            const filteredRows = initialRows.filter((row) =>
                Object.values(row).some((val) =>
                    String(val).toLowerCase().includes(value)
                )
            );
            setRows(filteredRows);
        }
    };


    const options = types.map((item) => ({
        value: item.type,
        label: item.name,
    }));
    const [prompt, setPrompt] = useState('get leads where firstname starts with B and is present in leadsegment list more than 5 times');
    const handleInputChange = (event) => {
        setPrompt(event.target.value);
    };
    const [query, setQuery] = useState('');
    const handleSearchNQL = async () => {
        setSearchLoading(true);
        try {
            const promptApi = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/lead/NaturalQueryLanguage`, { prompt }, { withCredentials: true });

            if (promptApi.status === 200) {
                const queryRes = promptApi.data;
                setQuery(queryRes.message)
                setSearchLoading(false);
            }
        } catch (error) {
            console.error(error);
            setSearchLoading(false);
        }
    };
    const [activeTooltip, setActiveTooltip] = useState(null);

    const toggleTooltip = (emailId) => {
        setActiveTooltip((prevState) => (prevState === emailId ? null : emailId));
    };

    const [ctaValue, setCtaValue] = useState('');
    const [showInput, setShowInput] = useState(false);
    const ctaRef = useRef();


    const handleChangeCTA = async (value) => {
        try {
            if (emailEdit) {
                if (value === 'Other') {
                    setShowInput(true);
                } else {
                    if (value !== '') {
                        setEmailEdit(false);
                        setShowInput(false);

                        const CTAApi = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/updateCTA`,
                            { cta: value, emailId: selectedEmail.emailId, programId },
                            { withCredentials: true }
                        );

                        if (CTAApi.status === 200) {
                            setEmailEdit(true);
                            // setCtaValue('');
                            setSelectedEmail({
                                ...selectedEmail,
                                customCTA: value
                            });
                            const updatedEmails = emailData.map(email =>
                                email.emailId === selectedEmail.emailId
                                    ? { ...email, customCTA: value }
                                    : email
                            );
                            setEmailData(updatedEmails);
                            setEmails(updatedEmails);
                            handleOnlyPreview(selectedEmail.emailId);
                            // ctaRef.current.value = '';
                        } else {
                            alert('Failed to update CTA');
                            setCtaValue('');
                        }
                    } else {
                        console.log('selected', value);
                    }
                }
            } else {
                alert('Please click preview and edit');
                setCtaValue('');
            }
        } catch (error) {
            console.log("Error updating CTA:", error);
            alert('An error occurred while updating the CTA');
            setCtaValue('');
        }
    };

    const handleSubmit = () => {
        handleChangeCTA(ctaValue);
    };

    const handleViewFailedList = (type) => {
        setFailed(true);
    }

    const handleAddNewEmail = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/addNewEmail`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    programId,
                }),
            });

            const data = await response.json();

            if (data.result === 1) {

                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/ai/getSubjectLine`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    //body: JSON.stringify({ emailId })  // Send the emailId as the body
                });
                let subjectLine = "";
                if (response.ok) {
                    const result = await response.json();
                    subjectLine = JSON.parse(result.response);
                }
                const scoreAdd = await axios.get(`https://sendcheckit.com/api/?subject=${subjectLine}`);
                const responseAdd = scoreAdd.data;

                // Create the cloned template with the new ID
                const clonedTemplate = {
                    emailId: data.clonedTemplateIds, // Assign the new ID from API response
                    templateName: `New Email`, // Change the template name
                    subject: subjectLine, // Ensure the subject line is copied
                    subjectScore: responseAdd.rating || 0,
                    customCTA: data.customCTA,
                    sendDate: '',
                    status: true,
                };

                console.log('addnew', clonedTemplate);
                setEmailSubjects({
                    ...emailSubjects,
                    [clonedTemplate.emailId]: subjectLine
                });
                // Update the state with the new cloned email
                setEmails(prevEmails => [...prevEmails, clonedTemplate]);

                setEmailData(prevEmails => [...prevEmails, clonedTemplate]);
            } else {
                console.error('Error cloning email:', data);
            }
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            // Hide the tooltip after cloning
            setActiveTooltip(null);
        }
    };

    const handleDelete = (emailId) => {
        setEmails(prevEmails => prevEmails.filter(email => email.emailId !== emailId));
        setEmailData(prevEmails => prevEmails.filter(email => email.emailId !== emailId));
        setActiveTooltip(null);
    };

    const handleClone = async (template) => {
        try {
            // Call the API to clone the email
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/cloneEmail/${template.emailId}`, {
                method: 'POST', // Use POST method to create a new record
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    // Include any necessary body data if your API expects it
                    // In this case, it seems you only need the emailId in the URL
                }),
            });

            const data = await response.json();

            if (data.result === 1) {
                // Create the cloned template with the new ID
                const clonedTemplate = {
                    ...template,
                    emailId: data.id, // Assign the new ID from API response
                    templateName: `Clone - ${template.templateName}`, // Change the template name
                    subject: template.subject, // Ensure the subject line is copied
                    customCTA: template.customCTA,
                };

                setEmails(prevEmails => [...prevEmails, clonedTemplate]);
                setEmailData(prevEmails => [...prevEmails, clonedTemplate]);
            } else {
                console.error('Error cloning email:', data);
            }
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            setActiveTooltip(null);
        }
    };


    const [moveEmail, setMoveEmail] = useState(null);

    const handleMoveStart = (email) => {
        setMoveEmail(email);
        setActiveTooltip(null)
    };

    const handleMoveEnd = (targetEmail) => {
        if (!moveEmail) return;

        const moveIndex = emails.findIndex(email => email.emailId === moveEmail.emailId);
        const targetIndex = emails.findIndex(email => email.emailId === targetEmail.emailId);

        const updatedEmails = [...emails];
        [updatedEmails[moveIndex], updatedEmails[targetIndex]] = [updatedEmails[targetIndex], updatedEmails[moveIndex]];

        setEmails(updatedEmails);
        setEmailData(updatedEmails);
        setMoveEmail(null);
    };
    const handleCancelMove = () => {
        setMoveEmail(null);
    }
    const [editMode, setEditMode] = useState(false)
    const handleEditMode = () => [

    ]
    return (
        <div>
            <Modal
                size="xl"
                show={EmailChannelDataModel}
                onHide={() => {
                    closeEmailChannelDataModel()
                    setEmailEdit(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                className="custom-emailChannel-modal"
            >
                <Modal.Header closeButton>
                    <nav className='emailChannel-tab'>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setActiveTab('Setup Drip Email')}>Drip Email</button>
                            {channelData.name === 'email' && <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setActiveTab('Recipient List')}>Recipient List ( {recipientCount} )</button>}
                        </div>
                        {editMode && <button className='email-builder-close' onClick={() => {
                            setEditMode(false)
                        }}>Close Builder</button>}
                    </nav>
                </Modal.Header>
                <Modal.Body>
                    {editMode ?
                        <MyEmailTemplateEditor journey_id={selectedEmail.emailId} setEmailData={setEmailData} emailData={emailData} setEmails={setEmails} emails={emails} programId={programId} handleOnlyPreview={handleOnlyPreview} getEmails={getEmails} cardName={channelData.name} />
                        :
                        <>
                            <div className='' style={{ display: 'flex', width: '100%' }}>
                                <div className='' style={{ width: '80%', background: '#E6F4F5', padding: '10px 30px' }}>
                                    <b>{activeTab}</b>
                                </div>
                                <div className='' style={{ width: '10%', }}>
                                    {activeTab === 'Recipient List' &&
                                        <>
                                            {previewShow === false
                                                ? <button onClick={() => {
                                                    handlePreview('')
                                                    setPreviewShow(!previewShow)
                                                }} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Preview List</button> : <button onClick={() => setPreviewShow(!previewShow)} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Close</button>
                                            }
                                        </>
                                    }
                                </div>
                                <div className='model-btn-top' style={{ width: '10%' }}>
                                    <button onClick={handleSave} style={{ marginLeft: '10px', padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', width: '100%', fontFamily: 'Poppins' }}>Save & Close</button>
                                </div>
                            </div>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    {emailLoading ?
                                        <BuilderLoader />
                                        :
                                        <>
                                            {emails.length > 0 &&
                                                (
                                                    <div className='row'>
                                                        <div className='col-sm-4'>
                                                            <div className='email-flow-div'>
                                                                <div className='flow-header d-flex'>
                                                                    <div className='header-text'>
                                                                        <h6>Flow Preview</h6>
                                                                    </div>
                                                                    <div className='header-buttons'>
                                                                        <button>Edit Flow</button>
                                                                        <button className='add-new-email' onClick={handleAddNewEmail}>Add New</button>
                                                                    </div>
                                                                </div>
                                                                <div className='email-flow mt-4'>
                                                                    {emails?.length > 0 && emails.map((template, index) => (
                                                                        <React.Fragment key={template.emailId}>
                                                                            <div
                                                                                className={`email-flow-card ${selectedEmail && selectedEmail.emailId === template.emailId ? 'selected' : ''}`}
                                                                                onClick={() => handleEmailClick(template)}
                                                                            >
                                                                                {moveEmail && moveEmail.emailId !== template.emailId ? (
                                                                                    <div className="move-here" onClick={() => handleMoveEnd(template)}>
                                                                                        <CgMathPlus />
                                                                                    </div>
                                                                                )
                                                                                    :
                                                                                    <>
                                                                                        <div className='row'>
                                                                                            <div className='col-sm-2'>
                                                                                                <div className='icon-email'>
                                                                                                    <svg width="23" height="14" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path
                                                                                                            d="M26.46 0.00616786C26.546 -0.00205595 26.6325 -0.00205595 26.7185 0.00616786L15.1523 9.94078C14.8042 10.1496 14.4059 10.2599 14 10.2599C13.5941 10.2599 13.1958 10.1496 12.8477 9.94078L1.28154 0.00616786C1.3675 -0.00205595 1.45404 -0.00205595 1.54 0.00616786H26.46ZM28 15.7185C27.9993 15.9195 27.9588 16.1184 27.8809 16.3037C27.803 16.489 27.6892 16.6571 27.5461 16.7983C27.4029 16.9394 27.2333 17.0508 27.0469 17.1261C26.8605 17.2014 26.661 17.2391 26.46 17.2369H1.54C1.33898 17.2391 1.13953 17.2014 0.953132 17.1261C0.766735 17.0508 0.597076 16.9394 0.45393 16.7983C0.310784 16.6571 0.196979 16.489 0.119073 16.3037C0.0411672 16.1184 0.00069904 15.9195 0 15.7185V1.52463C0.00133992 1.24503 0.0795797 0.971193 0.226154 0.733091L12.0723 10.8831L12.1423 10.9369C12.6907 11.2816 13.3253 11.4644 13.9731 11.4644C14.6208 11.4644 15.2554 11.2816 15.8038 10.9369L15.8738 10.8831L27.72 0.733091C27.8856 0.965512 27.9826 1.23977 28 1.52463V15.7185Z"
                                                                                                            fill={selectedEmail && selectedEmail.emailId === template.emailId ? 'white' : '#028897'}
                                                                                                        />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col-sm-8'>
                                                                                                <div className='email-info'>
                                                                                                    <p>Send Email {template.no}</p>
                                                                                                    <p className="template-name" style={{ cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top" title={template.templateName} >{template.templateName}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col-sm-2 tooltip-container-flow' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                {moveEmail && moveEmail.emailId === template.emailId ?
                                                                                                    <div className='close-move' onClick={handleCancelMove}><RxCross2 /></div>
                                                                                                    : <button className='email-more' onClick={() => toggleTooltip(template.emailId)}>
                                                                                                        <MdMoreVert />
                                                                                                    </button>}


                                                                                                {activeTooltip === template.emailId && (
                                                                                                    <div className='tooltip-options'>
                                                                                                        <ul>
                                                                                                            <li onClick={() => handleClone(template)}><FaRegClone /> Clone</li>
                                                                                                            <li onClick={() => handleMoveStart(template)}><GrClone /> Move</li>
                                                                                                            <li onClick={() => handleDelete(template.emailId)} style={{ color: 'red' }}><RiDeleteBin6Line /> Delete</li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </div>



                                                                            {index !== emails.length - 1 && (
                                                                                <>
                                                                                    <div className='connector-line'>
                                                                                        <img src={connector} alt='connector' />
                                                                                    </div>
                                                                                    <div className='remove-div'>
                                                                                        <div className='remove-card'>
                                                                                            <h6>Remove Registered from flow</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='connector-line'>
                                                                                        <img src={connector} alt='connector' />
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-3 email-sub'>
                                                            <div className='email-sub-card'>
                                                                <div className='sub-header'>
                                                                    <svg width="23" height="14" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M26.46 0.00616786C26.546 -0.00205595 26.6325 -0.00205595 26.7185 0.00616786L15.1523 9.94078C14.8042 10.1496 14.4059 10.2599 14 10.2599C13.5941 10.2599 13.1958 10.1496 12.8477 9.94078L1.28154 0.00616786C1.3675 -0.00205595 1.45404 -0.00205595 1.54 0.00616786H26.46ZM28 15.7185C27.9993 15.9195 27.9588 16.1184 27.8809 16.3037C27.803 16.489 27.6892 16.6571 27.5461 16.7983C27.4029 16.9394 27.2333 17.0508 27.0469 17.1261C26.8605 17.2014 26.661 17.2391 26.46 17.2369H1.54C1.33898 17.2391 1.13953 17.2014 0.953132 17.1261C0.766735 17.0508 0.597076 16.9394 0.45393 16.7983C0.310784 16.6571 0.196979 16.489 0.119073 16.3037C0.0411672 16.1184 0.00069904 15.9195 0 15.7185V1.52463C0.00133992 1.24503 0.0795797 0.971193 0.226154 0.733091L12.0723 10.8831L12.1423 10.9369C12.6907 11.2816 13.3253 11.4644 13.9731 11.4644C14.6208 11.4644 15.2554 11.2816 15.8038 10.9369L15.8738 10.8831L27.72 0.733091C27.8856 0.965512 27.9826 1.23977 28 1.52463V15.7185Z" fill='#028897' />
                                                                    </svg>
                                                                    <h6
                                                                        className=""
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            whiteSpace: 'nowrap',  // Prevents text from wrapping to the next line
                                                                            overflow: 'hidden',    // Ensures content is clipped
                                                                            textOverflow: 'ellipsis', // Adds ellipsis (...) when text overflows
                                                                            maxWidth: '200px',     // Adjust the width as needed
                                                                        }}
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        title={selectedEmail.templateName}  // Tooltip with the full name
                                                                    >
                                                                        {selectedEmail.templateName}
                                                                    </h6>
                                                                </div>
                                                                <div className='sub-body'>
                                                                    <p>Subject Line</p>
                                                                    <button
                                                                        className="sub-score"
                                                                        style={{
                                                                            backgroundColor: selectedEmail.subjectScore >= 90 ? '#29A148' :
                                                                                selectedEmail.subjectScore >= 80 && selectedEmail.subjectScore <= 89 ? 'orange' :
                                                                                    'red',
                                                                            color: 'white',  // You can change text color to make it visible
                                                                        }}
                                                                    >
                                                                        Score : {selectedEmail.subjectScore}
                                                                    </button>
                                                                    <h1></h1>
                                                                    <div className='input'>
                                                                        <div className='row'>
                                                                            <div className='col-sm-10'>
                                                                                <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
                                                                                    <Form.Control type="text" placeholder="Subject Line" className='subject-line' value={emailSubjects[selectedEmail.emailId] || ''} onChange={handleSubjectChange} disabled={subDisabeled} />
                                                                                </Form.Group>
                                                                            </div>
                                                                            <div className='col-sm-2 edit-sub-div'>
                                                                                <FaRegEdit className='edit-sub' onClick={() => setSubDisabled(false)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className='mb-3'>
                                                                            <p>Select Sender</p>
                                                                            <select className="mb-3 form-select">
                                                                                {senderList.map((sender, index) => (
                                                                                    <option key={index} value={sender.trim()}>
                                                                                        {sender.trim()}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <Form.Group className="mb-3" controlId="formGridAddress1">
                                                                            <Form.Label>Send Date</Form.Label>
                                                                            {channelData.builder !== "registerBuilder" && channelData.builder !== "attendBuilder" &&
                                                                                <Form.Control
                                                                                    type="datetime-local"
                                                                                    placeholder="Pick a Date"
                                                                                    value={selectedEmail.sendDate}
                                                                                    onChange={(e) => handleSendDateChange(e.target.value)}
                                                                                    min={new Date().toISOString().slice(0, 16)}
                                                                                />}
                                                                            {(channelData.builder === "registerBuilder" || channelData.builder === "attendBuilder") && <p style={{ color: '#028897', fontSize: '10px' }}>{selectedEmail.dateLogic + " " + selectedEmail.sendDate}</p>}
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formGroupEmail">
                                                                            <Form.Label>Button Text</Form.Label>
                                                                            <Form.Select
                                                                                ref={ctaRef}
                                                                                aria-label="Default select example"
                                                                                value={
                                                                                    selectedEmail.customCTA === ""
                                                                                        ? ""
                                                                                        : ["Book a Seat", "Register Now", "Enrol Now", "Last Chance"].includes(selectedEmail.customCTA)
                                                                                            ? selectedEmail.customCTA
                                                                                            : "Other"
                                                                                }
                                                                                onChange={(e) => {
                                                                                    setCtaValue(e.target.value);
                                                                                    handleChangeCTA(e.target.value);
                                                                                }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                <option value="Book a Seat">Book a Seat</option>
                                                                                <option value="Register Now">Register Now</option>
                                                                                <option value="Enrol Now">Enrol Now</option>
                                                                                <option value="Last Chance">Last Chance</option>
                                                                                <option value="Other">Other</option>
                                                                            </Form.Select>
                                                                        </Form.Group>


                                                                        {showInput && (
                                                                            <div>
                                                                                <Form.Control type="text" className='mb-2' placeholder="Enter CTA" value={ctaValue}
                                                                                    onChange={(e) => setCtaValue(e.target.value)} />
                                                                                <Button onClick={handleSubmit}>Submit</Button>
                                                                            </div>
                                                                        )}
                                                                        <button
                                                                            className='btn btn-primary mt-2 edit-preview'
                                                                            onClick={() => {
                                                                                handleEditEmailTemplate(selectedEmail.emailId)
                                                                            }}
                                                                        >
                                                                            Preview/Edit
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-5 email-builder-show'>
                                                            {emailEdit && (
                                                                <>
                                                                    <button onClick={() => setEmailEdit(!emailEdit)} className='email-builder-close'>Close</button>
                                                                    <button onClick={() => setEditMode(true)} className='email-builder-close'>Edit</button>
                                                                    {/* <MyEmailTemplateEditor journey_id={selectedEmail.emailId} programId={programId} /> */}
                                                                    <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    }
                                </div>
                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    {previewShow ?
                                        <>
                                            {error ? (
                                                <p>No data found</p>
                                            ) : (
                                                <div style={{ height: 450, width: '100%' }}>

                                                    <div className="search-wrapper mb-2" style={{ width: '100%', display: 'flex' }}>
                                                        <div className=''>
                                                            <input
                                                                type="text"
                                                                className="grid-search"
                                                                placeholder="search "
                                                                value={searchText}
                                                                onChange={handleSearch}
                                                            />
                                                        </div>
                                                        <div className=''>
                                                            <Select
                                                                style={{ width: 400 }}
                                                                placeholder="Select type"
                                                                options={options}  // Provide transformed options
                                                                onChange={(option) => handlePreview(option.value)}  // Use onChange to get the selected value
                                                            />
                                                        </div>
                                                    </div>
                                                    <DataGridTable
                                                        rows={rows}
                                                        columns={columns}
                                                    />
                                                </div>
                                            )}
                                        </> :
                                        <>
                                            {failed ?
                                                <div className='mt-4'>
                                                    <button onClick={() => setFailed(false)} style={{ padding: '10px 15px', background: '#018a99', color: 'white', borderRadius: '4px', fontFamily: 'Poppins' }}><MdCancel /></button>
                                                    <FailedList rows={failedRows} columns={failedColumns} />
                                                </div>
                                                :
                                                <div className='row mt-4'>
                                                    <div className='col-sm-4'>
                                                        <div className='upload-csv'>
                                                            <div className='csv-header'>
                                                                <div className='row'>
                                                                    <div className='col-sm-7 pt-1'>
                                                                        <h6>Upload CSV</h6>
                                                                    </div>
                                                                    <div className='col-sm-5'>
                                                                        <button className='csv-download' onClick={handleDownload}>Download CSV Format</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`csv-body ${dragActive ? 'drag-active' : ''}`}
                                                                onDragOver={handleDragOver}
                                                                onDragEnter={handleDragEnter}
                                                                onDragLeave={handleDragLeave}
                                                                onDrop={handleDrop}
                                                            >
                                                                <div className='upload-div'>
                                                                    <img src={uploadCsv} alt='upload-csv' />
                                                                    <p className='drop-upload'>Drop Your Files Here</p>
                                                                    <p>or</p>
                                                                    <input
                                                                        type="file"
                                                                        ref={fileInputRef}
                                                                        onChange={onInputChange}
                                                                        accept=".csv"
                                                                        style={{ display: 'none' }}
                                                                        id="file-upload"
                                                                    />

                                                                    <label htmlFor="file-upload" className="upload-button">
                                                                        Upload File
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            {pro > 0 &&
                                                                <div className='csv-footer'>
                                                                    <p>Uploaded File</p>
                                                                    <div className='csv-card'>
                                                                        <div className='csv-icon'>
                                                                            <img src={csvIcon} alt='csv-icon' />
                                                                        </div>
                                                                        <div className='csv-progress'>
                                                                            <p>{fileName}</p>

                                                                            <ProgressBar now={pro} label={`${pro}%`} className='mt-3' />

                                                                        </div>
                                                                        <div className='csv-cancel'>
                                                                            <img src={csvCancel} alt='csv-icon' onClick={() => setPro(0)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>

                                                        {success === 1 && successType === 'csv' && (
                                                            <div className="success-card">
                                                                <h6>Contacts Uploaded !</h6>
                                                                <ul>
                                                                    <li><strong>Created : </strong> {successMsg.uploaded}</li>
                                                                    <li><strong>Updated : </strong> {successMsg.existingUpdated}</li>
                                                                    <li><strong>Failed : </strong> {successMsg.failed} <button onClick={() => handleViewFailedList('csv')} style={{ color: 'red' }}>View Failed List</button></li>
                                                                    <li><strong>Existing - Segment: </strong> {successMsg.existingLeadSegment}</li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {success === 2 && successType === 'csv' && (
                                                            <div className="error-card">
                                                                <h6>Failed  to Upload</h6>
                                                                <p>{errorMsg}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <div className='crm-div'>
                                                            <div className='crm-header'>
                                                                <h6>Connect to Your CRM/Data Provider</h6>
                                                                <p>Select your CRM/Data Provider</p>
                                                            </div>
                                                            <div className='crm-body'>
                                                                <Tabs
                                                                    defaultActiveKey="CRM"
                                                                    id="justify-tab-example"
                                                                    className="mb-3"
                                                                    justify
                                                                >
                                                                    <Tab eventKey="CRM" title="CRM">
                                                                        <div className='row crm-card-main'>
                                                                            {crms.length > 0 && crms.map((crm, index) => (
                                                                                <div className='col-sm-6 mt-2' key={index}>
                                                                                    <div
                                                                                        className={`crm-card ${selectedCrm && selectedCrm.id === crm._id ? 'selected' : ''}`}
                                                                                        onClick={() => handleSelect(crm._id, crm.alias)}
                                                                                    >
                                                                                        <p>{crm.name}</p>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </Tab>

                                                                    <Tab eventKey="Data Provider" title="Data Provider">
                                                                        Tab content for Profile
                                                                    </Tab>
                                                                </Tabs>
                                                            </div>
                                                            <div className='crm-footer'>
                                                                <button
                                                                    className='crm-import'
                                                                    onClick={handleImportCrm}
                                                                    disabled={crmLoading || !selectedCrm}
                                                                >
                                                                    {crmLoading ? (
                                                                        <>
                                                                            <span className='spinner-border-crm spinner-border-sm mr-2' role='status' aria-hidden='true'></span>
                                                                            &nbsp;Importing Data...
                                                                        </>
                                                                    ) : (
                                                                        'Import Data'
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {success === 1 && successType === 'crm' && (
                                                            <div className="success-card">
                                                                <h6>Contacts Uploaded !</h6>
                                                                <ul>
                                                                    <li><strong>Created : </strong> {successMsg.uploaded}</li>
                                                                    <li><strong>Updated : </strong> {successMsg.existingUpdated}</li>
                                                                    <li><strong>Failed : </strong> {successMsg.failed} <button onClick={() => handleViewFailedList('crm')} style={{ color: 'red' }}>View Failed List</button></li>
                                                                    <li><strong>Existing - Segment: </strong> {successMsg.existingLeadSegment}</li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {success === 2 && successType === 'crm' && (
                                                            <div className="error-card">
                                                                <h6>Failed  to Upload</h6>
                                                                <p>{errorMsg}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <div className='crm-div'>
                                                            <div className='crm-header'>
                                                                <h6>Existing Master Database</h6>
                                                                <p>Choose from the master database</p>
                                                            </div>
                                                            <div className='crm-body'>
                                                                <textarea
                                                                    value={prompt}
                                                                    onChange={handleInputChange}
                                                                    className='n-textarea'
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className='crm-footer'>
                                                                <button
                                                                    className='crm-import'
                                                                    onClick={handleSearchNQL}
                                                                >
                                                                    {searchLoading ? (
                                                                        <>
                                                                            <span className='spinner-border-crm spinner-border-sm mr-2' role='status' aria-hidden='true'></span>
                                                                            &nbsp;Searching....
                                                                        </>
                                                                    ) : (
                                                                        'Search'
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <p>{query}</p>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }

                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EmailChannelModel;
