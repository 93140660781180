import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BiArrowBack } from 'react-icons/bi';
import axios from 'axios';
import { IoSettings } from 'react-icons/io5';
import { useGlobal } from 'context/GlobalProvider';
import { showToast } from 'screens/LeadGeneration/Helpers/ToastHelper';
import CommonTable from 'components/CommonTable';
import NameCell from 'screens/Journey/journeyModels/NameCell';
import { Active, No, Yes } from 'screens/Journey/journeyModels/DynamicButtons';

const CalendlyModal = ({ programId }) => {
    const columns = [
        { field: 'id', headerName: 'S.No', flex: 1 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerClassName: 'custom-header',
            renderCell: (params) => <NameCell name={params.value} /> // Use the NameCell component
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerClassName: 'custom-header',
            renderCell: (params) => {
                switch (params.value) {
                    case 'active':
                      return <Active />;
                    case 'inactive':
                      return <p className='paused-tag'>InActive</p>;
                    default:
                      return null;
                  }
            }
        },
        { field: 'cta', headerName: 'CTA', flex: 1, headerClassName: 'custom-header' },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            headerClassName: 'custom-header',
            renderCell: (params) => {
              return <button className='btn btn-success btn-sm cfs-5 text-light' onClick={()=>handleFormMap(params.row)}><IoSettings></IoSettings> Map leads</button>;
            }
        },
        {
            field: 'subscribed', headerName: 'Subscribed', flex: 1, headerClassName: 'custom-header',
            renderCell:(params)=>{
                return (
                    params.value ? <Yes /> : <No />
                );
            }
        }

        // { field: 'type', headerName: 'Type', flex: 1, headerClassName: 'custom-header' }
    ];
    const [tableData,settableData]=useState([]);
    const {LeadJourneyTabWiseData,toggleModal,modalState,cta_links} = useGlobal();
    const [SelectedFormId,setSelectedFormId]=useState(null);
    const [SelectedSegment,setSelectedSegment]=useState();
    
    const [LeadFields,setLeadFields]=useState();
    const [Formdata,setFormdata]=useState({});
    
    const [mappedCount,setmappedCount]=useState(null);
    const [formValues, setFormValues] = useState({});

    const [user,setuser]=useState({});
    const handleFormMap=(form)=>{
        setSelectedFormId(form._id);
    }
    const handleLeadformClose = () => {
        toggleModal('lead_calendly_form')
        setSelectedFormId(null)
    }
    useEffect(()=>{
        const fetchUser= async () => {
            try{
                const userRes= await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/calendly/user`,{credentials:'include'});
                const user= await userRes.json();
                setuser(user);
            } catch(error){
                console.log(error.message);
            }
            
        }
        const fetchSegmentData = async () => {
            try {
                const cards=LeadJourneyTabWiseData?.GetLeadTab?.cards;
                if(cards){
                    const cardData=cards.flatMap(group => group).find(card => card.channel === 'lead_list_label'); 
                    setSelectedSegment(cardData.cardId);

                    const leadfields = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/getColumns`, {
                        withCredentials: true
                    });
                    setLeadFields(leadfields.data);
                }

            } catch (err) {
                console.log(err.message);
            };
        };
        fetchSegmentData();
        fetchUser();
    },[LeadJourneyTabWiseData?.GetLeadTab?.cards])
    useEffect(() => {
        const fetchEvent_types = async () => {
            const cta = [
                ...new Set(
                    Object.values(cta_links)
                        .flat()
                        .map(item => (typeof item === 'string' ? item : item.url))
                        .filter(Boolean) // Removes falsy values like undefined/null
                )
            ];
            try {    
                const Event_typedata = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/calendly/event_types`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        eventUri:cta,
                        userUri:user?.resource?.uri
                    })
                });
                if (!Event_typedata.ok) {
                    showToast('Add calendly Form to atleast one campaign','warning');
                    return;
                }
                const event_types = await Event_typedata.json();
                if (event_types?.length > 0) {
                    const formattedData = event_types.map((form, index) => {
                        // console.log(form);
                        
                        const subscription = form.subscription.find(sub => sub.selectedSegment === SelectedSegment);
                        const subscribed = subscription && subscription.mappedValues && Object.keys(subscription.mappedValues).length > 0;
                        return({
                            _id: form._id,    
                            id: index,
                            name: form.eventName,
                            status: form.eventStatus,
                            cta: form.CtaLink,
                            subscribed: !!subscribed
                        })
                    });    
                    settableData(formattedData);
                    setFormdata(event_types);
                } else {
                    showToast('Connect atleast one campaign with a form','warning');
                    return;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        // if(selectedCampaigns)
        if(user && modalState.lead_calendly_form)
            fetchEvent_types();
    }, [modalState.lead_calendly_form,user]);
    
    useEffect(() => {
        let mappedfields = [];
        if (SelectedFormId && Formdata) {
            Formdata.filter(form => form._id === SelectedFormId).forEach((form) => {           
                const fields = [{name:'Name',enabled:true},{name:'Email',enabled:true}]; 
                const customfields=form.formContent||[];
                    // console.log(form);
                    fields.forEach((field) => {
                        const isfieldAvailable = getfieldvalue(field.name);
                        mappedfields.push({
                            inputType: field.name,
                            campfield: isfieldAvailable,
                            status: isfieldAvailable ? true : false,
                            label:field.name ? field.name.replace(/_/g, ' ') : '',
                        });
                    });
                    customfields.forEach((field) => {
                        const isfieldAvailable = getfieldvalue(field.name || 'Unspecified');
                        mappedfields.push({
                            inputType: field.name,
                            campfield: isfieldAvailable,
                            status: isfieldAvailable ? true : false,
                            label:field.name ? field.name.replace(/_/g, ' ') : '',
                        });
                        // console.log(field, 'this');
                    });
            });
        }
        const mappedCount = mappedfields.filter(field => field.status).length;
        setFormValues(mappedfields);
        setmappedCount(mappedCount);
    }, [Formdata, SelectedFormId]);

    const handleInputChange = (fieldInputType, value, key) => {
        // console.log(fieldInputType, value);
        setFormValues((prevValues) => {
            const updatedValues = prevValues.map((item, index) =>
                index === key
                    ? { ...item, campfield: value, fieldInputType,status:true }
                    : item
            );
            const mappedCount = updatedValues.filter(field => field.status).length;
            setmappedCount(mappedCount);
            console.log(mappedCount);
            
            return updatedValues;
        });
    };
    // console.log(SelectedSegment,'segmentid');

    const handlesubscribe = async()=>{
        const subscriptionData = {
            selectedFormId: SelectedFormId,
            selectedSegment: SelectedSegment,
            mappedValues: formValues,
            programId:programId
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/calendly/subscribe`, {
                method:'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(subscriptionData)
            });
            if(response.ok){
                const selectedData={
                    builder:'reachBuilder',
                    programId:programId, 
                    cardName:'lead_calendly_form',
                    cardType:'form',
                };
                await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedCardbytype`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(selectedData),
                });
            }
            else{
                showToast('Unable to subscribe!');
            }
            handleLeadformClose();
            // console.log('Subscription successful:', response.data);
        } catch (error) {
            console.error('Error subscribing:', error.response ? error.response.data : error.message);
        }
    }
    const searchLeadFields = (inputType) => {
        const matchedField = LeadFields.data.find((field) => field.alias === inputType);
        return matchedField ? matchedField._id : '';
    };
    const getfieldvalue = (inputType) => {
        const normalizedInput = inputType.replace(/[_\s]/g, '').toLowerCase();
        switch (normalizedInput) {
            case 'name':
                return searchLeadFields('firstname') || '';
            case 'email':
                return searchLeadFields('email') || '';
            case 'phone':
                return searchLeadFields('phone') || '';
            case 'postalcode':
            case 'postcode':
            case 'zipcode':
                return searchLeadFields('zipcode') || '';
            case 'streetaddress':
                return searchLeadFields('address1') || '';
            case 'city':
                return searchLeadFields('city') || '';
            case 'region':
            case 'state':
                return searchLeadFields('state') || '';
            case 'country':
                return searchLeadFields('country') || '';
            case 'companyname':
                return searchLeadFields('company') || '';
            case 'gender':
                return searchLeadFields('gender') || '';
            default:
                return '';
        }
    };
    return (
        <Modal
            show={modalState.lead_calendly_form}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={handleLeadformClose}
            className="custom-leadData-modal"
        >
            {SelectedFormId ?
                <>
                    <Modal.Header className='justify-content-between align-items-start'>
                        <Modal.Title id="contained-modal-title-vcenter" className='ad-details'>
                            <p className='ad-details-p'>Calendly Accounts Details</p>
                            <div className='ad-profile-card'>
                                <div className='ad-profile-avatar'>
                                    <img src={user?.resource?.avatar_url} alt='profile pic' />
                                </div>
                                <div className='ad-profile-details'>
                                    <p><span>{user?.resource?.name}</span>&nbsp;</p>
                                </div>
                            </div>
                        </Modal.Title>
                        <div>
                            <button className='btn btn-link fw-bold' onClick={()=>{setSelectedFormId(null);}}><BiArrowBack></BiArrowBack> Go back</button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className='list-head'>Subscribe form</h5>
                        <div style={{ width: '100%', height: '320px' }}>
                            <div  style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                                <h4 className='text-primary'>Lead Map</h4>
                                <div className='row align-items-center mt-3'>
                                    <div className='col-md-2'>
                                        <p className='text-dark'></p>
                                    </div>
                                    <div className='col-md-6'>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className='d-flex'><p>out of {formValues.length} fields {mappedCount} are mapped.</p>
                                        {formValues.length-mappedCount>0?(<p className='m-0'>{formValues.length-mappedCount} is pending.</p>):null}
                                        </span>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-6'>Channel Form fields</div>
                                    <div className='col-md-6'>Camp Fields</div>
                                </div>
                                {formValues.map((field, idx) => (
                                    <div className='row mt-3' key={idx}>
                                        <div className='col-md-6'>
                                            <input
                                            className='form-control'
                                            placeholder={field.inputType||field.customquestion}
                                            readOnly
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <select
                                            className={`form-select ${field.campfield?'border-green':'border-red'}`}
                                            defaultValue={getfieldvalue(field.inputType||field.customquestion)}
                                            disabled={field.inputType==="EMAIL"?true:false}
                                            onChange={(e) => handleInputChange(field.inputType, e.target.value,idx)}
                                            key={idx}
                                            >
                                            <option value="" disabled>
                                                Select an option...
                                            </option>
                                            {LeadFields.data.map((leadfield,index) => {
                                                const maxLength = 90;
                                                const truncatedName = leadfield.label.length > maxLength 
                                                ? leadfield.label.substring(0, maxLength) + '...' 
                                                : leadfield.label;
                                                return (
                                                <option key={index} value={leadfield._id}>
                                                    {truncatedName}
                                                </option>
                                                );
                                            })}
                                            </select>
                                        </div>
                                    </div>
                                ))}
                                <h6 className='mt-3'>Note: If you are unable to find fields to map, please setup the custom fields from settings</h6>
                            </div>
                            <button className='btn btn-success text-light text-end float-end' onClick={handlesubscribe}>
                            Subscribe
                            </button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                        </div>
                    </Modal.Footer>
                </>
                :
                <>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" className='ad-details'>
                            <div className='ad-profile-card'>
                                <div className='ad-profile-avatar'>
                                    <img src={user?.resource?.avatar_url} alt='profile pic' />
                                </div>
                                <div className='ad-profile-details'>
                                    <p><span>{user?.resource?.name}</span>&nbsp;</p>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className='list-head'>List of Forms</h5>
                        <div style={{ width: '100%' }}>
                            <div style={{ width: '100%', height: '320px' }}>
                                <CommonTable data={tableData} columns={columns}></CommonTable>
                            </div>
                        </div>
                    </Modal.Body>
                </>
            }

        </Modal>
    );
};
export default CalendlyModal;
