import React, { useState, useEffect } from "react";
import NavbarComp from "../../components/NavbarComp";
import { useNavigate } from "react-router-dom";
import "../../components/CSS/Style.css";
import axios from "axios";

const Brand = ({settingType}) => {
    const navigate = useNavigate();
    const [fields, setFields] = useState({
        selectedColors: [],
        selectedFonts: [],
        fullLogo: null,
        favicon: null,
        whiteLogo: null,
    });  
    
    const handleAddMore = () => {
        setFields([...fields, { linkName: '', linkLogo: '', linkUrl: '' }]);
    };

    const handleRemove = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };

    const handleChange = (index, e) => {
        const newFields = [...fields];
        newFields[index][e.target.name] = e.target.value;
        setFields(newFields);
    };  
    
    const handleColorRemove = (color) => {
        setFields((prevData) => ({
            ...prevData,
            selectedColors: prevData.selectedColors.filter((c) => c !== color),
        }));
    };

    // Font Selection Handlers
    const handleFontSelect = (font) => {
        setFields((prevData) => {
            const updatedFonts = prevData.selectedFonts.includes(font)
                ? prevData.selectedFonts.filter((f) => f !== font)
                : [...prevData.selectedFonts, font];

            return {
                ...prevData,
                selectedFonts: updatedFonts.slice(0, 4), // Limit to 4 fonts
            };
        });
    };

    const handleFontRemove = (font) => {
        setFields((prevData) => ({
            ...prevData,
            selectedFonts: prevData.selectedFonts.filter((f) => f !== font),
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFields({ ...fields, [name]: files[0] });
    };

    const handleSubmit = async () => {
        try {
            // Prepare the data to be sent to the API
            const barndArray = {
                selectedColors: fields.selectedColors,
                selectedFonts: fields.selectedFonts,
                fullLogo: fields.fullLogo,
                favicon: fields.favicon,
                whiteLogo: fields.whiteLogo,
            };
            const details = [barndArray];
            console.log(details);
            // Send the POST request
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/othersetting`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    details,
                    alias: settingType, // Pass the email form data to the API
                }),
            });

            const data = await response.json();

            // Check if data is successfully stored
            if (data._id) {
                alert('Data stored successfully!');
            } else {
                alert('Something went wrong!');
            }
        } catch (error) {
            alert('Error storing data!');
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {  
        // Function to fetch data from the API
        const fetchProgramDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/othersetting?platformAlias=${settingType}`, {
                    method: 'get',
                    credentials: 'include'
                });
                const data = await response.json();
                const detailsData = data.map((item) => item.details).flat();
                console.log(detailsData[0]);
                setFields(detailsData[0]); // Store the fetched data in state

            } catch (error) {
                console.error('Error fetching program details:', error);
            }
        };

        // Call the fetch function
        fetchProgramDetails();
    }, [settingType]);

    return (
        <div
            className="overflow-hidden position-relative"
            style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '91vh'
            }}>
            <div className="container">
                <div className="row justify-content-center" style={{ paddingTop: '20px' }}>
                    <h3>Brand</h3>
                    <br />
                    <br />
                    <>
                        {/* Color Palette Selection */}
                        <div>
                            <label>Select up to 4 Colors:</label>
                                {/* Input to Add New Color */}
                                {fields.selectedColors.length < 4 && (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        {/* Color Picker */}
                                        <label style={{ display: 'block', marginBottom: '5px' }}>Click the box to select a color:</label>
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="color"
                                                onChange={(e) => {
                                                    const selectedColor = e.target.value;
                                                    if (!fields.selectedColors.includes(selectedColor)) {
                                                        setFields((prevData) => ({
                                                            ...prevData,
                                                            selectedColors: [...prevData.selectedColors, selectedColor],
                                                        }));
                                                    }
                                                }}
                                                style={{
                                                    width: '50px',
                                                    height: '30px',
                                                    padding: '0',
                                                    border: '1px solid #ccc',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', margin: '10px 0' }}>
                                {/* Display Selected Colors */}
                                {fields.selectedColors.map((color, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '30px',
                                                height: '30px',
                                                backgroundColor: color,
                                                border: '1px solid #ccc',
                                            }}
                                        ></div>
                                        <button
                                            onClick={() => handleColorRemove(color)}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'red',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                            }}
                                        >
                                            ✖
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Font Palette Selection */}
                        <div>
                            <label>Select up to 4 Fonts:</label>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', margin: '10px 0' }}>
                                {fields.selectedFonts.map((font, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                        }}
                                    >
                                        <span style={{ fontFamily: font }}>{font}</span>
                                        <button
                                            onClick={() => handleFontRemove(font)}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'red',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                            }}
                                        >
                                            ✖
                                        </button>
                                    </div>
                                ))}
                            </div>
                            {fields.selectedFonts.length < 4 && (
                                <select
                                    onChange={(e) => {
                                        const newFont = e.target.value;
                                        if (!fields.selectedFonts.includes(newFont)) {
                                            handleFontSelect(newFont);
                                        }
                                    }}
                                    style={{ width: '200px', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                >
                                    <option value="">-- Select Font --</option>
                                    <option value="Arial">Arial</option>
                                    <option value="Verdana">Verdana</option>
                                    <option value="Helvetica">Helvetica</option>
                                    <option value="Times New Roman">Times New Roman</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Courier New">Courier New</option>
                                    <option value="Lucida Console">Lucida Console</option>
                                    <option value="Tahoma">Tahoma</option>
                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                    <option value="Impact">Impact</option>
                                    <option value="Comic Sans MS">Comic Sans MS</option>
                                    <option value="Palatino">Palatino</option>
                                    <option value="Garamond">Garamond</option>
                                    <option value="Bookman">Bookman</option>
                                    <option value="Arial Black">Arial Black</option>
                                    <option value="Avant Garde">Avant Garde</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Brush Script MT">Brush Script MT</option>
                                </select>
                            )}
                        </div>

                        {/* File Uploads */}
                        <div>
                            <label>Upload Full Logo:</label>
                            <input
                                type="file"
                                name="fullLogo"
                                onChange={handleFileChange}
                                style={{ display: 'block', margin: '10px 0' }}
                            />
                        </div>

                        <div>
                            <label>Upload Favicon:</label>
                            <input
                                type="file"
                                name="favicon"
                                onChange={handleFileChange}
                                style={{ display: 'block', margin: '10px 0' }}
                            />
                        </div>

                        <div>
                            <label>Upload White Logo:</label>
                            <input
                                type="file"
                                name="whiteLogo"
                                onChange={handleFileChange}
                                style={{ display: 'block', margin: '10px 0' }}
                            />
                        </div>

                        {/* Action Buttons */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <button
                                onClick={handleSubmit}
                                style={{ padding: '10px 20px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px' }}
                            >
                                Submit
                            </button>
                        </div>
                    </>
                </div>
            </div>
            <div className="position-absolute" style={{ bottom: '60px', left: '32px' }}>

            </div>
        </div>
    );
};

export default Brand;
