//src\components\HeaderComp.jsx
import React from "react";
import { useGlobal } from "../context/GlobalProvider";
const HeaderComp = ()=>{
    const {Header}=useGlobal();
    return(
    <div className="container-fluid ps-4 pt-2">
        <div className="row">
            <div className="col-md-6">
                <div style={{fontSize:'14px'}}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Attract event audience</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Basic information</li>
                        </ol>
                    </nav>
                </div>
                <div>
                    <h5 className="fw-bold">{Header}</h5>
                </div>
            </div>
        </div>
    </div>
    );
}
export default HeaderComp;