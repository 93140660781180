import axios from 'axios';
import { useGlobal } from 'context/GlobalProvider';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import Select from 'react-select';
import FilterBuilder from './filterBuilder';

function QualifyFilters({programId}) {
    const {toggleModal,modalState,getCardByName,channelData} = useGlobal();
    const [campFields,setcampFields]=useState([]);
    const [refresh,setrefresh]=useState(false);
    // const [Filters,setFilters]=useState({segmentId:null,logicalOperator:{value:'and',label:'And'},filter:[{value:'new',operator:{ label: 'Equals', value: 'eq' } ,label:'First Name'}]});
    const [Filters,setFilters]=useState({segmentId:null,logicalOperator:{value:'and',label:'And'},Filter:[]});
    const voice_qual_list=getCardByName('voice_qual')||null;
    const [selectValue, setselectValue] = useState(null);
    useEffect(()=>{
        const fetchSegmentData = async () => {
            try {
                setFilters((prev)=>({...prev,segmentId:voice_qual_list.cardId}));        
                const leadfields = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/lead/getleadfields`, {
                    withCredentials: true
                });
                const leadfieldOptions=leadfields.data.map((field)=>({label:field.label,id:field._id,type:field.type,alias:field.alias}));
                setcampFields(leadfieldOptions);
            } catch (err) {
                console.log(err.message);
            };
        };
        if(voice_qual_list?.cardId)
            fetchSegmentData();
    },[voice_qual_list,refresh]);
    useEffect(() => {
        if(modalState.qual_filters && channelData?.selectedData?.Filter?.length>0){
            setFilters(channelData.selectedData);
        }
    }, [modalState.qual_filters]);
    
    const handleAddFilter= (filter)=>{
        setFilters((prev)=>{
            const updatedState={...prev};
            updatedState.Filter.push({...filter,value:''});
            return updatedState;
        })
        setTimeout(() => { setselectValue([])}, 100);
        setselectValue(filter||[]);
    }
    const handleOperatorChange = (selected)=>{
        setFilters((prev)=>({...prev,logicalOperator:selected}));
    }
    const handleSubmit= async(e) =>{
        e.preventDefault();
        const selectedData = {
            programId: programId,
            selectedChannel: channelData.name,
            builder: channelData.builder,
            saveData: Filters
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/leadgen/saveSelectedChannel`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });

            if (response.ok) {
                await response.json();
                toggleModal("qual_filters")
            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };
    return (
        <Modal
        show={modalState.qual_filters}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={()=>toggleModal("qual_filters")}>
            <Modal.Header className='justify-content-between align-items-start' closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='ad-details'>
                    <p className='ad-details-p'>Qualification Filters</p>
                </Modal.Title>
                <Button variant='primary' onClick={()=>{setrefresh(true);setFilters({segmentId:null,logicalOperator:'and',Filter:[]})}}></Button>
            </Modal.Header>
            <Modal.Body>
                <h5 className='list-head'>Select the filters</h5>
                <div className='row' style={{ width: '100%' }}>
                    <div className='col-md-12' style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                    <Form className='row' onSubmit={(e)=>handleSubmit(e)}>
                        <Form.Group className="mb-3 col-md-6" controlId="formWebsiteName">
                            <Form.Label>Field</Form.Label>
                            <Select
                                // defaultValue={}
                                value={selectValue||[]}
                                name="colors"
                                options={campFields}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e)=>handleAddFilter(e)}
                            />
                        </Form.Group>
                        {Filters.Filter.length>1 && (<Form.Group className="mb-3 col-md-6" controlId="formOperator">
                            <Form.Label>Select Operator</Form.Label>
                            <Select
                                // defaultValue={}
                                value={Filters.logicalOperator||[]}
                                name="colors"
                                options={[{value:'and',label:'And'},{value:'or',label:'Or'}]}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleOperatorChange}
                            />
                        </Form.Group>)}
                        <Form.Group className="mb-3" controlId="formWebsiteURL">
                            <FilterBuilder filters={Filters.Filter} setFilters={setFilters}></FilterBuilder>
                        </Form.Group>
                        <Button className='col-md-2' variant="primary" type="submit">
                            Submit
                        </Button> 
                    </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>  
    )
}

export default QualifyFilters