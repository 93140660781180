import React, { createContext, useContext, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import metaIcon from '../components/Assets/meta.svg';
import googleIcon from '../components/Assets/google.svg';
import emailIcon from '../components/Assets/email.svg';
import page from '../components/Assets/page.svg';
import registerpage from '../components/Assets/registerpage.svg';
import registerlist from '../components/Assets/registerlist.svg';
import whatsapp from '../components/Assets/whatsapp.svg';
import attendlist from '../components/Assets/attendlist.svg';
// Create the context   
const GlobalContext = createContext();

// Provider component
export const GlobalProvider = ({ children }) => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      localStorage.removeItem('user');
      await axios.post(`${process.env.REACT_APP_BASE_URL_API}/login/logout`, {}, { withCredentials: true });
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const [channelDatas, setChannelDatas] = useState([
    { channel: "Meta Ads", no_campains: "3 Campains", expected: '150', reach: '100', icon: metaIcon, id: "1", status: 'completed', isEnabled: true },
    { channel: "Google Ads", no_campains: "3 Campains", expected: '150', reach: '200', icon: googleIcon, id: "2", status: 'completed', isEnabled: true },
    { channel: "Email", no_campains: "3 Campains", expected: '150', reach: '200', icon: emailIcon, id: "3", status: 'incompleted', isEnabled: true }
  ]);

  const [registrationDatas, setRegistrationDatas] = useState([
    { channel: "Registration Page", no_campains: "", expected: '150', reach: '200', icon: page, id: "4", status: 'completed', isEnabled: true }
  ]);

  const [formsDatas, setFormsDatas] = useState([
    { channel: "Meta Lead Form", no_campains: "", expected: '150', reach: '100', icon: metaIcon, id: "5", status: 'incompleted', isEnabled: true },
    { channel: "Registration Form", no_campains: "", expected: '150', reach: '100', icon: registerpage, id: "6", status: 'completed', isEnabled: true }
  ]);

  const [registrationListDatas, setRegistrationListDatas] = useState([
    { channel: "Registration List", no_campains: "", expected: '150', reach: '100', icon: registerlist, id: "7", status: 'completed', isEnabled: true }
  ]);

  const [connectionIds, setConnectionIds] = useState([
    { source: '1', target: '5' },
    { source: '2', target: '4' },
    { source: '3', target: '4' },
    { source: '4', target: '6' },
    { source: '5', target: '7' },
    { source: '6', target: '7' }
  ]);

  const [register_registrationListDatas, setRegister_registrationListDatas] = useState([
    {
      channel: "Registration List",
      no_campains: "",
      expected: '150',
      reach: '100',
      icon: registerlist,
      id: "1",
      status: 'completed',
      isEnabled: true
    },

  ]);
  const [register_reminderDatas, setRegister_reminderDatas] = useState([
    {
      channel: "Email Reminder",
      no_campains: "",
      expected: '150',
      reach: '100',
      icon: emailIcon,
      id: "2",
      status: 'completed',
      isEnabled: true
    },
    {
      channel: "Whatsapp Reminder",
      no_campains: "",
      expected: '150',
      reach: '100',
      icon: whatsapp,
      id: "3",
      status: 'completed',
      isEnabled: true
    },
  ]);
  const [register_attendDatas, setRegister_attenDatas] = useState([
    {
      channel: "Attend List",
      no_campains: "",
      expected: '150',
      reach: '100',
      icon: attendlist,
      id: "4",
      status: 'completed',
      isEnabled: true
    },
  ])
  const [register_connectionIds, setRegister_connectionIds] = useState([
    {
      source: '1',
      target: '2',
    },
    {
      source: '1',
      target: '3',
    },
    {
      source: '2',
      target: '4',
    },
    {
      source: '3',
      target: '4',
    },

  ]);


  //attend tab
  const [attend_List, setAttend_List] = useState([
    {
      channel: "Attend List",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "1",
      status: 'completed',
      isEnabled: true
    },
  ]);

  const [attend_Channel, setAttend_Channel] = useState([
    {
      channel: "ThankYou Email",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "2",
      status: 'completed',
      isEnabled: true
    },
    {
      channel: "CRM",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "3",
      status: 'completed',
      isEnabled: true
    },
  ]);

  const [attend_connectionIds, setAttend_connectionIds] = useState([
    {
      source: '1',
      target: '3'
    },
    {
      source: '1',
      target: '2'
    }
  ]);


  const [notAttend_List, setNotAttend_List] = useState([
    {
      channel: "Not Attend List",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "5",
      status: 'completed',
      isEnabled: true
    },
  ]);

  const [notAttend_Channel, setNotAttend_Channel] = useState([
    {
      channel: "ThankYou Email",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "6",
      status: 'completed',
      isEnabled: true
    },
    {
      channel: "CRM",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "7",
      status: 'completed',
      isEnabled: true
    },
  ]);

  const [notAttend_connectionIds, setNotAttend_connectionIds] = useState([
    {
      source: '5',
      target: '6'
    },
    {
      source: '5',
      target: '7'
    }
  ]);

  const [newsLetterDatas, setNewsLetterDatas] = useState([
    { channel: "NL Subscribers", no_campains: "", expected: '2', reach: '', icon: "", id: "1", status: '', isEnabled: true },
    { channel: "Letter", no_campains: "", expected: '2', reach: '', icon: "", id: "2", status: '', isEnabled: true },
  ]);
  const [newsLetterConnectsIds, setNewsLetterConnectsIds] = useState([
    {
      source: '1',
      target: '2'
    }
  ]);
  const [AnimationMode, ToggleAnimation] = useState(false);

  //tab status
  const [tabLoading, setTabLoading] = useState(false);
  const resetTransformRef = useRef(null);

  //can be used for multiple programs
  const [progressSteps,setprogressSteps]=useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [Header, setHeader] = useState('');
  const handleNextSubStep = () => {
    const nextSubStep = currentSubStep + 1;
    if (nextSubStep < progressSteps[currentStep].subSteps.length) {
        setCurrentSubStep(nextSubStep);
    } else {
        const nextStep = currentStep + 1;
        if (nextStep < progressSteps.length) {
            setCurrentStep(nextStep);
            setCurrentSubStep(0); // Reset sub-step to the first one
        }
    }
  };

  const handlePreviousSubStep = () => {
      const previousSubStep = currentSubStep - 1;
      if (previousSubStep >= 0) {
          setCurrentSubStep(previousSubStep);
      } else {
          const previousStep = currentStep - 1;
          if (previousStep >= 0) {
              setCurrentStep(previousStep);
              setCurrentSubStep(progressSteps[previousStep].subSteps.length - 1); // Set to last sub-step of the previous step
          }
      }
  };
  //lead gen
  const [EventDetails,setEventDetails]=useState({
    eventName:'',
    channelDetails:[],
    industry:null,
    subIndustry:null,
    exBudgets:0,
    exLeads:0
  });
  const [selectedEventType,setselectedEventType]=useState(false);
  const [activeTab,setactiveTab]=useState(0);//overall tab track

  //all Modal states
  const [modalState, setModalState] = useState({
      channelModel:false,
      channelDataModel: false,
      whatsappChannelDataModel: false,
      googleLeadFormModal: false,
      linkedinLeadFormModal: false,
      metaLeadFormModal: false,
      emailChannelDataModel: false,
      pageModal: false,
      formChannelDataModel:false,
      attendModel:false,
      listModel:false,
      crmModel:false,
      CalendlyModal:false,
      LeadFormModal:false,
      QualifyFilterModal:false,
      
      voice_qual:false,
      email:false,
      web_form_label:false,
      lead_calendly_form:false,
      qual_filters:false,
      lead_score:false,
      status_check:false,
      whatsapp:false,
      

  });
  const [accountDetails, setAccountDetails] = useState([]);//all channels account details
  const toggleModal = (modalKey) => {
    setModalState((prevState) => ({
        ...prevState,
        [modalKey]: !prevState[modalKey],
    }));
  };
  const [LeadJourneyTabWiseData,setLeadJourneyTabWiseData]=useState({
    GetLeadTab:{
      cards:[[{name:''},{}],[{}]],//[0]= channels [1]=pages [2]=forms [3]=lists
      connectionIds:[]
    },
    QualifyTab:{
      cards:[],//[0]= lists [1]=followUp [2]=lead score [3]=qualified leads
      connectionIds:[]
    },
    SalesTab:{
      cards:[],//[0]= qualified leads [1]=assign to sales [2]=lead progress [3]=order received
      connectionIds:[]
    },
  });
  const getCardByName = (cardName) => {
    for (const tabName in LeadJourneyTabWiseData) {
      const tab = LeadJourneyTabWiseData[tabName];
      for (const cardGroup of tab.cards) {
        const card = cardGroup.find((card) => card.channel === cardName);
        if (card) {
          return card;
        }
      }
    }
    return null;
  };

  
  const handleUpdateLeadJourneyData = (tab, property, value) => {
    setLeadJourneyTabWiseData(prevState => ({
      ...prevState,
      [tab]: {
        ...prevState[tab],
        [property]: value,
      },
    }));
  };
  const[channelData,setchannelData]=useState({});
  const [socket, setSocket] = useState(null);
  const [cta_links,setcta_links]=useState([]);
  
  const globalFunctions = {
    //logout
    logout,

    //reach datas
    channelDatas,
    setChannelDatas,
    registrationDatas,
    setRegistrationDatas,
    formsDatas,
    setFormsDatas,
    registrationListDatas,
    setRegistrationListDatas,
    connectionIds,
    setConnectionIds,

    //register datas
    register_registrationListDatas,
    setRegister_registrationListDatas,
    register_reminderDatas,
    setRegister_reminderDatas,
    register_attendDatas,
    setRegister_attenDatas,
    register_connectionIds,
    setRegister_connectionIds,

    //attend datas
    attend_List,
    setAttend_List,
    attend_Channel,
    setAttend_Channel,
    attend_connectionIds,
    setAttend_connectionIds,
    notAttend_List,
    setNotAttend_List,
    notAttend_Channel,
    setNotAttend_Channel,
    notAttend_connectionIds,
    setNotAttend_connectionIds,

    //tab status
    tabLoading,
    setTabLoading,

    //RESET ZOOM
    resetTransformRef,

    //journey animation
    AnimationMode,
    ToggleAnimation,

    //newsletter
    newsLetterDatas, 
    setNewsLetterDatas,
    newsLetterConnectsIds, 
    setNewsLetterConnectsIds,
    //lead gen
    EventDetails,
    setEventDetails,
    activeTab,
    setactiveTab,
    progressSteps,
    setprogressSteps,
    currentSubStep, 
    currentStep, 
    handleNextSubStep,
    handlePreviousSubStep,
    Header,
    setHeader,
    selectedEventType,setselectedEventType,
    modalState,setModalState,toggleModal,
    accountDetails, setAccountDetails,
    LeadJourneyTabWiseData,handleUpdateLeadJourneyData,
    cta_links,setcta_links,getCardByName,
    channelData,setchannelData,
    socket, setSocket
  };

  return (
    <GlobalContext.Provider value={globalFunctions}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook for using the context
export const useGlobal = () => useContext(GlobalContext);
