import React, { useState, useEffect } from "react";
import Xarrow, { Xwrapper } from 'react-xarrows';
import { useGlobal } from '../../context/GlobalProvider';
import RenderCardGroup from "./RenderCardGroup.jsx";

const Reach = ({ handleChannelModel, fetchProgramDetails }) => {
    const { channelDatas, registrationDatas, formsDatas, registrationListDatas, connectionIds, AnimationMode, ToggleAnimation } = useGlobal();
    const [renderIndex, setRenderIndex] = useState(0);
    const handleRenderComplete = () => {
        setRenderIndex((prevIndex) => prevIndex + 1);
    };
    // if(renderIndex>=4){
    //     ToggleAnimation(false);   
    // }
    useEffect(() => {
        if (renderIndex >= 4) {
            if(AnimationMode){
                const timer = setTimeout(() => {
                    setRenderIndex((prev) => prev + 1);
                    ToggleAnimation(false);
                  }, 2000);
                  return () => clearTimeout(timer);
            }
            else if(renderIndex<5){
                setRenderIndex((prev) => prev + 1);
            }

        }
      }, [renderIndex]);
    return (
        <Xwrapper>
            <div className='reach-tab'>
                <div className='row main-card-div'>
                    {renderIndex >= 0 && (
                        <RenderCardGroup 
                            CardData={channelDatas} 
                            CardType={'channel-list'} 
                            groupIndex={0} 
                            onRenderComplete={handleRenderComplete}
                            handleChannelModel={handleChannelModel}
                            fetchProgramDetails={fetchProgramDetails}
                            animation={AnimationMode}
                            ToggleAnimation={ToggleAnimation}
                        />
                    )}
                    {renderIndex >= 1 && (
                        <RenderCardGroup 
                            CardData={registrationDatas} 
                            CardType={'registration-page'} 
                            groupIndex={1} 
                            onRenderComplete={handleRenderComplete}
                            handleChannelModel={handleChannelModel}
                            fetchProgramDetails={fetchProgramDetails}
                            animation={AnimationMode}
                            ToggleAnimation={ToggleAnimation}
                        />
                    )}
                    {renderIndex >= 2 && (
                        <RenderCardGroup 
                            CardData={formsDatas} 
                            CardType={'form-list'} 
                            groupIndex={2} 
                            onRenderComplete={handleRenderComplete}
                            handleChannelModel={handleChannelModel}
                            fetchProgramDetails={fetchProgramDetails}
                            animation={AnimationMode}
                            ToggleAnimation={ToggleAnimation}
                        />
                    )}
                    {renderIndex >= 3 && ( 
                        <RenderCardGroup 
                            CardData={registrationListDatas} 
                            CardType={'registration-list'} 
                            groupIndex={3} 
                            onRenderComplete={handleRenderComplete}
                            handleChannelModel={handleChannelModel}
                            fetchProgramDetails={fetchProgramDetails}
                            animation={AnimationMode}
                            ToggleAnimation={ToggleAnimation}
                        />
                    )}
                </div>

                {renderIndex >= 5 &&connectionIds.length && connectionIds.map((connection, index) => (
                    <Xarrow
                        key={index}
                        start={`box-${connection.source}`}
                        end={`box-${connection.target}`}
                        startAnchor='right'
                        endAnchor='left'
                        color="#B3B3B3"
                        strokeWidth={2}
                        headSize={6}
                        curveness={0.1}
                        path={"grid"}
                    />
                ))}
            </div>
        </Xwrapper>
    );
};

export default Reach;
