import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

const BasicSection = ({ newsLetterDetails, setNewsLetterDetails }) => {
    const cmsList = ['WordPress', 'Blogger'];
    const [selectedCms, setSelectedCms] = useState('');
    const [topicsOptions, setTopicsOptions] = useState([]);
    const [selectedBlogTopic, setSelectedBlogTopic] = useState(null);
    const [selectedIndustryTrendsTopic, setSelectedIndustryTrendsTopic] = useState(null);
    const [selectedBlogType, setSelectedBlogType] = useState('');
    const [selectedArticleType, setSelectedArticleType] = useState('');
    const [selectedType, setSelectedType] = useState('');

    const handleCmsChange = (value) => {
        if (cmsList.includes(value)) {
            setSelectedCms(value);
            setNewsLetterDetails({ ...newsLetterDetails, cms: value });
        } else {
            alert('Not configured');
            setSelectedCms('');
            setNewsLetterDetails({ ...newsLetterDetails, url: '' });
        }
    };

    const fetchTopics = async () => {
        const topicPrompt = `Suggest 3 topics on industry trends & latest Blog for ${newsLetterDetails.industry} and ${newsLetterDetails.subIndustry} based on industry trends.`;
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/ai/getTopics`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ prompt: topicPrompt }),
                credentials: 'include',
            });

            const data = await response.json();
            if (data.response) {
                setTopicsOptions(data.response);
            }
        } catch (error) {
            console.error('Error fetching topics:', error);
        }
    };

    useEffect(() => {
        if (newsLetterDetails.subIndustry) {
            fetchTopics();
        }
    }, [newsLetterDetails.subIndustry]);

    const handleSelectTopic = (name, type) => {
        if (type === 'blog') {
            setSelectedBlogTopic(name);
            setNewsLetterDetails({ ...newsLetterDetails, latestBlogPrompt: `Write a short 300-word blog for the industry "${newsLetterDetails.industry}" and sub-industry "${newsLetterDetails.subIndustry}" .Based On ${name}.` });
        }
        if (type === 'industryTrends') {
            setSelectedIndustryTrendsTopic(name);
            setNewsLetterDetails({ ...newsLetterDetails, industryTrendsPrompt: `Provide 2 latest research published in the past 30 days to show industry trends in the industry "${newsLetterDetails.industry}" and sub-industry "${newsLetterDetails.subIndustry}".Based on ${name}` });
        }
    }
    // const handleSelectTopicBoth = (name) => {
    //     const updates = { ...newsLetterDetails };
    
    //     if (selectedBlogType === 'ai') {
    //         updates.latestBlogPrompt = `Write a short 300-word blog for the industry "${newsLetterDetails.industry}" and sub-industry "${newsLetterDetails.subIndustry}". Based On ${name}.`;
    //     }
    
    //     if (selectedArticleType === 'ai') {
    //         updates.industryTrendsPrompt = `Provide 2 latest research published in the past 30 days to show industry trends in the industry "${newsLetterDetails.industry}" and sub-industry "${newsLetterDetails.subIndustry}". Based on ${name}`;
    //     }
    
    //     setSelectedType(name);
    //     setNewsLetterDetails(updates);
    // };
    

    return (
        <div className='Basic-sections'>
            <div className='row'>
                <div className='col-sm-6'>
                    <h6>Blog</h6>
                    <div className="mb-2">
                        <Form.Check
                            inline
                            label="Fetch By URL"
                            name="blogtype"
                            type="radio"
                            id="inline-blog-fetch"
                            value="fetch"
                            checked={selectedBlogType === 'fetch'}
                            onChange={(e) => {
                                setSelectedBlogType(e.target.value);
                                setNewsLetterDetails({
                                    ...newsLetterDetails,
                                    generateByAi: { ...newsLetterDetails.generateByAi, blog: false },
                                });
                            }}
                        />
                        <Form.Check
                            inline
                            label="Generate By AI"
                            name="blogtype"
                            type="radio"
                            id="inline-blog-ai"
                            value="ai"
                            checked={selectedBlogType === 'ai'}
                            onChange={(e) => {
                                setSelectedBlogType(e.target.value);
                                setNewsLetterDetails({
                                    ...newsLetterDetails,
                                    generateByAi: { ...newsLetterDetails.generateByAi, blog: true },
                                });
                            }}
                        />

                        {selectedBlogType === 'fetch' && (
                            <div className='blog-url'>
                                <label>Blog URL</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Paste the URL'
                                    value={newsLetterDetails.url || ''}
                                    onChange={(e) =>
                                        setNewsLetterDetails({ ...newsLetterDetails, url: e.target.value })
                                    }
                                    disabled={newsLetterDetails.generateByAi.blog}
                                />
                                <label>CMS</label>
                                <Form>
                                    {cmsList.map((cms) => (
                                        <Form.Check
                                            inline
                                            key={`inline-${cms}`}
                                            label={cms}
                                            name="cms"
                                            type="radio"
                                            id={`inline-${cms}`}
                                            value={cms}
                                            checked={selectedCms === cms}
                                            onChange={(e) => handleCmsChange(e.target.value)}
                                            disabled={newsLetterDetails.generateByAi.blog}
                                        />
                                    ))}
                                </Form>
                            </div>
                        )}
                        {selectedBlogType === 'ai' && (
                            <>
                                {newsLetterDetails.generateByAi && (
                                    <div className="prompt-section">
                                        <label>Select a topic generated by AI</label>
                                        {topicsOptions.map((name, index) => (
                                            <button
                                                key={index}
                                                className={`btn btn-sm ${selectedBlogTopic === name ? 'btn-gradient' : 'btn-outline-gradient'}`}
                                                onClick={() => handleSelectTopic(name, 'blog')}
                                            >
                                                {name}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className='col-sm-6'>
                    <h6>Industry Trends</h6>
                    <div className="mb-2">
                        <Form.Check
                            inline
                            label="Fetch By URL"
                            name="articletype"
                            type="radio"
                            id="inline-article-fetch"
                            value="fetch"
                            checked={selectedArticleType === 'fetch'}
                            onChange={(e) => {
                                setSelectedArticleType(e.target.value)
                                setNewsLetterDetails({
                                    ...newsLetterDetails,
                                    generateByAi: { ...newsLetterDetails.generateByAi, industryTrends: false },
                                });
                            }}
                        />
                        <Form.Check
                            inline
                            label="Generate By AI"
                            name="articletype"
                            type="radio"
                            id="inline-article-ai"
                            value="ai"
                            checked={selectedArticleType === 'ai'}
                            onChange={(e) => {
                                setSelectedArticleType(e.target.value)
                                setNewsLetterDetails({
                                    ...newsLetterDetails,
                                    generateByAi: { ...newsLetterDetails.generateByAi, industryTrends: true },
                                });
                            }}
                        />
                        {selectedArticleType === 'ai' && (
                            <>
                                {newsLetterDetails.generateByAi && (
                                    <div className="prompt-section">
                                        <label>Select a topic generated by AI</label>
                                        {topicsOptions.map((name, index) => (
                                            <button
                                                key={index}
                                                className={`btn btn-sm ${selectedIndustryTrendsTopic === name ? 'btn-gradient' : 'btn-outline-gradient'}`}
                                                onClick={() => handleSelectTopic(name, 'industryTrends')}
                                            >
                                                {name}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                        {selectedArticleType === 'fetch' && (
                            <>
                                <p>-- Coming soon Please Select Ai  --</p>
                            </>
                        )}
                    </div>
                </div>
                {/* <p>{newsLetterDetails.latestBlogPrompt}</p>
                <p>{newsLetterDetails.industryTrendsPrompt}</p> */}
                {/* <div className='row'>
                    <div className='col-sm-12'>
                        {(selectedArticleType === 'ai' || selectedBlogType === 'ai') && (
                            <>
                                {newsLetterDetails.generateByAi && (
                                    <div className="prompt-section">
                                        <label>Select a topic generated by AI</label>
                                        {topicsOptions.map((name, index) => (
                                            <button
                                                key={index}
                                                className={`btn btn-sm ${selectedType === name ? 'btn-gradient' : 'btn-outline-gradient'}`}
                                                onClick={() => handleSelectTopicBoth(name)}
                                            >
                                                {name}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default BasicSection;
