import React, {useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    // console.log(className);

    return (
        <MuiPagination
            color="success"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}

        />
    );
}
function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}


const DataGridTable = ({rows, columns}) => {
    const [page, setPage] = useState(0);
    return (
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row._id}
            disableSelectionOnClick
            pageSizeOptions={[5, 10, 25]}
            slots={{
                pagination: CustomPagination,
            }}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            initialState={{
                ...rows.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
        />
    )
}

export default DataGridTable