import { useEffect, useRef, useState } from "react";
import CardComp from "./journeyModels/CardComp";

function RenderCardGroup({ CardData, CardType, groupIndex, handleChannelModel, onRenderComplete, animation }) {
    const [renderedCards, setRenderedCards] = useState(0);
    const [currentGroup, setCurrentGroup] = useState(groupIndex);
    const getClassName = (data) => {
        if (data.length === 1) return 'single';
        if (data.length === 2) return 'double';
        if (data.length > 2) return 'multiples';
        return '';
    };
    useEffect(() => {
        if (renderedCards === CardData.length && CardData.length > 0) {
            if (onRenderComplete) {
                onRenderComplete();
            }
        }
    }, [renderedCards]);
    useEffect(() => {
        if (currentGroup === groupIndex && CardData.length > 0 && animation) {
            const intervalId = setInterval(() => {
                setRenderedCards(prevCount => {
                    if (prevCount < CardData.length) {
                        return prevCount + 1;
                    } else {
                        clearInterval(intervalId);
                        setCurrentGroup(prev => prev + 1)
                        // setTimeout(() => , 1000);
                        return prevCount;
                    }
                });
            }, 1000);
            return () => clearInterval(intervalId);
        }
        else {
            setRenderedCards(CardData.length);
        }
    }, []);
    // console.log('this is called',currentGroup,' grp,',renderedCards,'rendered cards',animation);
    return (
        <>
            <div className={`col-sm-3 ${CardType} card-center ${getClassName(CardData)}`}>
                {CardData.slice(0, renderedCards).map((data, index) => (
                    <div className='card-wrap' key={`${index}`} id={`box-${data.id}`}>
                        <CardComp
                            CardData={data}
                            handleChannelModel={handleChannelModel}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
export default RenderCardGroup;