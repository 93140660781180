import React, { useEffect, useState } from 'react'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdOutlineLibraryAdd } from 'react-icons/md'
import { useGlobal } from 'context/GlobalProvider';

function ExpectationCards({formatNumber,editStatus}) {
	const {EventDetails,setEventDetails}=useGlobal();
    const [countEnabled,setcountEnabled]=useState();
    const [flag,setflag]=useState(false);
    useEffect(() => {
        const updateChannelLeadsAndBudgets = () => {
            const { exBudgets, exLeads, channelDetails } = EventDetails;
    
            if (!channelDetails || channelDetails.length === 0 || exBudgets <= 0 || exLeads <= 0) {
                return;
            }
    
            let enabledCount = 0;
            channelDetails.forEach((item) => {
                if (item.enabled) enabledCount += 1;
            });
    
            if (enabledCount > 0) {
                const LeadsPerChannel = exLeads / enabledCount;
                let difference = 0;
    
                channelDetails.forEach((item) => {
                    if (item.enabled) {
                        item.leads = Math.round(LeadsPerChannel);
                        difference += item.leads;
                    }
                });
    
                difference -= exLeads;
    
                if (difference !== 0) {
                    for (let i = 0; Math.abs(difference) > 0; i++) {
                        const item = channelDetails[i % channelDetails.length];
                        if (item.enabled) {
                            if (difference > 0) {
                                item.leads -= 1;
                                difference -= 1;
                            } else {
                                item.leads += 1;
                                difference += 1;
                            }
                        }
                    }
                }
    
                setEventDetails((prev) => ({
                    ...prev,
                    channelDetails: [...channelDetails],
                }));
            }
        };
        console.log(flag);
        
        if(!editStatus || !flag){
            updateChannelLeadsAndBudgets();
            if(EventDetails.exLeads>0 && EventDetails.exBudgets>0){
                setflag(true);
                console.log('true');
                
            }
        }
    }, [EventDetails.exLeads,EventDetails.exBudgets,countEnabled]);
    
    useEffect(() => {
        const updateChannelBudgets = () => {
            const { exBudgets, exLeads, channelDetails } = EventDetails;
    
            if (!channelDetails || channelDetails.length === 0 || exBudgets <= 0 || exLeads <= 0) {
                return;
            }
    
            channelDetails.forEach((item) => {
                if (item.enabled) {
                    item.budget = item.leads * (exBudgets / exLeads);
                    item.costPerLead = item.budget / item.leads;
                }
            });
    
            setEventDetails((prev) => ({
                ...prev,
                channelDetails: [...channelDetails],
            }));
        };
    
        updateChannelBudgets();
    }, [EventDetails.exBudgets,EventDetails.exLeads,countEnabled]);
    
    const toggleEnabled = (name) => {
        let count=0;
        setEventDetails((prev) => {
            const updatedChannelDetails = prev.channelDetails.map((item) => {
                if (item.name === name) {
                    count+= item.enabled? 1 : 0 ;
                    return {
                        ...item,
                        enabled:!item.enabled
                    };
                }
                count+= item.enabled? 1 : 0 ;
                return item;
            });
            return { ...prev, channelDetails: updatedChannelDetails };
        });
        setcountEnabled(count);
        setflag(false);
    };
	
    const calculatecostPerLead = (budget, leads) => {
        return leads > 0 ? (budget / leads) : 0;
    };
	
    const updateleads = (name, value) => {
        setEventDetails((prev) => {
            let exleadUpdate=0,exLeads=prev.exLeads;
            const updatedChannelDetails = prev.channelDetails.map((item) => {
                if (item.name === name) {
                    const difference=item.leads-value;
                    exleadUpdate+=exLeads-difference;
                    console.log(exleadUpdate);
                    
                    return {
                        ...item,
                        leads: value,
                        costPerLead: calculatecostPerLead(item.budget, value),
                    };
                }
                return item;
            });
            return { ...prev, channelDetails: updatedChannelDetails,exLeads:exleadUpdate};
        });
        // console.log(name,value);
        
    };
	
    return (
        <div className="row mb-3">
            {EventDetails.channelDetails.map((item, index) => {
                if(!item.enabled)
                    return null;
                else
                return (
                    <div className={`col-md-2 p-2`} key={index}>
                        <div className={`card h-100`} style={{borderRadius:'12px',boxShadow:' 0px 0px 25px 0px #00000014'}}>
                        <div className="card-body p-2 position-relative">
                            <div className='d-flex justify-content-start align-items-center mb-2'>
                                <img src={item.logo} height={25} width={25} alt={item.name}></img>
                                <div className='text-start ms-2'>
                                    <div className='fw-bolder'>{item.displayName}</div>
                                </div>                        
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor={`leads-${item.name}`} className='mb-1'>No of leads</label>
                                <input
                                    type="number"
                                    className="form-control p-1 fw-bolder"
                                    id={`leads-${item.name}`}
                                    value={item.leads}
                                    min={0}
                                    onChange={(e) => updateleads(item.name, parseInt(e.target.value))}
                                    style={{width:"70px"}}
                                    disabled={!editStatus}
                                />
                            </div>
                            <p className="card-text">Cost/Lead: <span className='fw-bolder text-success'>{formatNumber(item?.costPerLead.toFixed(2))} $</span></p>

                            <div className="btn-group position-absolute" style={{top:0,right:0}}>
                                <button className="btn btn-link btn-sm " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <BsThreeDotsVertical></BsThreeDotsVertical>
                                </button>
                                <ul className="dropdown-menu py-1" style={{minWidth:0,fontSize:'12px'}}>
                                    <li>
                                        <button
                                        className="dropdown-item"
                                        onClick={() => toggleEnabled(item.name)}
                                        >
                                        {item.enabled ? 'Delete' : ''}
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                );
            }
            )}
            {EventDetails.channelDetails.some(item => !item.enabled) && (
            <div className="col-md-2 p-2">
                <div className="card position-relative border border-2 h-100 bg-secondary border-success text-success border-dashed" style={{borderRadius:'12px',boxShadow:' 0px 0px 25px 0px #00000014'}}>
                    <div className="card-body text-center p-2 d-flex flex-column justify-content-center">
                        <button className="btn btn-link text-success">
                            <MdOutlineLibraryAdd size={"40px"} color=""></MdOutlineLibraryAdd> 
                            <div className='text-nowrap'>Add Goals</div>
                        </button>
                        <div className="form-group mt-3">
                        <FormControl fullWidth>
                            <InputLabel id="media-select-label" style={{top:'-14px'}}>Choose</InputLabel>
                            <Select style={{height:'25px'}}
                                labelId="media-select-label"
                                value={''}
                                onChange={(e)=>toggleEnabled(e.target.value)}
                                label="Choose"
                                sx={{
                                    '& .MuiFormControl-root': {
                                        '& .MuiInputLabel-root':{
                                            
                                        },
                                    '& .MuiSelect-select': {
                                        paddingTop: '0px',   // Adjust vertical padding
                                        paddingBottom: '0px', // Adjust vertical padding
                                    },
                                    },
                                }}
                                >
                                {EventDetails.channelDetails
                                .filter(item => !item.enabled)
                                .map((item, index) => (
                                    <MenuItem key={index} value={item.name}>
                                    <img src={item.logo} style={{height:'25px',width:'25px',marginRight:'5px'}} alt=''></img>
                                    {item.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    )
}

export default ExpectationCards