import axios from 'axios';
import { useGlobal } from 'context/GlobalProvider';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi';
import Select from 'react-select';

function LeadScoreModal({ programId }) {
    const { toggleModal, modalState, channelData } = useGlobal();
    const actionFields = [
        { value: 'email_reads', label: 'Email Reads' },
        { value: 'email_clicks', label: 'Email Clicks' },
        { value: 'whatsapp_reads', label: 'WhatsApp Reads' },
    ];
    const [saveData,setsaveData]=useState({type:{value:"voice_qual",label:"Voice Qualification"},exscore:null,selectedActions:[]});
    useEffect(()=>{
        if(channelData.name==='lead_score'&& channelData.selectedData.selectedActions)
            setsaveData(channelData.selectedData);
    },[channelData.selectedData]);
    const handleMultiSelectChange = (selectedOptions) => {
        // Ensure each action has a `points` field initialized
        const updatedActions = selectedOptions.map(option => ({
            ...option,
            points: saveData.selectedActions.find(action => action.value === option.value)?.points,
        }));
        setsaveData((prev)=>{
            return {...prev,selectedActions:updatedActions};
        });
    };
    const handleSelect = (selectedOption) => {
        console.log(selectedOption);
        setsaveData((prev)=>({...prev,type:selectedOption}))
    }
    const handlePointChange = (index, value) => {
        const updatedActions = [...saveData.selectedActions];
        updatedActions[index].points = parseInt(value, 10);
        setsaveData((prev)=>{
            return {...prev,selectedActions:updatedActions};
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedData = {
            programId,
            selectedChannel: channelData.name,
            builder: channelData.builder,
            saveData: saveData,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/leadgen/saveSelectedChannel`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });

            if (response.ok) {
                await response.json();
                toggleModal("lead_score");
            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };

    return (
        <Modal
            show={modalState.lead_score}
            size="xl"
            aria-labelledby="lead-score-modal"
            onHide={() => toggleModal('lead_score')}
        >
            <Modal.Header closeButton>
                <Modal.Title id="lead-score-modal">Lead Score Module</Modal.Title>
            </Modal.Header>
            {modalState.lead_score && saveData &&(
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 col-md-6">
                            <Form.Label>Select Actions</Form.Label>
                            <Select
                                options={actionFields}
                                isMulti
                                value={saveData.selectedActions||[]}
                                onChange={handleMultiSelectChange}
                                placeholder="Choose actions"
                            />
                        </Form.Group>
                        {saveData?.selectedActions.length > 0 && (
                            <div>
                                <h5>Selected Actions</h5>
                                {saveData.selectedActions.map((action, index) => (
                                    <div key={action.value} className="row align-items-center mb-2">
                                        <div className='col-md-2'>
                                            <span>{action.label}</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                value={action.points}
                                                onChange={(e) => handlePointChange(index, e.target.value)}
                                                placeholder="Points"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <Form.Group className="mb-3 col-md-6">
                            <Form.Label>Score required for the next step</Form.Label>
                            <Form.Control
                                    type="number"
                                    min="0"
                                    value={saveData.exscore}
                                    onChange={(e) => setsaveData((prev)=>({...prev,exscore:e.target.value}))}
                                    placeholder="Points"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6">
                            <Form.Label>Select next step</Form.Label>
                            <Select
                                options={[{value:"voice_qual",label:"Voice Qualification"},{value:"qualified_leads_list_label",label:"Qualify Lead"}]}
                                value={saveData.type}
                                onChange={handleSelect}
                                placeholder="Choose actions"
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Form>
                </Modal.Body>
            )}
        </Modal>
    );
}

export default LeadScoreModal;
